import React from "react";

export default function NewVisaIcon(props) {
  return (
    <svg
      width="90"
      height="29"
      viewBox="0 0 90 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.7728 28.4814H31.5625L36.0664 0.594238H43.2767L38.7728 28.4814Z"
        fill="#1336CC"
      />
      <path
        d="M64.9057 1.27497C62.8426 0.480361 60.6494 0.0776719 58.4386 0.0875809C51.3184 0.0875809 46.3027 3.88517 46.272 9.31437C46.2126 13.3208 49.8628 15.544 52.5917 16.889C55.382 18.2545 56.33 19.141 56.33 20.3693C56.3013 22.2384 54.078 23.0983 52.0001 23.0983C49.6608 23.1617 47.3415 22.6522 45.2443 21.614L44.2944 21.1677L43.2852 27.4281C45.849 28.4223 48.5768 28.9257 51.3266 28.9123C58.9012 28.9123 63.8146 25.1741 63.876 19.3887C63.9046 16.2135 61.9781 13.7814 57.8223 11.7935C55.3001 10.5181 53.7566 9.65625 53.7566 8.35218C53.7853 7.1648 55.0627 5.94876 57.9104 5.94876C59.7587 5.89506 61.5953 6.2604 63.2823 7.0174L63.9353 7.31425L64.916 1.29135L64.9057 1.27497Z"
        fill="#1336CC"
      />
      <path
        d="M74.4894 18.6012C75.0831 16.9982 77.3678 10.7992 77.3678 10.7992C77.337 10.8586 77.9594 9.16146 78.3176 8.13786L78.8212 10.5413C78.8212 10.5413 80.1867 17.2172 80.4836 18.6114L74.4894 18.6012ZM83.3906 0.585685H77.812C77.0149 0.48951 76.2087 0.665739 75.5245 1.08573C74.8402 1.50572 74.3181 2.14477 74.043 2.89903L63.332 28.4728H70.9067L72.4197 24.2904H81.6771C81.8818 25.2689 82.5369 28.4728 82.5369 28.4728H89.2129L83.3947 0.585685H83.3906Z"
        fill="#1336CC"
      />
      <path
        d="M25.535 0.594238L18.4722 19.6108L17.7004 15.7539C15.9433 10.7549 12.3896 6.58817 7.73047 4.06426L14.2099 28.4527H21.8358L33.1691 0.594238H25.535Z"
        fill="#1336CC"
      />
      <path
        d="M11.9156 0.59404H0.314048L0.195312 1.15703C9.24604 3.47038 15.2382 9.04904 17.701 15.7557L15.1789 2.93811C15.0247 2.21108 14.6047 1.56779 14.0011 1.13421C13.3975 0.70063 12.6538 0.507998 11.9156 0.59404Z"
        fill="#FAA61A"
      />
    </svg>
  );
}

export function NewVisaIconTwo(props) {
  return (
    <svg
      width="62"
      height="56"
      viewBox="0 0 62 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.1875"
        y="0.612305"
        width="60.8755"
        height="55"
        rx="12.58"
        fill="white"
      />
      <path
        d="M26.7068 37.3446H21.9453L24.9196 18.9287H29.681L26.7068 37.3446Z"
        fill="#1336CC"
      />
      <path
        d="M43.9729 19.379C42.6105 18.8542 41.1622 18.5883 39.7022 18.5948C35.0002 18.5948 31.688 21.1027 31.6677 24.688C31.6285 27.3337 34.039 28.8019 35.8411 29.6901C37.6838 30.5918 38.3098 31.1772 38.3098 31.9883C38.2908 33.2227 36.8226 33.7905 35.4504 33.7905C33.9056 33.8324 32.374 33.4959 30.9891 32.8103L30.3618 32.5156L29.6953 36.6498C31.3884 37.3063 33.1897 37.6388 35.0056 37.6299C40.0077 37.6299 43.2524 35.1613 43.2929 31.3408C43.3119 29.2439 42.0397 27.6379 39.2952 26.3251C37.6297 25.4829 36.6104 24.9137 36.6104 24.0526C36.6293 23.2684 37.4729 22.4654 39.3534 22.4654C40.574 22.4299 41.7868 22.6712 42.9009 23.1711L43.3321 23.3671L43.9797 19.3898L43.9729 19.379Z"
        fill="#1336CC"
      />
      <path
        d="M50.2977 30.8191C50.6897 29.7606 52.1985 25.6669 52.1985 25.6669C52.1782 25.7061 52.5892 24.5854 52.8258 23.9094L53.1583 25.4966C53.1583 25.4966 54.0601 29.9052 54.2561 30.8259L50.2977 30.8191ZM56.1758 18.9222H52.4918C51.9655 18.8587 51.4331 18.9751 50.9812 19.2524C50.5294 19.5298 50.1846 19.9518 50.0029 20.4499L42.9297 37.3381H47.9318L48.9309 34.5761H55.0443C55.1795 35.2223 55.612 37.3381 55.612 37.3381H60.0207L56.1785 18.9222H56.1758Z"
        fill="#1336CC"
      />
      <path
        d="M17.9647 18.9287L13.3006 31.4867L12.7909 28.9397C11.6305 25.6385 9.28377 22.8869 6.20703 21.2202L10.4859 37.3257H15.5218L23.006 18.9287H17.9647Z"
        fill="#1336CC"
      />
      <path
        d="M8.97413 18.9293H1.31278L1.23438 19.301C7.21123 20.8287 11.1683 24.5127 12.7947 28.9416L11.1291 20.4772C11.0273 19.9971 10.7499 19.5723 10.3513 19.286C9.95274 18.9997 9.46161 18.8724 8.97413 18.9293Z"
        fill="#FAA61A"
      />
    </svg>
  );
}

export function NewMasterIconTwo(props) {
  return (
    <svg
      width="61"
      height="56"
      viewBox="0 0 61 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0625"
        y="0.612305"
        width="60.8755"
        height="55"
        rx="12.58"
        fill="white"
      />
      <path
        d="M36.4037 17.2725H24.6445V38.9325H36.4037V17.2725Z"
        fill="#EA560D"
      />
      <path
        d="M25.383 28.0942C25.3778 26.011 25.8355 23.9539 26.7213 22.079C27.6071 20.204 28.8979 18.5605 30.4956 17.2729C28.5135 15.6748 26.1327 14.6808 23.6253 14.4042C21.1179 14.1277 18.5853 14.5799 16.3169 15.7092C14.0485 16.8385 12.1359 18.5993 10.7977 20.7902C9.45962 22.9811 8.75 25.5137 8.75 28.0985C8.75 30.6833 9.45962 33.216 10.7977 35.4069C12.1359 37.5978 14.0485 39.3585 16.3169 40.4878C18.5853 41.6171 21.1179 42.0693 23.6253 41.7928C26.1327 41.5163 28.5135 40.5222 30.4956 38.9242C28.8982 37.6344 27.6077 35.9891 26.722 34.1129C25.8363 32.2366 25.3784 30.1785 25.383 28.0942Z"
        fill="#E30613"
      />
      <path
        d="M50.9712 36.6386V36.1932H51.1501V36.0972H50.707V36.1932H50.8775V36.6386H50.9712ZM51.8233 36.6386V36.0972H51.687L51.5336 36.464L51.3802 36.0972H51.2439V36.6386H51.3461V36.2282L51.491 36.5775H51.5932L51.7381 36.2282V36.6386H51.8233Z"
        fill="#F59E00"
      />
      <path
        d="M52.2537 28.0955C52.2593 30.683 51.5538 33.2197 50.2179 35.4151C48.882 37.6106 46.9697 39.3759 44.7002 40.509C42.4307 41.642 39.8957 42.0968 37.3856 41.8214C34.8756 41.546 32.4919 40.5515 30.5078 38.9517C32.1093 37.6648 33.4044 36.0215 34.295 34.146C35.1855 32.2705 35.6483 30.2119 35.6483 28.1261C35.6483 26.0403 35.1855 23.9817 34.295 22.1062C33.4044 20.2307 32.1093 18.5873 30.5078 17.3004C32.4896 15.7026 34.8701 14.7084 37.3772 14.4317C39.8842 14.155 42.4165 14.6069 44.6848 15.7356C46.9532 16.8644 48.8659 18.6245 50.2043 20.8148C51.5427 23.0051 52.2529 25.5373 52.2537 28.1217V28.0955Z"
        fill="#F59E00"
      />
    </svg>
  );
}

export function NewAmexIconTwo(props) {
  return (
    <svg
      width="63"
      height="56"
      viewBox="0 0 63 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.9375"
        y="0.612305"
        width="60.8755"
        height="55"
        rx="12.58"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.56565 20.1968L0.625 36.008H8.93393L9.964 33.487H12.3185L13.3486 36.008H22.4944V34.0839L23.3093 36.008H28.0402L28.8552 34.0432V36.008H47.876L50.1888 33.5525L52.3544 36.008L62.1239 36.0283L55.1613 28.1465L62.1239 20.1968H52.506L50.2546 22.6069L48.1571 20.1968H27.465L25.6881 24.2778L23.8696 20.1968H15.578V22.0554L14.6557 20.1968H7.56565ZM9.17143 22.4418H13.2216L17.8253 33.1634V22.4418H22.2621L25.818 30.1291L29.0951 22.4418H33.5097V33.7873H30.8235L30.8016 24.897L26.8854 33.7873H24.4824L20.5443 24.897V33.7873H15.0182L13.9705 31.2438H8.31048L7.26499 33.7851H4.30416L9.17143 22.4418ZM46.8909 22.4418H35.9683V33.7806H46.7218L50.1878 30.0228L53.5285 33.7806H57.0208L51.9449 28.1441L57.0208 22.4418H53.6801L50.2317 26.1565L46.8909 22.4418ZM11.1461 24.3612L9.28135 28.8923H13.0087L11.1461 24.3612ZM38.6691 26.8627V24.7915V24.7896H45.4845L48.4583 28.1018L45.3527 31.4322H38.6691V29.1711H44.6278V26.8627H38.6691Z"
        fill="#1F72CD"
      />
    </svg>
  );
}
