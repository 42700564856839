import ReactDOM from "react-dom";

const TipModal = ({ onClose, isSuccess }) => {
  const successEmoji = "✅";
  const failureEmoji = "❌";
  return ReactDOM.createPortal(
    <>
      <div
        className=" flex justify-center    fixed  overflow-hidden top-0 w-[100vw] h-[100vh] bg-black-400 opacity-9 z-50 "
        onClick={(e) => {
          if (e.currentTarget !== e.target) {
            return;
          }
          onClose();
        }}
      >
        <div className="w-[340px] h-[240px] mt-[164px] p-[40px] bg-[#fff] flex flex-col  items-center font-sans rounded-[15px] text-[20px] font-[700]">
          <span>{isSuccess ? successEmoji : failureEmoji}</span>
          <p>
            {isSuccess
              ? "Tip has been succesfully send"
              : "Tip has been failed"}{" "}
          </p>
          <button
            className="w-[223px] h-[50px] text-[#fff] bg-[#170738] rounded-[16px] mt-[40px]"
            onClick={onClose}
          >
            Ok
          </button>
        </div>
      </div>
    </>,
    document.getElementById("modal-root")
  );
};

export default TipModal;
