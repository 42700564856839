import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useQueryString } from "hooks";
import { postTransactionAction } from "api";
import Loader from "components/Loader";

const PaymentFailedPage = () => {
  const [loading, setLoading] = useState(true);
  const { parseQuery } = useQueryString();
  const {
    acquiringTransactionId: transactionId = "",
    receiverId,
    receiverType,
    integratorOrderId: orderId,
    statusCode = "",
    exceptionGroup = "",
  } = parseQuery;

  const url = localStorage.getItem("url");

  useEffect(() => {
    (async () => {
      const integratorOrderId =
        localStorage.getItem("integratorOrderId") || orderId;
      if (integratorOrderId) {
        try {
          const { redirectRequired, failRedirectUri } =
            await postTransactionAction(integratorOrderId);
          if (redirectRequired && failRedirectUri) {
            window.location.href = failRedirectUri;
          }
        } finally {
          setLoading(false);
        }
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    // <Navigate
    //   to={`/pay?acquiringTransactionId=${transactionId}&receiverId=${receiverId}&receiverType=${receiverType}&status=FAIL`}
    // />
    <Navigate
      to={`${url}&status=FAIL&exceptionGroup=${exceptionGroup}&statusCode=${statusCode}`}
    />
  );
};

export default PaymentFailedPage;
