import { useTranslation, Trans } from "react-i18next";
import img from "../../../assets/images/heroImage.png";
import imgTwo from "../../../assets/images/eng-hero.png";
import Button from "../Button/Button";

const Hero = ({ openContactModel }) => {
  const { i18n } = useTranslation();

  return (
    <section
      style={{
        background:
          "radial-gradient(143.13% 447.78% at 70.36% 161.31%, #170738 0%, #290C64 100%)  ",
      }}
      className=" sm:mt-0 mt-5 grid grid-cols-1 md:grid-cols-2 sm:p-[24px] m-auto  sm: rounded-b-[32px] md:h-[620px]  md:rounded-[32px]   md:py-[40px] md:px-[60px] max-w-[1440px]  "
    >
      <div className="mt-[69px]">
        <div className="sm: text-center md:text-left">
          <p className=" sm:text-[24px]   md:text-[36px] text-[#ffffff] font-bold">
            <Trans i18nKey="header.title" />
          </p>
          <p className="sm:text-[24px] md:text-[36px] font-[700] text-[#8CC63F]">
            <Trans i18nKey="header.titleNext" />
          </p>
        </div>

        <p className="sm: text-center md:text-left  text-[14px] text-[#ffffff]  mt-4 text">
          <Trans i18nKey="header.description" />
        </p>
        <div className="flex gap-4 mt-[54px] sm:justify-center md:justify-start">
          <Button
            className=" border-[1px]  border-[#6C63FF] solid bg-[#6C63FF] text-[#fff]  hover:text-[#6C63FF] hover:bg-opacity-0 px-[40px] py-[10px] "
            onClick={openContactModel}
          >
            <Trans i18nKey="buttons.request" />
          </Button>
          <a href="#price">
            <Button className=" border-[#6C63FF1A] solid bg-[#6C63FF1A] text-[#fff]   px-[40px] py-[10px] border-[1px] hover:border-[#6C63FF] hover:text-[#6C63FF]">
              <Trans i18nKey="buttons.price" />
            </Button>
          </a>
        </div>
      </div>

      <img
        src={i18n.language === "en" ? imgTwo : img}
        className="m-auto"
        alt=""
      />
    </section>
  );
};

export default Hero;
