import { Trans, useTranslation } from "react-i18next";

import { Container } from "../LandingPage";
import GeorgianBankIcon from "assets/icons/GeorgianBankIcon";
import TbcBankIcon from "assets/icons/TbcBankIcon";
import CredoBankIcon from "assets/icons/CredoBankIcon";
import ProcBankIcon from "assets/icons/ProcBankIcon";
import BasisBankIcon from "assets/icons/BasisBankIcon";
import TeraBankIcon from "assets/icons/TeraBankIcon";
import LibertyBankIcon from "assets/icons/LibertyBankIcon";
import CityPayIcon from "assets/icons/CItyPayIcon";
import BinIcon from "assets/icons/BinIcon";
import BitIcon from "assets/icons/BitcIcon";
import EtherumIcon from "assets/icons/EtherumIcon";
import TetherIcon from "assets/icons/TetherIcon";
import LitIcon from "assets/icons/LitIcon";
import XIcon from "assets/icons/XIcon";

import { APayIconTwo } from "assets/icons/APayIcon";
import { NewGpayIconTwo } from "assets/icons/NewGpayIcon";

import {
  NewVisaIconTwo,
  NewMasterIconTwo,
  NewAmexIconTwo,
} from "assets/icons/NewVisaIcon";

const Price = ({ openContactModel }) => {
  return (
    <section
      id="price"
      style={{
        background: "#6C63FF1A",
        // background:
        //   "radial-gradient(81.18% 991.71% at 93.23% 14.81%, #280C62 0%, #170738 ",
      }}
      className="relative font-display sm:px-[20px] sm:py-[40px] md:px-0  md:py-[70px] mt-[50px]"
    >
      <h2
        className="text-center font-bold text-[38px] text-[#6C63FF]"
        style={{ fontVariant: "all-petite-caps" }}
      >
        <Trans i18nKey={"price.pricing"} />
      </h2>
      <Container>
        <div className="w-[100%]  mt-[40px] flex justify-center  sm:flex-col md:flex-row  gap-[20px]">
          <div className="border-[2px] relative border-[#8CC63F]   sm:h-[90px] md:h-auto    bg-[#FFFFFF] rounded-[16px]  sm:p-[20px]  md:p-[36px] flex sm:flex-row md:flex-col justify-between items-center  ">
            <div className="absolute inset-0 bg-[#8CC63F1A] rounded-[16px]"></div>
            <h2
              className="md:text-center md:text-[32px] sm:text-[24px] text-[#170738] font-bold"
              style={{ fontVariant: "all-petite-caps", lineHeight: "30px" }}
            >
              <Trans i18nKey={"price.fixedCommision"} />
            </h2>
            <p className="text-[#8CC63F] text-center font-bold sm:text-[50px] md:text-[150px] ">
              <span>0</span>
              <span className="sm:text-[30px] md:text-[90px]">₾</span>
            </p>
            <p className="text-center text-[14px]   sm:hidden md:block">
              <Trans i18nKey={"price.fixedCommisionDesc"} />
            </p>
          </div>
          <div className="border-[2px]  sm:h-[90px] md:h-auto bg-white rounded-[16px]  sm:p-[20px]  md:p-[36px] flex sm:flex-row md:flex-col justify-between items-center  ">
            <div className="flex flex-col justify-center md:items-center  ">
              <h2
                className="md:text-center md:text-[32px] sm:text-[24px] text-[#170738] font-bold"
                style={{ fontVariant: "all-petite-caps", lineHeight: "30px" }}
              >
                <Trans i18nKey={"price.mobilebank"} />
              </h2>
              <p className="text-gray-400 sm:text-[12px] md:text-[16px] ">
                <Trans i18nKey={"price.openBanking"} />
              </p>
            </div>
            <div className="flex flex-col justify-center md:items-center sm:items-end ">
              <p className="text-[#6C63FF]  text-center font-bold sm:text-[24px] md:text-[50px] ">
                1%
              </p>
              <p className="text-gray-400 sm:text-[12px] md:text-[16px]">
                <Trans i18nKey={"price.commision"} />
              </p>
            </div>
            <div className="sm:hidden md:flex flex-col items-center justify-center">
              <div className="flex gap-[20px] ">
                <GeorgianBankIcon />
                <TbcBankIcon />
                <CredoBankIcon />
                <ProcBankIcon />
              </div>
              <div className="flex gap-[20px]">
                <BasisBankIcon />
                <TeraBankIcon />
                <LibertyBankIcon />
              </div>
            </div>
          </div>
          <div className="border-[2px] sm:h-[90px] md:h-auto bg-white rounded-[16px]  sm:p-[20px]  md:p-[36px] flex sm:flex-row md:flex-col justify-between items-center  ">
            <div className="flex flex-col justify-center md:items-center  ">
              <h2
                className="md:text-center md:text-[32px] sm:text-[24px] text-[#170738] font-bold"
                style={{ fontVariant: "all-petite-caps", lineHeight: "30px" }}
              >
                <Trans i18nKey={"price.cryptoCurrency"} />
              </h2>

              <CityPayIcon className={"mt-[4px]"} />
            </div>
            <div className="flex flex-col justify-center md:items-center sm:items-end ">
              <p className="text-[#6C63FF]  text-center font-bold sm:text-[24px] md:text-[50px] ">
                1.5%
              </p>
              <p className="text-gray-400 sm:text-[12px] md:text-[16px]">
                <Trans i18nKey={"price.commision"} />
              </p>
            </div>
            <div className="sm:hidden md:flex flex-col items-center justify-center">
              <div className="flex gap-[20px] ">
                <BinIcon />
                <BitIcon />
                <EtherumIcon />
              </div>
              <div className="flex gap-[20px]">
                <TetherIcon />
                <LitIcon />
                <XIcon />
              </div>
            </div>
          </div>
          <div className="border-[2px] sm:h-[90px] md:h-auto bg-white rounded-[16px]  sm:p-[20px]  md:p-[36px] flex sm:flex-row md:flex-col justify-between items-center  ">
            <div className="flex flex-col justify-center md:items-center  ">
              <h2
                className="md:text-center md:text-[32px] sm:text-[24px] text-[#170738] font-bold"
                style={{ fontVariant: "all-petite-caps", lineHeight: "30px" }}
              >
                <Trans i18nKey={"price.bankCards"} />
              </h2>
            </div>
            <div className="flex flex-col justify-center md:items-center sm:items-end ">
              <p className="text-[#6C63FF]  text-center font-bold sm:text-[24px] md:text-[50px] ">
                2.5%
              </p>
              <p className="text-gray-400 sm:text-[12px] md:text-[16px]">
                <Trans i18nKey={"price.commision"} />
              </p>
            </div>
            <div className="sm:hidden gap-[20px] md:flex flex-col items-center justify-center">
              <div className="flex gap-[20px] items-center justify-center ">
                <APayIconTwo />
                <NewGpayIconTwo />
              </div>
              <div className="flex gap-[20px]">
                <NewVisaIconTwo />
                <NewMasterIconTwo />
                <NewAmexIconTwo />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#6C6BFB0D] sm:gap-[35px] md:gap-0 sm:text-center md:text-left flex sm:flex-col md:flex-row items-center justify-between rounded-[16px] md:mt-[40px] sm:mt-[20px] py-[40px] px-[20px] md:px-[60px]">
          <div>
            <p className="font-[800] sm:text-[24px] md:text-[30px] text-[#170738]">
              <Trans i18nKey={"price.haveQuestions"} />
            </p>
            <p className=" text-[18px] sm:mt-[20px] md:mt-0 text-[#170738] opacity-50">
              <Trans i18nKey={"price.leaveNumber"} />
            </p>
          </div>
          <button
            className="rounded-[12px] px-[40px] py-[10px]  bg-[#6C63FF1A] text-purple h-auto  hover:bg-[#6C6BFB0D] hover:border-[1px] hover:border-purple transition-all duration-300"
            onClick={() => openContactModel()}
          >
            <Trans i18nKey={"buttons.reqCall"} />
          </button>
        </div>
        {/* <div className="w-[100%]  sm:py-[50px] sm:px-[16px] md:px-0    md:py-[90px] sm:mt-[72px] md:mt-[90px] flex-wrap  flex items-center sm:justify-center md:justify-between  m-auto  "> */}
        {/* <div className="w-[100%]  sm:py-[50px] sm:px-[16px] md:px-0     flex-wrap  flex items-center sm:justify-center md:justify-between  m-auto  ">
          <svg
            style={{ position: "absolute", top: "0", left: "0" }}
            xmlns="http://www.w3.org/2000/svg"
            width="306"
            height="272"
            viewBox="0 0 306 272"
            fill="none"
          >
            <g opacity="0.1">
              <path
                d="M-12.514 -132.944C-33.4068 -132.944 -53.724 -122.484 -64.8899 -103.733C-81.5811 -75.8018 -71.6815 -40.0808 -42.7884 -23.9452L124.699 69.5303L-42.7884 163.006C-71.6815 179.142 -81.5811 214.863 -64.8899 242.794C-48.1987 270.725 -11.2478 280.295 17.6452 264.16L275.783 120.107C294.489 109.647 306 90.3954 306 69.5303C306 48.6653 294.489 29.3025 275.783 18.8978L17.6452 -125.155C8.1485 -130.441 -2.2691 -133 -12.514 -133V-132.944Z"
                fill="white"
              />
            </g>
          </svg>
          <div className="  md:text-left sm:text-center">
            <h2 className="font-[700]  sm:text-[24px]  md:text-[36px] text-[#FFFFFF]">
              <span className="text-[#8CC63F]">
                <Trans i18nKey={"tenTime"} />
              </span>
              <span className="ms-[15px]">
                <Trans i18nKey={"priceDesc"} />
              </span>
            </h2>
            <p className="sm:text-[14px] md:text-[16px] text-[#FFFFFF] mt-[22px]">
              <Trans i18nKey={"priceDescTwo"} />
            </p>
          </div>
          <div className="flex gap-[20px] justify-center sm:mt-[50px] md:mt-0 ">
            {i18n.language === "ge" ? (
              <img src="/svg/priceT.svg" className="w-[45%] md:w-auto" />
            ) : (
              <img src="/svg/perMonth.svg" className="w-[45%] md:w-auto" />
            )}
            {i18n.language === "ge" ? (
              <img src="/svg/priceO.svg" className="w-[45%] md:w-auto" />
            ) : (
              <img
                src="/svg/perTransaction.svg"
                className="w-[45%] md:w-auto"
              />
            )}
          </div>
        </div> */}
      </Container>
    </section>
  );
};

export default Price;
