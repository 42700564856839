export default function LitIcon(props) {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2575_3498)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.3085 13.7661L20.5991 31.3652L16.8756 32.8318L15.5312 37.8427L19.32 36.5635L16.8104 46.9519L43.9424 47.0089L45.5883 40.3522H29.0972L31.232 32.343L35.6969 30.4527L36.9761 25.564L32.6415 27.0306L36.0636 13.9535L25.3085 13.7661Z"
          fill="#325A98"
        />
      </g>
      <defs>
        <clipPath id="clip0_2575_3498">
          <rect
            width="60"
            height="60"
            fill="white"
            transform="translate(0.5 0.387695)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
