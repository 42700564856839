import React from "react";

export default function NewMasterIcon(props) {
  return (
    <svg
      width="67"
      height="43"
      viewBox="0 0 67 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.0529 5.08618H24.2461V37.8859H42.0529V5.08618Z"
        fill="#EA560D"
      />
      <path
        d="M25.363 21.4729C25.3552 18.3183 26.0482 15.2033 27.3897 12.3641C28.7311 9.52486 30.6856 7.03599 33.1051 5.08622C30.1036 2.66632 26.4983 1.16098 22.7014 0.742259C18.9045 0.323539 15.0693 1.00833 11.6343 2.7184C8.19923 4.42848 5.30297 7.09481 3.27667 10.4125C1.25036 13.7302 0.175781 17.5653 0.175781 21.4795C0.175781 25.3936 1.25036 29.2288 3.27667 32.5465C5.30297 35.8642 8.19923 38.5305 11.6343 40.2405C15.0693 41.9506 18.9045 42.6354 22.7014 42.2167C26.4983 41.798 30.1036 40.2926 33.1051 37.8727C30.6861 35.9196 28.732 33.4282 27.3907 30.587C26.0494 27.7458 25.3561 24.6292 25.363 21.4729Z"
        fill="#E30613"
      />
      <path
        d="M64.1148 34.4098V33.7353H64.3858V33.5898H63.7148V33.7353H63.9729V34.4098H64.1148ZM65.4052 34.4098V33.5898H65.1987L64.9665 34.1453L64.7342 33.5898H64.5278V34.4098H64.6826V33.7882L64.902 34.3172H65.0568L65.2762 33.7882V34.4098H65.4052Z"
        fill="#F59E00"
      />
      <path
        d="M66.0586 21.4741C66.0672 25.3922 64.9988 29.2336 62.9758 32.5581C60.9528 35.8827 58.0571 38.556 54.6204 40.2717C51.1837 41.9874 47.345 42.6762 43.544 42.2591C39.743 41.8421 36.1334 40.336 33.1289 37.9136C35.5541 35.9648 37.5152 33.4763 38.8638 30.6362C40.2124 27.7962 40.9132 24.6789 40.9132 21.5203C40.9132 18.3618 40.2124 15.2445 38.8638 12.4044C37.5152 9.56436 35.5541 7.07583 33.1289 5.12707C36.13 2.70741 39.7348 1.20202 43.5311 0.78299C47.3275 0.363961 51.1623 1.0482 54.5971 2.75748C58.032 4.46677 60.9284 7.13213 62.9552 10.4489C64.982 13.7657 66.0575 17.6001 66.0586 21.5137V21.4741Z"
        fill="#F59E00"
      />
    </svg>
  );
}
