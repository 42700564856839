import React from "react";

export default function CryptosIcon(props) {
  return (
    <svg
      width="93"
      height="24"
      viewBox="0 0 93 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.69144 10.4247L8.92164 8.20254L9.38926 7.73092L10.5044 6.61982L14.2973 10.4247L16.5035 8.20254L10.5044 2.19141L4.48523 8.20254L6.69144 10.4247Z"
        fill="#F0B90B"
      />
      <path
        d="M10.5077 9.78235L8.29199 11.998L10.5077 14.2137L12.7234 11.998L10.5077 9.78235Z"
        fill="#F0B90B"
      />
      <path
        d="M14.2973 13.5898L10.5044 17.3828L9.16944 16.0519L8.92563 15.8041L6.69144 13.5898L4.48523 15.7961L10.5044 21.8072L16.5035 15.7961L14.2973 13.5898Z"
        fill="#F0B90B"
      />
      <path
        d="M2.90795 9.79212L0.692261 12.0078L2.90795 14.2235L5.12365 12.0078L2.90795 9.79212Z"
        fill="#F0B90B"
      />
      <path
        d="M18.0928 9.79017L15.8771 12.0059L18.0928 14.2216L20.3085 12.0059L18.0928 9.79017Z"
        fill="#F0B90B"
      />
      <path
        d="M43.3411 10.1736C43.6328 8.21521 42.142 7.16406 40.1037 6.46063L40.7632 3.80677L39.1485 3.4031L38.505 5.98501C38.0813 5.8811 37.6457 5.78118 37.21 5.68126L37.8575 3.08336L36.2428 2.67969L35.5794 5.32954C35.2276 5.24961 34.8839 5.16967 34.5482 5.08574V5.07775L32.322 4.5222L31.8943 6.2488C31.8943 6.2488 33.0934 6.52457 33.0654 6.54056C33.7209 6.70443 33.8368 7.13608 33.8168 7.4798L33.0614 10.5133L32.0063 14.7459C31.9263 14.9458 31.7225 15.2415 31.2669 15.1296C31.2828 15.1536 30.0918 14.8378 30.0918 14.8378L29.2885 16.6883L31.3907 17.2119C31.7824 17.3118 32.1661 17.4118 32.5418 17.5077L31.8744 20.1895L33.4891 20.5932L34.1525 17.9393C34.5922 18.0592 35.0198 18.1711 35.4395 18.2751L34.78 20.9169L36.3947 21.3206L37.0622 18.6428C39.8159 19.1623 41.8863 18.9545 42.7575 16.4645C43.461 14.4582 42.7216 13.3031 41.2747 12.5477C42.3299 12.3039 43.1252 11.6085 43.3371 10.1776L43.3411 10.1736ZM39.6521 15.3454C39.1525 17.3518 35.7752 16.2687 34.6801 15.9929L35.5674 12.4398C36.6625 12.7116 40.1716 13.2551 39.6521 15.3454ZM40.1517 10.1416C39.696 11.9642 36.8863 11.0369 35.975 10.8131L36.7784 7.58771C37.6896 7.81553 40.6273 8.23919 40.1517 10.1416Z"
        fill="#F7931A"
      />
      <path
        d="M59.256 0.962891V9.12029L66.1504 12.2018L59.256 0.962891Z"
        fill="#343434"
      />
      <path
        d="M59.256 0.962891L52.3575 12.2018L59.256 9.12029V0.962891Z"
        fill="#8C8C8C"
      />
      <path
        d="M59.256 17.4911V23.0346L66.1544 13.4863L59.256 17.4911Z"
        fill="#343434"
      />
      <path
        d="M59.2561 23.0326V17.489L52.3577 13.4883L59.2561 23.0366V23.0326Z"
        fill="#8C8C8C"
      />
      <path
        d="M59.256 16.2034L66.1504 12.1986L59.256 9.12109V16.2034Z"
        fill="#141414"
      />
      <path
        d="M52.3577 12.2006L59.2561 16.2053V9.12305L52.3577 12.2006Z"
        fill="#393939"
      />
      <path
        d="M85.8857 9.30096V7.11074H90.8936V3.77344H77.2567V7.11074H82.2646V9.29697C78.1959 9.48481 75.1344 10.2882 75.1344 11.2554C75.1344 12.2226 78.1959 13.0259 82.2646 13.2138V20.2281H85.8857V13.2138C89.9464 13.0259 92.9999 12.2226 92.9999 11.2554C92.9999 10.2882 89.9464 9.48481 85.8857 9.29697M85.8857 12.6183C85.7818 12.6223 85.2582 12.6542 84.0911 12.6542C83.1559 12.6542 82.5004 12.6263 82.2686 12.6143C78.6755 12.4584 75.9897 11.8309 75.9897 11.0835C75.9897 10.3361 78.6715 9.70863 82.2686 9.54876V11.9948C82.5044 12.0108 83.1759 12.0507 84.1071 12.0507C85.2222 12.0507 85.7858 12.0028 85.8897 11.9948V9.54876C89.4788 9.70863 92.1566 10.3361 92.1566 11.0835C92.1566 11.8309 89.4788 12.4584 85.8897 12.6183"
        fill="#53AE94"
      />
    </svg>
  );
}
