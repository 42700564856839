import React from "react";

export default function DropdownIcon(props) {
  return (
    <svg
      id="Group_12270"
      data-name="Group 12270"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <rect
        id="Rectangle_4103"
        data-name="Rectangle 4103"
        width="20"
        height="20"
        fill="none"
        opacity="0"
      />
      <path
        id="Chevron"
        d="M6.286,13.7a.987.987,0,0,0,.707.3A1.009,1.009,0,0,0,8,12.983a1.021,1.021,0,0,0-.317-.744L2.4,6.992,7.683,1.76A1.049,1.049,0,0,0,8,1.017,1.009,1.009,0,0,0,6.993,0a.966.966,0,0,0-.707.289L.365,6.182A1.136,1.136,0,0,0,0,7a1.105,1.105,0,0,0,.357.81Z"
        transform="translate(3 14) rotate(-90)"
      />
    </svg>
  );
}
