export default function StartIcon(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.3986 17.9002C22.0748 18.214 21.9261 18.6677 21.9998 19.1127L23.1111 25.2627C23.2048 25.784 22.9848 26.3115 22.5486 26.6127C22.1211 26.9252 21.5523 26.9627 21.0861 26.7127L15.5498 23.8252C15.3573 23.7227 15.1436 23.6677 14.9248 23.6615H14.5861C14.4686 23.679 14.3536 23.7165 14.2486 23.774L8.71109 26.6752C8.43735 26.8127 8.12734 26.8615 7.8236 26.8127C7.08359 26.6727 6.58984 25.9677 6.71109 25.224L7.8236 19.074C7.89734 18.6252 7.74859 18.169 7.42484 17.8502L2.91109 13.4752C2.53359 13.109 2.40234 12.559 2.57484 12.0627C2.74234 11.5677 3.16984 11.2065 3.68609 11.1252L9.89859 10.224C10.3711 10.1752 10.7861 9.88774 10.9986 9.46274L13.7361 3.85024C13.8011 3.72524 13.8848 3.61024 13.9861 3.51274L14.0986 3.42524C14.1573 3.36024 14.2248 3.30649 14.2998 3.26274L14.4361 3.21274L14.6486 3.12524H15.1748C15.6448 3.17399 16.0586 3.45524 16.2748 3.87524L19.0486 9.46274C19.2486 9.87149 19.6373 10.1552 20.0861 10.224L26.2986 11.1252C26.8236 11.2002 27.2623 11.5627 27.4361 12.0627C27.5998 12.564 27.4586 13.114 27.0736 13.4752L22.3986 17.9002Z"
        fill="#FDCD2D"
      />
    </svg>
  );
}
