const MerchantReviewCard = ({
  img,
  description,
  author,
  authorImg,
  business,
}) => {
  return (
    <div className="flex flex-col md:shadow-[0px_5px_62.87px_0px_rgba(108,99,255,0.2)] h-[397px] p-[30px] justify-between rounded-2xl">
      <div className="flex flex-col gap-[20px]">
        <div className="h-[75px] w-[340p]">
          <img src={img} alt="image" />
        </div>
        <p className="text-[rgba(119,121,135,1)] mb-[5px] text-sm">
          {description}
        </p>
      </div>
      <div>
        <h4 className="text-base font-[800]">{author}</h4>
        <h5 className="text-[rgba(129,139,153,1)] font-[400]">{business}</h5>
      </div>
    </div>
  );
};

export default MerchantReviewCard;
