import React, { useLayoutEffect, useState } from "react";
import Lottie from "lottie-react";
import TransactionCompleteAnim from "../../utils/transactionCompleteAnimation.json";
import { useTranslation } from "react-i18next";
import RateMerchant from "./components/RateMerchant";
import { useQueryString } from "hooks";
import DownloadApps from "./components/DownloadApps";
import styles from "./styles.module.css";
import PendingIcon from "assets/icons/PendingIcon";
import {
  credoInstStatusUpdate,
  // commonServiceInstance,
  getCommisionForTips,
  // getTransactionDetails,
  postTransactionAction,
} from "api";
import { useEffect } from "react";
import Loader from "components/Loader";
import PDFDownloadButton from "./PDFDownloadButton";

const SuccessPage = () => {
  const [loading, setLoading] = useState(true);
  const [commisionPerncet, setCommisionPercent] = useState();
  const [insatllmentProperties, setInstallmetProperties] = useState({});

  const { t } = useTranslation();

  const { parseQuery: parsedQuery } = useQueryString();
  const {
    acquiringTransactionId: transactionId = "",
    amount = "",
    receiverName = "",
    status,
    ratingEnabled = "false",
    tipsEnabled = "false",
    receiverType = "",
    currency = "GEL",
    receiverId: receiverUserId,
    paymentProviderEnum = "",
    paymentMethod = "STANDARD",
    currencyRate: currRate = 0,
    integratorOrderId = "",
    genericId = "",
    order_code = "",
  } = parsedQuery;

  const shouldTip = !!JSON.parse(tipsEnabled.toLowerCase());
  const shouldRate = !!JSON.parse(ratingEnabled.toLowerCase());
  const decodedString = decodeURIComponent(
    receiverName || insatllmentProperties?.receiverNameInst
  );
  const userName = decodedString.replace(/\+/g, " ");
  useLayoutEffect(() => {
    if (order_code) {
      credoInstStatusUpdate(order_code).then(
        ({
          ratingEnabled,
          receiverName,
          amount,
          integratorId,
          integratorOrderId,
          receiverType,
          genericId,
          acquiringId,
        }) => {
          setInstallmetProperties({
            ratingEnabledInst: ratingEnabled,
            receiverNameInst: receiverName,
            amountInst: amount,
            integratorIdInst: integratorId,
            integratorOrderIdInst: integratorOrderId,
            receiverTypeInst: receiverType,
            genericIdinst: genericId,
            acquiringIdInst: acquiringId,
          });
        }
      );
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (shouldTip || shouldRate || order_code) {
        setLoading(false);
        return;
      }
      const integratororderId =
        localStorage.getItem("integratorOrderId") || integratorOrderId;
      if (integratororderId) {
        try {
          const { redirectRequired, successRedirectUri } =
            await postTransactionAction(integratororderId);
          if (redirectRequired && successRedirectUri) {
            window.location.href = successRedirectUri;
          }
        } finally {
          localStorage.removeItem("integratorOrderId");
          setLoading(false);
        }
      }
      setLoading(false);
    })();
  }, []);

  var currentDate = new Date();
  var formattedDate =
    ("0" + (currentDate.getMonth() + 1)).slice(-2) +
    "." +
    ("0" + currentDate.getDate()).slice(-2) +
    "." +
    currentDate.getFullYear() +
    " " +
    ("0" + currentDate.getHours()).slice(-2) +
    ":" +
    ("0" + currentDate.getMinutes()).slice(-2);

  useEffect(() => {
    if (!shouldTip) return;
    const getCommision = async () => {
      const reciverId =
        localStorage.getItem("receiverUserId") || receiverUserId;
      const { senderCommissionPercent } = await getCommisionForTips(
        receiverUserId,
        receiverType,
        transactionId
      );
      setCommisionPercent(senderCommissionPercent);
    };

    getCommision();
    return () => {
      localStorage.removeItem("receiverUserId");
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  const handleReviewDone = () => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const receiverName = url.searchParams.get("receiverName");
    const currency = url.searchParams.get("currency");
    const amount = url.searchParams.get("amount");
    const paymentProviderEnum = url.searchParams.get("paymentProviderEnum");
    const newURL = `${url.origin}${url.pathname}?receiverName=${receiverName}&amount=${amount}&tipsEnabled=false&acquiringTransactionId=${transactionId}&ratingEnabled=false&currency=${currency}&paymentProviderEnum=${paymentProviderEnum}`;
    window.location.href = newURL;
  };

  const currencyRate = Number(currRate);

  let content =
    shouldRate || insatllmentProperties?.ratingEnabledInst ? (
      <div className="w-full text-center">
        <RateMerchant
          commisionAmount={commisionPerncet}
          amount={amount}
          transactionId={
            transactionId || insatllmentProperties?.acquiringIdInst
          }
          onReviewDone={handleReviewDone}
          tipsEnabled={shouldTip}
          receiverName={userName}
          receiverType={receiverType || insatllmentProperties?.receiverTypeInst}
          currency={currency}
          currencyRate={currencyRate}
          paymentProviderEnum={paymentProviderEnum}
          paymentMethod={paymentMethod}
          integratorOrderId={
            integratorOrderId || insatllmentProperties?.integratorOrderIdInst
          }
        />
      </div>
    ) : (
      <DownloadApps
        commisionAmount={commisionPerncet}
        amount={amount}
        transactionId={transactionId}
        tipsEnabled={shouldTip}
        receiverName={userName}
        receiverType={receiverType || insatllmentProperties?.receiverTypeInst}
        skip={handleReviewDone}
        currency={currency}
        currencyRate={currencyRate}
        paymentProviderEnum={paymentProviderEnum}
        paymentMethod={paymentMethod}
        integratorOrderId={
          integratorOrderId || insatllmentProperties?.integratorOrderIdInst
        }
      />
    );

  return (
    <div
      className={`${shouldRate && styles.ratingCont} flex flex-col gap-0 `}
      style={{ height: "100%" }}
    >
      <PDFDownloadButton
        transactionId={transactionId}
        installmentId={insatllmentProperties?.acquiringIdInst}
        genericId={genericId || insatllmentProperties?.genericIdinst}
        paymentProviderEnum={paymentProviderEnum}
        currency={currency}
        shouldRate={shouldRate}
      />
      <div className="h-3/4 flex flex-col items-center  gap-5 text-center mt-[15px] justify-center  ">
        <div>
          {status !== "IN_PROGRESS" ? (
            <Lottie
              animationData={TransactionCompleteAnim}
              loop={false}
              style={{ width: "100px", height: "100px" }}
            />
          ) : (
            <PendingIcon />
          )}
        </div>
        <div style={{ transform: "translateY(-30px)", marginTop: "20px" }}>
          <h3 className="font-bold text-lg text-center">
            {status !== "IN_PROGRESS"
              ? t("description.transactionComplete")
              : t("paymentProcessing")}
          </h3>
          <p className="opacity-50 text-center whitespace-pre-line">
            {status == "IN_PROGRESS" ? (
              t("description.paymentProcessing")
            ) : (amount || insatllmentProperties?.amountInst) && userName ? (
              <>
                <p className="w-[270px] mt-[10px]">
                  {t("description.paymentSuccessfulInfo", {
                    amount: amount || insatllmentProperties?.amountInst,
                    ...(currency == "EUR" && { currency: "€" }),
                    ...(currency == "GEL" && { currency: "₾" }),
                    ...(currency == "USD" && { currency: "$" }),
                  })}{" "}
                  {userName + "  "} {formattedDate}
                </p>
              </>
            ) : (
              t("description.paymentSuccessful")
            )}
          </p>
          {(genericId || insatllmentProperties?.genericIdinst) && (
            <p className="opacity-50 text-center ">
              ID - {genericId || insatllmentProperties?.genericIdinst}
            </p>
          )}
        </div>
      </div>

      <div className="w-full flex flex-col justify-end">{content}</div>
    </div>
  );
};

export default SuccessPage;
