import wissolIcon from "../../../assets/images/wissol.png";
import skalIcon from "../../../assets/images/ska.png";
import hylaIcon from "../../../assets/images/hyla.png";
import vintagesd from "../../../assets/images/8000vintagesd.png";
import georgianBus from "../../../assets/images/georgianBus.png";
import toreli from "../../../assets/images/toreli.png";
import mrMaster from "../../../assets/images/mrMaster.png";
// import vloulez from "../../../assets/images/vloulez-vous.png";
// import hylaIcon from "../../../assets/images/hyla.png";

// import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import Modal from "../Modal/Modal";
import { Trans, useTranslation } from "react-i18next";

const Customers = () => {
  // const { t } = useTranslation();
  return (
    <section className="text-center my-[70px]  ">
      <p className="text-[#777987] md:hidden   md:text-[20px]   ">
        {/* 200+ ბიზნესი უკვე <br className="md:hidden" /> იღებს გადახდებს keepz-ით */}
        <Trans i18nKey={"amountOfCustomers"} />
      </p>
      <p className="text-[#777987]  sm:hidden md:block md:text-[20px]   ">
        {/* 200+ ბიზნესი უკვე <br className="md:hidden" /> იღებს გადახდებს keepz-ით */}
        <Trans i18nKey={"amountOfCustomersMd"} />
      </p>
      <div className="sm:hidden md:flex mt-[18px]  justify-between items-center mx-[30px] py-[5px] ">
        <img src={wissolIcon} style={{ width: "150px" }} alt="Wissol icon" />
        <img src={skalIcon} style={{ width: "55px" }} alt="Ska icon" />
        <img src={vintagesd} style={{ height: "70px" }} alt="Vinatge icon" />
        <img src={hylaIcon} style={{ width: "115px" }} alt="Hyla icon" />
        <img src={toreli} style={{ height: "" }} alt="Toreli icon" />
        <img
          src={georgianBus}
          style={{ width: "160px" }}
          alt="Georgian bus icon"
        />
        <img src={mrMaster} style={{ width: "" }} alt="Mr.master icon" />
      </div>
      {/* /////////////////////////////////////////////////////////////////////////////////// */}
      <div className="mt-[18px] md:hidden">
        <Swiper slidesPerView={4} spaceBetween={20}>
          <SwiperSlide
            style={{
              marginRight: "-10px",
              width: "55px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={skalIcon} alt="Ska icon" />
          </SwiperSlide>
          <SwiperSlide style={{ width: "70px" }}>
            <img src={wissolIcon} alt="Wissol icon" />
          </SwiperSlide>
          <SwiperSlide style={{ width: "70px" }}>
            <img src={toreli} alt="Toreli icon" />
          </SwiperSlide>
          <SwiperSlide style={{ width: "70px" }}>
            <img src={vintagesd} alt="Vintage icon" />
          </SwiperSlide>
          <SwiperSlide style={{ width: "70px" }}>
            <img src={hylaIcon} alt="Hyla icon" />
          </SwiperSlide>
          <SwiperSlide style={{ width: "100px" }}>
            <img src={georgianBus} alt="Georgian bus icon" />
          </SwiperSlide>
          <SwiperSlide style={{ width: "70px" }}>
            <img src={mrMaster} alt="Mr.master icon" />
          </SwiperSlide>
          {/* <SwiperSlide
              style={{
                marginRight: "-20px",
                width: "55px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={skalIcon} />
            </SwiperSlide>
            <SwiperSlide
              style={{
                marginRight: "20px",
              }}
            >
              <img src={wissolIcon} width={"70px"} />
            </SwiperSlide>
            <SwiperSlide style={{}}>
              <img src={toreli} width={"90px"} />
            </SwiperSlide>
            <SwiperSlide
              style={{
                marginRight: "20px",
              }}
            >
              <img src={vintagesd} width={"70px"} />
            </SwiperSlide>
            <SwiperSlide
              style={{
                marginRight: "100px",
              }}
            >
              <img src={hylaIcon} width={"70px"} />
            </SwiperSlide>
            <SwiperSlide
              style={{
                transform: "translateX(-10%)",
                width: "190px",
              }}
            >
              <img src={georgianBus} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={mrMaster} width={"77px"} />
            </SwiperSlide> */}
        </Swiper>
      </div>
      {/* <Modal /> */}
    </section>
  );
};

export default Customers;
