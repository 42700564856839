const QrNavEl = ({ icon, title, description }) => {
  return (
    <div className="flex md:flex-col items-center  md:w-[300px] sm:w-[361px] sm:flex-row sm:gap-[10px]">
      <img className="w-[50px] h-[50px]" src={icon} alt="icon" />
      <div className="sm:text-left md:text-center">
        <h3
          style={{ fontVariant: "all-petite-caps", lineHeight: "27.2px" }}
          className="text-[rgba(108,99,255,1)] text-[20px] font-bold"
        >
          {title}
        </h3>
        <h5 className="text-[rgba(23,7,56,1)] text-[14px] opacity-50">
          {description}
        </h5>
      </div>
    </div>
  );
};

export default QrNavEl;
