import React from "react";

export default function BinanceIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="40"
      viewBox="0 0 60 40"
      {...props}
    >
      <g
        id="Group_4412"
        data-name="Group 4412"
        transform="translate(-17.201 -486.461)"
      >
        <rect
          id="Rectangle_3737"
          data-name="Rectangle 3737"
          width="60"
          height="40"
          rx="8"
          transform="translate(17.201 486.461)"
          fill="#222"
        />
        <g id="Binance_Logo" transform="translate(32.293 491.007)">
          <path
            id="Path_29079"
            data-name="Path 29079"
            d="M27.357,12.989l6-6,6.006,6.006L42.858,9.5,33.36,0l-9.5,9.5Z"
            transform="translate(-18.451)"
            fill="#f3ba2f"
          />
          <rect
            id="Rectangle_5426"
            data-name="Rectangle 5426"
            width="4.94"
            height="4.94"
            transform="translate(2.946 11.962) rotate(45)"
            fill="#f3ba2f"
          />
          <path
            id="Path_29080"
            data-name="Path 29080"
            d="M27.341,73.4l6,6L39.349,73.4l3.495,3.491,0,0-9.5,9.5-9.5-9.5,0,0Z"
            transform="translate(-18.435 -55.478)"
            fill="#f3ba2f"
          />
          <rect
            id="Rectangle_5427"
            data-name="Rectangle 5427"
            width="4.94"
            height="4.94"
            transform="translate(30.364 15.456) rotate(135)"
            fill="#f3ba2f"
          />
          <path
            id="Path_29081"
            data-name="Path 29081"
            d="M55.323,52.324h0L51.78,48.78,49.161,51.4h0l-.3.3-.621.621h0l0,0,0,.005,3.541,3.541,3.544-3.544,0,0Z"
            transform="translate(-36.872 -36.871)"
            fill="#f3ba2f"
          />
        </g>
      </g>
    </svg>
  );
}
