import React from "react";
// import Html from "./TermsGe.html";

const Ge = () => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Sylfaen;
	panose-1:1 10 5 2 5 3 6 3 3 3;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:Georgia;
	panose-1:2 4 5 2 5 4 5 2 3 3;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:"\@Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"Noto Sans Symbols";}
@font-face
	{font-family:Merriweather;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
h1
	{margin-top:24.0pt;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:24.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
h2
	{margin-top:.25in;
	margin-right:0in;
	margin-bottom:4.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:18.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
h3
	{margin-top:14.0pt;
	margin-right:0in;
	margin-bottom:4.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
h4
	{margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:2.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
h5
	{margin-top:11.0pt;
	margin-right:0in;
	margin-bottom:2.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
h6
	{margin-top:10.0pt;
	margin-right:0in;
	margin-bottom:2.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoTitle, li.MsoTitle, div.MsoTitle
	{margin-top:24.0pt;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:36.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:41.45pt;
	text-align:justify;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Sylfaen",serif;}
p.MsoSubtitle, li.MsoSubtitle, div.MsoSubtitle
	{margin-top:.25in;
	margin-right:0in;
	margin-bottom:4.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:24.0pt;
	font-family:"Georgia",serif;
	color:#666666;
	font-style:italic;}
a:link, span.MsoHyperlink
	{color:#0563C1;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:#954F72;
	text-decoration:underline;}
p
	{margin-right:0in;
	margin-left:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"Comment Subject Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"Balloon Text Char";
	margin:0in;
	font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;}
p.MsoRMPane, li.MsoRMPane, div.MsoRMPane
	{margin:0in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{mso-style-link:"List Paragraph Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{mso-style-link:"List Paragraph Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{mso-style-link:"List Paragraph Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{mso-style-link:"List Paragraph Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.ListParagraphChar
	{mso-style-name:"List Paragraph Char";
	mso-style-link:"List Paragraph";}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-link:"Comment Text";}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-link:"Balloon Text";
	font-family:"Segoe UI",sans-serif;}
span.CommentSubjectChar
	{mso-style-name:"Comment Subject Char";
	mso-style-link:"Comment Subject";
	font-weight:bold;}
span.CommentSubjectChar1
	{mso-style-name:"Comment Subject Char1";
	font-weight:bold;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";
	font-family:"Sylfaen",serif;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:.5in;text-align:center;text-indent:-.25in;
line-height:115%;layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'><b>საგადახდო</b><b> </b><b>მომსახურებით</b><b> </b><b>სარგებლობის</b><b>
</b><b>წესები</b><b> </b><b>და</b><b> </b><b>პირობები</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>შპს ქიფზ.მი (შემდგომში
„KEEPZ.ME”), წარმოადგენს საქართველოს ეროვნული ბანკის მიერ ლიცენზირებულ საგადახდო
მომსახურების პროვაიდერს - 0099 – 7704, რომელიც  უზრუნველყოფს მომხმარებლებს „მომსახურების
აღწერის“ თავში განსაზღვრული სერვისებით. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მოცემული საგადახდო მომსახურებით
სარგებლობის  წესები და პირობები (შემდგომში „ხელშეკრულება“) წარმოადგენს სავალდებულო
იურიდიული ძალის მქონე შეთანხმებას KEEPZ.ME-ს მომხმარებელსა (შემდგომში „მომხმარებელი“)
და KEEPZ.ME-ს შორის, და <span style='color:black;background:white'>განსაზღვრავს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>არსებული</span><span style='color:black;background:white'>/</span><span
style='color:black;background:white'>დასამყარებელი</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ურთიერთობების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>პირობებს</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>რომლის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ფარგლებშიც</span><span style='color:black;
background:white'> </span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black;
background:white'> </span><span style='color:black;background:white'>იღებს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ვალდებულებას</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>კანონმდებლობისა</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>და</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>საერთაშორისო</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>საგადამხდელო</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>სისტემების</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>წესებისა</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>და</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>რეგულაციების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>შესაბამისად</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>გაუწიოს</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომხმარებელს</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>შესაბამისი</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>საგადახდო</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომსახურება</span><span style='color:black;
background:white'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წინამდებარე შეთანხმება ძალაშია
მომხმარებლის მიერ KEEPZ.ME-ს მომსახურებით სარგებლობის დაწყების მომენტიდან (შემდგომში
„ამოქმედების თარიღი“). <span style='color:black;background:white'>ამასთან</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>როდესაც</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომხმარებელი</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>იწყებს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ისეთი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომსახურებით</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>სარგებლობას</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>რომლითაც</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მანამდე</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>არ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>უსარგებლია</span><span style='color:black;background:white'>,
</span><span style='color:black;background:white'>მასზე</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ავტომატურად</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>გავრცელდება</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>შესაბამისი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომსახურებით</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>სარგებლობის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>წესები</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>და</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>პირობები</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>რომელიც</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>განსაზღვრულია</span><span
style='color:black;background:white'> „</span><span style='color:black;
background:white'>ცალკეული</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომსახურებით</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>სარგებლობის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>წესებისა</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>და</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>პირობების</span><span
style='color:black;background:white'>“ </span><span style='color:black;
background:white'>თავში</span><span style='color:black;background:white'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მოცემული ხელშეკრულების „საერთო
წესებისა და პირობების თავი“ ვრცელდება KEEPZ.ME -ს ყველა მომხმარებელზე; იმ შემთხვევაში,
თუ საერთო წესებისა და პირობების რომელიმე ტერმინი/დებულება ეწინააღმდეგება რომელიმე
ცალკეული მომსახურების პირობებს, უკანასკნელს მიენიჭება უპირატესობა.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black;
background:white'>წინამდებარე</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>ხელშეკრულებასთან</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ერთად</span><span style='color:black;background:white'>, </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black;
background:white'> -</span><span style='color:black;background:white'>ს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>და</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომხმარებელს</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>შორის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>არსებული</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ურთიერთობა</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>ასევე</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>რეგულირდება</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>მომხმარებლის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მიმართ</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მოქმედი</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>სამართლებრივი</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>აქტებით</span><span style='color:black;background:white'>, </span><span
style='color:black;background:white'>მომხმარებელთან</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>დადებული</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>დამატებითი</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>ხელშეკრულებებით</span><span
style='color:black;background:white'>/</span><span style='color:black;
background:white'>დანართებით</span><span style='color:black;background:white'>,
</span><span style='color:black;background:white'>პირობებით</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>რომელსაც</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომხმარებელი</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>ეთანხმება</span><span
style='color:black;background:white'> </span></span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'>KEEPZ.ME</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><span style='color:black;background:white'>-</span><span
style='color:black;background:white'>ს</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>აპლიკაციის</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>სამართავი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>პანელის</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>მეშვეობით</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ან</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>სხვა</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>ელექტრონული</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ფორმით</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>გონივრულობის</span><span style='color:
black;background:white'>, </span><span style='color:black;background:white'>კეთილსინდისიერებისა</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>და</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>სამართლიანობის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>წესებითა</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>და</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>პრინციპებით</span><span
style='color:black;background:white'>. </span></span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:0in;text-align:center;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ნაწილი</b><b>
I. </b><b>ზოგადი</b><b> </b><b>წესები</b><b> </b><b>და</b><b> </b><b>პირობები</b></span></p>

<ol style='margin-top:0in' start=1 type=1>
 <li class=MsoNormal style='color:black;margin-top:6.0pt;margin-bottom:6.0pt;
     text-align:justify;line-height:115%;layout-grid-mode:char;border:none'><span
     lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:windowtext'><b><span
     style='color:black'>KEEPZ.ME -</span></b><b><span style='color:black'>ს</span></b><b><span
     style='color:black'> </span></b><b><span style='color:black'>მომსახურებით</span></b><b><span
     style='color:black'> </span></b><b><span style='color:black'>სარგებლობა</span></b><b><span
     style='color:black'> </span></b></span></li>
</ol>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME-ს საზედამხედველო ორგანოს
წარმოადგენს საქართველოს ეროვნული ბანკი (ვებ გვერდის მისამართი: </span><span
lang="GEO/KAT"><a href="https://nbg.gov.ge"><span style='font-family:"Sylfaen",serif;
color:#0563C1'>https://nbg.gov.ge</span></a></span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'>, მისამართი: 0114 თბილისი, საქართველო სანაპიროს
ქუჩა № 2). ყოველგვარი ეჭვის თავიდან ასაცილებლად, საქართველოს ეროვნული ბანკი არ არის
პასუხისმგებელი KEEPZ.ME-ს მიერ  წინამდებარე ხელშეკრულებით, ან ნებისმიერი სხვა მასთან
დაკავშირებული დანართებით, ხელშეკრულებ(ებ)ით და განაცხადებით გათვალიწინებული ვალდებულებების
არაჯეროვან შესრულებაზე.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>მომსახურებით</span><span
style='color:black'> </span><span style='color:black'>სარგებლობის</span><span
style='color:black'> </span><span style='color:black'>წინაპირობას</span><span
style='color:black'> </span><span style='color:black'>წარმოადგენს</span><span
style='color:black'> </span><span style='color:black'>მოცემულ</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულებაზე</span><span
style='color:black'> </span><span style='color:black'>თანხმობა</span><span
style='color:black'>/</span><span style='color:black'>ხელმოწერა</span><span
style='color:black'>. </span><span style='color:black'>იმ</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'>, </span><span style='color:black;background:white'>თუ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მომხმარებელი</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>იწყებს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ისეთი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომსახურებით</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>სარგებლობას</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>რომლითაც</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მანამდე</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>არ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>უსარგებლია</span><span style='color:black;background:white'>,
</span><span style='color:black;background:white'>მის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მიმართ</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ავტომატურად</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>გამოიყენება</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>შესაბამისი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომსახურების</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>პირობები</span><span
style='color:black;background:white'>. </span><span style='color:black;
background:white'>თუ</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ახალი</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>მომსახურებით</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>სარგებლობისათვის</span><span style='color:black;background:
white'> </span><span style='color:black;background:white'>საჭიროა</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>დამატებითი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ვერიფიკაცია</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>ან</span><span
style='color:black;background:white'>/</span><span style='color:black;
background:white'>და</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომხმარებლის</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>მიერ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>დამატებითი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>დოკუმენტაციის</span><span
style='color:black;background:white'>/</span><span style='color:black;
background:white'>ინფორმაციის</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>წარდგენა</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ან</span><span style='color:black;background:white'>/</span><span
style='color:black;background:white'>და</span><span style='color:black;
background:white'> KEEPZ.ME-</span><span style='color:black;background:white'>ს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ან</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>შესაბამისი</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>ნორმატიული</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>აქტით</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>განსაზღვრული</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>მოთხოვნების</span><span
style='color:black;background:white'>/</span><span style='color:black;
background:white'>კრიტერიუმების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>დაკმაყოფილება</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>მომხმარებელი</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>ახალი</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მომსახურებით</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>სარგებლობას</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>შეძლებს</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მხოლოდ</span><span style='color:black;
background:white'> KEEPZ.ME-</span><span style='color:black;background:white'>ს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ყველა</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მითითების</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>შესრულებისა</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>და</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>შესაბამისი</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>მოთხოვნებისა</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>და</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>კრიტერიუმების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>დაკმაყოფილების</span><span style='color:black;background:
white'> </span><span style='color:black;background:white'>შემდეგ</span><span
style='color:black;background:white'>.</span></span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>მომსახურებით</span><span
style='color:black'> </span><span style='color:black'>სარგებლობა</span><span
style='color:black'> </span><span style='color:black'>შეუძლია</span><span
style='color:black'> </span><span style='color:black'>ცალკეული</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>პირობებით</span><span
style='color:black'> </span><span style='color:black'>გათვალიწინებულ</span><span
style='color:black'>/</span><span style='color:black'>ნებადართულ</span><span
style='color:black'> </span><span style='color:black'>პირებს</span><span
style='color:black'>. </span><span style='color:black'>ამასთან</span><span
style='color:black'>, </span><span style='color:black'>დაუშვებელია</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>წარმომადგენლის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>რეგისტრაციის</span><span
style='color:black'>/</span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნა</span><span
style='color:black'> </span><span style='color:black'>ისეთი</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>სახელით</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>სასარგებლოდ</span><span
style='color:black'>, </span><span style='color:black'>რომელსაც</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>გამოყენებაზე</span><span
style='color:black'> </span><span style='color:black'>უარი</span><span
style='color:black'> </span><span style='color:black'>ეთქვა</span><span
style='color:black'> </span><span style='color:black'>მოცემული</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულებით</span><span
style='color:black'> </span><span style='color:black'>განსაზღვრული</span><span
style='color:black'> </span><span style='color:black'>საფუძველების</span><span
style='color:black'> </span><span style='color:black'>შესაბამისად</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მოცემულ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებაზე</span><span style='color:black'> </span><span
style='color:black'>თანხმობით</span><span style='color:black'>, </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>წარმომადგენელი</span><span style='color:black'> (</span><span
style='color:black'>ასეთის</span><span style='color:black'> </span><span
style='color:black'>არსებობის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>) </span><span
style='color:black'>ეთანხმება</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ადასტურებს</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>წარმომადგენელი</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნების</span><span style='color:black'> </span><span
style='color:black'>გათვალიწინებით</span><span style='color:black'>, </span><span
style='color:black'>წარმოადგენს</span><span style='color:black'> </span><span
style='color:black'>ავტორიზებულ</span><span style='color:black'> </span><span
style='color:black'>პირს</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>უფლებამოსილია</span><span style='color:black'> </span><span
style='color:black'>აღნიშნულ</span><span style='color:black'> </span><span
style='color:black'>სამართალურთიერთობაში</span><span style='color:black'> </span><span
style='color:black'>იმოქმედოს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>სახელით</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მის</span><span style='color:black'> </span><span
style='color:black'>ნაცვლად</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>არა</span><span style='color:black'> </span><span
style='color:black'>მხოლოდ</span><span style='color:black'>, </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>სახელით</span><span style='color:black'> </span><span
style='color:black'>წარადგინოს</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>მიღებისათვის</span><span style='color:black'> </span><span
style='color:black'>საჭირო</span><span style='color:black'> </span><span
style='color:black'>ინფორმაცია</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>თანხმობა</span><span style='color:black'> </span><span
style='color:black'>განაცხადოს</span><span style='color:black'> </span><span
style='color:black'>მოცემულ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებაზე</span><span style='color:black'> </span><span
style='color:black'>იმგვარად</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>იგი</span><span style='color:black'> </span><span
style='color:black'>სავალდებულო</span><span style='color:black'> </span><span
style='color:black'>იურიდიული</span><span style='color:black'> </span><span
style='color:black'>ძალის</span><span style='color:black'> </span><span
style='color:black'>მქონე</span><span style='color:black'> </span><span
style='color:black'>იყოს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლისათვის</span><span style='color:black'>).
KEEPZ.ME-</span><span style='color:black'>მ</span><span style='color:black'>, </span><span
style='color:black'>საჭიროების</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>, </span><span
style='color:black'>შესაძლებელია</span><span style='color:black'> </span><span
style='color:black'>მოითხოვოს</span><span style='color:black'> </span><span
style='color:black'>დამატებითი</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'>/</span><span
style='color:black'>დოკუმენტაციის</span><span style='color:black'> </span><span
style='color:black'>წარმოდგენა</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>ადასტურებს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>წარმომადგენლის</span><span style='color:black'> </span><span
style='color:black'>უფლებამოსილებას</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>იმისათვის</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>მ</span><span style='color:black'> </span><span
style='color:black'>შეასრულოს</span><span style='color:black'> </span><span
style='color:black'>ფულის</span><span style='color:black'> </span><span
style='color:black'>გათეთრებისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ტერორიზმის</span><span style='color:black'> </span><span
style='color:black'>დაფინანსების</span><span style='color:black'> </span><span
style='color:black'>აღკვეთის</span><span style='color:black'> </span><span
style='color:black'>ხელშეწყობის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'> </span><span
style='color:black'>არსებული</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>სავალდებულო</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნები</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ასევე</span><span style='color:black'>, </span><span
style='color:black'>უზრუნველყოს</span><span style='color:black'> </span><span
style='color:black'>შესაძლო</span><span style='color:black'> </span><span
style='color:black'>თაღლითური</span><span style='color:black'> </span><span
style='color:black'>ქმედებების</span><span style='color:black'> </span><span
style='color:black'>აღკვეთა</span><span style='color:black'>,  KEEPZ.ME  </span><span
style='color:black'>უფლებამოსილია</span><span style='color:black'>, </span><span
style='color:black'>მოითხოვოს</span><span style='color:black'> </span><span
style='color:black'>ისეთი</span><span style='color:black'> </span><span
style='color:black'>მონაცემები</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დოკუმენტები</span><span style='color:black'>, </span><span
style='color:black'>რომელთა</span><span style='color:black'> </span><span
style='color:black'>მიხედვითაც</span><span style='color:black'> </span><span
style='color:black'>შესაძლებელი</span><span style='color:black'> </span><span
style='color:black'>იქნება</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>იდენტიფიკაცია</span><span style='color:black'>/</span><span
style='color:black'>ვერიფიკაცია</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'> </span><span
style='color:black'>მნიშვნელოვანი</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>მიღება</span><span style='color:black'>, </span><span
style='color:black'>რაც</span><span style='color:black'> </span><span
style='color:black'>აუცილებელია</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>სათანადოდ</span><span style='color:black'> </span><span
style='color:black'>გასაწევად</span><span style='color:black'>. </span><span
style='color:black'>წარსადგენი</span><span style='color:black'> </span><span
style='color:black'>კონკრეტული</span><span style='color:black'> </span><span
style='color:black'>მონაცემები</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დოკუმენტები</span><span style='color:black'> </span><span
style='color:black'>აღწერილი</span><span style='color:black'> </span><span
style='color:black'>იქნება</span><span style='color:black'> </span><span
style='color:black'>როგორც</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>პირობებში</span><span style='color:black'>, </span><span
style='color:black'>ისე</span><span style='color:black'> </span><span
style='color:black'>იდენტიფიკაციის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ვერიფიკაციის</span><span style='color:black'> </span><span
style='color:black'>პროცედურების</span><span style='color:black'> </span><span
style='color:black'>შესრულების</span><span style='color:black'> </span><span
style='color:black'>აუცილებლობის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლისათვის</span><span style='color:black'> </span><span
style='color:black'>გაგზავნილ</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>შეტყობინებაში</span><span style='color:black'>/</span><span
style='color:black'>დოკუმენტში</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>ადასტურებს</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>უპირობოდ</span><span style='color:black'> </span><span
style='color:black'>იძლევა</span><span style='color:black'> </span><span
style='color:black'>გარანტიას</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>გათვალიწინებული</span><span style='color:black'> </span><span
style='color:black'>ურთიერთობის</span><span style='color:black'> </span><span
style='color:black'>ფარგლებში</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> 1.4 </span><span
style='color:black'>მუხლის</span><span style='color:black'> </span><span
style='color:black'>მიზნებისათვის</span><span style='color:black'>) KEEPZ.ME-</span><span
style='color:black'>თვის</span><span style='color:black'> </span><span
style='color:black'>წარდგენილი</span><span style='color:black'>  </span><span
style='color:black'>ინფორმაცია</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'>/</span><span
style='color:black'>იქნება</span><span style='color:black'> </span><span
style='color:black'>სწორი</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>უტყუარი</span><span style='color:black'>. </span><span
style='color:black'>არასწორი</span><span style='color:black'> </span><span
style='color:black'>მონაცემების</span><span style='color:black'> </span><span
style='color:black'>წარდგენით</span><span style='color:black'> </span><span
style='color:black'>გამოწვეულ</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერ</span><span style='color:black'> </span><span
style='color:black'>ზიანზე</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებლობა</span><span style='color:black'> </span><span
style='color:black'>ეკისრება</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.gjdgxs"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>1.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>წინამდებარე</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ხელშეკრულებაზე</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>თანხმობით</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>, </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მომხმარებელი</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ადასტურებს</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>და</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>აძლევს</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>  KEEPZ.ME-</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ს</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>უფლებამოსილებას</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>, </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>რომ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მომხმარებლის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>შესახებ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>არსებული</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ინფორმაცია</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> (</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მათ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>შორის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>, </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ვებ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>-</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>გვერდის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მისამართები</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> (URL); </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მართვის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ორგანოებში</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>შემავალი</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>პირების</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>შესახებ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ინფორმაცია</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>; </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მომხმარებლის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მისამართები</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>; KEEPZ.ME-</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ს</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მომსახურების</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ფარგლებში</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ნავაჭრი</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>თანხისა</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>და</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ტრანზაქციების</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ოდენობა</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>და</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ნებისმიერი</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>სხვა</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>სახის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ინფორმაცია</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>/</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>დოკუმენტაცია</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>) </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>გადასცეს</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>პარტნიორ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ბანკს</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ან</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>/</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>და</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>საერთაშორისო</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>საგადამხდელო</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>სისტემებს</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ან</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>/</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>და</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>საზედამხედველო</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>, </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მაკონტროლებელ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ან</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>/</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>და</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>სარეგისტრაციო</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ორგანოებს</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>, </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>სახელმწიფო</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ან</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ადგილობრივი</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>თვითმმართველობის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ორგანოებს</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>და</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მათ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მიერ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>შექმნილ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>იურიდიულ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>პირებს</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>წინამდებარე ხელშეკრულებაზე თანხმობით, თქვენ ასევე გამოხატავთ თანხმობას
შპს “ქიფზ.მი”-ს მომხმარებლების პერსონალურ მონაცემთა დამუშავების პოლიტიკაზე, რომელიც
ხელმისაწვდომია შემდეგ ლინკზე - </span><span lang="GEO/KAT"><a
href="https://app.keepz.me/privacy"><span style='font-family:"Sylfaen",serif'>https://app.keepz.me/privacy</span></a></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>განცხადებები</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>და</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>გარანტიები</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მხარეები</span><span style='color:black'> </span><span
style='color:black'>აცხადებენ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>იძლევიან</span><span style='color:black'> </span><span
style='color:black'>გარანტიას</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'>: </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>დადების</span><span style='color:black'> </span><span
style='color:black'>დროისათვის</span><span style='color:black'> </span><span
style='color:black'>არიან</span><span style='color:black'>/</span><span
style='color:black'>იქნებიან</span><span style='color:black'> </span><span
style='color:black'>ქმედუნარიანი</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>დადგენილი</span><span style='color:black'> </span><span
style='color:black'>წესით</span><span style='color:black'> </span><span
style='color:black'>შექმნილი</span><span style='color:black'>).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>აქვთ</span><span style='color:black'> </span><span
style='color:black'>სრული</span><span style='color:black'> </span><span
style='color:black'>უფლებამოსილება</span><span style='color:black'> (</span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>მიიღებენ</span><span style='color:black'> </span><span
style='color:black'>შესაბამის</span><span style='color:black'> </span><span
style='color:black'>უფლებამოსილებას</span><span style='color:black'>), </span><span
style='color:black'>იმისათვის</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>ხელი</span><span style='color:black'> </span><span
style='color:black'>მოაწერონ</span><span style='color:black'>/</span><span
style='color:black'>დაადასტურონ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შეასრულონ</span><span style='color:black'> </span><span
style='color:black'>ამ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ით</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>განაცხადებით</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებული</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებები</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებული</span><span style='color:black'> </span><span
style='color:black'>პირობების</span><span style='color:black'> </span><span
style='color:black'>შესრულებით</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>დაირღვევა</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>პირობები</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>მათი</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>რაიმე</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>კანონი</span><span style='color:black'>, </span><span
style='color:black'>დებულება</span><span style='color:black'>, </span><span
style='color:black'>წესი</span><span style='color:black'>, </span><span
style='color:black'>განკარგულება</span><span style='color:black'>, </span><span
style='color:black'>სასამართლო</span><span style='color:black'> </span><span
style='color:black'>გადაწყვეტილება</span><span style='color:black'>, </span><span
style='color:black'>ბრძანება</span><span style='color:black'>, </span><span
style='color:black'>მითითება</span><span style='color:black'>, </span><span
style='color:black'>სასამართლო</span><span style='color:black'> </span><span
style='color:black'>დადგენილება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>სახელმწიფოს</span><span style='color:black'>, </span><span
style='color:black'>მარეგულირებელი</span><span style='color:black'> </span><span
style='color:black'>ორგანოს</span><span style='color:black'>, </span><span
style='color:black'>სასამართლო</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>საარბიტრაჟო</span><span style='color:black'> </span><span
style='color:black'>ორგანოს</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>დაწესებული</span><span style='color:black'> </span><span
style='color:black'>რაიმე</span><span style='color:black'> </span><span
style='color:black'>შეზღუდვა</span><span style='color:black'>, </span><span
style='color:black'>რომლის</span><span style='color:black'> </span><span
style='color:black'>დაცვაზეც</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებელია</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>მხარე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ამ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>პირობებით</span><span style='color:black'> </span><span
style='color:black'>განსაზღვრული</span><span style='color:black'> </span><span
style='color:black'>ოპერაციის</span><span style='color:black'> </span><span
style='color:black'>შესრულება</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>მოვა</span><span style='color:black'> </span><span
style='color:black'>წინააღმდეგობაში</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>დაარღვევს</span><span style='color:black'> </span><span
style='color:black'>რაიმე</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებას</span><span style='color:black'>, </span><span
style='color:black'>კონტრაქტს</span><span style='color:black'>, </span><span
style='color:black'>ლიცენზიას</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>შეთანხმებას</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>ვრცელდება</span><span style='color:black'> </span><span
style='color:black'>რომელიმე</span><span style='color:black'> </span><span
style='color:black'>მხარეზე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მათი</span><span style='color:black'> </span><span
style='color:black'>ქმედება</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'>/</span><span
style='color:black'>იქნება</span><span style='color:black'> </span><span
style='color:black'>მიმართული</span><span style='color:black'> </span><span
style='color:black'>მეორე</span><span style='color:black'> </span><span
style='color:black'>მხარის</span><span style='color:black'> </span><span
style='color:black'>მოტყუებისაკენ</span><span style='color:black'>. </span><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>პრინციპის</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებით</span><span style='color:black'>, </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>დასადებად</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>მის</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შესასრულებლად</span><span style='color:black'>, </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>მეორე</span><span style='color:black'> </span><span
style='color:black'>მხარისათვის</span><span style='color:black'> </span><span
style='color:black'>წარდგენილი</span><span style='color:black'> </span><span
style='color:black'>დოკუმენტი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ინფორმაცია</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>წარმოდგენის</span><span style='color:black'> </span><span
style='color:black'>მომენტისათვის</span><span style='color:black'>, </span><span
style='color:black'>ასევე</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'>/</span><span
style='color:black'>იქნება</span><span style='color:black'> </span><span
style='color:black'>უტყუარი</span><span style='color:black'>, </span><span
style='color:black'>ზუსტი</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>სრული</span><span style='color:black'>. </span><span
style='color:black'>ამასთანავე</span><span style='color:black'>, </span><span
style='color:black'>მათთვის</span><span style='color:black'> </span><span
style='color:black'>ცნობილია</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>ყალბი</span><span style='color:black'> </span><span
style='color:black'>დოკუმენტების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>მიწოდება</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'> </span><span
style='color:black'>წარმოადგენს</span><span style='color:black'> </span><span
style='color:black'>დასჯად</span><span style='color:black'> </span><span
style='color:black'>ქმედებას</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>არიან</span><span style='color:black'> </span><span
style='color:black'>ჩაბმული</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>მონაწილეობენ</span><span style='color:black'> </span><span
style='color:black'>არცერთი</span><span style='color:black'> </span><span
style='color:black'>ქვეყნის</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებულ</span><span style='color:black'> </span><span
style='color:black'>რაიმე</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>უკანონო</span><span style='color:black'> </span><span
style='color:black'>საქმიანობაში</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>ფულის</span><span style='color:black'> </span><span
style='color:black'>გათეთრებაში</span><span style='color:black'>, </span><span
style='color:black'>იარაღით</span><span style='color:black'> </span><span
style='color:black'>ვაჭრობაში</span><span style='color:black'>, </span><span
style='color:black'>ტერორიზმსა</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>უკანონო</span><span style='color:black'> </span><span
style='color:black'>საქმიანობაში</span><span style='color:black'>). </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მოპოვებული</span><span style='color:black'> </span><span
style='color:black'>აქვთ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>მთელი</span><span style='color:black'> </span><span
style='color:black'>ვადის</span><span style='color:black'> </span><span
style='color:black'>განმავლობაში</span><span style='color:black'> </span><span
style='color:black'>შეინარჩუნებენ</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>ლიცენზიას</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ნებართვას</span><span style='color:black'> </span><span
style='color:black'>რაც</span><span style='color:black'> </span><span
style='color:black'>აუცილებელია</span><span style='color:black'> </span><span
style='color:black'>მათი</span><span style='color:black'> </span><span
style='color:black'>პროდუქტის</span><span style='color:black'>/</span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>რეალიზაციისათვის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>დადგენილი</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>ვალდებულების</span><span style='color:black'> </span><span
style='color:black'>შესრულებისთვის</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.1.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მათი</span><span style='color:black'> </span><span
style='color:black'>კლიენტებისათვის</span><span style='color:black'> </span><span
style='color:black'>შეთავაზებული</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>მომსახურება</span><span style='color:black'> </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>შეესაბამება</span><span style='color:black'> </span><span
style='color:black'>მოქმედი</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნებს</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერ</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>იურისდიქციაში</span><span style='color:black'> </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>ვრცელდება</span><span style='color:black'> </span><span
style='color:black'>თითოეულ</span><span style='color:black'> </span><span
style='color:black'>მხარეზე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>განცხადებები</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>გარანტიები</span><span style='color:black'> </span><span
style='color:black'>ძალაშია</span><span style='color:black'> </span><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'> </span><span
style='color:black'>ნაკისრი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებების</span><span style='color:black'> </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ჯეროვნად</span><span style='color:black'> </span><span
style='color:black'>შესრულებამდე</span><span style='color:black'>, </span><span
style='color:black'>მიუხედავად</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ნაწილობრივ</span><span style='color:black'> </span><span
style='color:black'>შეწყვეტისა</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მოცემული</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ძალაში</span><span style='color:black'> </span><span
style='color:black'>შესვლის</span><span style='color:black'> </span><span
style='color:black'>შემდეგ</span><span style='color:black'> </span><span
style='color:black'>თითოეული</span><span style='color:black'> </span><span
style='color:black'>მხარე</span><span style='color:black'> </span><span
style='color:black'>დაუყოვნებლივ</span><span style='color:black'> </span><span
style='color:black'>წერილობით</span><span style='color:black'> </span><span
style='color:black'>აცნობებს</span><span style='color:black'> </span><span
style='color:black'>მეორე</span><span style='color:black'> </span><span
style='color:black'>მხარეს</span><span style='color:black'> </span><span
style='color:black'>ისეთი</span><span style='color:black'> </span><span
style='color:black'>გარემოებების</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'>, </span><span
style='color:black'>რომლებმაც</span><span style='color:black'> </span><span
style='color:black'>შეიძლება</span><span style='color:black'> </span><span
style='color:black'>გამოიწვიოს</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>მუხლით</span><span style='color:black'> </span><span
style='color:black'>გათვალიწინებული</span><span style='color:black'> </span><span
style='color:black'>გარანტიების</span><span style='color:black'> </span><span
style='color:black'>დარღვევა</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>წინააღმდეგობაში</span><span style='color:black'> </span><span
style='color:black'>მოვიდეს</span><span style='color:black'> </span><span
style='color:black'>აღნიშნულ</span><span style='color:black'> </span><span
style='color:black'>განცხადებებთან</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მხარეები</span><span style='color:black'> </span><span
style='color:black'>აცხადებენ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>აცნობიერებენ</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულება</span><span style='color:black'> </span><span
style='color:black'>იდება</span><span style='color:black'> </span><span
style='color:black'>ზემოაღნიშნული</span><span style='color:black'> </span><span
style='color:black'>განცხადებების</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>გარანტიების</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მათზე</span><span style='color:black'> </span><span
style='color:black'>დაყრდნობით</span><span style='color:black'> </span><span
style='color:black'>დებენ</span><span style='color:black'>/</span><span
style='color:black'>დადებენ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შეასრულებენ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებულ</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებებს</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>განცხადებების</span><span style='color:black'> </span><span
style='color:black'>დარღვევის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'> (</span><span
style='color:black'>რითაც</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>იზღუდება</span><span style='color:black'> </span><span
style='color:black'>ზიანის</span><span style='color:black'> </span><span
style='color:black'>ანაზღაურების</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნის</span><span style='color:black'> </span><span
style='color:black'>უფლება</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>გამოწვეული</span><span style='color:black'> </span><span
style='color:black'>იქნება</span><span style='color:black'> </span><span
style='color:black'>გარანტიების</span><span style='color:black'> </span><span
style='color:black'>ამგვარი</span><span style='color:black'> </span><span
style='color:black'>დარღვევით</span><span style='color:black'>),  </span><span
style='color:black'>დამრღვევი</span><span style='color:black'> </span><span
style='color:black'>მხარე</span><span style='color:black'> </span><span
style='color:black'>თანახმაა</span><span style='color:black'> </span><span
style='color:black'>მეორე</span><span style='color:black'> </span><span
style='color:black'>მხარეს</span><span style='color:black'> </span><span
style='color:black'>აუნაზღაუროს</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დაიცვას</span><span style='color:black'> </span><span
style='color:black'>იგი</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ზიანისგან</span><span style='color:black'>, </span><span
style='color:black'>საჩივრისგან</span><span style='color:black'>, </span><span
style='color:black'>ხარჯისგან</span><span style='color:black'>, </span><span
style='color:black'>იურიდიული</span><span style='color:black'> </span><span
style='color:black'>პროცესისგან</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებისგან</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>ამგვარი</span><span style='color:black'> </span><span
style='color:black'>დარღვევის</span><span style='color:black'> </span><span
style='color:black'>შედეგად</span><span style='color:black'> </span><span
style='color:black'>შეიძლება</span><span style='color:black'> </span><span
style='color:black'>წარმოიშვას</span><span style='color:black'>, </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>მოცემული</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>შეწყვეტის</span><span style='color:black'>/</span><span
style='color:black'>ვადის</span><span style='color:black'> </span><span
style='color:black'>გასვლის</span><span style='color:black'> </span><span
style='color:black'>შემდეგაც</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>მხარეთა</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>პასუხისმგებლობა</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>რამ</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>განსაზღვრული</span><span style='color:black'>, </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>თანხმაა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>აცხადებს</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>განხორციელების</span><span style='color:black'> </span><span
style='color:black'>პროცესში</span><span style='color:black'>, KEEPZ.ME </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>თავისუფლდება</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>წინაშე</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებლობისაგან</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>შეუზღუდავად</span><span style='color:black'>, </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>პირდაპირი</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>არაპირდაპირი</span><span style='color:black'> </span><span
style='color:black'>ზიანის</span><span style='color:black'> (</span><span
style='color:black'>ზარალის</span><span style='color:black'>) </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დაკარგული</span><span style='color:black'> </span><span
style='color:black'>მოგების</span><span style='color:black'>, </span><span
style='color:black'>ბიზნესის</span><span style='color:black'>, </span><span
style='color:black'>შემოსავლის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>დაგეგმილი</span><span style='color:black'> </span><span
style='color:black'>დანაზოგების</span><span style='color:black'> </span><span
style='color:black'>ანაზღაურების</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებისგან</span><span style='color:black'>), </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>გამოწვეულია</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>განზრახი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>უხეში</span><span style='color:black'> </span><span
style='color:black'>გაუფრთხილებლობის</span><span style='color:black'> </span><span
style='color:black'>გარეშე</span><span style='color:black'>, </span><span
style='color:black'>შესაბამისად</span><span style='color:black'>, KEEPZ.ME </span><span
style='color:black'>ვალდებულია</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'> </span><span
style='color:black'>აუნაზღაუროს</span><span style='color:black'> </span><span
style='color:black'>მხოლოდ</span><span style='color:black'> </span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>ხარჯი</span><span style='color:black'>/</span><span
style='color:black'>ზიანი</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>წარმოიშვა</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>ბრალეულობით</span><span style='color:black'> (</span><span
style='color:black'>განზრახი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>უხეში</span><span style='color:black'> </span><span
style='color:black'>გაუფრთხილებელი</span><span style='color:black'> </span><span
style='color:black'>ქმედებით</span><span style='color:black'>) </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>არა</span><span style='color:black'> </span><span
style='color:black'>მხოლოდ</span><span style='color:black'>, </span><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაციის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>დავალების</span><span style='color:black'> </span><span
style='color:black'>შეუსრულებლობის</span><span style='color:black'>, </span><span
style='color:black'>არასწორად</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>დაგვიანებით</span><span style='color:black'> </span><span
style='color:black'>შესრულების</span><span style='color:black'> </span><span
style='color:black'>შედეგად</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>აცნობიერებს</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> KEEPZ.ME </span><span
style='color:black'>ახორციელებს</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>განსაზღვრულ</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>მომსახურებას</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მოცემული</span><span style='color:black'> </span><span
style='color:black'>ურთიერთობის</span><span style='color:black'> </span><span
style='color:black'>ფარგლებში</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>წარმოადგენს</span><span style='color:black'> </span><span
style='color:black'>გამყიდველს</span><span style='color:black'>, </span><span
style='color:black'>მყიდველს</span><span style='color:black'>, </span><span
style='color:black'>დილერს</span><span style='color:black'>, </span><span
style='color:black'>მიმწოდებელს</span><span style='color:black'>, </span><span
style='color:black'>დისტრიბუტორს</span><span style='color:black'>, </span><span
style='color:black'>მწარმოებელს</span><span style='color:black'>, </span><span
style='color:black'>ბროკერს</span><span style='color:black'>, </span><span
style='color:black'>აგენტს</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებელი</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>მესამე</span><span style='color:black'> </span><span
style='color:black'>პირების</span><span style='color:black'> </span><span
style='color:black'>წინაშე</span><span style='color:black'> </span><span
style='color:black'>ნაკისრ</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებებზე</span><span style='color:black'>, </span><span
style='color:black'>გარდა</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებებისა</span><span style='color:black'>, </span><span
style='color:black'>რომელთა</span><span style='color:black'> </span><span
style='color:black'>შესრულებაზეც</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ფარგლებში</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებელია</span><span style='color:black'> KEEPZ.ME.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>იღებს</span><span style='color:black'> </span><span
style='color:black'>მთელ</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებლობას</span><span style='color:black'> </span><span
style='color:black'>მის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>რეალიზებული</span><span style='color:black'> </span><span
style='color:black'>პროდუქტის</span><span style='color:black'>/</span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>ხარისხზე</span><span style='color:black'> (</span><span
style='color:black'>ასეთის</span><span style='color:black'> </span><span
style='color:black'>არსებობის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>), </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მესამე</span><span style='color:black'> </span><span
style='color:black'>პირებისათვის</span><span style='color:black'> </span><span
style='color:black'>წარდგენილი</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>შინაარსზე</span><span style='color:black'>, </span><span
style='color:black'>აგრეთვე</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>შინაარსზე</span><span style='color:black'>, </span><span
style='color:black'>რომელსაც</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>ათავსებს</span><span style='color:black'> </span><span
style='color:black'>ინტერნეტ</span><span style='color:black'> </span><span
style='color:black'>ქსელში</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>პროდუქტის</span><span style='color:black'>/</span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>რეალიზებასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებით</span><span style='color:black'>, </span><span
style='color:black'>ამასთანავე</span><span style='color:black'> </span><span
style='color:black'>იგი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულია</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>ელექტრონულ</span><span style='color:black'> </span><span
style='color:black'>ფოსტაზე</span><span style='color:black'>  </span><span
style='color:black'>გაგზავნილი</span><span style='color:black'> </span><span
style='color:black'>თხოვნის</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'>, </span><span
style='color:black'>წაშალოს</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ინფორმაცია</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>განთავსებულია</span><span style='color:black'> </span><span
style='color:black'>ინტერნეტ</span><span style='color:black'> </span><span
style='color:black'>ქსელში</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხარებელი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულია</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>აუნაზღაუროს</span><span style='color:black'>, </span><span
style='color:black'>და</span><span style='color:black'>, </span><span
style='color:black'>ასევე</span><span style='color:black'>, </span><span
style='color:black'>დაიცვას</span><span style='color:black'> </span><span
style='color:black'>იგი</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>სარჩელისაგან</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>პირდაპირი</span><span style='color:black'>, </span><span
style='color:black'>ირიბი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>შედეგობრივი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებებისაგან</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>სარგებლის</span><span style='color:black'> </span><span
style='color:black'>დანაკარგის</span><span style='color:black'>, </span><span
style='color:black'>ბიზნესის</span><span style='color:black'> </span><span
style='color:black'>დანაკარგის</span><span style='color:black'>, </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მსგავსი</span><span style='color:black'> </span><span
style='color:black'>დანაკარგებისაგან</span><span style='color:black'>), </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>ხარჯებისაგან</span><span style='color:black'>, </span><span
style='color:black'>საქმისწარმოების</span><span style='color:black'>, </span><span
style='color:black'>ზარალისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დანახარჯებისაგან</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>პარტნიორი</span><span style='color:black'> </span><span
style='color:black'>ბანკის</span><span style='color:black'>) </span><span
style='color:black'>მიმართ</span><span style='color:black'> </span><span
style='color:black'>წარმოშობილი</span><span style='color:black'> </span><span
style='color:black'>ფულადი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებისგან</span><span style='color:black'>, </span><span
style='color:black'>იურიდიული</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>პროფესიული</span><span style='color:black'> </span><span
style='color:black'>მოსაკრებლებისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ხარჯებისაგან</span><span style='color:black'>), </span><span
style='color:black'>რომლებიც</span><span style='color:black'> </span><span
style='color:black'>მიადგა</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>დაეკისრა</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>მესამე</span><span style='color:black'> </span><span
style='color:black'>პირის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ორგანოს</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'>, </span><span
style='color:black'>იმის</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებით</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>ვალდებულება</span><span style='color:black'>, </span><span
style='color:black'>დანაკარგი</span><span style='color:black'>, </span><span
style='color:black'>ზიანი</span><span style='color:black'>, </span><span
style='color:black'>ზარალი</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ხარჯი</span><span style='color:black'> </span><span
style='color:black'>გამოწვეული</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>იყო</span><span style='color:black'> </span><span
style='color:black'>მოცემული</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>პირობებისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებების</span><span style='color:black'> </span><span
style='color:black'>განხორციელების</span><span style='color:black'> </span><span
style='color:black'>უგულებელყოფის</span><span style='color:black'>, </span><span
style='color:black'>დარღვევის</span><span style='color:black'>, </span><span
style='color:black'>შეუსრულებლობის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>დაგვიანებით</span><span style='color:black'> </span><span
style='color:black'>შესრულების</span><span style='color:black'> </span><span
style='color:black'>გამო</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>პირის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.30j0zll"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>3.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>ნებისმიერ</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'>, KEEPZ.ME-</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>მთლიანი</span><span
style='color:black'> </span><span style='color:black'>პასუხისმგებლობა</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>მიმართ</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> </span><span style='color:black'>სახის</span><span
style='color:black'> </span><span style='color:black'>ზიანის</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>ხარჯებისათვის</span><span
style='color:black'>, </span><span style='color:black'>შემოიფარგლება</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>თვის</span><span
style='color:black'> </span><span style='color:black'>თანამშრომლობის</span><span
style='color:black'> </span><span style='color:black'>მანძილზე</span><span
style='color:black'> </span><span style='color:black'>გადახდილი</span><span
style='color:black'> </span><span style='color:black'>მთლიანი</span><span
style='color:black'> </span><span style='color:black'>საკომისიოს</span><span
style='color:black'> </span><span style='color:black'>ოდენობით</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხარებლის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>წინაშე</span><span style='color:black'> </span><span
style='color:black'>შესასრულებელი</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ვადამოსული</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ვადამოუსვლელი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულების</span><span style='color:black'>/</span><span
style='color:black'>გადასახდელის</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>მხარეებს</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>არსებული</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>სახელშეკრულებო</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>არასახელშეკრულებო</span><span style='color:black'> </span><span
style='color:black'>ურთიერთობიდან</span><span style='color:black'> </span><span
style='color:black'>გამომდინარე</span><span style='color:black'>, </span><span
style='color:black'>ასევე</span><span style='color:black'>  KEEPZ.ME -</span><span
style='color:black'>ის</span><span style='color:black'>  </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>მესამე</span><span style='color:black'> </span><span
style='color:black'>პირისგან</span><span style='color:black'> </span><span
style='color:black'>შეძენილი</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნის</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'> </span><span
style='color:black'>წარმოშობილი</span><span style='color:black'>) </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ჯეროვნად</span><span style='color:black'> </span><span
style='color:black'>შესრულების</span><span style='color:black'> </span><span
style='color:black'>უზრუნველსაყოფად</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'>, KEEPZ.ME -</span><span
style='color:black'>ს</span><span style='color:black'>  </span><span
style='color:black'>უფლებებისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნების</span><span style='color:black'> </span><span
style='color:black'>რეალიზების</span><span style='color:black'> </span><span
style='color:black'>მიზნით</span><span style='color:black'>, KEEPZ.ME </span><span
style='color:black'>უფლებამოსილია</span><span style='color:black'> </span><span
style='color:black'>საკუთარი</span><span style='color:black'> </span><span
style='color:black'>შეხედულებისამებრ</span><span style='color:black'>, </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>შემდგომი</span><span style='color:black'>/</span><span
style='color:black'>დამატებითი</span><span style='color:black'> </span><span
style='color:black'>თანხმობის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>აქცეპტის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ინფორმირების</span><span style='color:black'> </span><span
style='color:black'>გარეშე</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>შეასრულოს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>წინაშე</span><span style='color:black'> </span><span
style='color:black'>არსებული</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულება</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>გადაუხადოს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'> </span><span
style='color:black'>მისთვის</span><span style='color:black'> </span><span
style='color:black'>გადასახდელი</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>თანხა</span><span style='color:black'>, </span><span
style='color:black'>რაც</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>მოცულობით</span><span style='color:black'> </span><span
style='color:black'>ჩაითვლება</span><span style='color:black'> (</span><span
style='color:black'>გაიქვითება</span><span style='color:black'>) </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>თვის</span><span style='color:black'> </span><span
style='color:black'>შესასრულებელი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულების</span><span style='color:black'>/</span><span
style='color:black'>გადასახდელის</span><span style='color:black'> </span><span
style='color:black'>ანგარიშში</span><span style='color:black'>).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ინფორმაციული</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>უსაფრთხოება</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.1fob9te"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>4.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>თითოეული</span><span style='color:black'> </span><span
style='color:black'>მხარე</span><span style='color:black'> </span><span
style='color:black'>შეინარჩუნებს</span><span style='color:black'> </span><span
style='color:black'>კომერციულად</span><span style='color:black'> </span><span
style='color:black'>გონივრულ</span><span style='color:black'> </span><span
style='color:black'>ადმინისტრაციულ</span><span style='color:black'>, </span><span
style='color:black'>ტექნიკურ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ფიზიკურ</span><span style='color:black'> </span><span
style='color:black'>კონტროლს</span><span style='color:black'>, </span><span
style='color:black'>მის</span><span style='color:black'> </span><span
style='color:black'>მფლობელობაში</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>მის</span><span style='color:black'> </span><span
style='color:black'>კონტროლ</span><span style='color:black'> </span><span
style='color:black'>ქვეშ</span><span style='color:black'> </span><span
style='color:black'>მყოფი</span><span style='color:black'> </span><span
style='color:black'>მონაცემების</span><span style='color:black'> </span><span
style='color:black'>დასაცავად</span><span style='color:black'> </span><span
style='color:black'>არაავტორიზებული</span><span style='color:black'> </span><span
style='color:black'>წვდომისგან</span><span style='color:black'>, </span><span
style='color:black'>შემთხვევითი</span><span style='color:black'> </span><span
style='color:black'>დაკარგვისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>არაავტორიზებული</span><span style='color:black'> </span><span
style='color:black'>ცვლილებებისგან</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME </span><span style='color:black'>უზრუნველყოფს</span><span
style='color:black'>, </span><span style='color:black'>მისი</span><span
style='color:black'> </span><span style='color:black'>კონტროლის</span><span
style='color:black'> </span><span style='color:black'>სფეროში</span><span
style='color:black'> </span><span style='color:black'>არსებული</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> PCI-DSS </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>კანონმდებლობის</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნების</span><span
style='color:black'> (</span><span style='color:black'>მათ</span><span
style='color:black'> </span><span style='color:black'>შორის</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>არა</span><span
style='color:black'> </span><span style='color:black'>მხოლოდ</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>ძლიერი</span><span
style='color:black'> </span><span style='color:black'>ავთენტიფიკაციის</span><span
style='color:black'> </span><span style='color:black'>წესთან</span><span
style='color:black'>) </span><span style='color:black'>შესაბამისად</span><span
style='color:black'> </span><span style='color:black'>განხორციელებას</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულია</span><span style='color:black'> </span><span
style='color:black'>თავიდან</span><span style='color:black'> </span><span
style='color:black'>აიცილოს</span><span style='color:black'> </span><span
style='color:black'>რაიმე</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>არაავტორიზებული</span><span style='color:black'> </span><span
style='color:black'>წვდომა</span><span style='color:black'> KEEPZ.ME -</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>ანგარიშზე</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>უზრუნველყოს</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>იგი</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>იქნება</span><span style='color:black'> </span><span
style='color:black'>გამოყენებული</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>შეცვლილი</span><span style='color:black'> </span><span
style='color:black'>მესამე</span><span style='color:black'> </span><span
style='color:black'>მხარის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'>. </span><span
style='color:black'>ამასთან</span><span style='color:black'>, </span><span
style='color:black'>არაავტორიზებული</span><span style='color:black'> </span><span
style='color:black'>წვდომის</span><span style='color:black'> </span><span
style='color:black'>ფაქტის</span><span style='color:black'> </span><span
style='color:black'>დადგომის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'> (</span><span
style='color:black'>რაც</span><span style='color:black'> </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>გულისხმობს</span><span style='color:black'> </span><span
style='color:black'>ეჭვის</span><span style='color:black'> </span><span
style='color:black'>არსებობას</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>ანგარიშის</span><span style='color:black'> </span><span
style='color:black'>მონაცემები</span><span style='color:black'> </span><span
style='color:black'>დაიკარგა</span><span style='color:black'>, </span><span
style='color:black'>მოიპარეს</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>მოხდა</span><span style='color:black'>/</span><span
style='color:black'>შესაძლოა</span><span style='color:black'> </span><span
style='color:black'>მოხდეს</span><span style='color:black'> </span><span
style='color:black'>ანგარიშის</span><span style='color:black'> </span><span
style='color:black'>არამიზნობრივად</span><span style='color:black'> </span><span
style='color:black'>გამოყენება</span><span style='color:black'>) </span><span
style='color:black'>მომხარებელმა</span><span style='color:black'> </span><span
style='color:black'>დაუყონებლივ</span><span style='color:black'> </span><span
style='color:black'>უნდა</span><span style='color:black'> </span><span
style='color:black'>აცნობოს</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ითანამშრომლოს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>თან</span><span style='color:black'>, </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>მიწოდებით</span><span style='color:black'>, </span><span
style='color:black'>რომელსაც</span><span style='color:black'> KEEPZ.ME </span><span
style='color:black'>ითხოვს</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხარებელი</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებელი</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ანგარიშვალდებული</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>აქტივობებზე</span><span style='color:black'>, </span><span
style='color:black'>რაც</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>ანგარიშის</span><span style='color:black'> </span><span
style='color:black'>გამოყენებით</span><span style='color:black'> </span><span
style='color:black'>განხორციელდება</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>რაც</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>ექცევა</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>კონტროლის</span><span style='color:black'> </span><span
style='color:black'>სფეროში</span><span style='color:black'>. </span><span
style='color:black'>ამასთან</span><span style='color:black'>, </span><span
style='color:black'>გარდა</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებითა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>შეთანხმებით</span><span style='color:black'> </span><span
style='color:black'>განსაზღვრული</span><span style='color:black'> </span><span
style='color:black'>ვალდებულების</span><span style='color:black'> </span><span
style='color:black'>დარღვევის</span><span style='color:black'> </span><span
style='color:black'>შედეგად</span><span style='color:black'> </span><span
style='color:black'>დამდგარი</span><span style='color:black'> </span><span
style='color:black'>ზიანისა</span><span style='color:black'>, </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>ანგარიშვალდებული</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>დამდგარ</span><span style='color:black'> </span><span
style='color:black'>დანაკარგზე</span><span style='color:black'>/</span><span
style='color:black'>ზიანზე</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>მიადგება</span><span style='color:black'> </span><span
style='color:black'>მოპარული</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>დაკარგული</span><span style='color:black'> </span><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ანგარიშის</span><span style='color:black'> </span><span
style='color:black'>მონაცემების</span><span style='color:black'> </span><span
style='color:black'>გამოყენების</span><span style='color:black'> </span><span
style='color:black'>შედეგად</span><span style='color:black'>. KEEPZ.ME </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>აზღვევს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'> </span><span
style='color:black'>დანაკარგებისგან</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>შესაძლოა</span><span style='color:black'>, </span><span
style='color:black'>მიადგეს</span><span style='color:black'> </span><span
style='color:black'>თაღლითობის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>გარემოებების</span><span style='color:black'> </span><span
style='color:black'>გამო</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>კონფიდენციალურობა</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მხარეები</span><span style='color:black'> </span><span
style='color:black'>ვალდებული</span><span style='color:black'> </span><span
style='color:black'>არიან</span><span style='color:black'>, </span><span
style='color:black'>როგორც</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>მოქმედების</span><span style='color:black'> </span><span
style='color:black'>პერიოდში</span><span style='color:black'>, </span><span
style='color:black'>ასევე</span><span style='color:black'> </span><span
style='color:black'>სახელშეკრულებო</span><span style='color:black'> </span><span
style='color:black'>ურთიერთობის</span><span style='color:black'> </span><span
style='color:black'>დამთავრების</span><span style='color:black'> </span><span
style='color:black'>შემდეგაც</span><span style='color:black'> </span><span
style='color:black'>დაიცვან</span><span style='color:black'> </span><span
style='color:black'>მეორე</span><span style='color:black'> </span><span
style='color:black'>მხარისაგან</span><span style='color:black'> </span><span
style='color:black'>მიღებული</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>კონფიდენციალურობა</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>კონფიდენციალურობის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'> </span><span
style='color:black'>ზემოაღნიშნული</span><span style='color:black'> </span><span
style='color:black'>შეზღუდვა</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>შეეხება</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციას</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>გამჟღავნებას</span><span style='color:black'>:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>დარღვევის</span><span style='color:black'> </span><span
style='color:black'>გარეშე</span><span style='color:black'> </span><span
style='color:black'>იყო</span><span style='color:black'> </span><span
style='color:black'>ცნობილი</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>მიმღები</span><span style='color:black'> </span><span
style='color:black'>მხარისათვის</span><span style='color:black'> </span><span
style='color:black'>მეორე</span><span style='color:black'> </span><span
style='color:black'>მხარის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>მიწოდებამდე</span><span style='color:black'>; </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>გამჟღავნება</span><span style='color:black'> </span><span
style='color:black'>მოხდება</span><span style='color:black'> </span><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნათა</span><span style='color:black'> </span><span
style='color:black'>დაცვით</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შესასრულებლად</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>რომელიმე</span><span style='color:black'> </span><span
style='color:black'>მხარის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>სასამართლო</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>საარბიტრაჟო</span><span style='color:black'> </span><span
style='color:black'>სასამართლო</span><span style='color:black'>) </span><span
style='color:black'>წესით</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>უფლებების</span><span style='color:black'> </span><span
style='color:black'>განსახორციელებლად</span><span style='color:black'>) </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>საზედამხედველო</span><span style='color:black'> </span><span
style='color:black'>ორგანოს</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>პარტნიორი</span><span style='color:black'> </span><span
style='color:black'>ბანკის</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნის</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>რომლის</span><span style='color:black'> </span><span
style='color:black'>მოპოვებაც</span><span style='color:black'> </span><span
style='color:black'>შესაძლებელია</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>წყაროებიდან</span><span style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>მესამე</span><span style='color:black'> </span><span
style='color:black'>პირისთვის</span><span style='color:black'> </span><span
style='color:black'>გამჟღავნება</span><span style='color:black'> </span><span
style='color:black'>მოხდება</span><span style='color:black'> </span><span
style='color:black'>ა</span><span style='color:black'>) </span><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>წერილობითი</span><span style='color:black'> </span><span
style='color:black'>შეთანხმებით</span><span style='color:black'>, </span><span
style='color:black'>რა</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაშიც</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>გამცემი</span><span style='color:black'> </span><span
style='color:black'>მხარე</span><span style='color:black'> </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>აგებს</span><span style='color:black'> </span><span
style='color:black'>პასუხს</span><span style='color:black'> </span><span
style='color:black'>მესამე</span><span style='color:black'> </span><span
style='color:black'>პირის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>მისთვის</span><span style='color:black'> </span><span
style='color:black'>გადაცემული</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>კონფიდენციალურობის</span><span style='color:black'> </span><span
style='color:black'>დაცვაზე</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ბ</span><span style='color:black'>) </span><span
style='color:black'>რომელიმე</span><span style='color:black'> </span><span
style='color:black'>მხარისგან</span><span style='color:black'> </span><span
style='color:black'>დამოუკიდებლად</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>კონფიდენციალურობასა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>პერსონალური</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>დამუშავებასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>დამატებითი</span><span style='color:black'> </span><span
style='color:black'>უფლება</span><span style='color:black'>-</span><span
style='color:black'>მოვალეობები</span><span style='color:black'> </span><span
style='color:black'>მოცემულია</span><span style='color:black'> </span><span
style='color:black'>ჩვენს</span><span style='color:black'> </span><span
style='color:black'>ვებგვერდზე</span><span style='color:black'>, „</span><span
style='color:black'>კონფიდენციალურობის</span><span style='color:black'> </span><span
style='color:black'>პოლიტიკა</span><span style='color:black'>“-</span><span
style='color:black'>ში</span><span style='color:black'>,</span></span><i><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span></i><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>რომელიც</span><span
style='color:black'> </span><span style='color:black'>წარმოადგენს</span><span
style='color:black'> </span><span style='color:black'>წინამდებარე</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულების</span><span
style='color:black'> </span><span style='color:black'>განუყოფელ</span><span
style='color:black'> </span><span style='color:black'>ნაწილს</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მოქმედებს</span><span
style='color:black'> </span><span style='color:black'>მასთან</span><span
style='color:black'> </span><span style='color:black'>ერთად</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ინტელექტუალური</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>საკუთრება</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>და</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>მარკეტინგული</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>თანამშრომლობა</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME -</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>ყველა</span><span
style='color:black'> </span><span style='color:black'>ინტელექტუალური</span><span
style='color:black'> </span><span style='color:black'>საკუთრების</span><span
style='color:black'> </span><span style='color:black'>უფლება</span><span
style='color:black'> </span><span style='color:black'>რჩება</span><span
style='color:black'> </span><span style='color:black'>მხოლოდ</span><span
style='color:black'> </span><span style='color:black'>მის</span><span
style='color:black'> </span><span style='color:black'>საკუთრებად</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელმა</span><span
style='color:black'> </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>უნდა</span><span
style='color:black'> </span><span style='color:black'>წამოაყენოს</span><span
style='color:black'> </span><span style='color:black'>რაიმე</span><span
style='color:black'> </span><span style='color:black'>პრეტენზია</span><span
style='color:black'> </span><span style='color:black'>მათზე</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერ</span><span
style='color:black'> </span><span style='color:black'>დროს</span><span
style='color:black'>, </span><span style='color:black'>მათ</span><span
style='color:black'> </span><span style='color:black'>შორის</span><span
style='color:black'>, </span><span style='color:black'>ამ</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულების</span><span
style='color:black'> </span><span style='color:black'>შეწყვეტის</span><span
style='color:black'> </span><span style='color:black'>შემდეგაც</span><span
style='color:black'>. </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>იყენებს</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>ინტელექტუალური</span><span
style='color:black'> </span><span style='color:black'>საკუთრების</span><span
style='color:black'> </span><span style='color:black'>უფლებებს</span></span><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>მხოლოდ</span><span
style='color:black'> </span><span style='color:black'>იმდენად</span><span
style='color:black'>, </span><span style='color:black'>რამდენადაც</span><span
style='color:black'> </span><span style='color:black'>დაშვებულია</span><span
style='color:black'> </span><span style='color:black'>ამ</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულებით</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მხოლოდ</span><span
style='color:black'> </span><span style='color:black'>ამ</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულების</span><span
style='color:black'> </span><span style='color:black'>მოქმედების</span><span
style='color:black'> </span><span style='color:black'>ვადის</span><span
style='color:black'> </span><span style='color:black'>განმავლობაში</span><span
style='color:black'>. </span><span style='color:black'>მომხმარებელმა</span><span
style='color:black'> </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>უნდა</span><span
style='color:black'> </span><span style='color:black'>განახორციელოს</span><span
style='color:black'> </span><span style='color:black'>რაიმე</span><span
style='color:black'> </span><span style='color:black'>ქმედება</span><span
style='color:black'>, </span><span style='color:black'>რომელიც</span><span
style='color:black'> </span><span style='color:black'>არათავსებადი</span><span
style='color:black'> </span><span style='color:black'>იქნება</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>ინტელექტუალური</span><span
style='color:black'> </span><span style='color:black'>საკუთრების</span><span
style='color:black'> </span><span style='color:black'>უფლებებთან</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>უნდა</span><span
style='color:black'> </span><span style='color:black'>გამოიჩინოს</span><span
style='color:black'> </span><span style='color:black'>გონივრული</span><span
style='color:black'> </span><span style='color:black'>მზრუნველობა</span><span
style='color:black'> </span><span style='color:black'>ამ</span><span
style='color:black'> </span><span style='color:black'>უფლებების</span><span
style='color:black'> </span><span style='color:black'>დასაცავად</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>ინტელექტუალური</span><span style='color:black'> </span><span
style='color:black'>საკუთრების</span><span style='color:black'> </span><span
style='color:black'>უფლება</span><span style='color:black'> </span><span
style='color:black'>რჩება</span><span style='color:black'> </span><span
style='color:black'>მხოლოდ</span><span style='color:black'> </span><span
style='color:black'>მის</span><span style='color:black'> </span><span
style='color:black'>საკუთრებად</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> KEEPZ.ME -</span><span
style='color:black'>მ</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>უნდა</span><span style='color:black'> </span><span
style='color:black'>წამოაყენოს</span><span style='color:black'> </span><span
style='color:black'>რაიმე</span><span style='color:black'> </span><span
style='color:black'>პრეტენზია</span><span style='color:black'> </span><span
style='color:black'>მათზე</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერ</span><span style='color:black'> </span><span
style='color:black'>დროს</span><span style='color:black'>, </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>ამ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>შეწყვეტის</span><span style='color:black'> </span><span
style='color:black'>შემდეგაც</span><span style='color:black'>. </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>მოქმედების</span><span style='color:black'> </span><span
style='color:black'>ვადის</span><span style='color:black'> </span><span
style='color:black'>განმავლობაში</span><span style='color:black'>, </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>გადასცემს</span><span style='color:black'> </span><span
style='color:black'>არაექსკლუზიურ</span><span style='color:black'>, </span><span
style='color:black'>როიალტისგან</span><span style='color:black'> </span><span
style='color:black'>გათავისუფლებულ</span><span style='color:black'> </span><span
style='color:black'>უფლებას</span><span style='color:black'> </span><span
style='color:black'>გამოიყენოს</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>ინტელექტუალური</span><span style='color:black'> </span><span
style='color:black'>საკუთრება</span><span style='color:black'>, </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ლოგო</span><span style='color:black'>, </span><span
style='color:black'>სასაქონლო</span><span style='color:black'> </span><span
style='color:black'>ნიშანი</span><span style='color:black'>, </span><span
style='color:black'>სავაჭრო</span><span style='color:black'> </span><span
style='color:black'>სახელწოდება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ინტელექტუალური</span><span style='color:black'> </span><span
style='color:black'>საკუთრება</span><span style='color:black'> </span><span
style='color:black'>მსოფლიოს</span><span style='color:black'> </span><span
style='color:black'>მასშტაბით</span><span style='color:black'>. </span><span
style='color:black'>ამასთან</span><span style='color:black'>, </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>წერილობითი</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნის</span><span style='color:black'> </span><span
style='color:black'>მიღებისთანავე</span><span style='color:black'>, KEEPZ.ME </span><span
style='color:black'>ვალდებულია</span><span style='color:black'> </span><span
style='color:black'>დაუყონებლივ</span><span style='color:black'> </span><span
style='color:black'>შეწყვიტოს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>ინტელექტუალური</span><span style='color:black'> </span><span
style='color:black'>საკუთრების</span><span style='color:black'> </span><span
style='color:black'>გამოყენება</span><span style='color:black'>, </span><span
style='color:black'>გარდა</span><span style='color:black'> </span><span
style='color:black'>ისეთი</span><span style='color:black'> </span><span
style='color:black'>შემთხვევებისა</span><span style='color:black'>, </span><span
style='color:black'>როცა</span><span style='color:black'> </span><span
style='color:black'>მსგავსი</span><span style='color:black'> </span><span
style='color:black'>შეწყვეტა</span><span style='color:black'> </span><span
style='color:black'>გამოიწვევს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>მხრიდან</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>დაკისრებული</span><span style='color:black'> </span><span
style='color:black'>ვალდებულების</span><span style='color:black'> </span><span
style='color:black'>დარღვევას</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულია</span><span style='color:black'> </span><span
style='color:black'>დაიცვას</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>მისთვის</span><span style='color:black'> </span><span
style='color:black'>გადაცემული</span><span style='color:black'> </span><span
style='color:black'>ინტელექტუალური</span><span style='color:black'> </span><span
style='color:black'>საკუთრების</span><span style='color:black'> </span><span
style='color:black'>უფლების</span><span style='color:black'> </span><span
style='color:black'>სარგებლობის</span><span style='color:black'> </span><span
style='color:black'>წესები</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>პირობები</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME </span><span style='color:black'>იტოვებს</span><span
style='color:black'> </span><span style='color:black'>უფლებას</span><span
style='color:black'>, </span><span style='color:black'>ნებისმიერ</span><span
style='color:black'> </span><span style='color:black'>დროს</span><span
style='color:black'> </span><span style='color:black'>მოსთხოვოს</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელს</span><span
style='color:black'> </span><span style='color:black'>შეწყვიტოს</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მასთან</span><span
style='color:black'> </span><span style='color:black'>დაკავშირებული</span><span
style='color:black'> </span><span style='color:black'>პირების</span><span
style='color:black'> </span><span style='color:black'>ინტელექტუალური</span><span
style='color:black'> </span><span style='color:black'>საკუთრების</span><span
style='color:black'> </span><span style='color:black'>გამოქვეყნება</span><span
style='color:black'>, </span><span style='color:black'>გავრცელება</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>სხვაგვარად</span><span
style='color:black'> </span><span style='color:black'>შეზღუდოს</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>მათი</span><span
style='color:black'> </span><span style='color:black'>გამოყენება</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>უფლებამოსილი</span><span style='color:black'> </span><span
style='color:black'>გამოიყენოს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>საფირმო</span><span style='color:black'> </span><span
style='color:black'>სახელწოდება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>სასაქონლო</span><span style='color:black'> </span><span
style='color:black'>ნიშანი</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>წინასწარი</span><span style='color:black'> </span><span
style='color:black'>წერილობითი</span><span style='color:black'> </span><span
style='color:black'>თანხმობის</span><span style='color:black'> </span><span
style='color:black'>გარეშე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>მუხლით</span><span style='color:black'> </span><span
style='color:black'>განსაზღვრული</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებები</span><span style='color:black'> </span><span
style='color:black'>ძალაში</span><span style='color:black'> </span><span
style='color:black'>რჩება</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მოქმედებს</span><span style='color:black'> </span><span
style='color:black'>ხელშკრულების</span><span style='color:black'> </span><span
style='color:black'>შეწვეტის</span><span style='color:black'>/</span><span
style='color:black'>ვადის</span><span style='color:black'> </span><span
style='color:black'>გასვლის</span><span style='color:black'> </span><span
style='color:black'>შემდეგაც</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>მომსახურების</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>საფასური</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.3znysh7"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>7.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>შესაბამისი</span><span
style='color:black'> </span><span style='color:black'>მომსახურებით</span><span
style='color:black'> </span><span style='color:black'>სარგებლობის</span><span
style='color:black'> </span><span style='color:black'>საფასური</span><span
style='color:black'> </span><span style='color:black'>განსაზღვრულია</span><span
style='color:black'> </span><span style='color:black'>ცალკეული</span><span
style='color:black'> </span><span style='color:black'>მომსახურებით</span><span
style='color:black'> </span><span style='color:black'>სარგებლობის</span><span
style='color:black'> </span><span style='color:black'>წესებსა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>პირობებში</span><span
style='color:black'>. </span><span style='color:black'>ამასთან</span><span
style='color:black'>, </span><span style='color:black'>მხარეთა</span><span
style='color:black'> </span><span style='color:black'>ორმხრივი</span><span
style='color:black'> </span><span style='color:black'>წერილობითი</span><span
style='color:black'> </span><span style='color:black'>შეთანხმების</span><span
style='color:black'> </span><span style='color:black'>საფუძველზე</span><span
style='color:black'>, </span><span style='color:black'>შესაძლებელია</span><span
style='color:black'> </span><span style='color:black'>დაწესდეს</span><span
style='color:black'> </span><span style='color:black'>სპეციალური</span><span
style='color:black'>, </span><span style='color:black'>სტანდარტული</span><span
style='color:black'> </span><span style='color:black'>საკომისიოსაგან</span><span
style='color:black'> </span><span style='color:black'>განსხვავებული</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>საფასური</span><span
style='color:black'>; </span><span style='color:black'>განსხვავებული</span><span
style='color:black'> </span><span style='color:black'>საკომისიოს</span><span
style='color:black'> </span><span style='color:black'>დაწესება</span><span
style='color:black'> </span><span style='color:black'>მოხდება</span><span
style='color:black'> </span><span style='color:black'>ბიზნეს</span><span
style='color:black'> </span><span style='color:black'>გადაწვეტილების</span><span
style='color:black'> </span><span style='color:black'>ფარგლებში</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>ბრუნვისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ტრანზაქციების</span><span
style='color:black'> </span><span style='color:black'>რაოდენობის</span><span
style='color:black'> </span><span style='color:black'>გათვალიწინებით</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.2et92p0"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>7.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>სტანდარტული</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>საკომისიოს</span><span
style='color:black'> </span><span style='color:black'>გარდა</span><span
style='color:black'>, </span><span style='color:black'>შესაძლებელია</span><span
style='color:black'> </span><span style='color:black'>გარკვეულ</span><span
style='color:black'> </span><span style='color:black'>შემთხვევებში</span><span
style='color:black'>, </span><span style='color:black'>დაწესდეს</span><span
style='color:black'> </span><span style='color:black'>დამატებითი</span><span
style='color:black'> </span><span style='color:black'>საკომისიო</span><span
style='color:black'>, </span><span style='color:black'>რომლის</span><span
style='color:black'> </span><span style='color:black'>გადახდაზეც</span><span
style='color:black'> </span><span style='color:black'>პასუხისმგებლობა</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელს</span><span
style='color:black'> </span><span style='color:black'>ეკისრება</span><span
style='color:black'>. </span><span style='color:black'>დამატებითი</span><span
style='color:black'> </span><span style='color:black'>საკომისიოს</span><span
style='color:black'> </span><span style='color:black'>დაწესება</span><span
style='color:black'> </span><span style='color:black'>მოხდება</span><span
style='color:black'>, </span><span style='color:black'>კანონმდებლობის</span><span
style='color:black'>, </span><span style='color:black'>მათ</span><span
style='color:black'> </span><span style='color:black'>შორის</span><span
style='color:black'>, „</span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>სისტემისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>შესახებ</span><span
style='color:black'>&quot; </span><span style='color:black'>კანონის</span><span
style='color:black'> </span><span style='color:black'>საკომისიოებთან</span><span
style='color:black'> </span><span style='color:black'>დაკავშირებული</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნების</span><span
style='color:black'>/</span><span style='color:black'>შეზღუდვების</span><span
style='color:black'> </span><span style='color:black'>მხედველობაში</span><span
style='color:black'> </span><span style='color:black'>მიღებით</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მხოლოდ</span><span
style='color:black'> </span><span style='color:black'>ისეთ</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'>, </span><span style='color:black'>როდესაც</span><span
style='color:black'> </span><span style='color:black'>აღნიშნული</span><span
style='color:black'> </span><span style='color:black'>განპირობებულია</span><span
style='color:black'> </span><span style='color:black'>ობიექტური</span><span
style='color:black'> </span><span style='color:black'>გარემოებებით</span><span
style='color:black'>, </span><span style='color:black'>მათ</span><span
style='color:black'> </span><span style='color:black'>შორის</span><span
style='color:black'> </span><span style='color:black'>ტრანზაქციასთან</span><span
style='color:black'> </span><span style='color:black'>დაკავშირებული</span><span
style='color:black'> </span><span style='color:black'>დანახარჯის</span><span
style='color:black'> </span><span style='color:black'>სტრუქტურიდან</span><span
style='color:black'> </span><span style='color:black'>გამომდინარე</span><span
style='color:black'> (</span><span style='color:black'>მაგალითად</span><span
style='color:black'>, </span><span style='color:black'>ბანკთაშორის</span><span
style='color:black'> </span><span style='color:black'>გადარიცხვის</span><span
style='color:black'> </span><span style='color:black'>საკომისიო</span><span
style='color:black'>).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>გადასახადები</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
Merriweather;color:black'>8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებელი</span><span style='color:black'>  </span><span
style='color:black'>თავად</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებელი</span><span style='color:black'> </span><span
style='color:black'>სახელმწიფო</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ორგანოსთვის</span><span style='color:black'> </span><span
style='color:black'>საგადასახადო</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებების</span><span style='color:black'> </span><span
style='color:black'>შესრულებაზე</span><span style='color:black'>. </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>აგრეთვე</span><span style='color:black'> </span><span
style='color:black'>თავად</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებელი</span><span style='color:black'> </span><span
style='color:black'>გადაიხადოს</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>გადაუხდელი</span><span style='color:black'> </span><span
style='color:black'>გადასახადი</span><span style='color:black'> (</span><span
style='color:black'>რაც</span><span style='color:black'> </span><span
style='color:black'>ყოველგვარი</span><span style='color:black'> </span><span
style='color:black'>შეზღუდვის</span><span style='color:black'> </span><span
style='color:black'>გარეშე</span><span style='color:black'> </span><span
style='color:black'>მოიცავს</span><span style='color:black'> </span><span
style='color:black'>დამატებული</span><span style='color:black'> </span><span
style='color:black'>ღირებულების</span><span style='color:black'> </span><span
style='color:black'>გადასახადს</span><span style='color:black'>), </span><span
style='color:black'>საბაჟო</span><span style='color:black'> </span><span
style='color:black'>გადასახადი</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>შეიძლება</span><span style='color:black'> </span><span
style='color:black'>დაწესდეს</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერ</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებაზე</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>შეიძლება</span><span style='color:black'> </span><span
style='color:black'>წარმოიშვას</span><span style='color:black'> </span><span
style='color:black'>ანგარიშ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>მართვასთან</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>განსაზღვრული</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერ</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>მომსახურებასთან</span><span style='color:black'> </span><span
style='color:black'>მიმართებაში</span><span style='color:black'> (</span><span
style='color:black'>ერთად</span><span style='color:black'>, ”</span><span
style='color:black'>საგადასახადო</span><span style='color:black'> </span><span
style='color:black'>ვალდებულება</span><span style='color:black'>”); </span><span
style='color:black'>ამასთანავე</span><span style='color:black'>, KEEPZ.ME </span><span
style='color:black'>თავად</span><span style='color:black'> </span><span
style='color:black'>იქნება</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებელი</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>გადასახადის</span><span style='color:black'> </span><span
style='color:black'>გადახდაზე</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებაზე</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებულია</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლისაგან</span><span style='color:black'> </span><span
style='color:black'>მიღებულ</span><span style='color:black'> </span><span
style='color:black'>საკომისიო</span><span style='color:black'> </span><span
style='color:black'>თანხასთან</span><span style='color:black'>. </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>ეკისრება</span><span style='color:black'> </span><span
style='color:black'>რაიმე</span><span style='color:black'> </span><span
style='color:black'>გადასახადი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ჯარიმა</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>საგადასახადო</span><span style='color:black'> </span><span
style='color:black'>ვალდებულების</span><span style='color:black'> </span><span
style='color:black'>გამო</span><span style='color:black'>, </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>დაუყოვნებლივ</span><span style='color:black'> </span><span
style='color:black'>აუნაზღაურებს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>ამგვარ</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებას</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევის</span><span style='color:black'> </span><span
style='color:black'>გარდა</span><span style='color:black'>, </span><span
style='color:black'>როდესაც</span><span style='color:black'> </span><span
style='color:black'>ამგვარი</span><span style='color:black'> </span><span
style='color:black'>გადასახადის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ჯარიმის</span><span style='color:black'> </span><span
style='color:black'>დაწესება</span><span style='color:black'> </span><span
style='color:black'>გამოწვეულია</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>განზრახი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>უხეში</span><span style='color:black'> </span><span
style='color:black'>გაუფრთხილებელი</span><span style='color:black'> </span><span
style='color:black'>ქმედებით</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
Merriweather;color:black'>8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მხარეებმა</span><span style='color:black'> </span><span
style='color:black'>უნდა</span><span style='color:black'> </span><span
style='color:black'>დააკმაყოფილონ</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ქვეყნის</span><span style='color:black'> </span><span
style='color:black'>საკანონმდებლო</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნები</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>ისინი</span><span style='color:black'> </span><span
style='color:black'>რაიმე</span><span style='color:black'> </span><span
style='color:black'>სახით</span><span style='color:black'> </span><span
style='color:black'>ვრცელდება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>შესაძლოა</span><span style='color:black'> </span><span
style='color:black'>გავრცელდეს</span><span style='color:black'> </span><span
style='color:black'>მათზე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ხელშეკრულების</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>მოქმედება</span></b><b><span style='color:black'>, </span></b><b><span
style='color:black'>შეჩერება</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>და</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>შეწყვეტა</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულება</span><span style='color:black'> </span><span
style='color:black'>ძალაში</span><span style='color:black'> </span><span
style='color:black'>შედის</span><span style='color:black'> </span><span
style='color:black'>ამოქმედების</span><span style='color:black'> </span><span
style='color:black'>თარიღიდან</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მოქმედებს</span><span style='color:black'> </span><span
style='color:black'>უვადოდ</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>შესაძლოა</span><span style='color:black'> </span><span
style='color:black'>დამატებით</span><span style='color:black'> </span><span
style='color:black'>იქნას</span><span style='color:black'> </span><span
style='color:black'>დადასტურებული</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>დადების</span><span style='color:black'> </span><span
style='color:black'>ფაქტი</span><span style='color:black'> </span><span
style='color:black'>საერთო</span><span style='color:black'> </span><span
style='color:black'>პირობების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>დანართების</span><span style='color:black'> </span><span
style='color:black'>ბეჭდურ</span><span style='color:black'> </span><span
style='color:black'>ვერსიაზე</span><span style='color:black'> </span><span
style='color:black'>ხელმოწერის</span><span style='color:black'> </span><span
style='color:black'>გზით</span><span style='color:black'>, </span><span
style='color:black'>ამასთან</span><span style='color:black'> </span><span
style='color:black'>ყოველგვარი</span><span style='color:black'> </span><span
style='color:black'>ეჭვის</span><span style='color:black'> </span><span
style='color:black'>გამოსარიცხად</span><span style='color:black'>, </span><span
style='color:black'>მხარეები</span><span style='color:black'> </span><span
style='color:black'>ადასტურებენ</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულება</span><span style='color:black'>, </span><span
style='color:black'>მიუხედავად</span><span style='color:black'> </span><span
style='color:black'>ბეჭდურ</span><span style='color:black'> </span><span
style='color:black'>ვერსიაზე</span><span style='color:black'> </span><span
style='color:black'>ხელმოწერის</span><span style='color:black'> </span><span
style='color:black'>თარიღისა</span><span style='color:black'>, </span><span
style='color:black'>ძალაშია</span><span style='color:black'> </span><span
style='color:black'>ამოქმედების</span><span style='color:black'> </span><span
style='color:black'>თარიღიდან</span><span style='color:black'>.  </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>კონკრეტული</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>ჭრილში</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>შეწყვეტის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შეჩერების</span><span style='color:black'> </span><span
style='color:black'>წესები</span><span style='color:black'> </span><span
style='color:black'>განსაზღვრულია</span><span style='color:black'> </span><span
style='color:black'>ცალკეული</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>პირობებით</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ნაწილობრივ</span><span style='color:black'> </span><span
style='color:black'>შეწყვეტა</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>ათავისუფლებს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>ნაკისრი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>დადგენილი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებების</span><span style='color:black'> </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ჯეროვნად</span><span style='color:black'> </span><span
style='color:black'>შესრულებისაგან</span><span style='color:black'> (</span><span
style='color:black'>გადახდისაგან</span><span style='color:black'>) </span><span
style='color:black'>ამგვარი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულების</span><span style='color:black'> </span><span
style='color:black'>იძულებითი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ნებაყოფლობითი</span><span style='color:black'> </span><span
style='color:black'>შესრულების</span><span style='color:black'> </span><span
style='color:black'>მომენტამდე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ცვლილებები</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>და</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>დამატებები</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME </span><span style='color:black'>უფლებამოსილია</span><span
style='color:black'>, </span><span style='color:black'>ნებისმიერ</span><span
style='color:black'> </span><span style='color:black'>დროს</span><span
style='color:black'>, </span><span style='color:black'>ვებ</span><span
style='color:black'>-</span><span style='color:black'>გვერდზე</span><span
style='color:black'> </span><span style='color:black'>შესაბამისი</span><span
style='color:black'> </span><span style='color:black'>ინფორმაციის</span><span
style='color:black'> </span><span style='color:black'>განთავსების</span><span
style='color:black'> </span><span style='color:black'>გზით</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> KEEPZ.ME -</span><span style='color:black'>თვის</span><span
style='color:black'> </span><span style='color:black'>მისაღები</span><span
style='color:black'> </span><span style='color:black'>სხვა</span><span
style='color:black'> </span><span style='color:black'>ფორმით</span><span
style='color:black'>, </span><span style='color:black'>თუ</span><span
style='color:black'> </span><span style='color:black'>კანონმდებლობით</span><span
style='color:black'> </span><span style='color:black'>სხვა</span><span
style='color:black'> </span><span style='color:black'>რამ</span><span
style='color:black'> </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>არის</span><span
style='color:black'> </span><span style='color:black'>განსაზღვრული</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>დამატებითი</span><span
style='color:black'> </span><span style='color:black'>თანხმობისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>აქცეპტის</span><span
style='color:black'> </span><span style='color:black'>გარეშე</span><span
style='color:black'>, </span><span style='color:black'>შეცვალოს</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულების</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> </span><span style='color:black'>პირობა</span><span
style='color:black'> (</span><span style='color:black'>მათ</span><span
style='color:black'> </span><span style='color:black'>შორის</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>არა</span><span
style='color:black'> </span><span style='color:black'>მხოლოდ</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>პროდუქტი</span><span
style='color:black'>). </span><span style='color:black'>ამასთან</span><span
style='color:black'>, </span><span style='color:black'>აღნიშნული</span><span
style='color:black'> </span><span style='color:black'>ცვლილებები</span><span
style='color:black'> </span><span style='color:black'>შესაძლებელია</span><span
style='color:black'> </span><span style='color:black'>შეეხოს</span><span
style='color:black'>, </span><span style='color:black'>როგორც</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელთა</span><span
style='color:black'> </span><span style='color:black'>გარკვეულ</span><span
style='color:black'> </span><span style='color:black'>ჯგუფს</span><span
style='color:black'>, </span><span style='color:black'>ისე</span><span
style='color:black'> </span><span style='color:black'>კონკრეტულ</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელს</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ცვლილება</span><span style='color:black'>/</span><span
style='color:black'>დამატება</span><span style='color:black'> </span><span
style='color:black'>ხორციელდება</span><span style='color:black'> </span><span
style='color:black'>მომხარებლის</span><span style='color:black'> </span><span
style='color:black'>სასარგებლოდ</span><span style='color:black'>, </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>აუარესებს</span><span style='color:black'> </span><span
style='color:black'>მომხარებლის</span><span style='color:black'> </span><span
style='color:black'>მდგომარეობას</span><span style='color:black'>, </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ცვლილება</span><span style='color:black'>/</span><span
style='color:black'>დამატება</span><span style='color:black'> </span><span
style='color:black'>ეხება</span><span style='color:black'> </span><span
style='color:black'>ახალ</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>მომსახურებას</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>ანაცვლებს</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>ცვლის</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებულ</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>მომსახურებას</span><span style='color:black'>, KEEPZ.ME </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>ვალდებული</span><span style='color:black'> </span><span
style='color:black'>შეტყობინოს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>ასეთი</span><span style='color:black'> </span><span
style='color:black'>ცვლილების</span><span style='color:black'>/</span><span
style='color:black'>დამატების</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებული</span><span style='color:black'> </span><span
style='color:black'>ცვლილების</span><span style='color:black'>/</span><span
style='color:black'>დამატების</span><span style='color:black'> </span><span
style='color:black'>ძალაში</span><span style='color:black'> </span><span
style='color:black'>შესვლამდე</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებულია</span><span style='color:black'> </span><span
style='color:black'>წინასწარ</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>ვადა</span><span style='color:black'>, </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ასეთი</span><span style='color:black'> </span><span
style='color:black'>ცვლილება</span><span style='color:black'>/</span><span
style='color:black'>დამატება</span><span style='color:black'> </span><span
style='color:black'>ჩაითვლება</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელთან</span><span style='color:black'> </span><span
style='color:black'>შეთანხმებულად</span><span style='color:black'>, </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>ცვლილების</span><span style='color:black'>/</span><span
style='color:black'>დამატების</span><span style='color:black'> </span><span
style='color:black'>ძალაში</span><span style='color:black'> </span><span
style='color:black'>შესვლამდე</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>შეატყობინებს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>ეთანხმება</span><span style='color:black'> </span><span
style='color:black'>ცვლილებას</span><span style='color:black'>/</span><span
style='color:black'>დამატებას</span><span style='color:black'>. </span><span
style='color:black'>ცვლილებების</span><span style='color:black'> </span><span
style='color:black'>ამოქმედებამდე</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>უფლებამოსილია</span><span style='color:black'> </span><span
style='color:black'>შეწყვიტოს</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულება</span><span style='color:black'> </span><span
style='color:black'>დაუყოვნებლივ</span><span style='color:black'>, </span><span
style='color:black'>ხოლო</span><span style='color:black'>, </span><span
style='color:black'>თავის</span><span style='color:black'> </span><span
style='color:black'>მხრივ</span><span style='color:black'>, </span><span
style='color:black'>ცვლილებებზე</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>უარის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'> KEEPZ.ME </span><span
style='color:black'>უფლებამოსილია</span><span style='color:black'> </span><span
style='color:black'>შეწყვიტოს</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულება</span><span style='color:black'> </span><span
style='color:black'>დაუყონებლივ</span><span style='color:black'>, </span><span
style='color:black'>ცვლილებების</span><span style='color:black'> </span><span
style='color:black'>ამოქმედების</span><span style='color:black'> </span><span
style='color:black'>თარიღის</span><span style='color:black'> </span><span
style='color:black'>შემდეგ</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულებაში</span><span style='color:black'> </span><span
style='color:black'>შეტანილი</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ცვლილება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დამატება</span><span style='color:black'> </span><span
style='color:black'>წარმოადგენს</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>დანართს</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მის</span><span style='color:black'> </span><span
style='color:black'>განუყოფელ</span><span style='color:black'> </span><span
style='color:black'>ნაწილს</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ფორსმაჟორი</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>11.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>არც</span><span style='color:black'> </span><span
style='color:black'>ერთი</span><span style='color:black'> </span><span
style='color:black'>მხარე</span><span style='color:black'> </span><span
style='color:black'>პასუხს</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>აგებს</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>ნაკისრი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებების</span><span style='color:black'> </span><span
style='color:black'>შეუსრულებლობაზე</span><span style='color:black'>, </span><span
style='color:black'>არადროულად</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>არასათანადოდ</span><span style='color:black'> </span><span
style='color:black'>შესრულებაზე</span><span style='color:black'>, </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>შეუსრულებლობა</span><span style='color:black'>, </span><span
style='color:black'>არასათანადო</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>არადროული</span><span style='color:black'> </span><span
style='color:black'>შესრულება</span><span style='color:black'> </span><span
style='color:black'>განპირობებულია</span><span style='color:black'> </span><span
style='color:black'>დაუძლეველი</span><span style='color:black'> </span><span
style='color:black'>ძალის</span><span style='color:black'> </span><span
style='color:black'>გარემოებათა</span><span style='color:black'> (</span><span
style='color:black'>ფორს</span><span style='color:black'>-</span><span
style='color:black'>მაჟორულ</span><span style='color:black'> </span><span
style='color:black'>გარემოებათა</span><span style='color:black'>) </span><span
style='color:black'>დადგომის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მოქმედების</span><span style='color:black'> </span><span
style='color:black'>შედეგად</span><span style='color:black'>. </span><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>ვალდებულება</span><span style='color:black'> </span><span
style='color:black'>ძალაში</span><span style='color:black'> </span><span
style='color:black'>შედის</span><span style='color:black'> </span><span
style='color:black'>დაუძლეველი</span><span style='color:black'> </span><span
style='color:black'>ძალის</span><span style='color:black'> </span><span
style='color:black'>გარემოებათა</span><span style='color:black'> </span><span
style='color:black'>გაუქმებისთანავე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>11.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>დაუძლეველი</span><span style='color:black'> </span><span
style='color:black'>ძალის</span><span style='color:black'> </span><span
style='color:black'>გარემოებები</span><span style='color:black'> </span><span
style='color:black'>გულისხმობს</span><span style='color:black'> </span><span
style='color:black'>სტიქიური</span><span style='color:black'> </span><span
style='color:black'>კატასტროფას</span><span style='color:black'>, </span><span
style='color:black'>ხანძარს</span><span style='color:black'>, </span><span
style='color:black'>ელექტროენერგიის</span><span style='color:black'> </span><span
style='color:black'>მიწოდების</span><span style='color:black'> </span><span
style='color:black'>შეწყვეტას</span><span style='color:black'>, </span><span
style='color:black'>მოწყობილობების</span><span style='color:black'> </span><span
style='color:black'>მწყობრიდან</span><span style='color:black'> </span><span
style='color:black'>გამოსვლას</span><span style='color:black'>, </span><span
style='color:black'>პროგრამული</span><span style='color:black'> </span><span
style='color:black'>უზრუნველყოფის</span><span style='color:black'> </span><span
style='color:black'>დაზიანებას</span><span style='color:black'>, </span><span
style='color:black'>საომარ</span><span style='color:black'> </span><span
style='color:black'>მოქმედებას</span><span style='color:black'>, </span><span
style='color:black'>ადმინისტრაციულ</span><span style='color:black'>-</span><span
style='color:black'>სამართლებრივი</span><span style='color:black'> </span><span
style='color:black'>აქტის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>რომელიმე</span><span style='color:black'> </span><span
style='color:black'>მხარისათვის</span><span style='color:black'> </span><span
style='color:black'>გადაულახავი</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>კონტროლისაგან</span><span style='color:black'> </span><span
style='color:black'>დამოუკიდებელ</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>გარემოებებს</span><span style='color:black'>, </span><span
style='color:black'>რომლებიც</span><span style='color:black'>: </span><span
style='color:black'>ა</span><span style='color:black'>) </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>რომელიმე</span><span style='color:black'> </span><span
style='color:black'>მხარის</span><span style='color:black'> </span><span
style='color:black'>შეცდომებსა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დაუდევრობასთან</span><span style='color:black'>, </span><span
style='color:black'>ბ</span><span style='color:black'>) </span><span
style='color:black'>დაიწყო</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>განვითარდა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ძალაში</span><span style='color:black'> </span><span
style='color:black'>შესვლის</span><span style='color:black'> </span><span
style='color:black'>შემდეგ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>გ</span><span style='color:black'>) </span><span
style='color:black'>პირდაპირ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>უშუალო</span><span style='color:black'> </span><span
style='color:black'>ზეგავლენას</span><span style='color:black'> </span><span
style='color:black'>ახდენს</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>ნაკისრი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებების</span><span style='color:black'> </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ჯეროვნად</span><span style='color:black'> </span><span
style='color:black'>შესრულებაზე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>11.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მხარე</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>განიცდის</span><span style='color:black'> </span><span
style='color:black'>ფორს</span><span style='color:black'>-</span><span
style='color:black'>მაჟორული</span><span style='color:black'> </span><span
style='color:black'>გარემოებების</span><span style='color:black'> </span><span
style='color:black'>ზემოქმედებას</span><span style='color:black'>, </span><span
style='color:black'>ვალდებულია</span><span style='color:black'> </span><span
style='color:black'>დაუყოვნებლივ</span><span style='color:black'>, </span><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>გარემოებების</span><span style='color:black'> </span><span
style='color:black'>დადგომიდან</span><span style='color:black'> </span><span
style='color:black'>არაუგვიანეს</span><span style='color:black'> 3 (</span><span
style='color:black'>სამი</span><span style='color:black'>) </span><span
style='color:black'>საბანკო</span><span style='color:black'> </span><span
style='color:black'>დღის</span><span style='color:black'> </span><span
style='color:black'>განმავლობაში</span><span style='color:black'>, </span><span
style='color:black'>წერილობითი</span><span style='color:black'> </span><span
style='color:black'>ფორმით</span><span style='color:black'> </span><span
style='color:black'>აცნობოს</span><span style='color:black'> </span><span
style='color:black'>მეორე</span><span style='color:black'> </span><span
style='color:black'>მხარეს</span><span style='color:black'> </span><span
style='color:black'>ფორს</span><span style='color:black'>-</span><span
style='color:black'>მაჟორულ</span><span style='color:black'> </span><span
style='color:black'>გარემოებათა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მათი</span><span style='color:black'> </span><span
style='color:black'>დადგომის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მიიღოს</span><span style='color:black'> </span><span
style='color:black'>აუცილებელი</span><span style='color:black'> </span><span
style='color:black'>ზომები</span><span style='color:black'> </span><span
style='color:black'>ამ</span><span style='color:black'> </span><span
style='color:black'>გარემოებებით</span><span style='color:black'> </span><span
style='color:black'>გამოწვეული</span><span style='color:black'> </span><span
style='color:black'>ნეგატიური</span><span style='color:black'> </span><span
style='color:black'>შედეგების</span><span style='color:black'> </span><span
style='color:black'>შესამცირებლად</span><span style='color:black'>. </span><span
style='color:black'>ანალოგიური</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნები</span><span style='color:black'> </span><span
style='color:black'>ვრცელდება</span><span style='color:black'> </span><span
style='color:black'>აგრეთვე</span><span style='color:black'> </span><span
style='color:black'>ფორსმაჟორული</span><span style='color:black'> </span><span
style='color:black'>გარემოებებისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მათი</span><span style='color:black'> </span><span
style='color:black'>შედეგების</span><span style='color:black'> </span><span
style='color:black'>შეწყვეტის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'> </span><span
style='color:black'>შეტყობინებაზეც</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>11.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ფორს</span><span style='color:black'>-</span><span
style='color:black'>მაჟორული</span><span style='color:black'> </span><span
style='color:black'>გარემოებების</span><span style='color:black'> </span><span
style='color:black'>დადგომის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>, </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებული</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებების</span><span style='color:black'> </span><span
style='color:black'>შესრულების</span><span style='color:black'> </span><span
style='color:black'>ვადა</span><span style='color:black'> </span><span
style='color:black'>გაგრძელდება</span><span style='color:black'> </span><span
style='color:black'>დაუძლეველი</span><span style='color:black'> </span><span
style='color:black'>ძალის</span><span style='color:black'> </span><span
style='color:black'>მოქმედების</span><span style='color:black'> </span><span
style='color:black'>დროის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ასიგნირება</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>და</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>სხვა</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>ურთიერთობები</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>12.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებელი</span><span style='color:black'>, KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>წინასწარი</span><span style='color:black'> </span><span
style='color:black'>წერილობითი</span><span style='color:black'> </span><span
style='color:black'>თანხმობის</span><span style='color:black'> </span><span
style='color:black'>გარეშე</span><span style='color:black'>, </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>უფლებამოსილი</span><span style='color:black'> </span><span
style='color:black'>მოახდინოს</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>სახელშეკრულებო</span><span style='color:black'> </span><span
style='color:black'>უფლებებისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებების</span><span style='color:black'> </span><span
style='color:black'>ასიგნირება</span><span style='color:black'>, </span><span
style='color:black'>გადაცემა</span><span style='color:black'>, </span><span
style='color:black'>გადაბარება</span><span style='color:black'>, </span><span
style='color:black'>ქვე</span><span style='color:black'>-</span><span
style='color:black'>კონტრაქტირება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ნდობის</span><span style='color:black'> </span><span
style='color:black'>გამოცხადება</span><span style='color:black'>. KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>უარი</span><span style='color:black'> </span><span
style='color:black'>გამორიცხავს</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ზემოაღნიშნული</span><span style='color:black'> </span><span
style='color:black'>ქმედების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>გარიგების</span><span style='color:black'> </span><span
style='color:black'>განხორციელების</span><span style='color:black'> </span><span
style='color:black'>შესაძლებლობას</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'> </span><span
style='color:black'>ამ</span><span style='color:black'> </span><span
style='color:black'>წესის</span><span style='color:black'> </span><span
style='color:black'>დარღვევით</span><span style='color:black'> </span><span
style='color:black'>განხორციელებული</span><span style='color:black'> </span><span
style='color:black'>ქმედება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>გარიგება</span><span style='color:black'> </span><span
style='color:black'>ბათილია</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>წარმოშობს</span><span style='color:black'> </span><span
style='color:black'>სამართლებრივ</span><span style='color:black'> </span><span
style='color:black'>შედეგებს</span><span style='color:black'>, </span><span
style='color:black'>გარდა</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>ცალსახად</span><span style='color:black'> </span><span
style='color:black'>განსაზღვრული</span><span style='color:black'> </span><span
style='color:black'>შემთხვევებისა</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>12.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME </span><span style='color:black'>უფლებამოსილია</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერ</span><span
style='color:black'> </span><span style='color:black'>დროს</span><span
style='color:black'>, </span><span style='color:black'>წინასწარი</span><span
style='color:black'> </span><span style='color:black'>წერილობითი</span><span
style='color:black'> </span><span style='color:black'>თანხმობის</span><span
style='color:black'> </span><span style='color:black'>გარეშე</span><span
style='color:black'> </span><span style='color:black'>მოახდინოს</span><span
style='color:black'> </span><span style='color:black'>მისი</span><span
style='color:black'> </span><span style='color:black'>სახელშეკრულებო</span><span
style='color:black'> </span><span style='color:black'>უფლებებისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ვალდებულებების</span><span
style='color:black'> </span><span style='color:black'>ასიგნირება</span><span
style='color:black'>, </span><span style='color:black'>გადაცემა</span><span
style='color:black'>, </span><span style='color:black'>გადაბარება</span><span
style='color:black'>, </span><span style='color:black'>ქვე</span><span
style='color:black'>-</span><span style='color:black'>კონტრაქტირება</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>ნდობის</span><span
style='color:black'> </span><span style='color:black'>გამოცხადება</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>კომუნიკაცია</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>მხარეთა</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>შორის</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებელთან</span><span style='color:black'> </span><span
style='color:black'>კომუნიკაცია</span><span style='color:black'>:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME </span><span style='color:black'>მომხმარებელთან</span><span
style='color:black'> </span><span style='color:black'>ურთიერთობას</span><span
style='color:black'> </span><span style='color:black'>განახორციელებს</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულებით</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მასთან</span><span
style='color:black'> </span><span style='color:black'>დაკავშირებული</span><span
style='color:black'> </span><span style='color:black'>სხვა</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულებით</span><span
style='color:black'> </span><span style='color:black'>განსაზღვრულ</span><span
style='color:black'> </span><span style='color:black'>მისამართებზე</span><span
style='color:black'>/</span><span style='color:black'>საკონტაქტო</span><span
style='color:black'> </span><span style='color:black'>მონაცემებზე</span><span
style='color:black'> (</span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>სხვა</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერ</span><span
style='color:black'> </span><span style='color:black'>მისამართზე</span><span
style='color:black'>/</span><span style='color:black'>საკონტაქტო</span><span
style='color:black'> </span><span style='color:black'>მონაცემზე</span><span
style='color:black'>, </span><span style='color:black'>რომელსაც</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>აცნობებს</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>წერილობით</span><span
style='color:black'>). </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>ვალდებულია</span><span
style='color:black'> </span><span style='color:black'>აღნიშნული</span><span
style='color:black'> </span><span style='color:black'>მისამართ</span><span
style='color:black'>(</span><span style='color:black'>ებ</span><span
style='color:black'>)</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>  </span><span style='color:black'>რომელიმე</span><span
style='color:black'> </span><span style='color:black'>მონაცემის</span><span
style='color:black'> </span><span style='color:black'>ცვლილების</span><span
style='color:black'> </span><span style='color:black'>შესახებ</span><span
style='color:black'> </span><span style='color:black'>დროულად</span><span
style='color:black'> </span><span style='color:black'>აცნობოს</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'>, </span><span style='color:black'>წინააღმდეგ</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'>, KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>აღნიშნულ</span><span
style='color:black'> </span><span style='color:black'>მისამართზე</span><span
style='color:black'> </span><span style='color:black'>განხორციელებული</span><span
style='color:black'> </span><span style='color:black'>ურთიერთობა</span><span
style='color:black'> (</span><span style='color:black'>შეტყობინების</span><span
style='color:black'> </span><span style='color:black'>გაგზავნა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>სხვა</span><span
style='color:black'>) </span><span style='color:black'>ჩაითვლება</span><span
style='color:black'> </span><span style='color:black'>ჯეროვნად</span><span
style='color:black'> </span><span style='color:black'>შესრულებულად</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>შეტყობინება</span><span style='color:black'> </span><span
style='color:black'>ჩაბარებულად</span><span style='color:black'> </span><span
style='color:black'>ითვლება</span><span style='color:black'> </span><span
style='color:black'>ადრესატის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>მიღების</span><span style='color:black'> </span><span
style='color:black'>დღეს</span><span style='color:black'>, </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>მიღება</span><span style='color:black'> </span><span
style='color:black'>დადასტურებულია</span><span style='color:black'> </span><span
style='color:black'>ადრესატის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>ელექტრონული</span><span style='color:black'> </span><span
style='color:black'>დოკუმენტით</span><span style='color:black'>, </span><span
style='color:black'>ქვითრით</span><span style='color:black'>, </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>საშუალებით</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ა</span><span style='color:black'>.</span><span
style='color:black'>შ</span><span style='color:black'>.). </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>მიღება</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>დადასტურებული</span><span style='color:black'> </span><span
style='color:black'>ადრესატის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'>, </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ასეთი</span><span style='color:black'> </span><span
style='color:black'>შეტყობინება</span><span style='color:black'> </span><span
style='color:black'>მიიჩნევა</span><span style='color:black'> </span><span
style='color:black'>სათანადო</span><span style='color:black'> </span><span
style='color:black'>წესის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'> </span><span
style='color:black'>გაგზავნილად</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მიღებულად</span><span style='color:black'>:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>კურიერის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>საფოსტო</span><span style='color:black'> </span><span
style='color:black'>გზავნილის</span><span style='color:black'> </span><span
style='color:black'>მეშვეობით</span><span style='color:black'> </span><span
style='color:black'>წერილობითი</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>დეპეშის</span><span style='color:black'> </span><span
style='color:black'>გაგზავნის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>შეტყობინება</span><span style='color:black'> </span><span
style='color:black'>გაგზავნილია</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'>, </span><span
style='color:black'>გაგზავნიდან</span><span style='color:black'> 3 (</span><span
style='color:black'>სამი</span><span style='color:black'>) </span><span
style='color:black'>კალენდარულ</span><span style='color:black'> </span><span
style='color:black'>დღეში</span><span style='color:black'>, </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ჩაბარების</span><span style='color:black'> </span><span
style='color:black'>დადასტურების</span><span style='color:black'> </span><span
style='color:black'>თარიღის</span><span style='color:black'> </span><span
style='color:black'>დღეს</span><span style='color:black'> (</span><span
style='color:black'>რომელსაც</span><span style='color:black'> </span><span
style='color:black'>უფრო</span><span style='color:black'> </span><span
style='color:black'>ადრე</span><span style='color:black'> </span><span
style='color:black'>ექნება</span><span style='color:black'> </span><span
style='color:black'>ადგილი</span><span style='color:black'>).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ფაქსის</span><span style='color:black'>, </span><span
style='color:black'>ელექტრონული</span><span style='color:black'> </span><span
style='color:black'>ფოსტის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ელექტრონული</span><span style='color:black'> </span><span
style='color:black'>საშუალებით</span><span style='color:black'> </span><span
style='color:black'>გაგზავნის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>, </span><span
style='color:black'>გაგზავნის</span><span style='color:black'> </span><span
style='color:black'>თარიღიდან</span><span style='color:black'> </span><span
style='color:black'>მეორე</span><span style='color:black'> </span><span
style='color:black'>საბანკო</span><span style='color:black'> </span><span
style='color:black'>დღეს</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>შეტყობინება</span><span style='color:black'> </span><span
style='color:black'>მიღებულად</span><span style='color:black'> </span><span
style='color:black'>ითვლება</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაშიც</span><span style='color:black'>, </span><span
style='color:black'>თუ</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>შეტყობინება</span><span style='color:black'> </span><span
style='color:black'>დაუბრუნდება</span><span style='color:black'> </span><span
style='color:black'>გაგზავნილ</span><span style='color:black'> </span><span
style='color:black'>მისამართზე</span><span style='color:black'>/</span><span
style='color:black'>საკონტაქტო</span><span style='color:black'> </span><span
style='color:black'>მონაცემებზე</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>ადრესატის</span><span style='color:black'> </span><span
style='color:black'>ადგილსამყოფელის</span><span style='color:black'> </span><span
style='color:black'>არარსებობის</span><span style='color:black'> </span><span
style='color:black'>გამო</span><span style='color:black'>, </span><span
style='color:black'>ადრესატი</span><span style='color:black'> </span><span
style='color:black'>უარს</span><span style='color:black'> </span><span
style='color:black'>განაცხადებს</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>მიღებაზე</span><span style='color:black'>, </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>თავს</span><span style='color:black'> </span><span
style='color:black'>აარიდებს</span><span style='color:black'> </span><span
style='color:black'>მის</span><span style='color:black'> </span><span
style='color:black'>მიღებას</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.1.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>, </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>მხარეს</span><span style='color:black'> </span><span
style='color:black'>წარმოადგენს</span><span style='color:black'> </span><span
style='color:black'>ერთზე</span><span style='color:black'> </span><span
style='color:black'>მეტი</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'>  KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>ერთ</span><span style='color:black'>-</span><span
style='color:black'>ერთი</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლისათვის</span><span style='color:black'> </span><span
style='color:black'>გაგზავნილი</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>შეტყობინება</span><span style='color:black'> </span><span
style='color:black'>ჩაითვლება</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლისათვის</span><span style='color:black'> </span><span
style='color:black'>გაგზავნილ</span><span style='color:black'> </span><span
style='color:black'>შეტყობინებად</span><span style='color:black'>, </span><span
style='color:black'>ხოლო</span><span style='color:black'> </span><span
style='color:black'>ერთ</span><span style='color:black'>–</span><span
style='color:black'>ერთი</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>მიღება</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>მიღებად</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>სთან</span><span
style='color:black'> </span><span style='color:black'>კომუნიკაცია</span><span
style='color:black'>:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME-</span><span style='color:black'>სთან</span><span
style='color:black'> </span><span style='color:black'>კომუნიკაციის</span><span
style='color:black'> </span><span style='color:black'>მიზნით</span><span
style='color:black'> </span><span style='color:black'>დაკავშირება</span><span
style='color:black'> </span><span style='color:black'>შესაძლებელია</span><span
style='color:black'> </span><span style='color:black'>მოხდეს</span><span
style='color:black'> </span><span style='color:black'>ელ</span><span
style='color:black'>.</span><span style='color:black'>ფოსტით</span><span
style='color:black'>: </span></span><span lang="GEO/KAT"><a
href="mailto:info@keepz.me"><span style='font-family:"Sylfaen",serif;
color:#0563C1'>info@keepz.me</span></a></span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><span style='color:black'> </span><span
style='color:black'>ასევე</span><span style='color:black'>, KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>პლატფორმაზე</span><span style='color:black'> </span><span
style='color:black'>არსებული</span><span style='color:black'> </span><span
style='color:black'>ლაივ</span><span style='color:black'> </span><span
style='color:black'>ჩეთით</span><span style='color:black'> (</span><span
style='color:black'>ნებისმიერ</span><span style='color:black'> </span><span
style='color:black'>დროს</span><span style='color:black'> </span><span
style='color:black'>შემდეგ</span><span style='color:black'> </span><span
style='color:black'>პერიოდებში</span><span style='color:black'>: </span><span
style='color:black'>ორშაბათიდან</span><span style='color:black'> </span><span
style='color:black'>პარასკევის</span><span style='color:black'> </span><span
style='color:black'>ჩათვლით</span><span style='color:black'> 10:00-</span><span
style='color:black'>დან</span><span style='color:black'> 19:00 </span><span
style='color:black'>საათამდე</span><span style='color:black'>, </span><span
style='color:black'>გარდა</span><span style='color:black'> </span><span
style='color:black'>საქართველოს</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>თანახმად</span><span style='color:black'> </span><span
style='color:black'>გამოცხადებული</span><span style='color:black'> </span><span
style='color:black'>უქმე</span><span style='color:black'> </span><span
style='color:black'>დღეებისა</span><span style='color:black'>).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>13.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>შეტობინება</span><span style='color:black'> </span><span
style='color:black'>მიღებულად</span><span style='color:black'> </span><span
style='color:black'>ჩაითვლება</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>მიღების</span><span style='color:black'> </span><span
style='color:black'>დადასტურების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>ქმედების</span><span style='color:black'> </span><span
style='color:black'>განხორციელების</span><span style='color:black'> </span><span
style='color:black'>თარიღიდან</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>პრეტენზიები</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>და</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>დავები</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>14.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულებიდან</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებიდან</span><span style='color:black'> </span><span
style='color:black'>გამომდინარე</span><span style='color:black'> </span><span
style='color:black'>პრეტენზიები</span><span style='color:black'> </span><span
style='color:black'>მხარეებმა</span><span style='color:black'> </span><span
style='color:black'>შეიძლება</span><span style='color:black'> </span><span
style='color:black'>ერთმანეთს</span><span style='color:black'> </span><span
style='color:black'>წაუყენონ</span><span style='color:black'> </span><span
style='color:black'>წერილობით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ზეპირად</span><span style='color:black'>. </span><span
style='color:black'>პრეტენზიის</span><span style='color:black'> </span><span
style='color:black'>მიმღები</span><span style='color:black'> </span><span
style='color:black'>მხარე</span><span style='color:black'> </span><span
style='color:black'>ვალდებულია</span><span style='color:black'> </span><span
style='color:black'>პრეტენზიის</span><span style='color:black'> </span><span
style='color:black'>მიღებიდან</span><span style='color:black'> 15 (</span><span
style='color:black'>თხუთმეტი</span><span style='color:black'>) </span><span
style='color:black'>კალენდარული</span><span style='color:black'> </span><span
style='color:black'>დღის</span><span style='color:black'> </span><span
style='color:black'>ვადაში</span><span style='color:black'> </span><span
style='color:black'>მთლიანად</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ნაწილობრივ</span><span style='color:black'> </span><span
style='color:black'>დააკმაყოფილოს</span><span style='color:black'> </span><span
style='color:black'>წამოყენებული</span><span style='color:black'> </span><span
style='color:black'>პრეტენზია</span><span style='color:black'>, </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>წერილობით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ზეპირად</span><span style='color:black'> </span><span
style='color:black'>აცნობოს</span><span style='color:black'> </span><span
style='color:black'>მეორე</span><span style='color:black'> </span><span
style='color:black'>მხარეს</span><span style='color:black'> </span><span
style='color:black'>მის</span><span style='color:black'> </span><span
style='color:black'>დაკმაყოფილებაზე</span><span style='color:black'> </span><span
style='color:black'>უარის</span><span style='color:black'> </span><span
style='color:black'>თქმის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>14.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> 14.1 </span><span
style='color:black'>მუხლის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'>, </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>თვის</span><span style='color:black'> </span><span
style='color:black'>წაყენებული</span><span style='color:black'> </span><span
style='color:black'>პრეტენზია</span><span style='color:black'>/</span><span
style='color:black'>საჩივრების</span><span style='color:black'> </span><span
style='color:black'>წარდგენის</span><span style='color:black'>, </span><span
style='color:black'>მიღებისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>განხილვის</span><span style='color:black'>  </span><span
style='color:black'>პროცედურა</span><span style='color:black'> </span><span
style='color:black'>მოცემულია</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>ვებგვერდზე</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>წარმოადგენს</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>განუყოფელ</span><span style='color:black'> </span><span
style='color:black'>ნაწილს</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მოქმედებს</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>ერთად</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>14.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ირგვლივ</span><span style='color:black'> </span><span
style='color:black'>წამოჭრილი</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>დავა</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>არსებობასთან</span><span style='color:black'>, </span><span
style='color:black'>ინტერპრეტაციასთან</span><span style='color:black'>, </span><span
style='color:black'>შესრულებასთან</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>აღსრულებასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებით</span><span style='color:black'>) </span><span
style='color:black'>წყდება</span><span style='color:black'> </span><span
style='color:black'>მოლაპარაკებით</span><span style='color:black'>. </span><span
style='color:black'>დავის</span><span style='color:black'> </span><span
style='color:black'>მოუგვარებლობის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>, </span><span
style='color:black'>მხარეები</span><span style='color:black'> </span><span
style='color:black'>მიმართავენ</span><span style='color:black'> </span><span
style='color:black'>სასამართლოს</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>სხვა</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>პირობები</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულება</span><span style='color:black'> </span><span
style='color:black'>წარმოადგენს</span><span style='color:black'> </span><span
style='color:black'>მთლიან</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებას</span><span style='color:black'> </span><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ანაცვლებს</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>აუქმებს</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>მანამდე</span><span style='color:black'> </span><span
style='color:black'>დადებულ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებას</span><span style='color:black'>, </span><span
style='color:black'>პირობას</span><span style='color:black'>, </span><span
style='color:black'>გარანტიას</span><span style='color:black'>, </span><span
style='color:black'>უზრუნველყოფას</span><span style='color:black'>, </span><span
style='color:black'>წარმომადგენლობას</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ურთიერთგაგებას</span><span style='color:black'> </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>იქნება</span><span style='color:black'> </span><span
style='color:black'>ეს</span><span style='color:black'> </span><span
style='color:black'>წერილობით</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>ზეპირად</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებულია</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>საგანთან</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ეჭვების</span><span style='color:black'> </span><span
style='color:black'>თავიდან</span><span style='color:black'> </span><span
style='color:black'>ასაცილებლად</span><span style='color:black'>, </span><span
style='color:black'>მხარეები</span><span style='color:black'> </span><span
style='color:black'>წარმოადგენენ</span><span style='color:black'> </span><span
style='color:black'>დამოუკიდებელ</span><span style='color:black'> </span><span
style='color:black'>ხელშემკვრელ</span><span style='color:black'> </span><span
style='color:black'>მხარეებს</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულება</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>შეიძლება</span><span style='color:black'> </span><span
style='color:black'>განმარტებულ</span><span style='color:black'> </span><span
style='color:black'>იქნას</span><span style='color:black'> </span><span
style='color:black'>როგორც</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>ერთობლივი</span><span style='color:black'> </span><span
style='color:black'>საწარმოს</span><span style='color:black'> </span><span
style='color:black'>დაარსება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>სააგენტო</span><span style='color:black'> </span><span
style='color:black'>ურთიერთობის</span><span style='color:black'> </span><span
style='color:black'>ჩამოყალიბება</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>არის</span><span
style='color:black'> </span><span style='color:black'>პასუხისმგებელი</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>შეტყობინებების</span><span
style='color:black'> </span><span style='color:black'>მიუღებლობაზე</span><span
style='color:black'>, </span><span style='color:black'>თუკი</span><span
style='color:black'> </span><span style='color:black'>იგი</span><span
style='color:black'> </span><span style='color:black'>გაგზავნილი</span><span
style='color:black'> </span><span style='color:black'>იქნა</span><span
style='color:black'> </span><span style='color:black'>იმ</span><span
style='color:black'> </span><span style='color:black'>მისამართზე</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>იმ</span><span
style='color:black'> </span><span style='color:black'>საკონტაქტო</span><span
style='color:black'> </span><span style='color:black'>მონაცემების</span><span
style='color:black'> </span><span style='color:black'>საფუძველზე</span><span
style='color:black'>, </span><span style='color:black'>რომელიც</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>წინასწარ</span><span
style='color:black'> </span><span style='color:black'>არის</span><span
style='color:black'> </span><span style='color:black'>მითითებული</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME-</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>საკუთარი</span><span
style='color:black'> </span><span style='color:black'>უფლებების</span><span
style='color:black'> </span><span style='color:black'>განხორციელების</span><span
style='color:black'> </span><span style='color:black'>გადავადება</span><span
style='color:black'> </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>ნიშნავს</span><span
style='color:black'> </span><span style='color:black'>იმას</span><span
style='color:black'>, </span><span style='color:black'>რომ</span><span
style='color:black'> KEEPZ.ME </span><span style='color:black'>უარს</span><span
style='color:black'> </span><span style='color:black'>ამბობს</span><span
style='color:black'> </span><span style='color:black'>თავის</span><span
style='color:black'> </span><span style='color:black'>უფლებებზე</span><span
style='color:black'>; </span><span style='color:black'>აგრეთვე</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> </span><span style='color:black'>უფლების</span><span
style='color:black'> </span><span style='color:black'>ნაწილობრივ</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>სრულად</span><span
style='color:black'> </span><span style='color:black'>გამოუყენებლობა</span><span
style='color:black'> </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>ნიშნავს</span><span
style='color:black'> </span><span style='color:black'>მომავალში</span><span
style='color:black'> </span><span style='color:black'>ამ</span><span
style='color:black'> </span><span style='color:black'>უფლების</span><span
style='color:black'> </span><span style='color:black'>სრულად</span><span
style='color:black'> </span><span style='color:black'>გამოყენების</span><span
style='color:black'> </span><span style='color:black'>შეზღუდვას</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>დანართსა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებულ</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებაზე</span><span style='color:black'> </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>ვრცელდება</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>მუხლ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'>, </span><span
style='color:black'>პუნქტ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ქვეპუნქტ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>მოქმედება</span><span style='color:black'>. </span><span
style='color:black'>ამასთან</span><span style='color:black'>, </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>დანართსა</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>პირობებს</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>წინააღმდეგობის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>შეუსაბამობის</span><span style='color:black'> </span><span
style='color:black'>არსებობისას</span><span style='color:black'>, </span><span
style='color:black'>ამგვარი</span><span style='color:black'> </span><span
style='color:black'>დანართის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>პირობებს</span><span style='color:black'> </span><span
style='color:black'>მიენიჭება</span><span style='color:black'> </span><span
style='color:black'>უპირატესობა</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>საკითხებთან</span><span style='color:black'> </span><span
style='color:black'>მიმართებაში</span><span style='color:black'>, </span><span
style='color:black'>რომელთა</span><span style='color:black'> </span><span
style='color:black'>დასარეგულირებლადაც</span><span style='color:black'> </span><span
style='color:black'>დადებულია</span><span style='color:black'> </span><span
style='color:black'>ამგვარი</span><span style='color:black'> </span><span
style='color:black'>დანართი</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულება</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მხარეები</span><span style='color:black'> </span><span
style='color:black'>ადასტურებენ</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>შინაარსი</span><span style='color:black'> </span><span
style='color:black'>ზუსტად</span><span style='color:black'> </span><span
style='color:black'>გამოხატავს</span><span style='color:black'> </span><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>ნებას</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>ნების</span><span style='color:black'> </span><span
style='color:black'>გამოვლენა</span><span style='color:black'> </span><span
style='color:black'>მოხდა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>შინაარსის</span><span style='color:black'> </span><span
style='color:black'>გონივრული</span><span style='color:black'> </span><span
style='color:black'>განსჯის</span><span style='color:black'> </span><span
style='color:black'>შედეგად</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>არა</span><span style='color:black'> </span><span
style='color:black'>მარტოოდენ</span><span style='color:black'> </span><span
style='color:black'>სიტყვა</span><span style='color:black'>-</span><span
style='color:black'>სიტყვითი</span><span style='color:black'> </span><span
style='color:black'>მნიშვნელობიდან</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ყოველი</span><span style='color:black'> </span><span
style='color:black'>უფლება</span><span style='color:black'>, </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>მიენიჭება</span><span style='color:black'> </span><span
style='color:black'>მხარეს</span><span style='color:black'> </span><span
style='color:black'>მეორე</span><span style='color:black'> </span><span
style='color:black'>მხარის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'>, </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ნაწილობრივ</span><span style='color:black'> </span><span
style='color:black'>დარღვევის</span><span style='color:black'> </span><span
style='color:black'>შედეგად</span><span style='color:black'>, </span><span
style='color:black'>კრებითია</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დაემატება</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'>, </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>მინიჭებულ</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>უფლებას</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ერთ</span><span style='color:black'>-</span><span
style='color:black'>ერთი</span><span style='color:black'> </span><span
style='color:black'>მხარის</span><span style='color:black'> </span><span
style='color:black'>მხრიდან</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'>, </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>სრულად</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ნაწილობრივ</span><span style='color:black'> </span><span
style='color:black'>დარღვევასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებით</span><span style='color:black'> </span><span
style='color:black'>მეორე</span><span style='color:black'> </span><span
style='color:black'>მხარის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>მისთვის</span><span style='color:black'> </span><span
style='color:black'>მინიჭებული</span><span style='color:black'> </span><span
style='color:black'>უფლებების</span><span style='color:black'> </span><span
style='color:black'>გამოუყენებლობა</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>გავრცელდება</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'>, </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერ</span><span style='color:black'> </span><span
style='color:black'>შემდგომ</span><span style='color:black'> </span><span
style='color:black'>დარღვევაზე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>რომელიმე</span><span style='color:black'> </span><span
style='color:black'>მუხლ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'>, </span><span
style='color:black'>პუნქტ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ქვეპუნქტ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>ბათილობა</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>გამოიწვევს</span><span style='color:black'> </span><span
style='color:black'>მთლიანად</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მათი</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>მუხლ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'>, </span><span
style='color:black'>პუნქტ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ქვეპუნქტ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>ბათილობას</span><span style='color:black'>. </span><span
style='color:black'>ბათილი</span><span style='color:black'> </span><span
style='color:black'>დებულების</span><span style='color:black'> </span><span
style='color:black'>ნაცვლად</span><span style='color:black'>, </span><span
style='color:black'>გამოიყენება</span><span style='color:black'> </span><span
style='color:black'>იმგვარი</span><span style='color:black'> </span><span
style='color:black'>დებულება</span><span style='color:black'>, </span><span
style='color:black'>რომლითაც</span><span style='color:black'> </span><span
style='color:black'>უფრო</span><span style='color:black'> </span><span
style='color:black'>ადვილად</span><span style='color:black'> </span><span
style='color:black'>მიიღწევა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>ბათილი</span><span style='color:black'> </span><span
style='color:black'>დებულებით</span><span style='color:black'>) </span><span
style='color:black'>გათვალისწინებული</span><span style='color:black'> </span><span
style='color:black'>მიზანი</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულებაში</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებაში</span><span style='color:black'> </span><span
style='color:black'>მხოლობით</span><span style='color:black'> </span><span
style='color:black'>რიცხვში</span><span style='color:black'> </span><span
style='color:black'>გამოყენებული</span><span style='color:black'> </span><span
style='color:black'>სიტყვები</span><span style='color:black'> </span><span
style='color:black'>გულისხმობს</span><span style='color:black'> </span><span
style='color:black'>მრავლობითს</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>პირიქით</span><span style='color:black'>, </span><span
style='color:black'>კონტექსტის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.11<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>მუხლ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ი</span><span style='color:black'>, </span><span
style='color:black'>პუნქტ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ქვეპუნქტ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ი</span><span style='color:black'> </span><span
style='color:black'>დანომრილია</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დასათაურებულია</span><span style='color:black'> </span><span
style='color:black'>მხოლოდ</span><span style='color:black'> </span><span
style='color:black'>მოხერხებულობისათვის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ამ</span><span style='color:black'> </span><span
style='color:black'>ფაქტს</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ინტერპრეტაციისათვის</span><span style='color:black'> </span><span
style='color:black'>მნიშვნელობა</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>ენიჭება</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.12<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულები</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებებითა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>უფლებებით</span><span style='color:black'> </span><span
style='color:black'>ვრცელდება</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>სავალდებულოა</span><span style='color:black'> </span><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>უფლებამონაცვლეების</span><span style='color:black'>/</span><span
style='color:black'>სამართალმემკვიდრეებისათვის</span><span style='color:black'>,
</span><span style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'>/</span><span
style='color:black'>მათი</span><span style='color:black'> </span><span
style='color:black'>მუხლ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'>, </span><span
style='color:black'>პუნქტ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ქვეპუნქტ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>შინაარსის</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებით</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობა</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>რამეს</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>ითვალისწინებს</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.13<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულება</span><span style='color:black'> </span><span
style='color:black'>განიმარტება</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>რეგულირდება</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'>. </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევებში</span><span style='color:black'>, </span><span
style='color:black'>რომლებიც</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებული</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'>, </span><span
style='color:black'>მხარეები</span><span style='color:black'> </span><span
style='color:black'>იხელმძღვანელებენ</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>დადგენილი</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>ურთიერთობის</span><span style='color:black'> </span><span
style='color:black'>მარეგულირებელი</span><span style='color:black'> </span><span
style='color:black'>ნორმებით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დამატებით</span><span style='color:black'> </span><span
style='color:black'>შეთახმებული</span><span style='color:black'> </span><span
style='color:black'>პირობებით</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>15.14<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულება</span><span style='color:black'> </span><span
style='color:black'>შედგნილია</span><span style='color:black'> </span><span
style='color:black'>როგორც</span><span style='color:black'> </span><span
style='color:black'>ქართულ</span><span style='color:black'>, </span><span
style='color:black'>ასევე</span><span style='color:black'> </span><span
style='color:black'>ინგლისურ</span><span style='color:black'> </span><span
style='color:black'>ენებზე</span><span style='color:black'>. </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ინტერპრეტაციისას</span><span style='color:black'> </span><span
style='color:black'>უპირატესობა</span><span style='color:black'> </span><span
style='color:black'>ენიჭება</span><span style='color:black'> </span><span
style='color:black'>ქართულ</span><span style='color:black'> </span><span
style='color:black'>ენაზე</span><span style='color:black'> </span><span
style='color:black'>შედგენილ</span><span style='color:black'> </span><span
style='color:black'>ტექსტს</span><span style='color:black'>. </span><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>ნორმები</span><span style='color:black'> </span><span
style='color:black'>ვრცელდება</span><span style='color:black'>, </span><span
style='color:black'>ასევე</span><span style='color:black'>, </span><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>ურთიერთობაზე</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'>, </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>დოკუმენტის</span><span style='color:black'> </span><span
style='color:black'>შედგენასა</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>ინტერპრეტაციაზე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:.75in;text-align:center;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='font-size:12.0pt;line-height:115%'>ნაწილი</span></b><b><span
style='font-size:12.0pt;line-height:115%'> II. </span></b><b><span
style='font-size:12.0pt;line-height:115%'>ტერმინთა</span></b><b><span
style='font-size:12.0pt;line-height:115%'> </span></b><b><span
style='font-size:12.0pt;line-height:115%'>განმარტებები</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>თუ ხელშეკრულებით ან/და მასთან
დაკავშირებული სხვა ხელშეკრულებით სხვა რამ არ არის განსაზღვრული, ან მისი კონტექსტიდან
სხვა რამ არ გამომდინარეობს, ხელშეკრულებაში ან/და მასთან დაკავშირებულ სხვა ხელშეკრულებაში
ქვემოთ მოცემულ დეფინიციებს აქვთ შემდეგი მნიშვნელობა:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>მხარე</b><b> </b>-
KEEPZ.ME ან მომხმარებელი დამოუკიდებლად.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>მხარეები</b><b> </b>-
KEEPZ.ME და მომხმარებელი ერთობლივად.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>საგადახდო</b><b> </b><b>მომსახურება</b><b>
- </b>KEEPZ.ME-ს “მომსახურების აღწერის” ნაწილში გათვალიწინებული მომსახურება, რომელსაც
KEEPZ.ME უწევს მომხმარებელს.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>კანონმდებლობა</b><b> </b>-
საქართველოს მოქმედი საკანონმდებლო და კანონქვემდებარე ნორმატიული აქტები და საქართველოს
ნორმატიულ აქტთა სისტემაში მოქცეული საერთაშორისო ხელშეკრულებები და შეთანხმებები.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>საბარათე</b><b> </b><b>ოპერაცია</b>
- მომხმარებლის საბარათე ანგარიშიდან გადასახდელის ჩამოჭრის და ანგარიშსწორების ანგარიშზე
ჩარიცხვის ოპერაცია.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ანგარიშსწორების</b><b> </b><b>ანგარიში</b>
- პარტნიორ ბანკში გახსნილი ნომინალური მფლობელობის ანგარიში, სადაც განცალკევებით
არის განთავსებული მომხმარებელთა ფულადი სახსრები.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span style='color:black;
background:white'>ანგარიში</span></b><b><span style='color:black;background:
white'> -  </span></b><span style='color:black;background:white'>საქართველოს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ეროვნული</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ბანკის</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>მიერ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>რეგისტრირებულ</span></span><b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'> </span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black;
background:white'>კომერციულ</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ბანკში</span><span style='color:black;
background:white'>, </span><span style='color:black;background:white'>მიკრობანკში</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>საბროკერო</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>კომპანიასთან</span><span style='color:
black;background:white'>, </span><span style='color:black;background:white'>საგადახდო</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მომსახურების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>პროვაიდერთან</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ან</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ვირტუალური</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>აქტივის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მომსახურების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>პროვაიდერთან</span><span
style='color:black;background:white'>  </span><span style='color:black;
background:white'>ფულადი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>სახსრების</span><span style='color:black;
background:white'>, </span><span style='color:black;background:white'>ფასიანი</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ქაღალდების</span><span style='color:black;background:white'>,
</span><span style='color:black;background:white'>ელექტრონული</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ფულის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ან</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>კონვერტირებადი</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ვირტუალური</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>აქტივის</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>აღრიცხვის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>უნიკალური</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>საშუალება</span><span style='color:black;
background:white'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ელექტრონული</b><b> </b><b>ფული</b><b>
- </b>KEEPZ.ME-ს მიერ საგადახდო მომსახურების გაწევის პროცესში, გადახდის ოპერაციების
განხორციელებისათვის მომხმარებლისგან მიღებული ან მასზე გაცემული ფულადი სახსრების
ეკვივალენტური ღირებულება, რომელიც შეიძლება, შენახული იყოს საგადახდო ინსტრუმენტზე
(ელექტრონულ საფულეზე) და რომელსაც KEEPZ.ME და სხვა პირები აღიარებენ გადახდის საშუალებად.
ელექტრონულ ფულის სანაცვლოდ მომხმარებელს აქვს შესაძლებლობა მიიღოს/გადარიცხოს შესაბამისი
ნომინალური ღირებულების ფულადი სახსრები მოცემული ხელშეკრულების პირობების შესაბამისად.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ელექტრონული</b><b> </b><b>საფულე</b><b>
- </b>ასეთის არსებობის შემთხვევაში, KEEPZ.ME-ს მიერ შექმნილი ელექტრონული ფულის ინსტრუმენტი
– საგადახდო ინსტრუმენტი, რომელიც იძლევა ელექტრონული ფულის გამოყენების საშუალებას
და რომელიც მომხმარებლის მიერ გამოიყენება საგადახდო მომსახურების მიღებისას.
KEEPZ.ME მომხმარებელს უხსნის ელექტრონული საფულის ანგარიშს/საგადახდო ანგარიშს და
უწევს მას მომსახურებას ხელშეკრულებით განსაზღვრული პირობებითა და წესით.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>საგადახდო ანგარიში იხსნება მომხმარებლის
მიერ შესაბამისი მომსახურების ჭრილში აუცილებელი დოკუმენტების/ინფორმაციის წარმოდგენის
საფუძველზე.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span style='color:black;
background:white'>საგადახდო</span></b><b><span style='color:black;background:
white'> </span></b><b><span style='color:black;background:white'>ანგარიში</span></b><span
style='color:black;background:white'> - </span><span style='color:black;
background:white'>მომხმარებლის</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>სახელზე</span><span
style='color:black;background:white'> KEEPZ.ME-</span><span style='color:black;
background:white'>ს</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>საგადახდო</span><span style='color:black;
background:white'>&nbsp;</span><span style='color:black;background:white'>სისტემაში</span><span
style='color:black;background:white'>&nbsp;</span><span style='color:black;
background:white'>გახსნილი</span><span style='color:black;background:white'>&nbsp;</span><span
style='color:black;background:white'>ვირტუალური</span><span style='color:black;
background:white'> (</span><span style='color:black;background:white'>ელექტრონული</span><span
style='color:black;background:white'>) </span><span style='color:black;
background:white'>საფულე</span><span style='color:black;background:white'>, </span><span
style='color:black;background:white'>ელექტრონული</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>ფულის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>უნიკალური</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ანგარიში</span><span style='color:black;
background:white'>, </span><span style='color:black;background:white'>რომელიც</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>წარმოადგენს</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>რეგისტრირებულ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ელექტრონულ</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ანგარიშსა</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>და</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>გადახდების</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ინსტრუმენტს</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>და</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მისი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მოხმარების</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>წესი</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>რეგულირდება</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>ამ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ხელშეკრულებით</span><span style='color:black;background:white'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>გადახდის</b><b> </b><b>ოპერაცია</b>
- გადამხდელის ან მიმღების მიერ ინიცირებული ფულადი სახსრების განთავსების/ჩარიცხვის,
გადარიცხვის ან გატანის ოპერაცია.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>კლიენტი</b><b> </b>- პირი,
რომელიც უხდის მომხმარებელს პროდუქტის/მომსახურების საფასურს.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ბარათი</b> -  ლოკალურ საგადახდო
სისტემაში ან/და საერთაშორისო საგადახდო სისტემაში შემავალი ფინანსური ინსტიტუტების
მიერ გამოშვებული ბარათი/ბარათები ან/და ნებისმიერი სხვა სახის საბარათე ინსტრუმენტი.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>საგადახდო</b><b> </b><b>დავალება</b>
- მომხმარებლის  მიერ KEEPZ.ME-თვის მიცემული დავალება გადახდის ოპერაციის განხორციელების
თაობაზე.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>გადამხდელი</b> – პირი, რომელიც
გასცემს საგადახდო დავალებას ან/და ეთანხმება საგადახდო დავალების გაცემას.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>მიმღები</b> – პირი, რომლის
სასარგებლოდაც ხორციელდება გადახდის ოპერაცია.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>მესამე</b><b> </b><b>პირი</b>
-  ნებისმიერი იურიდიული ან ფიზიკური პირი (მათ შორის საჯარო სამართლის იურიდიულ პირები
(სსიპ) და არასამეწარმეო (არაკომერციულ) იურიდიულ პირები  (ა(ა)იპ) გარდა
KEEPZ.ME-ს და მომხმარებლისა.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>დაკავშირებული</b><b> </b><b>პირი</b>
- რომელიმე მხარის ფილიალი, კონტრაქტორი, თანამშრომელი და სხვა.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>საეჭვო</b><b> </b><b>ოპერაცია</b>
- ტრანზაქცია, რომელიც ხორციელდება უკანონო შემოსავლების ლეგალიზებასთან დაკავშირებით
საერთაშირისო ან ლოკალურ სტოპ სიაში მოხვედრილი პირის მიერ ან ტრანზაქცია, რომელიც
ეწინააღმდეგება უკანონო შემოსავლების ლეგალიზების აღკვეთასთან დაკავშირებით არსებულ
საერთაშორისო რეგულაციებს, საქართველოს საკანონმდებლო მოთხოვნებს ან/და პარტნიორი ბანკის
ან/და KEEPZ.ME-ს მიერ დადგენილ მოთხოვნებსა და პროცედურებს.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ბარათის</b><b> </b><b>ემიტენტი</b>
- პროვაიდერი, რომელიც უშვებს საგადახდო ინსტრუმენტს, რომლითაც შესაძლებელია საბარათე
ოპერაციის ინიცირება.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>მომხმარებლის</b><b> </b><b>ინტერნეტ</b><b>
</b><b>პორტალი</b> - მომხმარებლის ვებ-გვერდი, მობილური აპლიკაცია ან/და სხვა ელექტრონულ-ტექნიკური
საშუალება, რომელსაც გააჩნია ინტერნეტთან კავშირი და იძლევა ინტერნეტის მეშვეობით პროდუქციის
/მომსახურების რეალიზაციის შესაძლებლობას.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>სავაჭრო</b><b> </b><b>პლატფორმა</b>
- ელექტრონული კომერციით მომსახურების ერთ-ერთი ფორმა, როდესაც ორი ან მეტი სხვადასხვა
სავაჭრო პლატფორმის მონაწილე თავისი პროდუქტის/მომსახურების გაყიდვას ახორციელებს სავაჭრო
პლატფორმის ორგანიზატორის მფლობელობაში არსებული ერთი სავაჭრო/მომსახურების ობიექტის
ინტერნეტ პორტალიდან.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>სავაჭრო</b><b> </b><b>პლატფორმის</b><b>
</b><b>მონაწილე</b> - პირი, რომელიც სავაჭრო პლატფორმის ორგანიზატორის მფლობელობაში
არსებული სავაჭრო/მომსახურების ობიექტის ინტერნეტ პორტალის მეშვეობით ახორციელებს თავისი
პროდუქტის/მომსახურების გაყიდვას და რომელსაც გაფორმებული აქვს სავაჭრო პლატფორმის
ორგანიზატორთან შესაბამისი ხელშეკრულება.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>სავაჭრო</b><b> </b><b>პლატფორმის</b><b>
</b><b>ორგანიზატორი</b> – სავაჭრო/მომსახურების ობიექტი, რომელიც, როგორც კომერციული
შუამავალი, მართავს სავაჭრო პლატფორმას.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>PCI DSS</span></b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> - (Payment Card Industry
Data Security Standard) - საბარათე უსაფრთხოების სტანდარტი, რომელიც შეიცავს მოთხოვნებს
საბარათე მონაცემების უსაფრთხოდ შენახვის, გადაცემის და/ან განკარგვასთან დაკავშირებით
და მიზნად ისახავს ორგანიზაციების დახმარებას მომხმარებელთა მონაცემების დაცვის პროცესში.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ინტელექტუალური</b><b> </b><b>საკუთრების</b><b>
</b><b>უფლება</b><b> </b>- გულისხმობს ნებისმიერ (i) საავტორო უფლებას, პატენტს, ნოუ-ჰაუს,
დომეინის სახელებთან დაკავშირებულ უფლებებს, სავაჭრო ნიშნებთან დაკავშირებულ უფლებებს,
სავაჭრო სახელებს, მომსახურების მარკას და დიზაინს (მიუხედავად იმისა, არის თუ არა
ისინი რეგისტრირებული), (ii) რომელიმე ზემოთჩამოთვლილის რეგისტრაციისთვის შეტანილ განცხადებას
ან რეგისტრაციაზე განცხადების შეტანის უფლებას და (iii) ყველა სხვა ინტელექტუალური
საკუთრების უფლებას ან მსოფლიოს ნებისმიერ წერტილში ამის ექვივალენტური ან მსგავსი
დაცვის ნებისმიერ სხვა ფორმას.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ავტორიზაციის</b><b> </b><b>გვერდი</b>
- KEEPZ.ME-ის პარტნიორი ბანკის ან/და მისი აფილირებული პირის მფლობელობაში არსებული
ვებ–გვერდ(ებ)ი, რომელზეც კლიენტი აფიქსირებს ბარათის რეკვიზიტებს ან ავტორიზაციის
გვერდზე წვდომის მისაღებად განკუთვნილ, მომხმარებლის სახელსა და პაროლს ან განთავსებულია
საბანკო გადარიცხვისათვის საჭირო შესაბამისი ინფორმაცია.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>საერთაშორისო</b><b> </b><b>საგადამხდელო</b><b>
</b><b>სისტემა</b> - “VISA”, “MasterCard” ან/და სხვა ალტერნატიული საგადახდო სისტემა,
რომელიც საერთო წესების და სტანდარტული პროცედურების ერთობლიობით, უზრუნველყოფს ფულადი
სახსრების გადარიცხვის ოპერაციების დამუშავებას და შესაბამის ანგარიშსწორებას.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>საერთაშორისო</b><b> </b><b>საგადამხდელო</b><b>
</b><b>სისტემის</b><b> </b><b>რეგულაცია</b> - საერთაშორისო საგადამხდელო სისტემის
მიერ შემუშავებული და საერთაშორისო საგადამხდელო სისტემაში მონაწილეობისთვის დადგენილი
აუცილებელი ნორმები, რომელშიც ცვლილების შეტანა ხორციელდება პერიოდულად საერთაშორისო
საგადამხდელო სისტემის ერთპიროვნული გადაწყვეტილების საფუძველზე.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>გადასახდელი</b><b> </b>- ფულადი
სახსრები, რომელსაც კლიენტი უხდის მომხმარებელს პროდუქციის/მომსახურების ღირებულების
ასანაზღაურებლად.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>პარტნიორი</b><b> </b><b>ბანკი</b>
- საქართველოს კანონმდებლობის შესაბამისად რეგისტრირებული ბანკი, რომლის მეშვეობითაც
KEEPZ.ME თავის მომხმარებლებს უწევს წინამდებარე პირობებით განსაზღვრულ მომსახურებას.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>საბანკო</b><b> </b><b>დღე</b><b>/</b><b>სამუშაო</b><b>
</b><b>დღე</b> - დღე, შაბათის და კვირის და კანონმდებლობით განსაზღვრული უქმე დღეების
გარდა. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ინციდენტური</b><b> </b><b>ციკლი</b>
- საბარათე ოპერაცი(ებ)ის შესრულების გარემოებათა დაზუსტების მიზნით, ანგარიშსწორების
მონაწილე მხარეებს შორის საანგარიშსწორებო-ფინანსური და სხვა ოპერაციების მიმოცვლის
პროცესი.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ექვაირინგი</b><b> -</b> საგადახდო
მომსახურება, რომელსაც KEEPZ.ME წინამდებარე წესების      საფუძველზე უზრუნველყოფს
და რომელიც მოიცავს გადახდის ოპერაციის მიღებასა და დამუშავებას, რის შედეგადაც ფულადი
სახსრები გადაირიცხება მომხმარებელთან.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ფულადი</b><b> </b><b>გზავნილი</b>
- საგადახდო მომსახურება, რომელსაც KEEPZ.ME წინამდებარე წესების საფუძველზე უზრუნველყოფს
და რომელიც მოცემულია ამ წესების შესაბამს ქვეთავში</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ღია</b><b> </b><b>ბანკინგი</b>
- მომსახურება, რომელსაც KEEPZ.ME წინამდებარე ხელშეკრულების საფუძველზე უზრუნველყოფს
და და რომელიც მოცემულია ამ წესების შესაბამს ქვეთავში</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>„</b><b>სამართავი</b><b> </b><b>პანელი</b><b>“</b></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'> / </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>„</b><b>ადმინისტრატორის</b><b>
</b><b>პანელი</b><b> (Admin Panel)“</b> - ინტერფეისი KEEPZ.ME-ს პლატფორმაზე, რომლითაც
მომხმარებლებს შეუძლიათ მართონ თავიანთი საგადახადო ანგარიში და მიიღონ ინფორმაცია
განხორციელებულ / მიღებულ გადახდებზე</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'>.</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:0in;text-align:center;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ნაწილი</b><b>
III.    </b><b>მომსახურების</b><b> </b><b>აღწერა</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.25in;text-indent:-.25in;line-height:115%;layout-grid-mode:char;
border:none'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ექვაირინგის</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>მომსახურება</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME, ექვაირინგის მომსახურების
ფარგლებში, მომხმარებლებს საშუალებას აძლევს მიიღონ გადახდები შემდეგი არხების გამოყენებით:</span></p>

<ol style='margin-top:0in' start=1 type=1>
 <li class=MsoNormal style='color:black;margin-top:6.0pt;margin-bottom:6.0pt;
     text-align:justify;line-height:115%;layout-grid-mode:char;border:none'><span
     lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:windowtext'> <span
     style='color:black'>QR </span><span style='color:black'>გადახდა</span><span
     style='color:black'> </span><span style='color:black'>და</span><span
     style='color:black'> </span><span style='color:black'>ელ</span><span
     style='color:black'>. </span><span style='color:black'>კომერცია</span>              </span></li>
</ol>

<ol style='margin-top:0in' start=2 type=1>
 <li class=MsoNormal style='margin-top:6.0pt;margin-bottom:6.0pt;text-align:
     justify;line-height:115%;layout-grid-mode:char'><span lang="GEO/KAT"
     style='font-family:"Sylfaen",serif'><span style='color:black'>API </span><span
     style='color:black'>ინტეგრაცია</span><span style='color:black'> </span></span></li>
</ol>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>„API ინტეგრაციას“ ძირითადად იყენებენ
ის მომხმარებლები, რომლებიც საქონლის/მომსახურების რეალიზაციას ახორციელებენ საკუთრებაში
ან მართლზომიერ მფლობელობაში არსებული ვებ-გვერდის/აპლიკაციის მეშვეობით. „No-code
გადაწყვეტა“ შეიქმნა ისეთი მომხმარებლებისათვის, რომლებიც თავიანთი საქონლის/მომსახურების
მიწოდებას სოციალური ქსელის ან კლიენტთან სხვა სახის კომუნიკაციის მეშვეობით ახორციელებენ.
</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char;
border:none'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'> QR გადახდა
და ელ. კომერცია     </span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.55in;text-align:justify;text-indent:-.3in;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></b><span
lang="GEO/KAT"><b>     </b><b><span style='font-family:"Sylfaen",serif'>QR </span></b><b><span
style='font-family:"Sylfaen",serif'>გადახდა</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char;
border:none'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>QR გადახდის
მეთოდის გამოყენებით, კლიენტებს შეუძლიათ ნივთის შეძენა QR კოდის დასკანერებით, კერძოდ,
კლიენტი დაასკანერებს QR კოდს, რომლის გენერერირებაც ხდება ინდივიდუალურად მომხმარებლისთვის
და არის უნიკალური კოდი,  მომხმარებლის მიერ KEEPZ.ME-ს სამართავი პანელიდან (მომხმარებელი
ამობეჭდავს QR კოდს, ან გაუხსნის გადამხდელს  KEEPZ.ME-ს აპლიკაციას გამოიყენებს ე.წ
„სურათის სახით დამახსოვრების“ ფუნქციონალს, იმისათვის რომ QR კოდი ხელმისაწვდომი იყოს
გადამხდელისთვისკლიენტისათვის). QR კოდის დასკანერების შემდგომ, კლიენტი გადამისამართდება
გვერდზე, სადაც მიუთითებს შერჩეული ნივთის / მომსახურების ღირებულებას, აირჩევს გადახდის
მეთოდს (მათ შორის, ბარათის მიბმა, ღია ბანკინგი (არსებობის შემთხვევაში) Apple
Pay ან Google Pay) და ასრულებს შესაბამის საგადახდო ოპერაციას.</span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.55in;text-align:justify;text-indent:-.3in;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></b><span
lang="GEO/KAT"><b>     </b><b><span style='font-family:"Sylfaen",serif;
color:black'>FB / Instagram </span></b><b><span style='font-family:"Sylfaen",serif;
color:black'>მაღაზია</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>იმ შემთხვევაში, თუ მომხმარებელს
აქვს ფეისბუქის/ინსტაგრამის მაღაზია, მომხმარებლის პანელზე განთავსებული სპეციალური
ინტეგრაციის ბმულის მეშვეობით, შესაძლებელია ონლაინ ბარათით გადახდების ინტეგრირება
ფეისბუქის/ინსტაგრამის მაღაზიასთან.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ასეთი ინტეგრაციის მეთოდის შემთხვევაში,
მომხმარებელი მხოლოდ განათავსებს/ატვირთავს პროდუქტებს მომხმარებლის პანელზე, რის შემდეგაც
ისინი ავტომატურად გამოჩნდება შესაბამის ციფრულ მაღაზიაში, საიდანაც კლიენტს პირდაპირ
მიეცემა მათი შეძენის საშუალება.</span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.55in;text-align:justify;text-indent:-.3in;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT">     <b><span style='font-family:"Sylfaen",serif;
color:black'>ბმულით</span></b><b><span style='font-family:"Sylfaen",serif;
color:black'> </span></b><b><span style='font-family:"Sylfaen",serif;
color:black'>გადახდა</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ბმულით გადახდა საშუალებას აძლევს
მომხმარებელს შექმნას ერთჯერადი  ან სტატიკური ბმული. აღნიშნული ბმული ავტომატურად
გადაეგზავნება კლიენტს, რომელმაც უნდა შეიყვანოს მოთხოვნილი ინფორმაცია და დაასრულოს
გადახდა ბარათის მეშვეობით.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.2 </span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>API ინტეგრაცია </span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>„API ინტეგრაციის“ გზით მომხმარებელმა
შესაძლებელია ისარგებლოს შემდეგი პროდუქტებით.  </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>1.2.1 </b><b>ერთჯერადი</b><b>
</b><b>გადახდა</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ერთჯერადი გადახდის კონცეფცია
ემსახურება ერთადერთ მიზანს - კლიენტის ბარათიდან მითითებული თანხის ჩამოჭრას.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>1.2.2 </b><b>გადახდა</b><b>
</b><b>შენს</b><b> </b><b>ვებგვერდზე</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>„გადახდა შენს ვებგვერდზე“ ოფცია
საშუალებას აძლევს მომხმარებელებს განახორციელონ გადახდა თავიანთ ვებგვერდზე და ამგვარად,
თავიდან აარიდონ კლიენტს  დამატებითი/მესამე მხარის ვებგვერდზე გადამისამართება და
ასეთ პროცედურასთან დაკავშირებული ფორმალობა/ძალისხმევა. </span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT"><b><span style='font-family:"Sylfaen",serif;
color:black'>ღია</span></b><b><span style='font-family:"Sylfaen",serif;
color:black'> </span></b><b><span style='font-family:"Sylfaen",serif;
color:black'>ბანკინგი</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:115%;layout-grid-mode:
char;border:none'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>2.1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>გადახდის</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>ინიციირების</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>მომსახურება</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>გადახდის ინციირების მომსახურების
მეშვეობით, KEEPZ.ME უზრუნველყოფს მომხმარებლის დავალებით, მომხმარებლის ანგარიშიდან
თანხის ჩამოჭრას და თანხის სხვა ანგარიშზე ან ბარათზე გადარიცხვას.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;text-indent:0in;line-height:115%;layout-grid-mode:
char;border:none'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>2.1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ანგარიშის</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>ინფორმაციაზე</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>წვდომა</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ანგარიშის ინფორმაციაზე წვდომის
მეშვეობით, KEEPZ.ME უზრუნველყოფს მომხმარებლის დავალებით, მომხმარებლის ანგარიშზე
არსებული ნაშთის თაობაზე ინფორმაციის ერთჯერად, მუდმივ ან პერიოდულ განახლებას, მათ
შორის ინფორმაციის KEEPZ.ME-ს პანელში დამატებას და ხელმისაწვდომოობას.</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:.25in;text-align:justify;text-indent:-.25in;
line-height:115%;layout-grid-mode:char'><b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT"><b><span style='font-family:"Sylfaen",serif;
color:black'>ფულადი</span></b><b><span style='font-family:"Sylfaen",serif;
color:black'> </span></b><b><span style='font-family:"Sylfaen",serif;
color:black'>გზავნილები</span></b></span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:21.3pt;text-align:justify;text-indent:-.3in;
line-height:115%;layout-grid-mode:char'><b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>3.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><span lang="GEO/KAT">     <b><span style='font-family:"Sylfaen",serif;
color:black'>ფულადი</span></b><b><span style='font-family:"Sylfaen",serif;
color:black'> </span></b><b><span style='font-family:"Sylfaen",serif;
color:black'>გზავნილის</span></b><span style='font-family:"Sylfaen",serif'> </span><b><span
style='font-family:"Sylfaen",serif;color:black'>ტრანზაქცია</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
</span><span style='color:black'>აპლიკაციის</span><span style='color:black'> </span><span
style='color:black'>მეშვეობით</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>ფიზიკური</span><span style='color:black'> </span><span
style='color:black'>პირის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>ინიციირებული</span><span style='color:black'> </span><span
style='color:black'>საქართველოს</span><span style='color:black'> </span><span
style='color:black'>ტერიტორიაზე</span><span style='color:black'> </span><span
style='color:black'>მოქმედი</span><span style='color:black'> </span><span
style='color:black'>ფინანსური</span><span style='color:black'> </span><span
style='color:black'>ინსტიტუტების</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>ემიტირებული</span><span style='color:black'> </span><span
style='color:black'>ბარათის</span><span style='color:black'> </span><span
style='color:black'>საბარათე</span><span style='color:black'> </span><span
style='color:black'>ანგარიშიდან</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>ფიზიკური</span><span style='color:black'> </span><span
style='color:black'>პირის</span><span style='color:black'> </span><span
style='color:black'>საქართველოს</span><span style='color:black'> </span><span
style='color:black'>ტერიტორიაზე</span><span style='color:black'> </span><span
style='color:black'>მოქმედი</span><span style='color:black'> </span><span
style='color:black'>ფინანსური</span><span style='color:black'> </span><span
style='color:black'>ინსტიტუტების</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>ემიტირებულ</span><span style='color:black'> </span><span
style='color:black'>ბარათის</span><span style='color:black'> </span><span
style='color:black'>საბარათე</span><span style='color:black'> </span><span
style='color:black'>ანგარიშზე</span><span style='color:black'>, </span><span
style='color:black'>ეროვნულ</span><span style='color:black'> </span><span
style='color:black'>ვალუტაში</span><span style='color:black'> </span><span
style='color:black'>თანხის</span><span style='color:black'> </span><span
style='color:black'>გადარიცხვის</span><span style='color:black'> </span><span
style='color:black'>ოპერაცია</span><span style='color:black'> (“</span><span
style='color:black'>ფულადი</span><span style='color:black'> </span><span
style='color:black'>გზავნილის</span> <span style='color:black'>ტრანზაქცია</span><span
style='color:black'>”)</span></span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:21.3pt;text-align:justify;text-indent:-.3in;
line-height:115%;layout-grid-mode:char'><b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>3.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><span lang="GEO/KAT">     <b><span style='font-family:"Sylfaen",serif;
color:black'>ვირტუალური აქტივის კონვერტაციის გზით მომხმარებლის ანგარიშზე ფულადი სახსრების ჩარიცხვა</span></b><b><span style='font-family:"Sylfaen",serif;
color:black'> </span></b><b><span style='font-family:"Sylfaen",serif;
color:black'></span></b><span style='font-family:"Sylfaen",serif'> </span><b><span
style='font-family:"Sylfaen",serif;color:black'></span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'>
    <span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>
        <span style='color:black'>KEEPZ.ME </span>
        <span style='color:black'>აპლიკაციის </span>
        <span style='color:black'>მეშვეობით </span>
        <span style='color:black'>შესაძლებელია </span>
        <span style='color:black'>მომხმარებლის </span>
        <span style='color:black'>ანგარიშზე </span>
        <span style='color:black'>ფულადი </span>
        <span style='color:black'>სახსრების </span>
        <span style='color:black'>ჩარიცხვა </span>
        <span style='color:black'>ვირტუალური </span>
        <span style='color:black'>აქტივის </span>
        <span style='color:black'>კონვერტაციის </span>
        <span style='color:black'>გზით, </span>
        <span style='color:black'>კლიენტის </span>
        <span style='color:black'>მიერ </span>
        <span style='color:black'>ჩარიცხვის </span>
        <span style='color:black'>ინიცირების </span>
        <span style='color:black'>საფუძველზე. </span>
        <span style='color:black'>აღნიშნული </span>
        <span style='color:black'>მომსახურების </span>
        <span style='color:black'>ფარგლებში, </span>
        <span style='color:black'>KEEPZ.ME </span>
        <span style='color:black'>უზრუნველყოფს </span>
        <span style='color:black'>ვირტუალური </span>
        <span style='color:black'>აქტივის </span>
        <span style='color:black'>კონვერტაციის </span>
        <span style='color:black'>შედეგად </span>
        <span style='color:black'>მიღებული </span>
        <span style='color:black'>ფულადი </span>
        <span style='color:black'>სახსრების </span>
        <span style='color:black'>ასახვას </span>
        <span style='color:black'>მომხმარებლის </span>
        <span style='color:black'>ანგარიშზე. </span>
        <span style='color:black'>KEEPZ.ME </span>
        <span style='color:black'>ვირტუალური </span>
        <span style='color:black'>აქტივის </span>
        <span style='color:black'>კონვერტაციის </span>
        <span style='color:black'>გზით </span>
        <span style='color:black'>მომხმარებლისთვის </span>
        <span style='color:black'>საგადახდო </span>
        <span style='color:black'>მომსახურების </span>
        <span style='color:black'>გაწევის </span>
        <span style='color:black'>პროცესში </span>
        <span style='color:black'>თანამშრომლობს </span>
        <span style='color:black'>პარტნიორ </span>
        <span style='color:black'>ვირტუალური </span>
        <span style='color:black'>აქტივის </span>
        <span style='color:black'>მომსახურების </span>
        <span style='color:black'>პროვაიდერთან </span>
        <span style='color:black'>მათ </span>
        <span style='color:black'>შორის </span>
        <span style='color:black'>არსებული </span>
        <span style='color:black'>შესაბამისი </span>
        <span style='color:black'>შეთანხმების </span>
        <span style='color:black'>საფუძველზე; </span>
        <span style='color:black'>ამ </span>
        <span style='color:black'>მომსახურების </span>
        <span style='color:black'>მიზნებისთვის, </span>
        <span style='color:black'>ვირტუალური </span>
        <span style='color:black'>აქტივის </span>
        <span style='color:black'>მომსახურების </span>
        <span style='color:black'>პროვაიდერი </span>
        <span style='color:black'>კომპანია </span>
        <span style='color:black'>შესაძლებელია </span>
        <span style='color:black'>იყოს </span>
        <span style='color:black'>მათ </span>
        <span style='color:black'>შორის, </span>
        <span style='color:black'>შპს </span>
        <span style='color:black'>სითი </span>
        <span style='color:black'>ფეი </span>
        <span style='color:black'>(ს/ნ: </span>
        <span style='color:black'>402132312).</span>
    </span>
</p>


<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.25in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>დამატებითი</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>ფუნქციები</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წინამდებარე დოკუმენტის პირველ
და მეორე ნაწილში განხილული პროდუქტების გარდა, KEEPZ.ME დამატებით უზრუნველყოფს შემდეგი
ფუნქციონალებით სარგებლობის შესაძლებლობას:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Noto Sans Symbols";color:black'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>წინაპირობების</span><span style='color:black'> </span><span
style='color:black'>არსებობის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'> </span><span
style='color:black'>თანხის</span><span style='color:black'> </span><span
style='color:black'>დაბრუნება</span><span style='color:black'>, </span><span
style='color:black'>თანხის</span><span style='color:black'> </span><span
style='color:black'>ნაწილობრივი</span><span style='color:black'> </span><span
style='color:black'>დაბრუნება</span><span style='color:black'> </span><b><span
style='color:black'>(„</span></b><b><span style='color:black'>რეფანდის</span></b><b><span
style='color:black'> </span></b><b><span style='color:black'>ოპერაცია</span></b><b><span
style='color:black'>“). </span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Noto Sans Symbols";color:black'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>წინასწარი</span><span style='color:black'> </span><span
style='color:black'>ავტორიზაცია</span><span style='color:black'> - </span><span
style='color:black'>დროებით</span><span style='color:black'> </span><span
style='color:black'>ბლოკავს</span><span style='color:black'> </span><span
style='color:black'>თანხებს</span><span style='color:black'> </span><span
style='color:black'>კლიენტის</span><span style='color:black'> </span><span
style='color:black'>ბარათზე</span><span style='color:black'>, </span><span
style='color:black'>ტრანზაქციის</span><span style='color:black'> </span><span
style='color:black'>განხორციელებამდე</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Noto Sans Symbols";color:black'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ტრანზაქცასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>მიღება</span><span style='color:black'> – </span><span
style='color:black'>საშუალებას</span><span style='color:black'> </span><span
style='color:black'>აძლევს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლებს</span><span style='color:black'> </span><span
style='color:black'>მიიღონ</span><span style='color:black'> </span><span
style='color:black'>ინფორმაცია</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ტრანზაქციის</span><span style='color:black'> </span><span
style='color:black'>სტატუსის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'>. </span><span
style='color:black'>ტრანზაქციის</span><span style='color:black'> </span><span
style='color:black'>სტატუსის</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნა</span><span style='color:black'> </span><span
style='color:black'>შესაძლებელია</span><span style='color:black'> </span><span
style='color:black'>მანუალურადაც</span><span style='color:black'>, </span><span
style='color:black'>თუმცა</span><span style='color:black'> </span><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>ტიპს</span><span style='color:black'> </span><span
style='color:black'>აქვს</span><span style='color:black'> </span><span
style='color:black'>ე</span><span style='color:black'>.</span><span
style='color:black'>წ</span><span style='color:black'>. „hookUrl“ </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>ავტომატურად</span><span style='color:black'> </span><span
style='color:black'>აგზავნის</span><span style='color:black'> </span><span
style='color:black'>ტრანზაქციის</span><span style='color:black'> </span><span
style='color:black'>სტატუსს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>სერვერზე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;line-height:115%;layout-grid-mode:char'><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'>დამატებითი მომსახურებები მიემართება ამ აღწერილობაში
განსაზღვრულ პროდუქტებს შესაბამისად.</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:0in;text-align:center;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black;background:white'>ნაწილი</span></b><b><span
style='color:black;background:white'> IV. </span></b><b><span style='color:
black;background:white'>ცალკეული</span></b><b><span style='color:black;
background:white'> </span></b><b><span style='color:black;background:white'>მომსახურებით</span></b><b><span
style='color:black;background:white'> </span></b><b><span style='color:black;
background:white'>სარგებლობის</span></b><b><span style='color:black;background:
white'> </span></b><b><span style='color:black;background:white'>წესები</span></b><b><span
style='color:black;background:white'> </span></b><b><span style='color:black;
background:white'>და</span></b><b><span style='color:black;background:white'> </span></b><b><span
style='color:black;background:white'>პირობები</span></b></span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:2.0in;text-indent:-.25in;line-height:115%;layout-grid-mode:
char;border:none'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>I.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT"><b><span style='font-family:"Sylfaen",serif;
color:black'>ექვაირინგის</span></b><b><span style='font-family:"Sylfaen",serif;
color:black'> </span></b><b><span style='font-family:"Sylfaen",serif;
color:black'>მომსახურება</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>შესავალი</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;line-height:115%;layout-grid-mode:char;
border:none'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მოცემული</span><span style='color:black'> </span><span
style='color:black'>თავი</span><span style='color:black'> </span><span
style='color:black'>განსაზღვრავს</span><span style='color:black'> </span><span
style='color:black'>ექვაირინგის</span><span style='color:black'> </span><span
style='color:black'>მომსახურებით</span><span style='color:black'> </span><span
style='color:black'>სარგებლობის</span><span style='color:black'>  </span><span
style='color:black'>წესებსა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>პირობებს</span><span style='color:black'>, </span><span
style='color:black'>რომლის</span><span style='color:black'> </span><span
style='color:black'>ფარგლებშიც</span><span style='color:black'>,  </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>პარტნიორ</span><span style='color:black'> </span><span
style='color:black'>ბანკთან</span><span style='color:black'> </span><span
style='color:black'>გაფორმებული</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>შეთანხმების</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'>, </span><span
style='color:black'>უზრუნველყოფილია</span><span style='color:black'> </span><span
style='color:black'>ბანკის</span><span style='color:black'> </span><span
style='color:black'>ელექტრონულ</span><span style='color:black'>-</span><span
style='color:black'>ტექნიკური</span><span style='color:black'> </span><span
style='color:black'>საშუალებით</span><span style='color:black'>, </span><span
style='color:black'>ბარათის</span><span style='color:black'> </span><span
style='color:black'>მფლობელის</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>ინსტრუმენტის</span><span style='color:black'> (</span><span
style='color:black'>ბარათის</span><span style='color:black'>) </span><span
style='color:black'>მეშვეობით</span><span style='color:black'> </span><span
style='color:black'>საბარათე</span><span style='color:black'> </span><span
style='color:black'>ოპერაციის</span><span style='color:black'> </span><span
style='color:black'>განხორციელება</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>ანგარიშსწორება</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>დასაშვებობა</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;line-height:115%;layout-grid-mode:char;
border:none'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ექვაირინგის</span><span style='color:black'> </span><span
style='color:black'>მომსახურებით</span><span style='color:black'> </span><span
style='color:black'>სარგებლობა</span><span style='color:black'> </span><span
style='color:black'>შეუძლია</span><span style='color:black'> </span><span
style='color:black'>საქართველოს</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'> </span><span
style='color:black'>რეგისტრირებულ</span><span style='color:black'> </span><span
style='color:black'>სამეწარმეო</span><span style='color:black'> </span><span
style='color:black'>საზოგადოებას</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ორგანიზაციულ</span><span style='color:black'> </span><span
style='color:black'>წარმონაქმნს</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ინდივიდუალურ</span><span style='color:black'> </span><span
style='color:black'>მეწარმეს</span><span style='color:black'> („</span><span
style='color:black'>იმ</span><span style='color:black'>“), </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>ახორციელებს</span><span style='color:black'> </span><span
style='color:black'>საქმიანობას</span><span style='color:black'> </span><span
style='color:black'>საქართველოს</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>რეგისტრაცია</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.1<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>ექვაირინგის</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>მისაღებად</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>აუცილებელია</span><span
style='color:black'> </span><span style='color:black'>დარეგისტრირდეს</span><span
style='color:black'> </span><span style='color:black'>ვებგვერდზე</span><span
style='color:black'> </span></span><span lang="GEO/KAT"><a
href="http://www.keepz.me"><span style='font-family:"Sylfaen",serif;color:#0563C1'>www.keepz.me</span></a></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დააფიქსიროს</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>ინფორმაცია</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.2<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>იმისათვის</span><span
style='color:black'>, </span><span style='color:black'>რომ</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>მ</span><span
style='color:black'> </span><span style='color:black'>შეასრულოს</span><span
style='color:black'> </span><span style='color:black'>ფულის</span><span
style='color:black'> </span><span style='color:black'>გათეთრებისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ტერორიზმის</span><span
style='color:black'> </span><span style='color:black'>დაფინანსების</span><span
style='color:black'> </span><span style='color:black'>აღკვეთის</span><span
style='color:black'> </span><span style='color:black'>ხელშეწყობის</span><span
style='color:black'> </span><span style='color:black'>შესახებ</span><span
style='color:black'> </span><span style='color:black'>არსებული</span><span
style='color:black'> </span><span style='color:black'>კანონმდებლობის</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნები</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ასევე</span><span
style='color:black'>, </span><span style='color:black'>უზრუნველყოს</span><span
style='color:black'> </span><span style='color:black'>შესაძლო</span><span
style='color:black'> </span><span style='color:black'>თაღლითური</span><span
style='color:black'> </span><span style='color:black'>ქმედებების</span><span
style='color:black'> </span><span style='color:black'>აღკვეთა</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებლისათვის</span><span
style='color:black'> </span><span style='color:black'>ვებ</span><span
style='color:black'>-</span><span style='color:black'>გვერდზე</span><span
style='color:black'> </span><span style='color:black'>რეგისტრაციის</span><span
style='color:black'> </span><span style='color:black'>წარმატებით</span><span
style='color:black'> </span><span style='color:black'>გავლის</span><span
style='color:black'> </span><span style='color:black'>შემდგომი</span><span
style='color:black'> </span><span style='color:black'>ეტაპია</span><span
style='color:black'> </span><span style='color:black'>სამართავ</span><span
style='color:black'> </span><span style='color:black'>პანელზე</span><span
style='color:black'> </span><span style='color:black'>განთავსებული</span><span
style='color:black'> </span><span style='color:black'>ფორმის</span><span
style='color:black'> </span><span style='color:black'>შევსება</span><span
style='color:black'>, </span><span style='color:black'>რომლის</span><span
style='color:black'> </span><span style='color:black'>ფარგლებშიც</span><span
style='color:black'> </span><span style='color:black'>მან</span><span
style='color:black'> </span><span style='color:black'>უნდა</span><span
style='color:black'> </span><span style='color:black'>წარმოადგინოს</span><span
style='color:black'> </span><span style='color:black'>ინფორმაცია</span><span
style='color:black'> </span><span style='color:black'>საკუთარი</span><span
style='color:black'> </span><span style='color:black'>თავის</span><span
style='color:black'>, </span><span style='color:black'>საქმიანობისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>აქციონერების</span><span
style='color:black'> </span><span style='color:black'>შესახებ</span><span
style='color:black'> (</span><span style='color:black'>შემდგომში</span><span
style='color:black'> „</span><span style='color:black'>რეგისტრაციისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>განაცხადი</span><span
style='color:black'>“). </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>უპირობოდ</span><span
style='color:black'> </span><span style='color:black'>იძლევა</span><span
style='color:black'> </span><span style='color:black'>გარანტიას</span><span
style='color:black'>, </span><span style='color:black'>რომ</span><span
style='color:black'> </span><span style='color:black'>მის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>მოწოდებული</span><span
style='color:black'> </span><span style='color:black'>ინფორმაცია</span><span
style='color:black'> </span><span style='color:black'>არის</span><span
style='color:black'> </span><span style='color:black'>სწორი</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ვალიდური</span><span
style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.3<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>წინამდებარე</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულების</span><span
style='color:black'> </span><span style='color:black'>მოქმედების</span><span
style='color:black'> </span><span style='color:black'>ვადის</span><span
style='color:black'> </span><span style='color:black'>განმავლობაში</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მის</span><span
style='color:black'> </span><span style='color:black'>შემდგომ</span><span
style='color:black'> (</span><span style='color:black'>თუ</span><span
style='color:black'> </span><span style='color:black'>ეს</span><span
style='color:black'> </span><span style='color:black'>საჭიროა</span><span
style='color:black'> </span><span style='color:black'>მარეგულირებელი</span><span
style='color:black'> </span><span style='color:black'>კანონმდებლობის</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>პარტნიორი</span><span
style='color:black'> </span><span style='color:black'>ბანკის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>წარდგენილი</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნების</span><span
style='color:black'> </span><span style='color:black'>დაცვისთვის</span><span
style='color:black'>) KEEPZ.ME </span><span style='color:black'>უფლებამოსილია</span><span
style='color:black'> </span><span style='color:black'>მოსთხოვოს</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელს</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ასეთ</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>ვალდებულია</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'>, </span><span style='color:black'>მის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნილ</span><span
style='color:black'> </span><span style='color:black'>ვადაში</span><span
style='color:black'>, </span><span style='color:black'>წარუდგინოს</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>ბიზნესთან</span><span
style='color:black'> </span><span style='color:black'>დაკავშირებული</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> </span><span style='color:black'>ინფორმაცია</span><span
style='color:black'>, </span><span style='color:black'>რაც</span><span
style='color:black'> </span><span style='color:black'>შეზღუდვის</span><span
style='color:black'> </span><span style='color:black'>გარეშე</span><span
style='color:black'> </span><span style='color:black'>გულისხმობს</span><span
style='color:black'> </span><span style='color:black'>ინფორმაციას</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>საკუთრებაში</span><span
style='color:black'> </span><span style='color:black'>არსებული</span><span
style='color:black'> </span><span style='color:black'>ქონების</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>წესდების</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>დამფუძნებლების</span><span
style='color:black'>, </span><span style='color:black'>ბენეფიციარი</span><span
style='color:black'> </span><span style='color:black'>მესაკუთრეების</span><span
style='color:black'>, </span><span style='color:black'>დირექტორების</span><span
style='color:black'>, </span><span style='color:black'>შვილობილი</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>დაკავშირებული</span><span
style='color:black'> </span><span style='color:black'>კომპანიების</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>ფილიალების</span><span
style='color:black'> </span><span style='color:black'>შესახებ</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.4<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>ვალდებულია</span><span
style='color:black'> </span><span style='color:black'>დაუყონებლივ</span><span
style='color:black'> </span><span style='color:black'>შეატყობინოს</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>ყოველგვარი</span><span
style='color:black'> </span><span style='color:black'>ცვლილების</span><span
style='color:black'> (</span><span style='color:black'>მათ</span><span
style='color:black'> </span><span style='color:black'>შორის</span><span
style='color:black'> </span><span style='color:black'>კორპორატიულ</span><span
style='color:black'> </span><span style='color:black'>სტრუქტურაში</span><span
style='color:black'>, </span><span style='color:black'>პროდუქტების</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მომსახურებების</span><span
style='color:black'> </span><span style='color:black'>ჩამონათვალში</span><span
style='color:black'> </span><span style='color:black'>განხორცილებული</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>განსახორციელებელი</span><span
style='color:black'> </span><span style='color:black'>ცვლილებების</span><span
style='color:black'>) </span><span style='color:black'>შესახებ</span><span
style='color:black'>. </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>ვალდებულია</span><span
style='color:black'> </span><span style='color:black'>დაიცვას</span><span
style='color:black'> KEEPZ.ME </span><span style='color:black'>ყოველგვარი</span><span
style='color:black'> </span><span style='color:black'>ზარალისგან</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>დანაკარგისგან</span><span
style='color:black'>, </span><span style='color:black'>რაც</span><span
style='color:black'> </span><span style='color:black'>შეიძლება</span><span
style='color:black'> </span><span style='color:black'>გამოიწვიოს</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>მის</span><span
style='color:black'> </span><span style='color:black'>კორპორატიულ</span><span
style='color:black'> </span><span style='color:black'>სტრუქტურაში</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>საქმიანობაში</span><span
style='color:black'> </span><span style='color:black'>ზემოაღნიშნული</span><span
style='color:black'> </span><span style='color:black'>ცვლილების</span><span
style='color:black'> </span><span style='color:black'>შეტანის</span><span
style='color:black'> </span><span style='color:black'>შეუტყობინებლობამ</span><span
style='color:black'>. </span><span style='color:black'>გარდა</span><span
style='color:black'> </span><span style='color:black'>ამისა</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>ვალდებულია</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნის</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'>, </span><span style='color:black'>დაუყონებლივ</span><span
style='color:black'> </span><span style='color:black'>წარმოადგინოს</span><span
style='color:black'> </span><span style='color:black'>დამატებითი</span><span
style='color:black'> </span><span style='color:black'>ინფორმაცია</span><span
style='color:black'>/</span><span style='color:black'>დამხმარე</span><span
style='color:black'> </span><span style='color:black'>დოკუმენტაცია</span><span
style='color:black'>, </span><span style='color:black'>რომელიც</span><span
style='color:black'> </span><span style='color:black'>საჭირო</span><span
style='color:black'> </span><span style='color:black'>იქნება</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>არსებულ</span><span
style='color:black'> </span><span style='color:black'>კანონმდებლობასთან</span><span
style='color:black'> </span><span style='color:black'>შესაბამისობის</span><span
style='color:black'> </span><span style='color:black'>უზრუნველსაყოფად</span><span
style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.5<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>წინამდებარე</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულებაზე</span><span
style='color:black'> </span><span style='color:black'>თანხმობით</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>ადასტურებს</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>აძლევს</span><span
style='color:black'>  KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>უფლებამოსილებას</span><span
style='color:black'>, </span><span style='color:black'>რომ</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>შესახებ</span><span
style='color:black'> </span><span style='color:black'>არსებული</span><span
style='color:black'> </span><span style='color:black'>ინფორმაცია</span><span
style='color:black'> (</span><span style='color:black'>მათ</span><span
style='color:black'> </span><span style='color:black'>შორის</span><span
style='color:black'>, </span><span style='color:black'>ვებ</span><span
style='color:black'>-</span><span style='color:black'>გვერდის</span><span
style='color:black'> </span><span style='color:black'>მისამართები</span><span
style='color:black'> (URL); </span><span style='color:black'>მართვის</span><span
style='color:black'> </span><span style='color:black'>ორგანოებში</span><span
style='color:black'> </span><span style='color:black'>შემავალი</span><span
style='color:black'> </span><span style='color:black'>პირების</span><span
style='color:black'> </span><span style='color:black'>შესახებ</span><span
style='color:black'> </span><span style='color:black'>ინფორმაცია</span><span
style='color:black'>; </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>მისამართები</span><span
style='color:black'>; KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>ფარგლებში</span><span
style='color:black'> </span><span style='color:black'>ნავაჭრი</span><span
style='color:black'> </span><span style='color:black'>თანხისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ტრანზაქციების</span><span
style='color:black'> </span><span style='color:black'>ოდენობა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> </span><span style='color:black'>სხვა</span><span
style='color:black'> </span><span style='color:black'>სახის</span><span
style='color:black'> </span><span style='color:black'>ინფორმაცია</span><span
style='color:black'>/</span><span style='color:black'>დოკუმენტაცია</span><span
style='color:black'>) </span><span style='color:black'>გადასცეს</span><span
style='color:black'> </span><span style='color:black'>პარტნიორ</span><span
style='color:black'> </span><span style='color:black'>ბანკს</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>საერთაშორისო</span><span
style='color:black'> </span><span style='color:black'>საგადამხდელო</span><span
style='color:black'> </span><span style='color:black'>სისტემებს</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>საზედამხედველო</span><span
style='color:black'>, </span><span style='color:black'>მაკონტროლებელ</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>სარეგისტრაციო</span><span
style='color:black'> </span><span style='color:black'>ორგანოებს</span><span
style='color:black'>, </span><span style='color:black'>სახელმწიფო</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>ადგილობრივი</span><span
style='color:black'> </span><span style='color:black'>თვითმმართველობის</span><span
style='color:black'> </span><span style='color:black'>ორგანოებს</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მათ</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>შექმნილ</span><span
style='color:black'> </span><span style='color:black'>იურიდიულ</span><span
style='color:black'> </span><span style='color:black'>პირებს</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>3.6<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
</span><span style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>წარმოდგენილი</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციის</span><span style='color:black'> </span><span
style='color:black'>გამოკვლევის</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'> </span><span
style='color:black'>მიიღებს</span><span style='color:black'> </span><span
style='color:black'>გადაწყვეტილებას</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>მომსახურებით</span><span style='color:black'> </span><span
style='color:black'>სარგებლობაზე</span><span style='color:black'> </span><span
style='color:black'>თანხმობის</span><span style='color:black'>/</span><span
style='color:black'>უარის</span><span style='color:black'> </span><span
style='color:black'>გაცემის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'>, </span><span
style='color:black'>რაც</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'>, </span><span
style='color:black'>წერილობით</span><span style='color:black'> (</span><span
style='color:black'>რეგისტრაციისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>განაცხადში</span><span style='color:black'> </span><span
style='color:black'>მითითებული</span><span style='color:black'> </span><span
style='color:black'>ელ</span><span style='color:black'>. </span><span
style='color:black'>ფოსტის</span><span style='color:black'> </span><span
style='color:black'>საშუალებით</span><span style='color:black'>) </span><span
style='color:black'>ეცნობება</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ლიმიტისა</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>და</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>რეზერვის</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>მოცულობების</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>დადგენა</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.1</span></b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><b><span style='color:black'>ლიმიტები</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ზემოაღნიშნული</span><span style='color:black'> </span><span
style='color:black'>მე</span><span style='color:black'>-3 </span><span
style='color:black'>მუხლით</span><span style='color:black'> </span><span
style='color:black'>განსაზღვრული</span><span style='color:black'> </span><span
style='color:black'>პროცედურის</span><span style='color:black'> </span><span
style='color:black'>წარმატებით</span><span style='color:black'> </span><span
style='color:black'>გავლის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>, KEEPZ.ME </span><span
style='color:black'>უფლებამოსილია</span><span style='color:black'> </span><span
style='color:black'>ელ</span><span style='color:black'>.</span><span
style='color:black'>ფოსტის</span><span style='color:black'> </span><span
style='color:black'>საშუალებით</span><span style='color:black'> </span><span
style='color:black'>გაგზავნილი</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'>, </span><span
style='color:black'>დაადგინოს</span><span style='color:black'> </span><span
style='color:black'>თანხობრივი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'>  </span><span
style='color:black'>რაოდენობრივი</span><span style='color:black'> </span><span
style='color:black'>ლიმიტი</span><span style='color:black'> </span><span
style='color:black'>შესრულებული</span><span style='color:black'>/</span><span
style='color:black'>შესასრულებელი</span><span style='color:black'> </span><span
style='color:black'>საბარათე</span><span style='color:black'> </span><span
style='color:black'>ოპერაციისთვის</span><span style='color:black'>, </span><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაციისათვის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>რეფანდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაციისთვის</span><span style='color:black'> </span><span
style='color:black'>დროის</span><span style='color:black'> </span><span
style='color:black'>გარკვეულ</span><span style='color:black'> </span><span
style='color:black'>მონაკვეთში</span><span style='color:black'> (</span><span
style='color:black'>მაგ</span><span style='color:black'>. </span><span
style='color:black'>დღე</span><span style='color:black'>, </span><span
style='color:black'>კვირა</span><span style='color:black'>, </span><span
style='color:black'>თვე</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ა</span><span style='color:black'>.</span><span
style='color:black'>შ</span><span style='color:black'>.). </span><span
style='color:black'>ამასთან</span><span style='color:black'>, KEEPZ.ME </span><span
style='color:black'>უფლებამოსილია</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>განახორციელოს</span><span style='color:black'> </span><span
style='color:black'>მომსახურება</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>საბარათე</span><span style='color:black'> </span><span
style='color:black'>ოპერაციისთვის</span><span style='color:black'>, </span><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაციისათვის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>რეფანდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაციისთვის</span><span style='color:black'> </span><span
style='color:black'>რომლებიც</span><span style='color:black'> </span><span
style='color:black'>აჭარბებს</span><span style='color:black'> </span><span
style='color:black'>დადგენილ</span><span style='color:black'> </span><span
style='color:black'>თანხობრივ</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>რაოდენობრივ</span><span style='color:black'> </span><span
style='color:black'>ლიმიტს</span><span style='color:black'>. </span><span
style='color:black'>გარდა</span><span style='color:black'> </span><span
style='color:black'>ამისა</span><span style='color:black'>, KEEPZ.ME </span><span
style='color:black'>უფლებამოსილია</span><span style='color:black'> </span><span
style='color:black'>დამატებითი</span><span style='color:black'> </span><span
style='color:black'>თანხმობის</span><span style='color:black'> </span><span
style='color:black'>გარეშე</span><span style='color:black'>, 30 (</span><span
style='color:black'>ოცდაათი</span><span style='color:black'>) </span><span
style='color:black'>კალენდარული</span><span style='color:black'> </span><span
style='color:black'>დღით</span><span style='color:black'> </span><span
style='color:black'>ადრე</span><span style='color:black'> </span><span
style='color:black'>ელ</span><span style='color:black'>.</span><span
style='color:black'>ფოსტის</span><span style='color:black'> </span><span
style='color:black'>საშუალებით</span><span style='color:black'> </span><span
style='color:black'>გაგზავნილი</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'>, </span><span
style='color:black'>შეცვალოს</span><span style='color:black'> </span><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>ლიმიტები</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.2</span></b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><b><span style='color:black'>რეზერვი</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებული</span><span style='color:black'> </span><span
style='color:black'>ფინანსური</span><span style='color:black'> </span><span
style='color:black'>ვალდებულებების</span><span style='color:black'> </span><span
style='color:black'>უზრუნველსაყოფად</span><span style='color:black'>, KEEPZ.ME </span><span
style='color:black'>უფლებამოსილია</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'> </span><span
style='color:black'>განუსაზღვროს</span><span style='color:black'> </span><span
style='color:black'>ფიქსირებული</span><span style='color:black'> </span><span
style='color:black'>სარეზერვო</span><span style='color:black'> </span><span
style='color:black'>თანხა</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.3dy6vkm"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>4.2.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME </span><span style='color:black'>უფლებამოსილია</span><span
style='color:black'> </span><span style='color:black'>სარეზერვო</span><span
style='color:black'> </span><span style='color:black'>ანგარიშიდან</span><span
style='color:black'> </span><span style='color:black'>უაქცეპტო</span><span
style='color:black'> </span><span style='color:black'>წესით</span><span
style='color:black'> </span><span style='color:black'>ჩამოჭრას</span><span
style='color:black'> </span><span style='color:black'>თანხები</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მიმართოს</span><span
style='color:black'> </span><span style='color:black'>იგი</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> </span><span style='color:black'>ზიანის</span><span
style='color:black'>, </span><span style='color:black'>საკომისიოს</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ხარჯის</span><span
style='color:black'> </span><span style='color:black'>ასანაზღაურებლად</span><span
style='color:black'>, </span><span style='color:black'>რომელიც</span><span
style='color:black'> </span><span style='color:black'>მიადგა</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>მიადგება</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>წინამდებარე</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულებით</span><span
style='color:black'> </span><span style='color:black'>ნაკისრი</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> </span><span style='color:black'>ვალდებულების</span><span
style='color:black'> </span><span style='color:black'>შეუსრულებლობით</span><span
style='color:black'>. </span><span style='color:black'>ამასთან</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>ვალდებულია</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>შესაბამისი</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნიდან</span><span
style='color:black'> 10 (</span><span style='color:black'>ათი</span><span
style='color:black'>) </span><span style='color:black'>საბანკო</span><span
style='color:black'> </span><span style='color:black'>დღის</span><span
style='color:black'> </span><span style='color:black'>განმავლობაში</span><span
style='color:black'> </span><span style='color:black'>განახორციელოს</span><span
style='color:black'> </span><span style='color:black'>რეზერვის</span><span
style='color:black'> </span><span style='color:black'>შესაბამისი</span><span
style='color:black'> </span><span style='color:black'>ოდენობით</span><span
style='color:black'> </span><span style='color:black'>შევსება</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>შეწყვეტის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'> </span><span
style='color:black'>სარეზერვო</span><span style='color:black'> </span><span
style='color:black'>ანგარიშზე</span><span style='color:black'> </span><span
style='color:black'>არსებული</span><span style='color:black'> </span><span
style='color:black'>თანხების</span><span style='color:black'> </span><span
style='color:black'>განკარგვის</span><span style='color:black'> </span><span
style='color:black'>უფლება</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'> </span><span
style='color:black'>მიეცემა</span><span style='color:black'> </span><span
style='color:black'>მის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>განხორციელებული</span><span style='color:black'> </span><span
style='color:black'>ბოლო</span><span style='color:black'> </span><span
style='color:black'>ავტორიზებული</span><span style='color:black'> </span><span
style='color:black'>ტრანზაქციიდან</span><span style='color:black'> 180 (</span><span
style='color:black'>ას</span><span style='color:black'> </span><span
style='color:black'>ოთხმოცი</span><span style='color:black'>) </span><span
style='color:black'>დღის</span><span style='color:black'> </span><span
style='color:black'>გასვლის</span><span style='color:black'> </span><span
style='color:black'>შემდეგ</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>4.2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ფიქსირებული</span><span style='color:black'> </span><span
style='color:black'>რეზერვის</span><span style='color:black'> </span><span
style='color:black'>არსებობის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>, </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>შეძლებს</span><span style='color:black'> </span><span
style='color:black'>ექვაირინგის</span><span style='color:black'> </span><span
style='color:black'>მომსახურებით</span><span style='color:black'> </span><span
style='color:black'>სარგებლობას</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ექვაირინგის</span><span style='color:black'> </span><span
style='color:black'>მომსახურებით</span><span style='color:black'> </span><span
style='color:black'>სარგებლობის</span><span style='color:black'> </span><span
style='color:black'>გაგრძელებას</span><span style='color:black'>, </span><span
style='color:black'>მხოლოდ</span><span style='color:black'> </span><span
style='color:black'>მას</span><span style='color:black'> </span><span
style='color:black'>შემდეგ</span><span style='color:black'> </span><span
style='color:black'>რაც</span><span style='color:black'>  </span><span
style='color:black'>წარმოადგენს</span><span style='color:black'> </span><span
style='color:black'>რეზერვის</span><span style='color:black'> </span><span
style='color:black'>ჩარიცხვის</span><span style='color:black'>/</span><span
style='color:black'>შვსების</span><span style='color:black'> </span><span
style='color:black'>დამადასტურებელ</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>დავალებას</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ანგარიშსწორების</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>წესი</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>და</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>პროცედურა</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.1<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>თითოეული</span><span
style='color:black'> </span><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაცია</span><span
style='color:black'>/</span><span style='color:black'>გადახდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაცია</span><span
style='color:black'> </span><span style='color:black'>შესაძლოა</span><span
style='color:black'> </span><span style='color:black'>წარმოადგენდეს</span><span
style='color:black'> </span><span style='color:black'>შესაბამისი</span><span
style='color:black'> </span><span style='color:black'>ავტორიზებული</span><span
style='color:black'> </span><span style='color:black'>ფინანსური</span><span
style='color:black'> </span><span style='color:black'>ინსტიტუტის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>მიღების</span><span
style='color:black'> </span><span style='color:black'>საგანს</span><span
style='color:black'>, </span><span style='color:black'>როგორც</span><span
style='color:black'> </span><span style='color:black'>ეს</span><span
style='color:black'> </span><span style='color:black'>განსაზღვრულია</span><span
style='color:black'>  </span><span style='color:black'>კანონმდებლობით</span><span
style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.2<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME-</span><span
style='color:black'>მ</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შესაბამისმა</span><span style='color:black'> </span><span
style='color:black'>ფინანსურმა</span><span style='color:black'> </span><span
style='color:black'>ინსტიტუტმა</span><span style='color:black'> </span><span
style='color:black'>შესაძლოა</span><span style='color:black'>, </span><span
style='color:black'>დააკავოს</span><span style='color:black'>, </span><span
style='color:black'>უარყოს</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>გაიხმოს</span><span style='color:black'> </span><span
style='color:black'>საბარათე</span><span style='color:black'> </span><span
style='color:black'>ოპერაცია</span><span style='color:black'>/</span><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაცია</span><span style='color:black'> </span><span
style='color:black'>საერთაშორისო</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ლოკალური</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>სისტემების</span><span style='color:black'> </span><span
style='color:black'>წესებისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>მიხედვით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შიდა</span><span style='color:black'> </span><span
style='color:black'>მართვის</span><span style='color:black'> </span><span
style='color:black'>პროცედურის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME 
</span><span style='color:black'>უფლებამოსილია</span><span style='color:black'>
</span><span style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>მიიღოს</span><span style='color:black'> </span><span
style='color:black'>შესასრულებლად</span><span style='color:black'> </span><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაცია</span><span style='color:black'>/</span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>დავალება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>უარი</span><span style='color:black'> </span><span
style='color:black'>განაცხადოს</span><span style='color:black'> </span><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაციის</span><span style='color:black'>/ </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>დავალების</span><span style='color:black'> </span><span
style='color:black'>შესრულებაზე</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'>:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაცია</span><span style='color:black'>/</span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>დავალება</span><span style='color:black'> </span><span
style='color:black'>შედგენილი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>წარდგენილია</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>დადგენილი</span><span style='color:black'> </span><span
style='color:black'>წესის</span><span style='color:black'> </span><span
style='color:black'>დარღვევით</span><span style='color:black'>,  </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>პირობების</span><span style='color:black'> </span><span
style='color:black'>საწინააღმდეგოდ</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>შეიცავს</span><span style='color:black'> </span><span
style='color:black'>არაზუსტ</span><span style='color:black'> </span><span
style='color:black'>ინფორმაციას</span><span style='color:black'> (</span><span
style='color:black'>მითითებებს</span><span style='color:black'>);</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>დავალების</span><span style='color:black'> </span><span
style='color:black'>შესრულებისათვის</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>ანგარიშზე</span><span style='color:black'> </span><span
style='color:black'>არსებული</span><span style='color:black'> </span><span
style='color:black'>თანხა</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>საკმარისი</span><span style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME -</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>მიუღია</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>დავალება</span><span
style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაცია</span><span style='color:black'>/</span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>დავალება</span><span style='color:black'> </span><span
style='color:black'>გადააჭარბებს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>დაწესებულ</span><span style='color:black'> </span><span
style='color:black'>ლიმიტს</span><span style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>ანგარიშზე</span><span style='color:black'> </span><span
style='color:black'>არსებული</span><span style='color:black'> </span><span
style='color:black'>თანხები</span><span style='color:black'> </span><span
style='color:black'>ექვემდებარება</span><span style='color:black'> </span><span
style='color:black'>საჯარო</span><span style='color:black'> </span><span
style='color:black'>სამართლებრივ</span><span style='color:black'> </span><span
style='color:black'>შეზღუდვას</span><span style='color:black'> (</span><span
style='color:black'>ყოველგვარი</span><span style='color:black'> </span><span
style='color:black'>ეჭვის</span><span style='color:black'> </span><span
style='color:black'>თავიდან</span><span style='color:black'> </span><span
style='color:black'>ასაცილებლად</span><span style='color:black'>, KEEPZ.ME </span><span
style='color:black'>უფლებამოსილია</span><span style='color:black'> </span><span
style='color:black'>განახორციელოს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>ანგარიშზე</span><span style='color:black'> </span><span
style='color:black'>საჯარო</span><span style='color:black'> </span><span
style='color:black'>სამართლებრივი</span><span style='color:black'> </span><span
style='color:black'>შეზღუდვის</span><span style='color:black'> </span><span
style='color:black'>დადება</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>შეზღუდვით</span><span style='color:black'> </span><span
style='color:black'>უზრუნველყოფილი</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნის</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>საინკასო</span><span style='color:black'> </span><span
style='color:black'>დავალების</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ყადაღის</span><span style='color:black'>)  </span><span
style='color:black'>დაკმაყოფილება</span><span style='color:black'>, </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>მიმართ</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებული</span><span style='color:black'> </span><span
style='color:black'>იძულებითი</span><span style='color:black'> </span><span
style='color:black'>აღსრულების</span><span style='color:black'> </span><span
style='color:black'>მექანიზმების</span><span style='color:black'> </span><span
style='color:black'>გამოყენების</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>);</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>არსებობს</span><span style='color:black'> </span><span
style='color:black'>ეჭვი</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>ადგილი</span><span style='color:black'> </span><span
style='color:black'>აქვს</span><span style='color:black'> </span><span
style='color:black'>თაღლითობას</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>უკანონო</span><span style='color:black'> </span><span
style='color:black'>ქმედებას</span><span style='color:black'>; </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაციის</span><span style='color:black'>/</span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>დავალების</span><span style='color:black'> </span><span
style='color:black'>შესრულება</span><span style='color:black'> </span><span
style='color:black'>გამოიწვევს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'>  </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>პირების</span><span style='color:black'> </span><span
style='color:black'>სანქცირებას</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>მათზე</span><span style='color:black'> </span><span
style='color:black'>სხვაგვარი</span><span style='color:black'> </span><span
style='color:black'>სამართლებრივი</span><span style='color:black'> </span><span
style='color:black'>შეზღუდვების</span><span style='color:black'> </span><span
style='color:black'>დაკისრებას</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'>, </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>სახელმწიფოების</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>საერთაშორისო</span><span style='color:black'> </span><span
style='color:black'>სამართლის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.3.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაცია</span><span style='color:black'>/</span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>დავალება</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>შეესაბამება</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>დადგენილ</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნებს</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ში</span><span style='color:black'> </span><span
style='color:black'>დადგენილ</span><span style='color:black'> </span><span
style='color:black'>წესებსა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>პროცედურებს</span><span style='color:black'>, </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>არსებობს</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ისეთი</span><span style='color:black'> </span><span
style='color:black'>ხარვეზი</span><span style='color:black'>/</span><span
style='color:black'>გარემოება</span><span style='color:black'> </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>შეუძლებელს</span><span style='color:black'> </span><span
style='color:black'>ხდის</span><span style='color:black'> </span><span
style='color:black'>დავალების</span><span style='color:black'> </span><span
style='color:black'>შესრულებას</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.4<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაციის</span><span
style='color:black'> </span><span style='color:black'>წარმატებით</span><span
style='color:black'> </span><span style='color:black'>შესრულებიდან</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>პარტნიორი</span><span
style='color:black'> </span><span style='color:black'>ბანკის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>შესაბამისი</span><span
style='color:black'> </span><span style='color:black'>თანხის</span><span
style='color:black'> </span><span style='color:black'>მიღებიდან</span><span
style='color:black'> </span><span style='color:black'>არაუგვიანეს</span><span
style='color:black'> </span><span style='color:black'>მომდევნო</span><span
style='color:black'> </span><span style='color:black'>საბანკო</span><span
style='color:black'> </span><span style='color:black'>დღისა</span><span
style='color:black'>, </span><span style='color:black'>პარტნიორი</span><span
style='color:black'> </span><span style='color:black'>ბანკი</span><span
style='color:black'> </span><span style='color:black'>ვალდებულია</span><span
style='color:black'> </span><span style='color:black'>უზრუნველყოს</span><span
style='color:black'> </span><span style='color:black'>ელექტრონულ</span><span
style='color:black'>-</span><span style='color:black'>ტექნიკური</span><span
style='color:black'> </span><span style='color:black'>საშუალებით</span><span
style='color:black'>, </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>ინსტრუმენტის</span><span
style='color:black'> (</span><span style='color:black'>ბარათის</span><span
style='color:black'>) </span><span style='color:black'>მეშვეობით</span><span
style='color:black'> </span><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაციის</span><span
style='color:black'> </span><span style='color:black'>განხორციელება</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ანგარიშსწორების</span><span
style='color:black'> </span><span style='color:black'>ანგარიშზე</span><span
style='color:black'> </span><span style='color:black'>გადასახდელის</span><span
style='color:black'> </span><span style='color:black'>ჩარიცხვა</span><span
style='color:black'>  </span><span style='color:black'>გარდა</span><span
style='color:black'> </span><span style='color:black'>იმ</span><span
style='color:black'> </span><span style='color:black'>შემთხვევისა</span><span
style='color:black'>, </span><span style='color:black'>როდესაც</span><span
style='color:black'> </span><span style='color:black'>გადახდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაცია</span><span
style='color:black'> </span><span style='color:black'>პარტნიორი</span><span
style='color:black'> </span><span style='color:black'>ბანკის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>საეჭვოდ</span><span
style='color:black'> </span><span style='color:black'>იქნება</span><span
style='color:black'> </span><span style='color:black'>მიჩნეული</span><span
style='color:black'>.   </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.5<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
</span><span style='color:black'>ვალდებულია</span><span style='color:black'>, </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>პირობების</span><span style='color:black'> 5.4 </span><span
style='color:black'>მუხლის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'>, </span><span
style='color:black'>ანგარიშსწორების</span><span style='color:black'> </span><span
style='color:black'>ანგარიშზე</span><span style='color:black'> </span><span
style='color:black'>ოპერაციის</span><span style='color:black'> </span><span
style='color:black'>თანხის</span><span style='color:black'> </span><span
style='color:black'>ჩარიცხვის</span><span style='color:black'> </span><span
style='color:black'>დღეს</span><span style='color:black'>, </span><span
style='color:black'>ხოლო</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>თანხის</span><span style='color:black'> </span><span
style='color:black'>ჩარიცხვის</span><span style='color:black'> </span><span
style='color:black'>დღე</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>თვის</span><span style='color:black'> </span><span
style='color:black'>არასამუშაო</span><span style='color:black'> </span><span
style='color:black'>დღეა</span><span style='color:black'> − </span><span
style='color:black'>არაუგვიანეს</span><span style='color:black'> </span><span
style='color:black'>მომდევნო</span><span style='color:black'> </span><span
style='color:black'>სამუშაო</span><span style='color:black'> </span><span
style='color:black'>დღისა</span><span style='color:black'>, </span><span
style='color:black'>ასახოს</span><span style='color:black'> </span><span
style='color:black'>ჩარიცხული</span><span style='color:black'> </span><span
style='color:black'>თანხა</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>ანგარიშზე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.6<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>სამართავი</span><span
style='color:black'> </span><span style='color:black'>პანელის</span><span
style='color:black'> </span><span style='color:black'>მეშვეობით</span><span
style='color:black'> </span><span style='color:black'>გადახდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაციის</span><span
style='color:black'> </span><span style='color:black'>ინიცირება</span><span
style='color:black'>, </span><span style='color:black'>წინამდებარე</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>პირობების</span><span
style='color:black'> </span><span style='color:black'>მიზნებისათვის</span><span
style='color:black'> </span><span style='color:black'>განიხილება</span><span
style='color:black'>, </span><span style='color:black'>როგორც</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>დავალების</span><span
style='color:black'> </span><span style='color:black'>გაცემა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>თანხმობა</span><span
style='color:black'> </span><span style='color:black'>გადახდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაციის</span><span
style='color:black'> </span><span style='color:black'>განხორციელებაზე</span><span
style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.7<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
</span><span style='color:black'>უფლებამოსილია</span><span style='color:black'>,
</span><span style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'>&nbsp;</span><span
style='color:black'>ანგარიშიდან</span><span style='color:black'>&nbsp;</span><span
style='color:black'>უაქცეპტოდ</span><span style='color:black'>, </span><span
style='color:black'>მომხმრებლის</span><span style='color:black'> </span><span
style='color:black'>შემდგომი</span><span style='color:black'>&nbsp;</span><span
style='color:black'>თანხმობის</span><span style='color:black'> </span><span
style='color:black'>გარეშე</span><span style='color:black'> </span><span
style='color:black'>ჩამოწეროს</span><span style='color:black'> </span><span
style='color:black'>თანხები</span><span style='color:black'> </span><span
style='color:black'>შემდეგ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევებში</span><span style='color:black'>:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'><span style='color:black'>ა</span><span style='color:black'>) </span><span
style='color:black'>შეცდომით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნათა</span><span style='color:black'> </span><span
style='color:black'>დარღვევით</span><span style='color:black'> </span><span
style='color:black'>ჩარიცხული</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>საეჭვო</span><span style='color:black'> </span><span
style='color:black'>თანხ</span><span style='color:black'>(</span><span
style='color:black'>ებ</span><span style='color:black'>)</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>უკან</span><span style='color:black'> </span><span
style='color:black'>დასაბრუნებლად</span><span style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'><span style='color:black'>ბ</span><span style='color:black'>)
KEEPZ.ME-</span><span style='color:black'>ს</span><span style='color:black'>&nbsp;</span><span
style='color:black'>წინაშე</span><span style='color:black'> </span><span
style='color:black'>არსებული</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>დავალიანების</span><span style='color:black'> </span><span
style='color:black'>დასაფარად</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ვალდებულების</span><span style='color:black'> </span><span
style='color:black'>შესასრულებლად</span><span style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'><span style='color:black'>გ</span><span style='color:black'>) </span><span
style='color:black'>მოქმედი</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელსა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'>&nbsp;</span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>დადებული</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'>, </span><span
style='color:black'>შეთანხმებით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>დოკუმენტით</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებულ</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>შემთხვევებში</span><span style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><a name="_heading=h.1t3h5sf"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>5.8<span
style='font:7.0pt "Times New Roman"'> </span></span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><span style='color:black'>გადახდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაციის</span><span
style='color:black'> </span><span style='color:black'>განხორციელება</span><span
style='color:black'> </span><span style='color:black'>ხდება</span><span
style='color:black'> </span><span style='color:black'>როგორც</span><span
style='color:black'> </span><span style='color:black'>ადგილობრივი</span><span
style='color:black'>, </span><span style='color:black'>ისე</span><span
style='color:black'> </span><span style='color:black'>უცხოური</span><span
style='color:black'> </span><span style='color:black'>ვალუტით</span><span
style='color:black'>. </span><span style='color:black'>ამასთან</span><span
style='color:black'>, </span><span style='color:black'>უცხოური</span><span
style='color:black'> </span><span style='color:black'>ვალუტით</span><span
style='color:black'> </span><span style='color:black'>გადახდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაციის</span><span
style='color:black'> </span><span style='color:black'>განხორციელება</span><span
style='color:black'> </span><span style='color:black'>დაშვებულია</span><span
style='color:black'>, </span><span style='color:black'>გარდა</span><span
style='color:black'> </span><span style='color:black'>ისეთი</span><span
style='color:black'> </span><span style='color:black'>შემთხვევისა</span><span
style='color:black'>, </span><span style='color:black'>როდესაც</span><span
style='color:black'> </span><span style='color:black'>გადახდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაციის</span><span
style='color:black'> </span><span style='color:black'>შესრულებაში</span><span
style='color:black'> </span><span style='color:black'>მონაწილე</span><span
style='color:black'> </span><span style='color:black'>ყველა</span><span
style='color:black'> </span><span style='color:black'>პროვაიდერი</span><span
style='color:black'> </span><span style='color:black'>არის</span><span
style='color:black'> </span><span style='color:black'>საქართველოს</span><span
style='color:black'> </span><span style='color:black'>ეროვნული</span><span
style='color:black'> </span><span style='color:black'>ბანკის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>ლიცენზირებული</span><span
style='color:black'> </span><span style='color:black'>ბანკი</span><span
style='color:black'>, </span><span style='color:black'>მიკრო</span><span
style='color:black'> </span><span style='color:black'>ბანკი</span><span
style='color:black'>, </span><span style='color:black'>საქართველოს</span><span
style='color:black'> </span><span style='color:black'>ეროვნული</span><span
style='color:black'> </span><span style='color:black'>ბანკის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>რეგისტრირებული</span><span
style='color:black'> </span><span style='color:black'>პროვაიდერი</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>მიკროსაფინანსო</span><span
style='color:black'> </span><span style='color:black'>ორგანიზაცია</span><span
style='color:black'>, </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>აღნიშნული</span><span
style='color:black'> </span><span style='color:black'>წარმოადგენს</span><span
style='color:black'> </span><span style='color:black'>ერთ</span><span
style='color:black'>-</span><span style='color:black'>ერთ</span><span
style='color:black'> </span><span style='color:black'>შემდეგ</span><span
style='color:black'> </span><span style='color:black'>გადახდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაციას</span><span
style='color:black'>:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'><span style='color:black'>ა</span><span style='color:black'>) </span><span
style='color:black'>საქონლის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>საფასურის</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, </span><span
style='color:black'>საკომისიოს</span><span style='color:black'>) </span><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაციას</span><span style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'><span style='color:black'>ბ</span><span style='color:black'>) </span><span
style='color:black'>შრომის</span><span style='color:black'> </span><span
style='color:black'>ანაზღაურების</span><span style='color:black'>, </span><span
style='color:black'>გარდა</span><span style='color:black'> </span><span
style='color:black'>მივლინების</span><span style='color:black'>, </span><span
style='color:black'>დანიშნულებით</span><span style='color:black'> </span><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაციას</span><span style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif'><span style='color:black'>გ</span><span style='color:black'>) </span><span
style='color:black'>ფინანსურ</span><span style='color:black'> </span><span
style='color:black'>ინსტრუმენტებთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებულ</span><span style='color:black'> </span><span
style='color:black'>საკომისიოს</span><span style='color:black'> </span><span
style='color:black'>გადახდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაციას</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>5.9<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>უფლებამოსილია</span><span
style='color:black'> </span><span style='color:black'>გასცეს</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>დავალება</span><span
style='color:black'> </span><span style='color:black'>გადახდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაციების</span><span
style='color:black'> </span><span style='color:black'>განხორციელებაზე</span><span
style='color:black'> </span><span style='color:black'>მხოლოდ</span><span
style='color:black'> </span><span style='color:black'>იმ</span><span
style='color:black'> </span><span style='color:black'>ვალუტაში</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>იმ</span><span
style='color:black'> </span><span style='color:black'>თანხის</span><span
style='color:black'> </span><span style='color:black'>ფარგლებში</span><span
style='color:black'>, </span><span style='color:black'>რომელშიც</span><span
style='color:black'> </span><span style='color:black'>დენომინირებულია</span><span
style='color:black'> </span><span style='color:black'>შესაბამისი</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>ანგარიში</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>ჩარჯბექი</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.1<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>ჩარჯბექი</span><span
style='color:black'> </span><span style='color:black'>გულისხმობს</span><span
style='color:black'> </span><span style='color:black'>პროცედურას</span><span
style='color:black'>, </span><span style='color:black'>რომლის</span><span
style='color:black'> </span><span style='color:black'>ფარგლებშიც</span><span
style='color:black'> </span><span style='color:black'>ბარათის</span><span
style='color:black'> </span><span style='color:black'>ემიტენტი</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>ბარათის</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'>, </span><span style='color:black'>საერთაშორისო</span><span
style='color:black'> </span><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>სქემის</span><span
style='color:black'> </span><span style='color:black'>წესების</span><span
style='color:black'> </span><span style='color:black'>შესაბამისად</span><span
style='color:black'>, </span><span style='color:black'>ასაჩივრებს</span><span
style='color:black'> </span><span style='color:black'>შესრულებულ</span><span
style='color:black'> </span><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაციას</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>უკან</span><span
style='color:black'> </span><span style='color:black'>ითხოვს</span><span
style='color:black'> </span><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაციის</span><span
style='color:black'> </span><span style='color:black'>მთლიან</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>ნაწილობრივ</span><span
style='color:black'> </span><span style='color:black'>თანხას</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.2<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
</span><span style='color:black'>ვალდებულია</span><span style='color:black'>, </span><span
style='color:black'>ჩარჯბექის</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნის</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'> </span><span
style='color:black'>კონსულტაცია</span><span style='color:black'> </span><span
style='color:black'>გაუწიოს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'>, </span><span
style='color:black'>დაიცვას</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>ინტერესები</span><span style='color:black'> </span><span
style='color:black'>საერთაშორისო</span><span style='color:black'> </span><span
style='color:black'>საბარათე</span><span style='color:black'> </span><span
style='color:black'>სქემებთან</span><span style='color:black'> </span><span
style='color:black'>ურთიერთობაში</span><span style='color:black'>, </span><span
style='color:black'>მიაწოდოს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'> </span><span
style='color:black'>ინფორმაცია</span><span style='color:black'> </span><span
style='color:black'>ჩარჯბექის</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნის</span><span style='color:black'> </span><span
style='color:black'>მიმდინარეობის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>აღმოუჩინოს</span><span style='color:black'> </span><span
style='color:black'>მას</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'>/</span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>გონივრული</span><span style='color:black'> </span><span
style='color:black'>დახმარება</span><span style='color:black'> </span><span
style='color:black'>რათა</span><span style='color:black'> </span><span
style='color:black'>ჩარჯბექის</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნა</span><span style='color:black'> </span><span
style='color:black'>დაკმაყოფილდეს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>სასარგებლოდ</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.3<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>ჩარჯბექის</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნის</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'>, KEEPZ.ME </span><span style='color:black'>უფლებამოსილია</span><span
style='color:black'> </span><span style='color:black'>ჩარჯბექის</span><span
style='color:black'> </span><span style='color:black'>პროცესის</span><span
style='color:black'> </span><span style='color:black'>დამთავრებამდე</span><span
style='color:black'>  </span><span style='color:black'>მომხმარებელს</span><span
style='color:black'>, </span><span style='color:black'>წინამდებარე</span><span
style='color:black'> </span><span style='color:black'>პირობების</span><span
style='color:black'> 6.2 </span><span style='color:black'>მუხლით</span><span
style='color:black'> </span><span style='color:black'>განსაზღვრული</span><span
style='color:black'>  </span><span style='color:black'>სარეზერვო</span><span
style='color:black'> </span><span style='color:black'>თანხიდან</span><span
style='color:black'>, </span><span style='color:black'>დაუბლოკოს</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ჩარჯბექის</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნის</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>სასარგებლოდ</span><span
style='color:black'> </span><span style='color:black'>დაკმაყოფილების</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'>, </span><span style='color:black'>უაქცეპტოდ</span><span
style='color:black'> </span><span style='color:black'>ჩამოაჭრას</span><span
style='color:black'> </span><span style='color:black'>შესაბამისი</span><span
style='color:black'> </span><span style='color:black'>თანხა</span><span
style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.4<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>იმ</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'>, </span><span style='color:black'>თუ</span><span
style='color:black'> </span><span style='color:black'>ზემოაღნიშნული</span><span
style='color:black'> </span><span style='color:black'>რეზერვი</span><span
style='color:black'> </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>იქნება</span><span
style='color:black'> </span><span style='color:black'>საკმარისი</span><span
style='color:black'> </span><span style='color:black'>ჩარჯბექის</span><span
style='color:black'> </span><span style='color:black'>თანხის</span><span
style='color:black'> </span><span style='color:black'>დასაფარად</span><span
style='color:black'>, KEEPZ.ME </span><span style='color:black'>უფლებამოსილი</span><span
style='color:black'> </span><span style='color:black'>იქნება</span><span
style='color:black'> </span><span style='color:black'>ჩარჯბექის</span><span
style='color:black'> </span><span style='color:black'>შესაბამისი</span><span
style='color:black'> </span><span style='color:black'>თანხის</span><span
style='color:black'> </span><span style='color:black'>დარჩენილი</span><span
style='color:black'> </span><span style='color:black'>ნაწილის</span><span
style='color:black'> </span><span style='color:black'>დაკავება</span><span
style='color:black'> </span><span style='color:black'>მოახდინოს</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>ანგარიშზე</span><span
style='color:black'> </span><span style='color:black'>არსებული</span><span
style='color:black'> </span><span style='color:black'>ხელმისაწვდომი</span><span
style='color:black'> </span><span style='color:black'>ბალანსიდან</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>შემდგომი</span><span
style='color:black'> </span><span style='color:black'>წარმატებით</span><span
style='color:black'> </span><span style='color:black'>შესრულებული</span><span
style='color:black'> </span><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაცი</span><span
style='color:black'>(</span><span style='color:black'>ებ</span><span
style='color:black'>)</span><span style='color:black'>იდან</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.5<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>თუ</span><span
style='color:black'> </span><span style='color:black'>ჩარჯბექის</span><span
style='color:black'> </span><span style='color:black'>ოდენობა</span><span
style='color:black'> </span><span style='color:black'>აღემატება</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>განთავსებულ</span><span
style='color:black'> </span><span style='color:black'>რეზერვსა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>კონკრეტული</span><span
style='color:black'> </span><span style='color:black'>მომენტისთვის</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>ანგარიშზე</span><span
style='color:black'> </span><span style='color:black'>არსებული</span><span
style='color:black'> </span><span style='color:black'>ხელმისაწვდომი</span><span
style='color:black'> </span><span style='color:black'>ბალანსის</span><span
style='color:black'> </span><span style='color:black'>თანხის</span><span
style='color:black'> </span><span style='color:black'>ოდენობების</span><span
style='color:black'> </span><span style='color:black'>ჯამს</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>ვალდებულია</span><span
style='color:black'> </span><span style='color:black'>უზრუნველყოს</span><span
style='color:black'> </span><span style='color:black'>სხვაობის</span><span
style='color:black'> </span><span style='color:black'>ანაზღაურება</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>სათვის</span><span
style='color:black'>, </span><span style='color:black'>ანგარისშწორების</span><span
style='color:black'> </span><span style='color:black'>ანგარიშზე</span><span
style='color:black'> </span><span style='color:black'>ჩარჯბექის</span><span
style='color:black'> </span><span style='color:black'>თანხის</span><span
style='color:black'> </span><span style='color:black'>დარჩენილი</span><span
style='color:black'> </span><span style='color:black'>ნაწილის</span><span
style='color:black'> </span><span style='color:black'>ჩარიცხვის</span><span
style='color:black'> </span><span style='color:black'>გზით</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.6<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>ჩარჯბექის</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნის</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'> (</span><span style='color:black'>აგრეთვე</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> </span><span style='color:black'>საეჭვო</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>უკანონო</span><span
style='color:black'> </span><span style='color:black'>გარიგებისას</span><span
style='color:black'>), KEEPZ.ME </span><span style='color:black'>უფლებამოსილია</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლისაგან</span><span
style='color:black'> </span><span style='color:black'>მოითხოვოს</span><span
style='color:black'> </span><span style='color:black'>ყველა</span><span
style='color:black'> </span><span style='color:black'>საჭირო</span><span
style='color:black'> </span><span style='color:black'>ინფორმაცია</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>დოკუმენტაცია</span><span
style='color:black'>, </span><span style='color:black'>ხოლო</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>ვალდებულია</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნისთანავე</span><span
style='color:black'> </span><span style='color:black'>გადასცეს</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>ჩარჯბექის</span><span
style='color:black'> </span><span style='color:black'>დოკუმენტები</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.7<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>იმ</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'>, </span><span style='color:black'>თუ</span><span
style='color:black'> </span><span style='color:black'>ერთი</span><span
style='color:black'> </span><span style='color:black'>კალენდარული</span><span
style='color:black'> </span><span style='color:black'>თვის</span><span
style='color:black'> </span><span style='color:black'>განმავლობაში</span><span
style='color:black'> </span><span style='color:black'>ჩარჯბექების</span><span
style='color:black'> </span><span style='color:black'>თანხა</span><span
style='color:black'> </span><span style='color:black'>მიაღწევს</span><span
style='color:black'> </span><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაციების</span><span
style='color:black'> </span><span style='color:black'>თანხის</span><span
style='color:black'> 2%-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>მკვეთრად</span><span
style='color:black'> </span><span style='color:black'>გაიზრდება</span><span
style='color:black'> </span><span style='color:black'>ტრანზაქციათა</span><span
style='color:black'> </span><span style='color:black'>რაოდენობა</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>თანხა</span><span
style='color:black'>, </span><span style='color:black'>მიზეზის</span><span
style='color:black'> </span><span style='color:black'>დადგენამდე</span><span
style='color:black'> (</span><span style='color:black'>შემოწმების</span><span
style='color:black'> </span><span style='color:black'>საფუძველზე</span><span
style='color:black'>) KEEPZ.ME </span><span style='color:black'>უფლებამოსილი</span><span
style='color:black'> </span><span style='color:black'>იქნება</span><span
style='color:black'> </span><span style='color:black'>დროებით</span><span
style='color:black'> </span><span style='color:black'>შეაჩეროს</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლისათვის</span><span
style='color:black'> </span><span style='color:black'>წინამდებარე</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულებით</span><span
style='color:black'> </span><span style='color:black'>განსაზღვრული</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>გაწევა</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>6.8<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>წინამდებარე</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულებაზე</span><span
style='color:black'> </span><span style='color:black'>თანხმობით</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>აცნობიერებს</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ეთანხმება</span><span
style='color:black'>, </span><span style='color:black'>რომ</span><span
style='color:black'> KEEPZ.ME </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>არის</span><span
style='color:black'> </span><span style='color:black'>პასუხისმგებელი</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>შესაბამისად</span><span
style='color:black'>, </span><span style='color:black'>არც</span><span
style='color:black'> </span><span style='color:black'>ერთ</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'> </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>არის</span><span
style='color:black'> </span><span style='color:black'>ვალდებული</span><span
style='color:black'> </span><span style='color:black'>დაუბრუნოს</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელს</span><span
style='color:black'> </span><span style='color:black'>ჩარჯბექის</span><span
style='color:black'> </span><span style='color:black'>ოპერაციის</span><span
style='color:black'> </span><span style='color:black'>თანხა</span><span
style='color:black'>. </span><span style='color:black'>ამასთან</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებელს</span><span
style='color:black'> </span><span style='color:black'>ეკისრება</span><span
style='color:black'> </span><span style='color:black'>პასუხისმგებლობა</span><span
style='color:black'> </span><span style='color:black'>ჩარჯბექთან</span><span
style='color:black'> </span><span style='color:black'>დაკავშირებულ</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერ</span><span
style='color:black'> </span><span style='color:black'>ხარჯზე</span><span
style='color:black'> (</span><span style='color:black'>მათ</span><span
style='color:black'> </span><span style='color:black'>შორის</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>არა</span><span
style='color:black'> </span><span style='color:black'>მხოლოდ</span><span
style='color:black'>,  </span><span style='color:black'>გასაჩივრების</span><span
style='color:black'> </span><span style='color:black'>ბაჟის</span><span
style='color:black'>, </span><span style='color:black'>საერთაშორისო</span><span
style='color:black'> </span><span style='color:black'>საგადამხდელო</span><span
style='color:black'> </span><span style='color:black'>სისტემის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>ინციდენტურ</span><span
style='color:black'> </span><span style='color:black'>ციკლებში</span><span
style='color:black'> </span><span style='color:black'>ოპერაციების</span><span
style='color:black'> </span><span style='color:black'>გარემოებათა</span><span
style='color:black'> </span><span style='color:black'>დაზუსტების</span><span
style='color:black'> </span><span style='color:black'>შედეგად</span><span
style='color:black'> </span><span style='color:black'>გადასახდელი</span><span
style='color:black'> </span><span style='color:black'>თანხების</span><span
style='color:black'>, </span><span style='color:black'>ასევე</span><span
style='color:black'> </span><span style='color:black'>სხვა</span><span
style='color:black'> </span><span style='color:black'>ზარალისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ხარჯის</span><span
style='color:black'> </span><span style='color:black'>რომელიც</span><span
style='color:black'> </span><span style='color:black'>მიყენებულ</span><span
style='color:black'> </span><span style='color:black'>იქნა</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>სთვის</span><span
style='color:black'> </span><span style='color:black'>ინციდენტური</span><span
style='color:black'> </span><span style='color:black'>ციკლების</span><span
style='color:black'> </span><span style='color:black'>ჩატარებასთან</span><span
style='color:black'> </span><span style='color:black'>დაკავშირებით</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>საერთაშორისო</span><span
style='color:black'> </span><span style='color:black'>საგადამხდელო</span><span
style='color:black'> </span><span style='color:black'>სისტემის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>სთვის</span><span
style='color:black'> </span><span style='color:black'>დაქვითულ</span><span
style='color:black'> </span><span style='color:black'>ჩარჯბექის</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>სხვა</span><span
style='color:black'> </span><span style='color:black'>თანხების</span><span
style='color:black'>, </span><span style='color:black'>ჯარიმებისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>ის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>გაწეულ</span><span
style='color:black'> </span><span style='color:black'>სხვა</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> </span><span style='color:black'>ხარჯებისათვის</span><span
style='color:black'>).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>რეფანდის</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>ოპერაცია</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.1<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>რეფანდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაცია</span><span
style='color:black'> </span><span style='color:black'>გულისხმობს</span><span
style='color:black'> </span><span style='color:black'>გაყიდული</span><span
style='color:black'> </span><span style='color:black'>პროდუქტის</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>უკან</span><span
style='color:black'> </span><span style='color:black'>დაბრუნების</span><span
style='color:black'> </span><span style='color:black'>გამო</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>მისი</span><span
style='color:black'> </span><span style='color:black'>კლიენტისათვის</span><span
style='color:black'> </span><span style='color:black'>გადასახდელის</span><span
style='color:black'> </span><span style='color:black'>სრულად</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>ნაწილობრივ</span><span
style='color:black'> </span><span style='color:black'>დაბრუნების</span><span
style='color:black'> </span><span style='color:black'>ოპერაციას</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.2<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
</span><span style='color:black'>უფლებამოსილია</span><span style='color:black'>
</span><span style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>განახორციელოს</span><span style='color:black'> </span><span
style='color:black'>რეფანდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაცია</span><span style='color:black'> </span><span
style='color:black'>ტრანზაქციის</span><span style='color:black'> </span><span
style='color:black'>შესრულებიდან</span><span style='color:black'> 14 (</span><span
style='color:black'>თოთხმეტი</span><span style='color:black'>) </span><span
style='color:black'>სამუშაო</span><span style='color:black'> </span><span
style='color:black'>დღის</span><span style='color:black'> </span><span
style='color:black'>შემდეგ</span><span style='color:black'>. </span><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>ვადა</span><span style='color:black'> </span><span
style='color:black'>შესაძლებელია</span><span style='color:black'> </span><span
style='color:black'>მოდიფიცირდეს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>ბიზნეს</span><span style='color:black'> </span><span
style='color:black'>საქმიანობისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მიწოდების</span><span style='color:black'> </span><span
style='color:black'>პოლიტიკის</span><span style='color:black'> </span><span
style='color:black'>გათვალიწინებით</span><span style='color:black'>, </span><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>ორმხრივი</span><span style='color:black'> </span><span
style='color:black'>შეთანხმების</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.3<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>KEEPZ.ME
</span><span style='color:black'>რეფანდის</span><span style='color:black'> </span><span
style='color:black'>ოპერაციას</span><span style='color:black'> </span><span
style='color:black'>განახორციელებს</span><span style='color:black'> </span><span
style='color:black'>მხოლოდ</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>, </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>კონკრეტული</span><span style='color:black'> </span><span
style='color:black'>მომენტისათვის</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'> </span><span
style='color:black'>ექნება</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>ოდენობის</span><span style='color:black'> </span><span
style='color:black'>ხელმისაწვდომი</span><span style='color:black'> </span><span
style='color:black'>ბალანსი</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>ანგარიშზე</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>7.4<span style='font:7.0pt "Times New Roman"'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>რეფანდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაციის</span><span
style='color:black'> </span><span style='color:black'>განხორციელების</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>თვის</span><span
style='color:black'> </span><span style='color:black'>გადახდილი</span><span
style='color:black'> </span><span style='color:black'>საკომისიო</span><span
style='color:black'> </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>ექვემდებარება</span><span
style='color:black'> </span><span style='color:black'>დაბრუნებას</span><span
style='color:black'>, </span><span style='color:black'>ამასთან</span><span
style='color:black'>, </span><span style='color:black'>მომხმარებელს</span><span
style='color:black'> </span><span style='color:black'>ეკისრება</span><span
style='color:black'> </span><span style='color:black'>პასუხისმგებლობა</span><span
style='color:black'> </span><span style='color:black'>რეფანდის</span><span
style='color:black'> </span><span style='color:black'>ოპერაციასთან</span><span
style='color:black'> </span><span style='color:black'>დაკავშირებულ</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერ</span><span
style='color:black'> </span><span style='color:black'>ხარჯზე</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>მომხმარებლის</span></b><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'> </span></b><b><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>ვალდებულბები</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-22.5pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>მომხმარებელი</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>ვალდებულია</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>გადახდების</span><span style='color:black'> </span><span
style='color:black'>მისაღებად</span><span style='color:black'> </span></span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>KEEPZ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span style='color:black'>.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>ჩართულობით</span><span style='color:black'> </span><span
style='color:black'>უზრუნველყოს</span><span style='color:black'> </span><span
style='color:black'>ინტერნეტ</span><span style='color:black'> </span><span
style='color:black'>პორტალის</span><span style='color:black'> </span><span
style='color:black'>ინტეგრაცია</span><span style='color:black'> </span><span
style='color:black'>ავტორიზაციის</span><span style='color:black'> </span><span
style='color:black'>გვერდთან</span><span style='color:black'>.</span></span></p>

<p class=MsoListParagraph style='margin-left:14.2pt;text-align:justify;
text-indent:-.5in'><span lang="GEO/KAT" style='color:black'>8.1.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>განახორციელოს</span><span lang="GEO/KAT" style='color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>საქმიანობა</span><span
lang="GEO/KAT" style='color:black'>, </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>მართლზომიერ</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>მფლობელობაში</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>არსებული</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>ინტერნეტ</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>პორტალის</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>მეშვეობით</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>და</span><span lang="GEO/KAT"
style='color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>უზრუნველყოს</span><span lang="GEO/KAT" style='color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ინტერნეტ</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>პორტალზე</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>მითითებული</span><span
lang="GEO/KAT" style='color:black'>/</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>გაცხადებული</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>პროდუქტის</span><span
lang="GEO/KAT" style='color:black'>/</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>მომსახურების</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>რეალიზება</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>წინამდებარე</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>ხელშეკრულების</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>და</span><span lang="GEO/KAT"
style='color:black'>  </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>კანონმდებლობის</span><span lang="GEO/KAT" style='color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>სრული</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>მოთხოვნების</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>დაცვით</span><span
lang="GEO/KAT" style='color:black'>. </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>ასევე</span><span lang="GEO/KAT"
style='color:black'>, </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>იქონიოს</span><span lang="GEO/KAT" style='color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>და</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>საჭიროების</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>შემთხვევაში</span><span
lang="GEO/KAT" style='color:black'>, </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>წარადგინოს</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>ყველა</span><span lang="GEO/KAT"
style='color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>საჭირო</span><span lang="GEO/KAT" style='color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>დოკუმენტი</span><span
lang="GEO/KAT" style='color:black'>, </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>რომელიც</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>დაადასტურებს</span><span
lang="GEO/KAT" style='color:black'>, </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>რომ</span><span lang="GEO/KAT"
style='color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>ინტერნეტ</span><span lang="GEO/KAT" style='color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>პორტალზე</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>რეალურად</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>მხოლოდ</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>იმ</span><span lang="GEO/KAT"
style='color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>პროდუქტის</span><span lang="GEO/KAT" style='color:black'>/</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>მომსახურების</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>რეალიზება</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>ხდება</span><span lang="GEO/KAT"
style='color:black'>, </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>რომელიც</span><span lang="GEO/KAT" style='color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>გაცხადებული</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>აქვს</span><span lang="GEO/KAT"
style='color:black'>  KEEPZ.ME-</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>სთან</span><span lang="GEO/KAT" style='color:black'>.
</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black;background:white'>ნებისმიერი</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>ინფორმაცია</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>რომელსაც</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>წინამდებარე</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>ხელშეკრულების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ფარგლებში</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მიაწოდებს</span><span style='color:black;
background:white'> KEEPZ.ME-</span><span style='color:black;background:white'>ს</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>იყოს</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ზუსტი</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>და</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>განახლებული</span><span style='color:black;background:white'>,
</span><span style='color:black;background:white'>ხოლო</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ცვლილების</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ან</span><span style='color:black;
background:white'>/</span><span style='color:black;background:white'>და</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>დამატებების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>შემთხვევაში</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>უზრუნველყოს</span><span style='color:black;background:white'>
KEEPZ.ME-</span><span style='color:black;background:white'>ის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>წინასწარ</span><span style='color:black;background:white'>, </span><span
style='color:black;background:white'>ხოლო</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>შეუძლებლობის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>შემთხვევაში</span><span style='color:black;background:white'>,
</span><span style='color:black;background:white'>ცვლილების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>შემდეგ</span><span style='color:black;background:white'>, </span><span
style='color:black;background:white'>პირველივე</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>შესაძლებლობისთანავე</span><span
style='color:black;background:white'>/</span><span style='color:black;
background:white'>დაუყონებლივი</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>ინფორმირება</span><span
style='color:black;background:white'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'>ინტერნეტ</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>პორტალზე</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>განათავსოს</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>: </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'>საკუთარი</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'> (</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>მათ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>შორის</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'>, </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>ყოველი</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>სავაჭრო</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>პლატფორმის</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'>მონაწილის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>,
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>ასეთის</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>არსებობის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>შემთხვევაში</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>) </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'>საიდენტიფიკაციო</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>და</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>საკონტაქტო</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>ინფორმაცია</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>. </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'>უფრო</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>კონკრეტულად</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>, </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'>სახელწოდება</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
(</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>საფირმო</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'>)/</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>სახელი</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'>, </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>გვარი</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>,
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>საიდენტიფიკაციო</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>/</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'>პირადი</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>ნომერი</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'>, </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>იურიდიული</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>და</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>საკორესპონდენციო</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
(</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>ფაქტობრივი</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>) </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'>მისამართი</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>,
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>ტელეფონის</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>ნომერი</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'>, </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>ელფოსტა</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black;background:white'>ინტერნეტ</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>პორტალის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მოხმარების</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>წესები</span><span style='color:black;
background:white'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black;background:white'>პროდუქციის</span><span style='color:black;
background:white'>/</span><span style='color:black;background:white'>მომსახურების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>დეტალური</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>აღწერა</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>და</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ფასი</span><span style='color:black;background:white'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black;background:white'>პროდუქციის</span><span style='color:black;
background:white'>/</span><span style='color:black;background:white'>მომსახურების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მიწოდების</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>პირობები</span><span style='color:black;
background:white'>, </span><span style='color:black;background:white'>მათ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>შორის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ინფორმაცია</span><span style='color:black;
background:white'>, </span><span style='color:black;background:white'>პროდუქციის</span><span
style='color:black;background:white'>/</span><span style='color:black;
background:white'>მომსახურების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>მიწოდების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ვადის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>და</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>მიწოდების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>საფასურის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>შესახებ</span><span style='color:black;
background:white'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black;background:white'>კლიენტის</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>მიერ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>გადასახდელის</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>გადახდის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>პროცედურა</span><span style='color:black;background:white'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black;background:white'>კლიენტისათვის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>თანხის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>უკან</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>დაბრუნების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>პირობები</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>და</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>პროცედურა</span><span
style='color:black;background:white'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black;background:white'>კლიენტისა</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>და</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>საბარათე</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ოპერაციასთან</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>დაკავშირებული</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ინფორმაციის</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>კონფიდენციალურობის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>დაცვის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>პოლიტიკა</span><span style='color:black;
background:white'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.6.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black;background:white'>განცხადება</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>კლიენტის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მიერ</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>კანონმდებლობის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>და</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>საგადახდო</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>სისტემების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მოთხოვნების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>დაცვის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ვალდებულებასთან</span><span style='color:black;background:
white'> </span><span style='color:black;background:white'>დაკავშირებით</span><span
style='color:black;background:white'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black;background:white'>იმ</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>შემთხვევაში</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>თუ</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მომხმარებელი</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>წარმოადგენს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>სავაჭრო</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>პლატფორმის</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>ორგანიზატორს</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>ყველა</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>სავაჭრო</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>პლატფორმის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მონაწილესთან</span><span style='color:black;background:white'>/</span><span
style='color:black;background:white'>სავაჭრო</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>მომსახურების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ობიექტთან</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>გაფორმებული</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>ჰქონდეს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ხელშეკრულება</span><span style='color:black;background:white'>;
</span><span style='color:black;background:white'>უზრუნველყოს</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>რომ</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>თითოეული</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>სავაჭრო</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>პლატფორმის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მონაწილე</span><span style='color:black;
background:white'>/</span><span style='color:black;background:white'>სავაჭრო</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მომსახურების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>ობიექტი</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ახორციელებდეს</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>შესაბამის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ხელშეკრულებაში</span><span style='color:black;background:
white'> </span><span style='color:black;background:white'>გაცხადებულ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>საქმიანობას</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>და</span><span
style='color:black;background:white'> KEEPZ.ME-</span><span style='color:black;
background:white'>ის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მოთხოვნის</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>შემთხვევაში</span><span
style='color:black;background:white'>, </span><span style='color:black;
background:white'>უზრუნველყოს</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>შესაბამისი</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>დამადასტურებელი</span><span style='color:black;background:
white'> </span><span style='color:black;background:white'>მტკიცებულებების</span><span
style='color:black;background:white'>/</span><span style='color:black;
background:white'>დოკუმენტაციის</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>წარმოდგენა</span><span
style='color:black;background:white'>. </span><span style='color:black;
background:white'>გარდა</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ამისა</span><span style='color:black;
background:white'>, </span><span style='color:black;background:white'>მომხმარებელი</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ვალდებულია</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>გაატაროს</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>ყველა</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>საჭირო</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ღონისძიება</span><span style='color:black;
background:white'>, </span><span style='color:black;background:white'>რომელიც</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>შეუძლებელს</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>გახდის</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>სავაჭრო</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>პლატფორმის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მონაწილეებთან</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ხელშეკრულების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>გაფორმებამდე</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ინტერნეტ</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>პორტალით</span><span style='color:black;
background:white'> </span><span style='color:black'>პროდუქტის</span><span
style='color:black;background:white'>/</span><span style='color:black;
background:white'>მომსახურების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>რეალიზებას</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>და</span><span style='color:black;background:white'>
KEEPZ.ME-</span><span style='color:black;background:white'>ს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მოთხოვნისთანავე</span><span style='color:black;background:
white'>, </span><span style='color:black;background:white'>დაუყოვნებლივ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>აუკრძალოს</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>სავაჭრო</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>პლატფორმის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მონაწილე</span><span style='color:black;background:white'>(</span><span
style='color:black;background:white'>ებ</span><span style='color:black;
background:white'>)</span><span style='color:black;background:white'>ს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>საკუთარი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>სავაჭრო</span><span style='color:black;
background:white'>/</span><span style='color:black;background:white'>მომსახურების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ობიექტის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ინტერნეტ</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>პორტალის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მეშვეობით</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>ვაჭრობა</span><span style='color:black;
background:white'>, </span><span style='color:black;background:white'>იმ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>შემთხვევაში</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>თუ</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მომხმარებელსა</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>და</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>აღნიშნული</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>სავაჭრო</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>პლატფორმის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მონაწილე</span><span style='color:black;background:white'>(</span><span
style='color:black;background:white'>ებ</span><span style='color:black;
background:white'>)</span><span style='color:black;background:white'>ს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>შორის</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>დადებული</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>შესაბამისი</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ხელშეკრულების</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>მოქმედება</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ნებისმიერი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მიზეზით</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>შეწყდება</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>ან</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>შეჩერდება</span><span style='color:black;
background:white'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'>ნებისმიერი</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>დავის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>თავიდან</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>აცილების</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>მიზნით</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'>, </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>ტრანზაქციის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>თარიღიდან</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>მინიმუმ</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'> 180 (</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>ას</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>ოთხმოცი</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'>) </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>კალენდარული</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>დღის</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>ვადაში</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'>, </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>თუ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>სერთაშორისო</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'>საგადახდო</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>სისტემის</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>რეგულაციით</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>სხვა</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>ვადა</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>არ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>არის</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>განსაზღვრული</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>,
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>შეეძლოს</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>მის</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>ინტერნეტ</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>პორტალზე</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>შესრულებული</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>ყიდვის</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>ოპერაციის</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>შესახებ</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'> </span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>შემდეგი</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black;background:white'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>დეტალების</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;background:white'>
</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black;
background:white'>მიწოდება</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black;background:white'>:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>კლიენტის სახელი და გვარი;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>კლიენტის პირადი ნომერი;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>კლიენტის მისამართი;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>კლიენტის ტელეფონის ნომერი;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>შეკვეთის მიწოდების ადგილი, თარიღი, დრო;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>შეკვეთის მიღებასთან და მიწოდებასთან დაკავშირებული დოკუმენტაცია/თვალსაჩინოება,
კლიენტთან განხორციელებული სრული მიმოწერა შეკვეთასთან დაკავშირებით;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>პროდუქტის/სერვისის აღწერა; </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.8.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>კლიენტის IP მისამართი;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black;background:white'>ინტერნეტ</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>პორტალზე</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>პროდუქციის</span><span style='color:black;background:white'>/</span><span
style='color:black;background:white'>მომსახურების</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>რეალიზაცია</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>განახორციელოს</span><span style='color:black;background:white'>
KEEPZ.ME-</span><span style='color:black;background:white'>ის</span><span
style='color:black;background:white'>  </span><span style='color:black;
background:white'>მიერ</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>პერიოდულად</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>განსაზღვრული</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>პირობებითა</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>და</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>საგადახდო</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>სისტემების</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>მოთხოვნათა</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>დაცვით</span><span
style='color:black;background:white'>, KEEPZ.ME-</span><span style='color:black;
background:white'>გან</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>შესაბამისი</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>მოთხოვნის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მიღების</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>შემთხვევაში</span><span style='color:black;
background:white'>, </span><span style='color:black;background:white'>უზრუნველყოს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>დამატებითი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>უსაფრთხოების</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>ღონისძიებების</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>გატარება</span><span style='color:black;background:white'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black;background:white'>ინტერნეტ</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>პორტალის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>მეშვეობით</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>გაყიდოს</span><span style='color:black;
background:white'> </span><span style='color:black;background:white'>მხოლოდ</span><span
style='color:black;background:white'> KEEPZ.ME-</span><span style='color:black;
background:white'>თან</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>შეთანხმებული</span><span style='color:
black;background:white'> </span><span style='color:black;background:white'>ბიზნეს</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>საქმიანობის</span><span style='color:black;background:white'>
</span><span style='color:black;background:white'>კატეგორიის</span><span
style='color:black;background:white'> </span><span style='color:black;
background:white'>შესაბამისი</span><span style='color:black;background:white'> </span><span
style='color:black;background:white'>პროდუქცია</span><span style='color:black;
background:white'>/</span><span style='color:black;background:white'>მომსახურება</span><span
style='color:black;background:white'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.11<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მოითხოვოს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>წინასწარი</span><span style='color:black'> </span><span
style='color:black'>წერილობითი</span><span style='color:black'> </span><span
style='color:black'>თანხმობა</span><span style='color:black'> </span><span
style='color:black'>რეგისტრირებულ</span><span style='color:black'> </span><span
style='color:black'>კომერციულ</span><span style='color:black'> </span><span
style='color:black'>საქმიანობაში</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ცვლილების</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>დამატების</span><span style='color:black'> </span><span
style='color:black'>განსახორციელებლად</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.12<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>რეგისტრაციის</span><span style='color:black'> </span><span
style='color:black'>დროს</span><span style='color:black'> </span><span
style='color:black'>წარმოადგინოს</span><span style='color:black'> </span><span
style='color:black'>ზუსტი</span><span style='color:black'>, </span><span
style='color:black'>მიმდინარე</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>სრული</span><span style='color:black'> </span><span
style='color:black'>ინფორმაცია</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>უზრუნველყოს</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>რეგულარული</span><span style='color:black'> </span><span
style='color:black'>განახლება</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.13<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>შეინარჩუნოს</span><span style='color:black'> </span><span
style='color:black'>კონფიდენციალურობა</span><span style='color:black'>, </span><span
style='color:black'>ხელმისაწვდომი</span><span style='color:black'> </span><span
style='color:black'>მონაცემების</span><span style='color:black'> </span><span
style='color:black'>უსაფრთხოდ</span><span style='color:black'> </span><span
style='color:black'>დაცვა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>გაუზიაროს</span><span style='color:black'> </span><span
style='color:black'>თავისი</span><span style='color:black'> </span><span
style='color:black'>მონაცემები</span><span style='color:black'> </span><span
style='color:black'>მესამე</span><span style='color:black'> </span><span
style='color:black'>პირებს</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.14<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>განახორციელოს</span><span style='color:black'> </span><span
style='color:black'>პორნოგრაფიული</span><span style='color:black'> </span><span
style='color:black'>მასალების</span><span style='color:black'> </span><span
style='color:black'>გავრცელება</span><span style='color:black'>, </span><span
style='color:black'>აზარტული</span><span style='color:black'> </span><span
style='color:black'>თამაშების</span><span style='color:black'> </span><span
style='color:black'>მოწყობა</span><span style='color:black'>, </span><span
style='color:black'>ფარმაცია</span><span style='color:black'> (</span><span
style='color:black'>გარდა</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევისა</span><span style='color:black'>, </span><span
style='color:black'>როდესაც</span><span style='color:black'> </span><span
style='color:black'>აღნიშნულზე</span><span style='color:black'> </span><span
style='color:black'>არსებობს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'> </span><span
style='color:black'>წინასწარი</span><span style='color:black'> </span><span
style='color:black'>წერილობითი</span><span style='color:black'> </span><span
style='color:black'>თანხმობა</span><span style='color:black'>), </span><span
style='color:black'>იარაღით</span><span style='color:black'>, </span><span
style='color:black'>ნარკოტიკული</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ფსიქოტროპული</span><span style='color:black'> </span><span
style='color:black'>საშუალებებით</span><span style='color:black'> </span><span
style='color:black'>ვაჭრობა</span><span style='color:black'>, </span><span
style='color:black'>თამბაქოს</span><span style='color:black'> </span><span
style='color:black'>პროდუქციის</span><span style='color:black'> </span><span
style='color:black'>რეალიზება</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>აკრძალული</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ქმედება</span><span style='color:black'>/</span><span
style='color:black'>საქმიანობა</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black;background:white'>8.1.15<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>დაუყოვნებლივ</span><span style='color:black'> </span><span
style='color:black'>უზრუნველყოს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>თვის</span><span style='color:black'> </span><span
style='color:black'>თანხის</span><span style='color:black'> </span><span
style='color:black'>დაბრუნება</span><span style='color:black'> </span><span
style='color:black'>თუ</span><span style='color:black'>:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.15.1</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><span style='color:black'>შესაბამისი</span><span
style='color:black'> </span><span style='color:black'>თანხების</span><span
style='color:black'> </span><span style='color:black'>ჩარიცხვა</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>საბანკო</span><span
style='color:black'> </span><span style='color:black'>ანგარიშზე</span><span
style='color:black'> </span><span style='color:black'>მოხდა</span><span
style='color:black'> </span><span style='color:black'>შეცდომით</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>არასწორად</span><span
style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.15.2</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაცია</span><span
style='color:black'> </span><span style='color:black'>შესრულებულია</span><span
style='color:black'> </span><span style='color:black'>ბარათით</span><span
style='color:black'> </span><span style='color:black'>სარგებლობის</span><span
style='color:black'> </span><span style='color:black'>პირობების</span><span
style='color:black'>, </span><span style='color:black'>კანონმდებლობის</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>სისტემების</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნების</span><span
style='color:black'> </span><span style='color:black'>დარღვევით</span><span
style='color:black'>, </span><span style='color:black'>რაც</span><span
style='color:black'> </span><span style='color:black'>მოიცავს</span><span
style='color:black'> </span><span style='color:black'>არაუფლებამოსილი</span><span
style='color:black'> </span><span style='color:black'>პირის</span><span
style='color:black'> </span><span style='color:black'>მიერ</span><span
style='color:black'> </span><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაციებს</span><span
style='color:black'>, </span><span style='color:black'>ვადაგასული</span><span
style='color:black'> </span><span style='color:black'>ბარათით</span><span
style='color:black'> </span><span style='color:black'>განხორციელებულ</span><span
style='color:black'> </span><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაციებს</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ისეთ</span><span
style='color:black'> </span><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაციებს</span><span
style='color:black'>, </span><span style='color:black'>რომელიც</span><span
style='color:black'> </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>გამოხატავს</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლის</span><span
style='color:black'> </span><span style='color:black'>ნებას</span><span
style='color:black'>;</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.15.3</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'><span style='color:black'>გამოვლინდება</span><span
style='color:black'>, </span><span style='color:black'>რომ</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>არღვევს</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულებით</span><span
style='color:black'> </span><span style='color:black'>გათვალისწინებულ</span><span
style='color:black'> </span><span style='color:black'>ვალდებულებებს</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.16<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>დაუყოვნებლივ</span><span style='color:black'> </span><span
style='color:black'>შეატყობინოს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>შესრულებისთვის</span><span style='color:black'> </span><span
style='color:black'>გამოყენებული</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>ტექნიკური</span><span style='color:black'> </span><span
style='color:black'>საშუალების</span><span style='color:black'> </span><span
style='color:black'>გაუმართავობის</span><span style='color:black'>/</span><span
style='color:black'>ხარვეზის</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>აღმოუჩინოს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>აუცილებელი</span><span style='color:black'> </span><span
style='color:black'>მხარდაჭერა</span><span style='color:black'> </span><span
style='color:black'>გაუმართავობის</span><span style='color:black'>/</span><span
style='color:black'>ხარვეზის</span><span style='color:black'> </span><span
style='color:black'>აღმოსაფხვრელად</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.17<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>უზრუნველყოს</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>დაცვა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>სისტემების</span><span style='color:black'> </span><span
style='color:black'>წესებთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>მისთვის</span><span style='color:black'> </span><span
style='color:black'>პერიოდულად</span><span style='color:black'> </span><span
style='color:black'>განსაზღვრული</span><span style='color:black'> </span><span
style='color:black'>ღონისძიებების</span><span style='color:black'> </span><span
style='color:black'>გატარება</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.18<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>პროდუქციის</span><span style='color:black'> </span><span
style='color:black'>მიწოდებისას</span><span style='color:black'> </span><span
style='color:black'>მოახდინოს</span><span style='color:black'> </span><span
style='color:black'>კლიენტის</span><span style='color:black'> </span><span
style='color:black'>იდენტიფიცირება</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>პროდუქციის</span><span style='color:black'>/</span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>სპეციფიკის</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებით</span><span style='color:black'>, </span><span
style='color:black'>გააფორმოს</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>მიღება</span><span style='color:black'>-</span><span
style='color:black'>ჩაბარების</span><span style='color:black'> </span><span
style='color:black'>აქტი</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.19<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>მოსთხოვოს</span><span style='color:black'> </span><span
style='color:black'>კლიენტს</span><span style='color:black'> </span><span
style='color:black'>ინფორმაცია</span><span style='color:black'> </span><span
style='color:black'>ბარათის</span><span style='color:black'> </span><span
style='color:black'>მონაცემების</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>შეაგროვოს</span><span style='color:black'>, </span><span
style='color:black'>შეინახოს</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>დაამუშავოს</span><span style='color:black'> </span><span
style='color:black'>საბარათე</span><span style='color:black'> </span><span
style='color:black'>მონაცემები</span><span style='color:black'>, </span><span
style='color:black'>რაც</span><span style='color:black'> </span><span
style='color:black'>შეზღუდვის</span><span style='color:black'> </span><span
style='color:black'>გარეშე</span><span style='color:black'> </span><span
style='color:black'>მოიცავს</span><span style='color:black'> </span><span
style='color:black'>საბანკო</span><span style='color:black'> </span><span
style='color:black'>ბარათის</span><span style='color:black'> </span><span
style='color:black'>ნომერს</span><span style='color:black'>, CVV2/CVC2, </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ბარათის</span><span style='color:black'> </span><span
style='color:black'>მოქმედების</span><span style='color:black'> </span><span
style='color:black'>ვადას</span><span style='color:black'>, </span><span
style='color:black'>წინააღმდეგ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'> </span><span
style='color:black'>უზრუნველყოს</span><span style='color:black'> PCI DSS-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> PA DSS </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> Pin security-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნებთან</span><span style='color:black'> </span><span
style='color:black'>სრული</span><span style='color:black'> </span><span
style='color:black'>შესაბამისობა</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.20<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME-</span><span style='color:black'>გან</span><span
style='color:black'> </span><span style='color:black'>შესაბამისი</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნის</span><span
style='color:black'> </span><span style='color:black'>მიღებიდან</span><span
style='color:black'> 2(</span><span style='color:black'>ორი</span><span
style='color:black'>) </span><span style='color:black'>სამუშაო</span><span
style='color:black'> </span><span style='color:black'>დღის</span><span
style='color:black'> </span><span style='color:black'>ვადაში</span><span
style='color:black'>, </span><span style='color:black'>მიაწოდოს</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნილი</span><span
style='color:black'> </span><span style='color:black'>დოკუმენტაცია</span><span
style='color:black'>/</span><span style='color:black'>ინფორმაცია</span><span
style='color:black'>, </span><span style='color:black'>იმ</span><span
style='color:black'> </span><span style='color:black'>საბარათე</span><span
style='color:black'> </span><span style='color:black'>ოპერაციასთან</span><span
style='color:black'> </span><span style='color:black'>დაკავშირებით</span><span
style='color:black'>, </span><span style='color:black'>რომელიც</span><span
style='color:black'> </span><span style='color:black'>საერთაშორისო</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>ლოკალური</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>სისტემების</span><span
style='color:black'> </span><span style='color:black'>წესებისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>კანონმდებლობის</span><span
style='color:black'> </span><span style='color:black'>მიხედვით</span><span
style='color:black'> </span><span style='color:black'>შეიძლება</span><span
style='color:black'> </span><span style='color:black'>გახდეს</span><span
style='color:black'>/</span><span style='color:black'>გახდა</span><span
style='color:black'> </span><span style='color:black'>დავის</span><span
style='color:black'> </span><span style='color:black'>საგანი</span><span
style='color:black'>, </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერი</span><span
style='color:black'> </span><span style='color:black'>დოკუმენტაცია</span><span
style='color:black'>/</span><span style='color:black'>ინფორმაცია</span><span
style='color:black'>, </span><span style='color:black'>რომლის</span><span
style='color:black'> </span><span style='color:black'>მიღებაც</span><span
style='color:black'> </span><span style='color:black'>სავალდებულოა</span><span
style='color:black'> KEEPZ.ME-</span><span style='color:black'>თვის</span><span
style='color:black'> </span><span style='color:black'>კანონმდებლობის</span><span
style='color:black'> </span><span style='color:black'>შესაბამისად</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.21<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მიაწოდოს</span><span style='color:black'> </span><span
style='color:black'>კლიენტთან</span><span style='color:black'>, </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>მომსახურებასთან</span><span style='color:black'> (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>საბარათე</span><span style='color:black'> </span><span
style='color:black'>ოპერაციასთან</span><span style='color:black'>), </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებასთან</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებულ</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>სახის</span><span style='color:black'> </span><span
style='color:black'>ინფორმაცია</span><span style='color:black'>/</span><span
style='color:black'>დოკუმენტაცია</span><span style='color:black'>  KEEPZ.ME-</span><span
style='color:black'>ს</span><span style='color:black'>, </span><span
style='color:black'>ზეპირი</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>წერილობითი</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნის</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.22<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>საბარათე</span><span style='color:black'> </span><span
style='color:black'>ოპერაციის</span><span style='color:black'> </span><span
style='color:black'>განხორციელების</span><span style='color:black'> </span><span
style='color:black'>პროცესში</span><span style='color:black'> </span><span
style='color:black'>დაიცვას</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>უსაფრთხოების</span><span style='color:black'> </span><span
style='color:black'>ზომა</span><span style='color:black'>, </span><span
style='color:black'>რომელსაც</span><span style='color:black'> KEEPZ.ME  </span><span
style='color:black'>განუსაზღვრავს</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.23<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ვებ</span><span style='color:black'>-</span><span
style='color:black'>გვერდზე</span><span style='color:black'>/</span><span
style='color:black'>ინტერნეტ</span><span style='color:black'>-</span><span
style='color:black'>პორტალზე</span><span style='color:black'> </span><span
style='color:black'>არსებითი</span><span style='color:black'> </span><span
style='color:black'>ცვლილებების</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>, </span><span
style='color:black'>რაც</span><span style='color:black'> </span><span
style='color:black'>შეზღუდვის</span><span style='color:black'> </span><span
style='color:black'>გარეშე</span><span style='color:black'> </span><span
style='color:black'>გულისხმობს</span><span style='color:black'> </span><span
style='color:black'>პროდუქტის</span><span style='color:black'>/</span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>ტიპის</span><span style='color:black'> </span><span
style='color:black'>ცვლილებას</span><span style='color:black'>, </span><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>წინასწარ</span><span style='color:black'> </span><span
style='color:black'>წერილობით</span><span style='color:black'>, (</span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>ელ</span><span style='color:black'>.</span><span
style='color:black'>ფოსტით</span><span style='color:black'>) </span><span
style='color:black'>შეათანხმოს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>თან</span><span style='color:black'>, </span><span
style='color:black'>რის</span><span style='color:black'> </span><span
style='color:black'>საფუძველზეც</span><span style='color:black'> </span><span
style='color:black'>განხორციელდება</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>დანართის</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>რეგისტრაციისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'>  </span><span
style='color:black'>განაცხადის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>ცვლილება</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.24<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>გამოიყენოს</span><span style='color:black'> </span><span
style='color:black'>მომსახურება</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>პროდუქტის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'>/</span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>მომსახურებისთვის</span><span style='color:black'> </span><span
style='color:black'>გადახდებისთვის</span><span style='color:black'>, </span><span
style='color:black'>რომლებიც</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>უკანონო</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'>/</span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ონლაინ</span><span style='color:black'> </span><span
style='color:black'>გაყიდვა</span><span style='color:black'> </span><span
style='color:black'>აკრძალულია</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>შეზღუდულია</span><span style='color:black'> </span><span
style='color:black'>საქართველოს</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'>/</span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებაში</span><span style='color:black'> </span><span
style='color:black'>მოცემული</span><span style='color:black'> </span><span
style='color:black'>პირობების</span><span style='color:black'> </span><span
style='color:black'>შესაბამისად</span><span style='color:black'>. </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>არის</span><span style='color:black'> </span><span
style='color:black'>სრულად</span><span style='color:black'>, </span><span
style='color:black'>ინდივიდუალურად</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ერთპიროვნულად</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებელი</span><span style='color:black'>, </span><span
style='color:black'>უზრუნველყოს</span><span style='color:black'>, </span><span
style='color:black'>რომ</span><span style='color:black'> </span><span
style='color:black'>პროდუქტი</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'>/</span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>მომსახურება</span><span style='color:black'>, </span><span
style='color:black'>რომელსაც</span><span style='color:black'> </span><span
style='color:black'>ყიდის</span><span style='color:black'>, </span><span
style='color:black'>შესაბამისობაშია</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობასთან</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>საერთაშორისოდ</span><span style='color:black'> </span><span
style='color:black'>აღიარებულ</span><span style='color:black'> </span><span
style='color:black'>სტანდარტებთან</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.25<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>უზრუნველყოს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>თვის</span><span style='color:black'> </span><span
style='color:black'>ყველა</span><span style='color:black'> </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>ინტერნეტ</span><span style='color:black'>-</span><span
style='color:black'>რესურსის</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'>/</span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>საინფორმაციო</span><span style='color:black'> </span><span
style='color:black'>რესურსის</span><span style='color:black'> </span><span
style='color:black'>შეუფერხებელი</span><span style='color:black'> </span><span
style='color:black'>ხელმისაწვდომობა</span><span style='color:black'>, </span><span
style='color:black'>რომელსაც</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>იყენებს</span><span style='color:black'> </span><span
style='color:black'>პროდუქტის</span><span style='color:black'>/</span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>რეალიზებისთვის</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.26<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>განახორციელოს</span><span style='color:black'> </span><span
style='color:black'>ტრანზაქცია</span><span style='color:black'> (</span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>მიიღონ</span><span style='color:black'> </span><span
style='color:black'>გადახდა</span><span style='color:black'>), </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>მიზანმიმართული</span><span style='color:black'> </span><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>იქნება</span><span style='color:black'> </span><span
style='color:black'>მის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>კლიენტებისათვის</span><span style='color:black'> </span><span
style='color:black'>შეთავაზებული</span><span style='color:black'> </span><span
style='color:black'>პროდუქციის</span><span style='color:black'>/</span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>რეალიზებისთვის</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.27<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>განახორციელოს</span><span style='color:black'> </span><span
style='color:black'>კლიენტის</span><span style='color:black'> </span><span
style='color:black'>მიერ</span><span style='color:black'> </span><span
style='color:black'>ინტერნეტ</span><span style='color:black'> </span><span
style='color:black'>პორტალზე</span><span style='color:black'> </span><span
style='color:black'>სარგებლობის</span><span style='color:black'> </span><span
style='color:black'>მონიტორინგი</span><span style='color:black'> </span><span
style='color:black'>საეჭვო</span><span style='color:black'> </span><span
style='color:black'>ოპერაციების</span><span style='color:black'> </span><span
style='color:black'>დროულად</span><span style='color:black'> </span><span
style='color:black'>გამოვლენის</span><span style='color:black'> </span><span
style='color:black'>მიზნით</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-.5in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>8.1.28<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>არ</span><span style='color:black'> </span><span
style='color:black'>მიიღოს</span><span style='color:black'> </span><span
style='color:black'>მონაწილეობა</span><span style='color:black'> </span><span
style='color:black'>ტრანზაქციებში</span><span style='color:black'> / </span><span
style='color:black'>ფიქტიურ</span><span style='color:black'> </span><span
style='color:black'>ოპერაციებში</span><span style='color:black'> (</span><span
style='color:black'>კლიენტისათვის</span><span style='color:black'> </span><span
style='color:black'>სერვისის</span><span style='color:black'>/</span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>მიწოდების</span><span style='color:black'> </span><span
style='color:black'>გარეშე</span><span style='color:black'>), </span><span
style='color:black'>რომელიც</span><span style='color:black'> </span><span
style='color:black'>პირდაპირ</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'>/</span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>ირიბად</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებულია</span><span style='color:black'> </span><span
style='color:black'>ფულის</span><span style='color:black'> </span><span
style='color:black'>გათეთრებასთან</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>მომსახურების</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>საფასური</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ექვაირინგის</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>საფასური</span><span style='color:black'> (</span><span
style='color:black'>პარტნიორი</span><span style='color:black'> </span><span
style='color:black'>ბანკისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>თვის</span><span style='color:black'> </span><span
style='color:black'>გადასახდელი</span><span style='color:black'> </span><span
style='color:black'>საკომისიოების</span><span style='color:black'> </span><span
style='color:black'>ჩათვლით</span><span style='color:black'>) </span><span
style='color:black'>განისაზღვრება</span><span style='color:black'> </span><span
style='color:black'>ყოველი</span><span style='color:black'> </span><span
style='color:black'>წარმატებით</span><span style='color:black'> </span><span
style='color:black'>შესრულებული</span><span style='color:black'> </span><span
style='color:black'>საბარათე</span><span style='color:black'> </span><span
style='color:black'>ოპერაციიდან</span><span style='color:black'> </span><span
style='color:black'>შემდეგი</span><span style='color:black'> </span><span
style='color:black'>ოდენობით</span><span style='color:black'> („</span><span
style='color:black'>სტანდარტული</span><span style='color:black'> </span><span
style='color:black'>საკომისიო</span><span style='color:black'>“): </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME </span><span style='color:black'>უფლებამოსილია</span><span
style='color:black'> </span><span style='color:black'>დაადგინოს</span><span
style='color:black'> </span><span style='color:black'>ყოველთვიური</span><span
style='color:black'> </span><span style='color:black'>საფასური</span><span
style='color:black'> 5 </span><span style='color:black'>ლარის</span><span
style='color:black'> </span><span style='color:black'>ოდენობით</span><span
style='color:black'> </span><span style='color:black'>QR </span><span
style='color:black'>კოდით</span><span style='color:black'> </span><span
style='color:black'>სარგებლობისთვის</span></span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ქართული</span><span style='color:black'> </span><span
style='color:black'>ბარათებით</span><span style='color:black'> </span><span
style='color:black'>გადახდისას</span><span style='color:black'> </span><span
style='color:black'>საკომისიოს</span><span style='color:black'> </span><span
style='color:black'>მოცულობა</span><span style='color:black'> </span><span
style='color:black'>განისაზღვრება</span><span style='color:black'>:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>2.5%-</span><span style='color:black'>ით</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>უცხოური</span><span style='color:black'> </span><span
style='color:black'>ბარათებით</span><span style='color:black'> </span><span
style='color:black'>გადახდისას</span><span style='color:black'> </span><span
style='color:black'>საკომისიოს</span><span style='color:black'> </span><span
style='color:black'>მოცულობა</span><span style='color:black'> </span><span
style='color:black'>განისაზღვრება</span><span style='color:black'>:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.1.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>3.2%-</span><span style='color:black'>ით</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებლისათვის</span><span style='color:black'> </span><span
style='color:black'>მისი</span><span style='color:black'> </span><span
style='color:black'>ინდივიდუალური</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>საფასური</span><span style='color:black'> </span><span
style='color:black'>მითითებული</span><span style='color:black'> </span><span
style='color:black'>იქნება</span><span style='color:black'> KEEPZ.ME </span><span
style='color:black'>აპლიკაციაში</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მხარეთა</span><span style='color:black'> </span><span
style='color:black'>ორმხრივი</span><span style='color:black'> </span><span
style='color:black'>წერილობითი</span><span style='color:black'> </span><span
style='color:black'>შეთანხმების</span><span style='color:black'> </span><span
style='color:black'>საფუძველზე</span><span style='color:black'>, </span><span
style='color:black'>შესაძლებელია</span><span style='color:black'> </span><span
style='color:black'>დაწესდეს</span><span style='color:black'> </span><span
style='color:black'>სპეციალური</span><span style='color:black'>, </span><span
style='color:black'>სტანდარტული</span><span style='color:black'> </span><span
style='color:black'>საკომისიოსაგან</span><span style='color:black'> </span><span
style='color:black'>განსხვავებული</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>საფასური</span><span style='color:black'>; </span><span
style='color:black'>განსხვავებული</span><span style='color:black'> </span><span
style='color:black'>საკომისიოს</span><span style='color:black'> </span><span
style='color:black'>დაწესება</span><span style='color:black'> </span><span
style='color:black'>მოხდება</span><span style='color:black'> </span><span
style='color:black'>ბიზნეს</span><span style='color:black'> </span><span
style='color:black'>გადაწვეტილების</span><span style='color:black'> </span><span
style='color:black'>ფარგლებში</span><span style='color:black'>, </span><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>ბრუნვისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ტრანზაქციების</span><span style='color:black'> </span><span
style='color:black'>რაოდენობის</span><span style='color:black'> </span><span
style='color:black'>გათვალიწინებით</span><span style='color:black'>. </span><span
style='color:black'>შეთანხმება</span><span style='color:black'> </span><span
style='color:black'>შესაძლებელია</span><span style='color:black'> </span><span
style='color:black'>გაფორმდეს</span><span style='color:black'> </span><span
style='color:black'>ელ</span><span style='color:black'>. </span><span
style='color:black'>კომუნიკაციის</span><span style='color:black'> </span><span
style='color:black'>ფორმით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'> </span><span
style='color:black'>დამატებითი</span><span style='color:black'> </span><span
style='color:black'>შეთანხმების</span><span style='color:black'> </span><span
style='color:black'>გაფორმების</span><span style='color:black'> </span><span
style='color:black'>გზით</span><span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>სტანდარტული</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>საკომისიოს</span><span style='color:black'> </span><span
style='color:black'>გარდა</span><span style='color:black'>, </span><span
style='color:black'>შესაძლებელია</span><span style='color:black'> </span><span
style='color:black'>გარკვეულ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევებში</span><span style='color:black'>, </span><span
style='color:black'>დაწესდეს</span><span style='color:black'> </span><span
style='color:black'>დამატებითი</span><span style='color:black'> </span><span
style='color:black'>საკომისიო</span><span style='color:black'>, </span><span
style='color:black'>რომლის</span><span style='color:black'> </span><span
style='color:black'>გადახდაზეც</span><span style='color:black'> </span><span
style='color:black'>პასუხისმგებლობა</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელს</span><span style='color:black'> </span><span
style='color:black'>ეკისრება</span><span style='color:black'>. </span><span
style='color:black'>დამატებითი</span><span style='color:black'> </span><span
style='color:black'>საკომისიოს</span><span style='color:black'> </span><span
style='color:black'>დაწესება</span><span style='color:black'> </span><span
style='color:black'>მოხდება</span><span style='color:black'>, </span><span
style='color:black'>კანონმდებლობის</span><span style='color:black'>, </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'>, „</span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>სისტემისა</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>საგადახდო</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>შესახებ</span><span style='color:black'>&quot; </span><span
style='color:black'>კანონის</span><span style='color:black'> </span><span
style='color:black'>საკომისიოებთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნების</span><span style='color:black'>/</span><span
style='color:black'>შეზღუდვების</span><span style='color:black'> </span><span
style='color:black'>მხედველობაში</span><span style='color:black'> </span><span
style='color:black'>მიღებით</span><span style='color:black'> </span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>მხოლოდ</span><span style='color:black'> </span><span
style='color:black'>ისეთ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>, </span><span
style='color:black'>როდესაც</span><span style='color:black'> </span><span
style='color:black'>აღნიშნული</span><span style='color:black'> </span><span
style='color:black'>განპირობებულია</span><span style='color:black'> </span><span
style='color:black'>ობიექტური</span><span style='color:black'> </span><span
style='color:black'>გარემოებებით</span><span style='color:black'>, </span><span
style='color:black'>მათ</span><span style='color:black'> </span><span
style='color:black'>შორის</span><span style='color:black'> </span><span
style='color:black'>ტრანზაქციასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>დანახარჯის</span><span style='color:black'> </span><span
style='color:black'>სტრუქტურიდან</span><span style='color:black'> </span><span
style='color:black'>გამომდინარე</span><span style='color:black'> (</span><span
style='color:black'>მაგალითად</span><span style='color:black'>, </span><span
style='color:black'>ბანკთაშორის</span><span style='color:black'> </span><span
style='color:black'>გადარიცხვის</span><span style='color:black'> </span><span
style='color:black'>საკომისიო</span><span style='color:black'>).</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>9.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME </span><span style='color:black'>უფლებამოსილია</span><span
style='color:black'>, </span><span style='color:black'>საკუთარი</span><span
style='color:black'> </span><span style='color:black'>შეხედულებისამებრ</span><span
style='color:black'>, </span><span style='color:black'>ნებისმიერ</span><span
style='color:black'> </span><span style='color:black'>დროს</span><span
style='color:black'> </span><span style='color:black'>დააწესოს</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>შეცვალოს</span><span
style='color:black'> </span><span style='color:black'>სტანდარტული</span><span
style='color:black'> </span><span style='color:black'>საკომისიო</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლისათვის</span><span
style='color:black'> 30 (</span><span style='color:black'>ოცდაათი</span><span
style='color:black'>) </span><span style='color:black'>კალენდარული</span><span
style='color:black'> </span><span style='color:black'>დღით</span><span
style='color:black'> </span><span style='color:black'>ადრე</span><span
style='color:black'>, </span><span style='color:black'>რეგისტრაციისა</span><span
style='color:black'> </span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>განაცხადში</span><span
style='color:black'> </span><span style='color:black'>მითითებულ</span><span
style='color:black'> </span><span style='color:black'>ელექტორნული</span><span
style='color:black'> </span><span style='color:black'>ფოსტაზე</span><span
style='color:black'> </span><span style='color:black'>გაგზავნილი</span><span
style='color:black'> </span><span style='color:black'>შეტყობინების</span><span
style='color:black'> </span><span style='color:black'>საფუძველზე</span><span
style='color:black'>. </span><span style='color:black'>იმ</span><span
style='color:black'> </span><span style='color:black'>შემთხვევაში</span><span
style='color:black'>, </span><span style='color:black'>თუ</span><span
style='color:black'> </span><span style='color:black'>აღნიშნულ</span><span
style='color:black'> </span><span style='color:black'>ვადაში</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელი</span><span
style='color:black'> </span><span style='color:black'>წერილობით</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>ელექტორნული</span><span
style='color:black'> </span><span style='color:black'>ფოსტის</span><span
style='color:black'> </span><span style='color:black'>საშუალებით</span><span
style='color:black'> </span><span style='color:black'>განაცხადებს</span><span
style='color:black'> </span><span style='color:black'>რომ</span><span
style='color:black'> </span><span style='color:black'>არ</span><span
style='color:black'> </span><span style='color:black'>ეთანხმება</span><span
style='color:black'> </span><span style='color:black'>საკომისიოს</span><span
style='color:black'> </span><span style='color:black'>ცვლილებას</span><span
style='color:black'>, KEEPZ.ME -</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>წარმოეშვება</span><span
style='color:black'> </span><span style='color:black'>უფლება</span><span
style='color:black'> </span><span style='color:black'>დაუყონებლივ</span><span
style='color:black'> </span><span style='color:black'>შეწყვიტოს</span><span
style='color:black'> </span><span style='color:black'>ხელშეკრულება</span><span
style='color:black'>, </span><span style='color:black'>რის</span><span
style='color:black'> </span><span style='color:black'>შესახებაც</span><span
style='color:black'> </span><span style='color:black'>წერილობით</span><span
style='color:black'> </span><span style='color:black'>შეატყობინებს</span><span
style='color:black'> </span><span style='color:black'>მომხმარებელს</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><b><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='color:black'>მომსახურების</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>შეჩერება</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>და</span></b><b><span style='color:black'> </span></b><b><span
style='color:black'>შეწყვეტა</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME-</span><span style='color:black'>ს</span><span
style='color:black'> </span><span style='color:black'>შეუძლია</span><span
style='color:black'> </span><span style='color:black'>დაუყოვნებლივ</span><span
style='color:black'>, </span><span style='color:black'>წერილობითი</span><span
style='color:black'> </span><span style='color:black'>შეტყობინების</span><span
style='color:black'> </span><span style='color:black'>გზით</span><span
style='color:black'>, </span><span style='color:black'>სრულიად</span><span
style='color:black'> </span><span style='color:black'>საკუთარი</span><span
style='color:black'> </span><span style='color:black'>შეხედულებისამებრ</span><span
style='color:black'>, </span><span style='color:black'>შეაჩეროს</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>შეწყვიტოს</span><span
style='color:black'> </span><span style='color:black'>მოცემული</span><span
style='color:black'> </span><span style='color:black'>ექვაირინგის</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>გაწევა</span><span
style='color:black'>, </span><span style='color:black'>თუ</span><span
style='color:black'>:</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებლის</span><span style='color:black'> </span><span
style='color:black'>ელექტრონული</span><span style='color:black'> </span><span
style='color:black'>საფულის</span><span style='color:black'> </span><span
style='color:black'>ანგარიში</span><span style='color:black'> </span><span
style='color:black'>არააქტიურია</span><span style='color:black'> 6 (</span><span
style='color:black'>ექვსი</span><span style='color:black'>) </span><span
style='color:black'>თვის</span><span style='color:black'> </span><span
style='color:black'>განმავლობაში</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>KEEPZ.ME </span><span style='color:black'>დაადგენს</span><span
style='color:black'>, </span><span style='color:black'>რომ</span><span
style='color:black'> </span><span style='color:black'>კონკრეტული</span><span
style='color:black'> </span><span style='color:black'>მომხმარებლისათვის</span><span
style='color:black'> </span><span style='color:black'>მომსახურების</span><span
style='color:black'> </span><span style='color:black'>მიწოდების</span><span
style='color:black'> </span><span style='color:black'>გაგრძელება</span><span
style='color:black'> </span><span style='color:black'>წინააღმდეგობაში</span><span
style='color:black'> </span><span style='color:black'>მოვა</span><span
style='color:black'>/</span><span style='color:black'>დაარღვევს</span><span
style='color:black'> </span><span style='color:black'>არსებულ</span><span
style='color:black'> </span><span style='color:black'>კანონმდებლობას</span><span
style='color:black'>/</span><span style='color:black'>საერთაშორისო</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>სისტემის</span><span
style='color:black'> </span><span style='color:black'>რეგულაციებს</span><span
style='color:black'>, </span><span style='color:black'>მის</span><span
style='color:black'> </span><span style='color:black'>შიდა</span><span
style='color:black'> </span><span style='color:black'>პოლიტიკას</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>ნებისმიერ</span><span
style='color:black'> </span><span style='color:black'>მისთვის</span><span
style='color:black'> </span><span style='color:black'>სავალდებულოდ</span><span
style='color:black'> </span><span style='color:black'>შესასრულებელ</span><span
style='color:black'> </span><span style='color:black'>რეგულაციებს</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'>/</span><span style='color:black'>და</span><span
style='color:black'> </span><span style='color:black'>საერთაშორისო</span><span
style='color:black'> </span><span style='color:black'>ან</span><span
style='color:black'> </span><span style='color:black'>ლოკალური</span><span
style='color:black'> </span><span style='color:black'>საგადახდო</span><span
style='color:black'> </span><span style='color:black'>სისტემის</span><span
style='color:black'> </span><span style='color:black'>მოთხოვნებს</span><span
style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ასეთი</span><span style='color:black'> </span><span
style='color:black'>მოთხოვნა</span><span style='color:black'> </span><span
style='color:black'>განხორციელებულია</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერი</span><span style='color:black'> </span><span
style='color:black'>სამთავრობო</span><span style='color:black'> </span><span
style='color:black'>ორგანოს</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>ფინანსური</span><span style='color:black'> </span><span
style='color:black'>პარტნიორის</span><span style='color:black'> </span><span
style='color:black'>მხრიდან</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.1.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>დაარღვევს</span><span style='color:black'>  </span><span
style='color:black'>წინამდებარე</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულების</span><span style='color:black'> </span><span
style='color:black'>პირობებს</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>გარდა</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>მოცემული</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>ხელშეკრულების</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> 10.1 </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>მუხლით</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>განსაზღვრული</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>საფუძვლებისა</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>, </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>მომსახურების</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>სრულად</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>ან</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>ნაწილობრივ</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>შეწყვეტა</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>შესაძლებელია</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>: </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.2.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>KEEPZ.ME-</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>ს</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>  </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>მიერ</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>ნებისმიერ</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'> </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>დროს</span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>, </span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>მომხმარებლისათვის</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'> 30 (</span><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>ოცდაათი</span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>) </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>კალენდარული</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>დღით</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>ადრე</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>გაგზავნილი</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>შეტყობინების</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>საფუძველზე</span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.2.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
color:black'>მხარეთა</span><span lang="GEO/KAT" style='color:black'> </span><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif;color:black'>წერილობითი</span><span
lang="GEO/KAT" style='color:black'> </span><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif;color:black'>შეთანხმებით</span><span
lang="GEO/KAT" style='color:black'>;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:0in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.2.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'>, </span><span
style='color:black'>მასთან</span><span style='color:black'> </span><span
style='color:black'>დაკავშირებული</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულებით</span><span style='color:black'> </span><span
style='color:black'>ან</span><span style='color:black'>/</span><span
style='color:black'>და</span><span style='color:black'> </span><span
style='color:black'>კანონმდებლობით</span><span style='color:black'> </span><span
style='color:black'>გათვალისწინებულ</span><span style='color:black'> </span><span
style='color:black'>სხვა</span><span style='color:black'> </span><span
style='color:black'>შემთხვევებში</span><span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char;border:none'><span lang="GEO/KAT" style='font-family:
"Sylfaen",serif;color:black'>10.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>უფლებამოსილია</span><span style='color:black'> </span><span
style='color:black'>შეწყვიტოს</span><span style='color:black'> </span><span
style='color:black'>ექვაირინგის</span><span style='color:black'> </span><span
style='color:black'>მომსახურების</span><span style='color:black'> </span><span
style='color:black'>მიღება</span><span style='color:black'> </span><span
style='color:black'>ნებისმიერ</span><span style='color:black'> </span><span
style='color:black'>დროს</span><span style='color:black'>, KEEPZ.ME-</span><span
style='color:black'>თვის</span><span style='color:black'> </span><span
style='color:black'>შესაბამისი</span><span style='color:black'> </span><span
style='color:black'>წერილობითი</span><span style='color:black'> </span><span
style='color:black'>შეტყობინების</span><span style='color:black'> </span><span
style='color:black'>გაგზავნის</span><span style='color:black'> </span><span
style='color:black'>გზით</span><span style='color:black'>. </span><span
style='color:black'>იმ</span><span style='color:black'> </span><span
style='color:black'>შემთხვევაში</span><span style='color:black'>, </span><span
style='color:black'>თუ</span><span style='color:black'> </span><span
style='color:black'>შეწყვეტის</span><span style='color:black'> </span><span
style='color:black'>შემდგომ</span><span style='color:black'> </span><span
style='color:black'>მომხმარებელი</span><span style='color:black'> </span><span
style='color:black'>გაანახლებს</span><span style='color:black'> KEEPZ.ME-</span><span
style='color:black'>ის</span><span style='color:black'> </span><span
style='color:black'>ექვაირინგის</span><span style='color:black'> </span><span
style='color:black'>მომსახურებით</span><span style='color:black'> </span><span
style='color:black'>სარგებლობას</span><span style='color:black'>, </span><span
style='color:black'>მოცემული</span><span style='color:black'> </span><span
style='color:black'>ხელშეკრულება</span><span style='color:black'> </span><span
style='color:black'>კვლავ</span><span style='color:black'> </span><span
style='color:black'>შევა</span><span style='color:black'> </span><span
style='color:black'>ძალაში</span><span style='color:black'>, </span><span
style='color:black'>მომსახურებით</span><span style='color:black'> </span><span
style='color:black'>სარგებლობის</span><span style='color:black'> </span><span
style='color:black'>განახლების</span><span style='color:black'> </span><span
style='color:black'>თარიღიდან</span><span style='color:black'>.</span></span></p>

<p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0in;margin-bottom:
6.0pt;margin-left:2.0in;text-align:justify;text-indent:-.25in;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>II.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span
lang="GEO/KAT"><a name="_heading=h.tyjcwt"></a><b>     </b><b><span
style='font-family:"Sylfaen",serif;color:black'>ფულადი</span></b><b><span
style='font-family:"Sylfaen",serif;color:black'> </span></b><b><span
style='font-family:"Sylfaen",serif;color:black'>გზავნილები</span></b><b><span
style='font-family:"Sylfaen",serif'>     </span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>შესავალი</b><a
name="_heading=h.aomij4vnm4uv"></a></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წინამდებარე
დოკუმენტის საფუძველზე და მისი პირობების შესაბამისად, KEEPZ.ME კისრულობს ვალდებულებას
გაუწიოს მომხმარებელს  ფულადი გზავნილების      მომსახურება, ხოლო მომხმარებელი კისრულობს
ვალდებულებას შეასრულოს წინამდებარე დოკუმენტის პირობები და გადაუხადოს KEEPZ.ME-ს
მომსახურების საფასური (“ფულადი გზავნილების     <a name="_heading=h.dkcnw5juaeoc"></a>მომსახურების
საფასური”).</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ფულადი
გზავნილების      მომსახურების საფასურის ოდენობას მომხმარებელი გადაიხდის შესაბამისი
ფულადი გზავნილების     <a name="_heading=h.kge97qxluub9"></a> ტრანზაქციის  შესრულებასთან
ერთად.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>დასაშვებობა</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;line-height:115%;layout-grid-mode:char'><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ფულადი გზავნილების      მომსახურებით
სარგებლობა შეუძლია მხოლოდ      ფიზიკურ პირს, რომელიც ფლობს საქართველოს ტერიტორიაზე
რეგისტრირებული ემიტენტის მიერ მის სახელზე გამოცემულ საბარათე ინსტრუმენტს ან/და ბარათს
და რომელიც ფულადი გზავნილების ტრანზაქციების მომსახურებით სარგებლობის დროს არ ახორციელებს
სამეწარმეო საქმიანობას.     </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>განცხადებები</b><b>
</b><b>და</b><b> </b><b>გარანტიები</b><a name="_heading=h.40xmstj84yhs"></a></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომხმარებელი
აცხადებს და იძლევა გარანტიას, რომ:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წინამდებარე
დოკუმენტზე თანხმობის განცხადების დროისათვის/ფულადი გზავნილების      ტრანზაქციის
ოპერაციის შესრულების მომენტისათვის არის/იქნება ქმედუნარიანი (მათ შორის, მოქმედი
კანონმდებლობით დადგენილი წესით);</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წინამდებარე
დოკუმენტზე თანხმობა გამოხატულია/ფულადი გზავნილების      ტრანზაქციის ოპერაცია შესრულებულია
მის მიერ ნებაყოფლობით, KEEPZ.ME-ს ან მესამე პირის მხრიდან მის მიმართ ძალადობის,
მუქარის, მოტყუების, შეცდომის ან/და რაიმე სხვა გარემოების გამოყენების გარეშე;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წინამდებარე
დოკუმენტზე თანხმობის გამოხატვა და მისი პირობების შესრულება, ფულადი გზავნილების ტრანზაქციის
ოპერაციის შესრულება არ იწვევს/გამოიწვევს იმ სახელშეკრულებო, სასამართლო (მათ შორის,
საარბიტრაჟო სასამართლო), კანონმდებლობის ან/და სხვა ვალდებულებების დარღვევას, რომელთა
დაცვაზეც ის პასუხისმგებელია;<a name="_heading=h.ls6ltdysf42m"></a></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ფულადი გზავნილების
ტრანზაქცია არ უკავშირდება მომხმარებლის სამეწარმეო საქმიანობას.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ფულადი გზავნილების    
 ტრანზაქციას ახორციელებს ისეთი მოწყობილობის ან/და ინტერნეტ კავშირის გამოყენებით,
რომელიც დაცულია მესამე პირების არასანქცირებული, არავტორიზებული წვდომისგან.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ბარათი/ბარათის
საიდენტიფიკაციო მონაცემები, რომლის საშუალებითაც სრულდება ფულადი გზავნილების<a
name="_heading=h.g8nkhouzpb9x"></a>      ტრანზაქცია არის მის კანონიერ სარგებლობაში/მფლობელობაში
და ის არის ბარათის მფლობელი.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომხმარებელი
სრულად არის პასუხისმგებელი: </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მის მიერ
ფულადი გზავნილების      ტრანზაქციის განხორციელებისას/მისი ინიცირებისას მის მიერ
მითითებული ნებისმიერი სახის ინფორმაციის, მათ შორის ბარათის საიდენტიფიკაციო მონაცემების,
სისწორეზე, სისრულესა და უსაფრთხოებაზე და ამ მოთხოვნების დაუცველობის შედეგად გამოწვეულ
ნებისმიერ შედეგზე, მათ შორის ნებისმიერ ზიანზე (ზარალზე), რომელიც მიადგა
KEEPZ.ME-ს ან/და მომხმარებელს;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ფულადი გზავნილების    
 ტრანზაქციის განხორციელებისას/მისი ინიცირებისას, მის მიერ დაფიქსირებულ მესამე პირის
მონაცემებს ფლობს მითითებული პიროვნება და მომხმარებელს მოპოვებული აქვს ამ პირის თანხმობა
მისი მონაცემების KEEPZ.ME-ს მიერ დამუშავების (მათ შორის, KEEPZ.ME-სთვის გადაცემის)
შესახებ. ამასთან, მომხმარებელი ვალდებულია აუნაზღაუროს KEEPZ.ME-ს ამ ქვეპუნქტით განსაზღვრული
ვალდებულების სრულად და ჯეროვნად შეუსრულებლობის გამო KEEPZ.ME-სთვის მიყენებული ზიანი
(ზარალი), მათ შორის, ერთმნიშვნელოვნად, KEEPZ.ME-სთვის ნებისმიერი მესამე პირის მიერ
დაკისრებული გადასახდელი.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ფულადი გზავნილის
ტრანზაქციის მომსახურებით სარგებლობისას, ავტორიზაციის გვერდზე მის მიერ დამახსოვრებულ/შენახულ
ბარათს ფლობს თავად და თანახმაა, რომ მომსახურების ფარგლებში დაფიქსირებულ მის მობილური
ტელეფონის ნომერთან ერთად (მათ შორის მასთან მიმართებით), KEEPZ.ME-მ დაამუშაოს, მათ
შორის შეინახოს აპლიკაციაში მის მიერ მითითებული ბარათის საიდენტიფიკაციო მონაცემები.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
უფლებამოსილია, კანონმდებლობის მოთხოვნათა დაცვითა და KEEPZ.ME-ში დადგენილი წესებისა
და პროცედურების შესაბამისად, მომხმარებლის მიერ ფულადი გზავნილების      ტრანზაქციის
მომსახურებასთან დაკავშირებით გადაცემული ინფორმაცია (მათ შორის პერსონალური მონაცემები)
დაამუშავოს მომხმარებლის მიერ სხვა ნებისმიერი პროდუქტებით/მომსახურებით სარგებლობის
შემთხვევაში KEEPZ.ME-ს  უფლებების განხორციელების მიზნით.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-indent:-21.3pt;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>მომსახურების</b><b>
</b><b>ტარიფი</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომსახურების
ტარიფი:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
უფლებამოსილია დაადგინოს ყოველთვიური საფასური 5 ლარის ოდენობით QR კოდით სარგებლობისთვის;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ქართული ბარათებით
გადახდისას საკომისიოს მოცულობა განისაზღვრება: - 1% - 2.5%-ით შუალედში</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.1.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>უცხოური ბარათებით
გადახდისას საკომისიოს მოცულობა განისაზღვრება 2% - 3.2%-ით</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომსახურების
ტარიფი მომხმარებელს აესახება KEEPZ.ME-ს აპლიკაციაში<a
name="_heading=h.a11od3xymrv"></a></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-indent:-21.3pt;line-height:115%;layout-grid-mode:char'><b><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ფულადი
გზავნილების      მომსახურების პირობები</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ფულადი გზავნილების    
 ტრანზაქციის ინიციირებისთვის მომხმარებელი უნდა სარგებლობდეს KEEPZ.ME აპლიკაციით.
</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ფულადი გზავნილების    
 ტრანზაქციის  ინიციირებისა და ფულადი გზავნილების      ტრანზაქციის  საფუძველზე თანხის
მიღებისთვის, მომხმარებელმა KEEPZ.ME-ს აპლიკაციაში უნდა შეიყვანოს ბარათის საიდენტიფიკაციო
მონაცემები.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ფულადი გზავნილების
განხორციელება მომხმარებლის მიერ განხორციელდება ქიფზ.მი-ს მიერ დადგენილი პირობების
შესაბამისად, რომელიც შესაძლოა დროდადრო შეიცვალოს, მათ შორის ტრანზაქციების ლიმიტი
და განხორციელების წესი</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომხმარებელი 
უფლებამოსილია: </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.4.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ფულადი გზავნილების    
 ტრანზაქციის მომსახურებით სარგებლობისთვის KEEPZ.ME-ს აპლიკაციაში შეინახოს/დაიმახსოვროს
ერთი ან რამდენიმე ბარათი/ბარათის საიდენტიფიკაციო მონაცემები. ამასთან, ბარათის/ბარათის
საიდენტიფიკაციო მონაცემების შენახვა განხორციელდება კანონმდებლობისა და KEEPZ.ME-ში
არსებული პროცედურების შესაბამისად;     </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.4.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ნებისმიერ
დროს წაშალოს მომსახურებით სარგებლობისთვის დამახსოვრებული/შენახული ბარათი/ბარათის
საიდენტიფიკაციო მონაცემები;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.4.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ერთჯერადი
ფულადი გზავნილის ტრანზაქციის ფარგლებში ჰქონდეს მაქსიმუმ 500 ლარის, ხოლო თვეში ჯამურად
3,000 ლარის გადარიცხვის საშუალება. წინამდებარე ლიმიტები შეიძლება შეიცვალოს დროთა
განმავლობაში. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME<a
name="_heading=h.yi5xlw4wcbx0"></a> უფლებამოსილია, ყოველ ჯერზე ფულადი გზავნილის    
 ტრანზაქციის  შესრულებისთვის ან/და KEEPZ.ME-ს აპლიკაციაში ბარათის საიდენტიფიკაციო
მონაცემების შენახვის/დამახსოვრებისთვის მოითხოვოს ოპერაციის შესაბამისი წესით/ფორმით
(მათ შორის ერთჯერადი კოდის მეშვეობით) დადასტურება.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
background:white'>5.6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>     ტრანზაქციის
 განხორციელების შემთხვევაში, მიმღები მიიღებს მოკლე ტექსტურ (SMS) შეტყობინებას. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif;
background:white'>5.7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ვინაიდან
ბარათის/ბარათის საიდენტიფიკაციო მონაცემების შენახვა/დამახსოვრება ხორციელდება ფულადი
გზავნილის ტრანზაქციაში მონაწილე/მითითებული კონკრეტული მობილური ტელეფონის ნომრის
მიმართ, მომხმარებელი ვალდებულია მობილური ტელეფონის ნომრის/ფინანსური ნომრის ცვლილების
ან/და დაკარგვის ან/და საკუთრებაში/სარგებლობაში მესამე პირისთვის გადაცემის ან/და
რაიმე მიზეზით მისით სარგებლობის შეწყვეტის შემთხვევაში, დაუყოვნებლივ წაშალოს ფულადი
გზავნილების<a name="_heading=h.z9rx1t8f6bw3"></a>      ტრანზაქციის  მომსახურებით
სარგებლობისთვის ავტორიზაციის გვერდზე შენახული/დამახსოვრებული ბარათი/ბარათის საიდენტიფიკაციო
მონაცემები. ამასთან, KEEPZ.ME პასუხს არ აგებს აღნიშნული ვალდებულების მომხმარებლის
მიერ დარღვევის გამო დამდგარი ნებისმიერი შედეგისთვის.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ფულადი
გზავნილის</b><b> </b><a name="_heading=h.4i8smceyroi9"></a><b> </b><b>მომსახურების</b><b>
</b><b>დამატებითი</b><b> </b><b>სახელშეკრულებო</b><b> </b><b>პირობები</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
უფლებამოსილია<a name="_heading=h.fo4ur4bkipza"></a></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
აპლიკაციაში შესაბამისი ინფორმაციის განთავსების გზით ან/და KEEPZ.ME-სთვის მისაღები
სხვა ფორმით, თუ კანონმდებლობით სხვა რამ არ არის განსაზღვრული, მომხმარებლის დამატებითი
თანხმობისა და აქცეპტის გარეშე, შეცვალოს წინამდებარე დოკუმენტით განსაზღვრული ნებისმიერი
პირობა (მათ შორის შესაბამისი საკომისიოს ოდენობა, ლიმიტები ან/და საბარათე სქემები,
რომელში ჩართული ბარათებითაც შესაძლებელია ფულადი გზავნილის      გადარიცხვის ოპერაციის
შესრულება). ამასთან, ამგვარი ცვლილება სავალდებულოა მომხმარებლებისათვის მისი გამოქვეყნების
დღის მომდევნო საბანკო დღიდან, თუ KEEPZ.ME-ს მიერ ან/და კანონმდებლობით სხვა რამ არ
არის განსაზღვრული.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ნებისმიერ
დროს, ცალმხრივად, მომხმარებლის დამატებითი შეტყობინების ან/და თანხმობის/აქცეპტის
გარეშე სრულად ან ნაწილობრივ შეწყვიტოს ფულადი გზავნილის      ტრანზაქციის მომსახურება.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
არ არის პასუხისმგებელი ნებისმიერ ისეთ შეზღუდვაზე ან/და ფულადი გზავნილის      ტრანზაქციის
მომსახურებით სარგებლობის შეუძლებლობაზე, რომელიც განპირობებულია ბარათის ემიტენტის
მიერ დაწესებული შეზღუდვების/აკრძალვების გამო. ამასთან, KEEPZ.ME იხსნის ყოველგვარ
პასუხისმგებლობას, თუ მომხმარებელს მომსახურებით სარგებლობიდან გამომდინარე დამტებით
დაეკისრება რაიმე სახის გადასახდელი ბარათის ემიტენტის მიერ.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წინამდებარე
ნაწილი      არეგულირებს მხოლოდ ფულადი გზავნილების      ტრანზაქციის სარგებლობის წესებს
და პირობებს და არ არეგულირებს მომხმარებელსა და ბარათის ემიტენტს შორის სამართლებრივ
ურთიერთობას, მათ შორის ბარათით სარგებლობის პირობებს. ამასთან, იმ შემთხვევაში თუ
მომხმარებელი არასრულწლოვანია, არასრულწლოვანის კანონიერი წარმომადგენლის თანხმობა
წინამდებარე დოკუმენტის პირობებზე გაცემულად ითვლება ბარათის ემიტენტის მიმართ გაცემული
თანხმობით, რომლითაც კანონიერი წარმომადგენელი დაეთანხმა არასრულწლოვნის მიერ ბარათით
სარგებლობას და მისი საშუალებით ოპერაციების შესრულებას.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომხმარებელი
და KEEPZ.ME ადასტურებენ და თანხმდებიან, რომ თუ მომხმარებელზე გავრცელდება, ამერიკის
შეერთებული შტატების უცხოური აქტივების კონტროლის სამსახურის, გაერთიანებული სამეფოს
ფინანსური სანქციების იმპლემენტაციის სამსახურის, ევროპის კავშირის ან/და გაერთიანებული
ერების უშიშროების საბჭოს ან/და მათი ნებისმიერი სამართალმემკვიდრის (შემდგომში - სანქციების
ორგანო) მიერ დაწესებული ნებისმიერი სანქცია ან/და ნებისმიერი სანქციით გათვალისწინებული
ან/და მოთხოვნილი ნებისმიერი შეზღუდვა ან/და აკრძალვა ან/და თუ მომხმარებელი ან/და
ქვეყანა/ტერიტორია, რომლის რეზიდენტიც არის მომხმარებელი ან რომელშიც მდებარეობს მომხმარებელი,
მოხვდება კონკრეტული სანქცირებული ქვეყნების/ტერიტორიების ან/და პირების სიაში, რომელიც
გამოცემულია/წარმოებულია სანქციების ორგანოს მიერ, მასში დროდადრო შეტანილი ცვლილებების
გათვალისწინებით: (i) KEEPZ.ME უფლებამოსილია იმოქმედოს სანქციების ორგანოს მიერ დაწესებული
შესაბამისი სანქციის მიხედვით და განახორციელოს სანქციების ორგანოს მიერ განსაზღვრული
ან/და შესაბამისი სანქციით გათვალისწინებული ან/და მოთხოვნილი ნებისმიერი და ყველა
ქმედება; და (ii) KEEPZ.ME-ს მოქმედება სანქციების ორგანოს მიერ დაწესებული შესაბამისი
სანქციის მიხედვით ან/და სანქციების ორგანოს მიერ განსაზღვრული ან/და შესაბამისი სანქციით
გათვალისწინებული ან/და მოთხოვნილი ნებისმიერი ქმედების განხორციელება არ ჩაითვლება
KEEPZ.ME-ს მიერ მომხმარებლის წინაშე არსებული/ნაკისრი რაიმე ვალდებულების რაიმე ფორმით
დარღვევად და KEEPZ.ME-ს არ დაეკისრება რაიმე სახის პასუხისმგებლობა სანქციების ორგანოს
მიერ დაწესებული შესაბამისი სანქციის მიხედვით მოქმედებისთვის ან/და სანქციების ორგანოს
მიერ განსაზღვრული ან/და შესაბამისი სანქციით გათვალისწინებული ან/და მოთხოვნილი ნებისმიერი
ქმედების განხორციელებისთვის. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
უფლებამოსილია არ შეასრულოს ფულადი გზავნილის გადარიცხვის ოპერაცია, თუ:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ოპერაცია
არ შეესაბამება კანონმდებლობით დადგენილ მოთხოვნებს ან/და KEEPZ.ME-ში დადგენილ წესებსა
და პროცედურებს (მათ შორის საერთაშორისო საბარათე სქემის მიერ დადგენილ პირობებს);</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ოპერაცია
შეიცავს არაზუსტ ინფორმაციას;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>თუ
KEEPZ.ME-ს  არ მიუღია ინფორმაცია ოპერაციის შესახებ;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ოპერაციის
შესრულებისთვის აუცილებელი თანხა ან მისი ნაწილი დაბლოკილია ან/და ნებისმიერი სხვა
მიზეზით შეუძლებელია მისი გამოყენება;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ოპერაცია
გადააჭარბებს KEEPZ.ME-ს  მიერ დაწესებულ ლიმიტს;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME-ს
აქვს ეჭვი მესამე პირის მიერ აპლიკაციის ან/და ბარათის არამართლზომიერი (მათ შორის
თაღლითურად) გამოყენების შესახებ; </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>საერთაშორისო
საგადახდო სქემის მიერ KEEPZ.ME-სთვის მიწოდებული ინფორმაციის შესაბამისად არსებობს
არაავტორიზებული ოპერაციის შესრულების რისკი ან/და არსებობს საერთაშორისო საგადახდო
სქემის მოთხოვნა ამგვარ ქმედებაზე; </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME-სგან
დამოუკიდებელი მიზეზით შეუძლებელია ოპერაციის შესრულება;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>კანონმდებლობით
გათვალისწინებულ სხვა შემთხვევებში;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.5.10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>არსებობს
სხვა ისეთი ხარვეზი/გარემოება რომელიც შეუძლებელს ხდის ოპერაციის შესრულებას.<a
name="_heading=h.7q1yo2mpbz4m"></a>     </span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;margin-right:0in;
margin-bottom:6.0pt;margin-left:99.0pt;text-align:center;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b><span
style='font-family:"Calibri",sans-serif'><span lang=EN-US style='font-family:
"Sylfaen",serif;color:black'>III. </span></span></b><b><span style='color:black'>ღია</span></b><b><span
style='color:black'> </span></b><b><span style='color:black'>ბანკინგი</span></b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>შესავალი</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მოცემული
თავი განსაზღვრავს KEEPZ.ME-ს მიერ მომხმარებლისათვის გადახდის ინიციირების მომსახურებისა
და ანგარიშის ინფორმაციაზე წვდომის მომსახურების მიღების (“<b>ღია</b><b> </b><b>ბანკინგის</b><b>
</b><b>მომსახურება</b>”) წესებსა და პირობებს. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ღია ბანკინგის
მომსახურება გულისხმობს: </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წვდომა ანგარიშის
ინფორმაციაზე რამდენიმე საბანკო ანგარიშიდან      (&quot;<b>ანგარიშის</b><b> </b><b>ინფორმაციაზე</b><b>
</b><b>წვდომა</b>&quot;); და </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>გადახდების
ინიცირება პირდაპირ მომხმარებლის საბანკო ანგარიშებიდან KEEPZ.ME-ს აპლიკაციის საშუალებით
(„<b>გადახდის</b><b> </b><b>ინიციირება</b>“)     </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>1.2.1 და
1.2.2 მუხლში მითითებული მომსახურებები მომხმარებელს აძლევს საშუალებას სრულად მართოს
და მიიღოს წვდომა მის ფინანსურ ინფორმაციაზე, დააინიციროს ტრანზაქციები და ნახოს ნაშთები
საკუთარ საბანკო ანგარიშებზე KEEPZ.ME აპლიკაციის საშუალებით.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>დასაშვებობა</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>იმისათვის,
რომ მომხმარებელმა მიიღოს ღია ბანკინგის მომსახურება, ის უნდა ჰქონდეს: </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>2.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მოქმედი საბანკო
ანგარიშები საქართველოში რეგისტრირებულ ფინანსურ ინსტიტუტებში; და</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>2.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>საჭირო უფლებამოსილება
და ნებართვები საბანკო ანგარიშების სამართავად. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>განცხადებები</b><b>
</b><b>და</b><b> </b><b>გარანტიები</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომხმარებელი
აცხადებს და იძლევა გარანტიას, რომ:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>აქვს სრული
უფლებამოსილება მართოს KEEPZ.ME-ს აპლიკაციაში ჩართული საბანკო ანგარიშები. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წინამდებარე
დოკუმენტზე თანხმობის განცხადების დროისათვის/საბანკო ანგარიშების KEEPZ.ME აპლიკაციაში
ჩართვის დროისათვის არის/იქნება ქმედუნარიანი (მათ შორის, მოქმედი კანონმდებლობით დადგენილი
წესით);</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წინამდებარე
დოკუმენტზე თანხმობა გამოხატულია/ღია ბანკინგის მომსახურება მიღებულია მის მიერ ნებაყოფლობით,
KEEPZ.ME-ს ან მესამე პირის მხრიდან მის მიმართ ძალადობის, მუქარის, მოტყუების, შეცდომის
ან/და რაიმე სხვა გარემოების გამოყენების გარეშე;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წინამდებარე
დოკუმენტზე თანხმობის გამოხატვა და მისი პირობების შესრულება, ღია ბანკინგის მომსახურების
მიღება არ იწვევს/გამოიწვევს იმ სახელშეკრულებო, სასამართლო (მათ შორის, საარბიტრაჟო
სასამართლო), კანონმდებლობის ან/და სხვა ვალდებულებების დარღვევას, რომელთა დაცვაზეც
ის პასუხისმგებელია;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ღია ბანკინგის
მომსახურებას იღებს ისეთი მოწყობილობის ან/და ინტერნეტ კავშირის გამოყენებით, რომელიც
დაცულია მესამე პირების არასანქცირებული, არავტორიზებული წვდომისგან.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.1.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ბარათი/ბარათის
საიდენტიფიკაციო მონაცემები, რომლის საშუალებითაც საბანკო ანგარიში ერთვება ღია ბანკინგში
არის მის კანონიერ სარგებლობაში/მფლობელობაში და ის არის ბარათის მფლობელი.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომხმარებელი
სრულად არის პასუხისმგებელი: </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მის მიერ
საბანკო ანგარიშის ღია ბანკინგში ჩართვის/ღია ბანკინგის მომსახურების მიღებისას მის
მიერ მითითებული ნებისმიერი სახის ინფორმაციის, მათ შორის ბარათის საიდენტიფიკაციო
მონაცემების, სისწორეზე, სისრულესა და უსაფრთხოებაზე და ამ მოთხოვნების დაუცველობის
შედეგად გამოწვეულ ნებისმიერ შედეგზე, მათ შორის ნებისმიერ ზიანზე (ზარალზე), რომელიც
მიადგა KEEPZ.ME-ს ან/და მომხმარებელს;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ღია ბანკინგში
საბანკო ანგარიშის ჩართვის/ღია ბანკინგის მომსახურების მიღებისას, მის მიერ დაფიქსირებულ
მესამე პირის მონაცემებს (სახელი, გვარი, მობილური ტელეფონის ნომერი) ფლობს მითითებული
პიროვნება და მომხმარებელს მოპოვებული აქვს ამ პირის თანხმობა მისი მონაცემების
KEEPZ.ME-ს მიერ დამუშავების (მათ შორის, KEEPZ.ME-სთვის გადაცემის) შესახებ. ამასთან,
მომხმარებელი ვალდებულია აუნაზღაუროს KEEPZ.ME-ს ამ ქვეპუნქტით განსაზღვრული ვალდებულების
სრულად და ჯეროვნად შეუსრულებლობის გამო KEEPZ.ME-სთვის მიყენებული ზიანი (ზარალი),
მათ შორის, ერთმნიშვნელოვნად, KEEPZ.ME-სთვის ნებისმიერი მესამე პირის მიერ დაკისრებული
გადასახდელი.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>3.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
უფლებამოსილია, კანონმდებლობის მოთხოვნათა დაცვითა და KEEPZ.ME-ში დადგენილი წესებისა
და პროცედურების შესაბამისად, მომხმარებლის მიერ ღია ბანკინგის მომსახურებასთან დაკავშირებით
გადაცემული ინფორმაცია (მათ შორის პერსონალური მონაცემები) დაამუშავოს მომხმარებლის
მიერ სხვა ნებისმიერი პროდუქტებით/მომსახურებით სარგებლობის შემთხვევაში KEEPZ.ME-ს 
უფლებების განხორციელების მიზნით.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'><b>ღია</b><b>
</b><b>ბანკინგის</b><b> </b><b>მომსახურების</b><b> </b><b>პირობები</b></span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ღია ბანკინგის
მომსახურების მიღებისას მომხმარებელი უნდა სარგებლობდეს KEEPZ.ME აპლიკაციით. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.fdbhq5r5qnse"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ანგარიშზე
ინფორმაციის წვდომის ფარგლებში მომხმარებელს ეძლევა საშუალება: </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.5tnycii0f9sy"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>შეამოწმოს
ღია ბანკინგში ჩართულ საბანკო ანგარიშზე არსებული ბალანსი; და</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.nl4lv1xq0eu3"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ჰქონდეს წვდომა
ღია ბანკინგში ჩართულ საბანკო ანგარიშზე განხორციელებულ ტრანზაქციებთან დაკავშირებულ
ინფორმაციაზე; </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.w2thbjq0q6ou"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.2. მუხლის
მიზნებისათვის, KEEPZ ME არ ინახავს მომხმარებლის სენსიტიური საგადახდო მონაცემებს</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.sk1hgjeab7pw"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>გადახდის
ინიციირების ფარგლებში, მომხმარებელს ეძლევა საშუალება: </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><a name="_heading=h.mi1j8hl6tkpu"></a><span
lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.4.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME-ს
აპლიკაციის საშუალებით, ღია ბანკინგში ჩართული ბარათების საშუალებით დააინიციროს გადახდები
მითითებულ საკუთარ ან მესამე პირების საბანკო ანგარიშებზე; </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>4.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
უფლებამოსილია, ყოველ ჯერზე ღია ბანკინგის ფარგლებში ინიციირებული გადახდის ან/და
KEEPZ.ME-ს აპლიკაციაში საბანკო ანგარიშის საიდენტიფიკაციო მონაცემების შენახვის/დამახსოვრებისთვის
მოითხოვოს ოპერაციის შესაბამისი წესით/ფორმით (მათ შორის ერთჯერადი კოდის მეშვეობით)
დადასტურება.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
უფლებამოსილია:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
აპლიკაციაში შესაბამისი ინფორმაციის განთავსების გზით ან/და KEEPZ.ME-სთვის მისაღები
სხვა ფორმით, თუ კანონმდებლობით სხვა რამ არ არის განსაზღვრული, მომხმარებლის დამატებითი
თანხმობისა და აქცეპტის გარეშე, შეცვალოს წინამდებარე დოკუმენტით განსაზღვრული ნებისმიერი
პირობა. ამასთან, ამგვარი ცვლილება სავალდებულოა მომხმარებლებისათვის მისი გამოქვეყნების
დღის მომდევნო საბანკო დღიდან, თუ KEEPZ.ME-ს მიერ ან/და კანონმდებლობით სხვა რამ არ
არის განსაზღვრული.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>5.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ნებისმიერ
დროს, ცალმხრივად, მომხმარებლის დამატებითი შეტყობინების ან/და თანხმობის/აქცეპტის
გარეშე სრულად ან ნაწილობრივ შეწყვიტოს ღია ბანკინგის მომსახურება.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
არ არის პასუხისმგებელი ნებისმიერ ისეთ შეზღუდვაზე ან/და ღია ბანკინგის მომსახურებით
სარგებლობის შეუძლებლობაზე, რომელიც განპირობებულია საბანკო ანგარიშის მომსახურე ფინანსური
ინსტიტუტის მიერ დაწესებული შეზღუდვების/აკრძალვების გამო. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>წინამდებარე
დოკუმენტი არეგულირებს მხოლოდ ღია ბანკინგის მომსახურების წესებს და პირობებს და არ
არეგულირებს მომხმარებელსა და  საბანკო ანგარიშის მომსახურე ფინანსურ ინსტიტუტს შორის
სამართლებრივ ურთიერთობას, მათ შორის ბარათით სარგებლობის პირობებს. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომხმარებელი
და KEEPZ.ME ადასტურებენ და თანხმდებიან, რომ თუ მომხმარებელზე გავრცელდება, ამერიკის
შეერთებული შტატების უცხოური აქტივების კონტროლის სამსახურის, გაერთიანებული სამეფოს
ფინანსური სანქციების იმპლემენტაციის სამსახურის, ევროპის კავშირის ან/და გაერთიანებული
ერების უშიშროების საბჭოს ან/და მათი ნებისმიერი სამართალმემკვიდრის (შემდგომში - <b>სანქციების</b><b>
</b><b>ორგანო</b>) მიერ დაწესებული ნებისმიერი სანქცია ან/და ნებისმიერი სანქციით
გათვალისწინებული ან/და მოთხოვნილი ნებისმიერი შეზღუდვა ან/და აკრძალვა ან/და თუ მომხმარებელი
ან/და ქვეყანა/ტერიტორია, რომლის რეზიდენტიც არის მომხმარებელი ან რომელშიც მდებარეობს
მომხმარებელი, მოხვდება კონკრეტული სანქცირებული ქვეყნების/ტერიტორიების ან/და პირების
სიაში, რომელიც გამოცემულია/წარმოებულია სანქციების ორგანოს მიერ, მასში დროდადრო შეტანილი
ცვლილებების გათვალისწინებით: (i) KEEPZ.ME უფლებამოსილია იმოქმედოს სანქციების ორგანოს
მიერ დაწესებული შესაბამისი სანქციის მიხედვით და განახორციელოს სანქციების ორგანოს
მიერ განსაზღვრული ან/და შესაბამისი სანქციით გათვალისწინებული ან/და მოთხოვნილი ნებისმიერი
და ყველა ქმედება; და (ii) KEEPZ.ME-ს მოქმედება სანქციების ორგანოს მიერ დაწესებული
შესაბამისი სანქციის მიხედვით ან/და სანქციების ორგანოს მიერ განსაზღვრული ან/და შესაბამისი
სანქციით გათვალისწინებული ან/და მოთხოვნილი ნებისმიერი ქმედების განხორციელება არ
ჩაითვლება KEEPZ.ME-ს მიერ მომხმარებლის წინაშე არსებული/ნაკისრი რაიმე ვალდებულების
რაიმე ფორმით დარღვევად და KEEPZ.ME-ს არ დაეკისრება რაიმე სახის პასუხისმგებლობა სანქციების
ორგანოს მიერ დაწესებული შესაბამისი სანქციის მიხედვით მოქმედებისთვის ან/და სანქციების
ორგანოს მიერ განსაზღვრული ან/და შესაბამისი სანქციით გათვალისწინებული ან/და მოთხოვნილი
ნებისმიერი ქმედების განხორციელებისთვის. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
უფლებამოსილია მომხმარებელს არ გაუწიოს ღია ბანკინგის მომსახურება, თუ:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ის არ შეესაბამება
კანონმდებლობით დადგენილ მოთხოვნებს ან/და KEEPZ.ME-ში დადგენილ წესებსა და პროცედურებს
;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მოთხოვნა
მომსახურების გაწევაზე შეიცავს არაზუსტ ინფორმაციას;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>თუ
KEEPZ.ME-ს  არ მიუღია ინფორმაცია მომსახურების გაწევის მოთხოვნის შესახებ;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომსახურების
გაწევისათვის აუცილებელი თანხა ან მისი ნაწილი დაბლოკილია ან/და ნებისმიერი სხვა მიზეზით
შეუძლებელია მისი გამოყენება;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME-ს
აქვს ეჭვი მესამე პირის მიერ აპლიკაციის ან/და საბანკო ანგარიშის არამართლზომიერი
(მათ შორის თაღლითურად) გამოყენების შესახებ; </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME-სგან
დამოუკიდებელი მიზეზით შეუძლებელია ოპერაციის შესრულება;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>კანონმდებლობით
გათვალისწინებულ სხვა შემთხვევებში;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>არსებობს
სხვა ისეთი ხარვეზი/გარემოება რომელიც შეუძლებელს ხდის ოპერაციის შესრულებას.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომსახურების
ტარიფი:</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.9.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>KEEPZ.ME
უფლებამოსილია დაადგინოს ყოველთვიური საფასური 5 ლარის ოდენობით QR კოდით სარგებლობისთვის;</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.9.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>ქართული ბარათებით
გადახდისას საკომისიოს მოცულობა განისაზღვრება: - 1% - 2.5%-ით შუალედში</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.9.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>უცხოური ბარათებით
გადახდისას საკომისიოს მოცულობა განისაზღვრება 2% - 3.2%-ით</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:115%;
layout-grid-mode:char'><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>9.10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="GEO/KAT" style='font-family:"Sylfaen",serif'>მომსახურების
ტარიფი მომხმარებელს აესახება KEEPZ.ME-ს აპლიკაციაში</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;
margin-left:21.3pt;line-height:115%;layout-grid-mode:char'><span lang="GEO/KAT"
style='font-family:"Sylfaen",serif'>&nbsp;</span></p>

</div>

</body>

</html>

`,
      }}
    />
  );
};

export default Ge;
