import * as React from "react"
const PendingIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={81}
        height={80}
        fill="none"
        {...props}
    >
        <path
            fill="#FFB103"
            fillRule="evenodd"
            d="M40.5 80c22.091 0 40-17.909 40-40S62.591 0 40.5 0 .5 17.909.5 40s17.909 40 40 40ZM24 44a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm21-4a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm12.75 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
            clipRule="evenodd"
        />
    </svg>
)
export default PendingIcon
