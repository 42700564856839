export default function EtherumIcon(props) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2575_3482)">
        <path
          d="M31.1875 8.00537V24.3867L45.0326 30.5749L31.1875 8.00537Z"
          fill="#343434"
        />
        <path
          d="M31.1891 8.00537L17.3359 30.5749L31.1891 24.3867V8.00537Z"
          fill="#8C8C8C"
        />
        <path
          d="M31.1875 41.1936V52.3258L45.0406 33.1514L31.1875 41.1936Z"
          fill="#343434"
        />
        <path
          d="M31.1891 52.3256V41.1934L17.3359 33.1592L31.1891 52.3336V52.3256Z"
          fill="#8C8C8C"
        />
        <path
          d="M31.1875 38.6091L45.0326 30.5669L31.1875 24.3867V38.6091Z"
          fill="#141414"
        />
        <path
          d="M17.3359 30.5747L31.1891 38.6169V24.3945L17.3359 30.5747Z"
          fill="#393939"
        />
      </g>
      <defs>
        <clipPath id="clip0_2575_3482">
          <rect
            width="59.1045"
            height="59.1045"
            fill="white"
            transform="translate(0.710938 0.612305)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
