import React from "react";

function Ge() {
  return (
    <div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p className="text-center font-bold">
            შპს “ქიფზ.მი”-ს მომხმარენლებლის პერსონალურ მონაცემთა დამუშავების
            პოლიტიკა
          </p>
          <p>
            <span className="font-bold">შპს “ქიფზ.მი”</span>, კომპანია
            ორგაიზებული და დაფუძნებული საქართველოს კანონმდებლობის შესაბამისად,
            საიდენტიფიკაციო კოდი: 405618915, იურიდიული/ფაქტობრივი მისამართი:
            საქართველო, თბილისი, საბურთალოს რაიონი, მიხეილ თამარაშვილის გამზირი,
            N13თ, საოფისე ფართი N1, სართული 1, ბლოკი "ა". (შემდგომში{" "}
            <span className="font-bold">„ქიფზ.მი”</span>,{" "}
            <span className="font-bold">„კომპანია“</span> ან{" "}
            <span className="font-bold">
              „დამუშავებისთვის პასუხისმგებელი პირი“
            </span>
            ) მომხმარებლებისათვის უზრუნველყოფს ექვაირინგისა და ონლაინ
            გადარიცხვების მომსახურების გაწევას.
          </p>
          <p>
            <span className="font-bold">პრეამბულა</span>
          </p>
          <p>ქიფზ.მი:</p>
          <p>
            (1) პატივს სცემს და აღიარებს პერსონალური მონაცემების დამუშავებისას
            ადამიანის ძირითად უფლებებსა და თავისუფლებებს, მათ შორის, პირადი
            ცხოვრებისა და კომუნიკაციის ხელშეუხებლობის უფლებებს;
          </p>
          <p>
            (2) კისრულობს პასუხისმგებლობას პერსონალურ მონაცემთა დამუშავებისას
            დაიცვას მოქმედი კანონმდებლობა;
          </p>
          <p>
            (3) უზრუნველყოფს პერსონალური მონაცემების სათანადო დაცვასა და
            უსაფრთხოებას;
          </p>
          <p>
            (4) გამოიყენებს პერსონალურ მონაცემებს მხოლოდ მართლზომიერად და
            კეთილსინდისიერად;
          </p>
          <p>
            (5) ნებისმიერ დროს მიაწვდის მომხმარებელს სრულ და ამომწურავ
            ინფორმაციას მისი პერსონალური მონაცემების დამუშავებასთან
            დაკავშირებით.
          </p>
          <p className="font-bold">
            1.<b> </b>პოლიტიკის მიზანი და მოქმედების სფერო
          </p>
          <p>
            1.1. პერსონალურ მონაცემთა დამუშავების პოლიტიკა (შემდგომში
            „პერსონალურ მონაცემთა დამუშავების პოლიტიკა“ ან „პოლიტიკა“) ვრცელდება
            ნებისმიერ მომხმარებელზე, რომელიც რეგისტრირებულია და იყენებს
            ქიფზ.მი-ს მიერ შეთავაზებულ ნებისმიერი სახის მომსახურებას.
          </p>
          <p>
            1.2. პოლიტიკის მიზანია მაქსიმალურად მარტივად, ნათლად და გასაგებას
            აღწეროს ქიფზ.მი-ს მიერ პერსონალური მონაცემების დამუშავების პროცესი,
            მათ შორის, რა სახეობის პერსონალური მონაცემები მუშავდება და როგორ
            ხდება მათი შეგროვება, რა საფუძვლითა და მიზნით ხდება პერსონალური
            მონაცემების დამუშავება და ვისთან ხდება მათი გაზიარება, რა უფლებები
            და სამართლებრივი დაცვის მექანიზმები გააჩნია მომხმარებელს პერსონალურ
            მონაცემთა დაცვასთან დაკავშირებით, ასევე, როგორ ხდება პერსონალურ
            მონაცემთა უსაფრთხოების უზრუნველყოფა.
          </p>
          <p className="font-bold">
            2.<b> </b>ტერმინთა განმარტება{" "}
          </p>
          <p>
            პოლიტიკაში გამოყენებულ ტერმინებს აქვთ „პერსონალურ მონაცემთა დაცვის
            შესახებ“ საქართველოს კანონით და სხვა საკანონმდებლო აქტებით
            განსაზღვრული მნიშვნელობა.{" "}
          </p>
          <p className="font-bold">
            პოლიტიკაში გამოყენებულ ტერმინებს და განმარტებებს აქვთ ქვემოთ
            განსაზღვრული მნიშვნელობა, გარდა იმ შემთხვევისა, თუ პოლიტიკის
            კონტექსტიდან აშკარად სხვა რამ არ გამომდინარეობს:{" "}
          </p>
          <p>
            <span className="font-bold">
              2.1. შპს ქიფზ.მი , კომპანია ან დამუშავებისათვის პასუხისმგებელი
              პირი
            </span>{" "}
            &#8211; შპს “ქიფზ.მი”, კომპანია ორგანიზებული და დაფუძნებული
            საქართველოს კანონმდებლობის შესაბამისად, საიდენტიფიკაციო კოდი:
            405618915, იურიდიული/ფაქტობრივი მისამართი: საქართველო, თბილისი,
            საბურთალოს რაიონი, მიხეილ თამარაშვილის გამზირი, N13თ, საოფისე ფართი
            N1, სართული 1, ბლოკი &quot;ა&quot;;{" "}
          </p>
          <p>
            <span className="font-bold">
              2.2. მომხმარებელი ან მონაცემთა სუბიექტი
            </span>{" "}
            &#8211; ფიზიკური ან/და იურიდიული პირი, რომელიც სარგებლობს ან
            სარგებლობდა კომპანიის სერვისებით და რომლის პერსონალურ მონაცემებს
            წინამდებარე მომსახურების შეთავაზებისა და გაწევის მიზნებისათვის
            ამუშავებს ქიფზ.მი.{" "}
          </p>
          <p>
            <span className="font-bold">
              2.3. პერსონალური მონაცემი ან მონაცემი
            </span>{" "}
            &#8211; ნებისმიერი ინფორმაცია, რომელიც იდენტიფიცირებულ ან
            იდენტიფიცირებად მომხმარებელს უკავშირდება. მომხმარებელი
            იდენტიფიცირებადია, როდესაც შესაძლებელია შესაძლებელია მომხმარებლის
            იდენტიფიცირება პირდაპირ ან არაპირდაპირ, მათ შორის, სახელით, გვარით,
            საიდენტიფიკაციო ნომრით, გეოლოკაციის მონაცემებით, ელექტრონული
            კომუნიკაციის მაიდენტიფიცირებელი მონაცემებით, ფიზიკური, ფიზიოლოგიური,
            ფსიქიკური, ფსიქოლოგიური, გენეტიკური, ეკონომიკური, კულტურული ან
            სოციალური მახასიათებლით.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <span className="font-bold">2.4. მონაცემთა დამუშავება</span> &#8211;
            პერსონალურ მონაცემთა მიმართ შესრულებული ნებისმიერი მოქმედება, მათ
            შორის, მათი შეგროვება, მოპოვება, მათზე წვდომა, ორგანიზება,
            დაჯგუფება, ურთიერთდაკავშირება, შენახვა, შეცვლა, აღდგენა, გამოთხოვა,
            გამოყენება, დაბლოკვა, წაშლა ან განადგურება, აგრეთვე, პერსონალურ
            მონაცემთა გამჟღავნება მათი გადაცემით, გასაჯაროებით, გავრცელებით ან
            სხვაგვარად ხელმისაწვდომად გახდომით;{" "}
          </p>
          <p>
            <span className="font-bold">
              2.5. თანადამუშავებისთვის პასუხისმგებელი პირები
            </span>{" "}
            &#8211; დამუშავებისთვის პასუხისმგებელი ორი ან ორზე მეტი პირი,
            რომლებიც ერთობლივად განსაზღვრავენ პერსონალურ მონაცემთა დამუშავების
            მიზნებსა და საშუალებებს;{" "}
          </p>
          <p>
            <span className="font-bold">
              2.6. დამუშავებაზე უფლებამოსილი პირი
            </span>{" "}
            &#8211; ფიზიკური პირი (გარდა “ქიფზ.მი”-ს დასაქმებულებისა), იურიდიული
            პირი ან საჯარო დაწესებულება, რომელიც პერსონალურ მონაცემებს ამუშავებს
            შპს “ქიფზ.მი”-სათვის ან შპს “ქიფზ.მი”-ს სახელით;{" "}
          </p>
          <p>
            <span className="font-bold">2.7. მონაცემთა მიმღები</span> &#8211;
            ფიზიკური პირი, იურიდიული პირი ან საჯარო დაწესებულება, რომელსაც
            მონაცემები გადაეცა, გარდა პერსონალურ მონაცემთა დაცვის სამსახურისა;{" "}
          </p>
          <p>
            <span className="font-bold">2.8. პირდაპირი მარკეტინგი</span> &#8211;
            მომხმარებლისათვის ტელეფონის, ფოსტის, ელექტრონული ფოსტის ან სხვა
            ელექტრონული საშუალებით, ინფორმაციის პირდაპირი და უშუალო მიწოდება
            ფიზიკური პირის ან/და იურიდიული პირის, საქონლის, იდეის, მომსახურების,
            სამუშაოს ან/და წამოწყების, აგრეთვე საიმიჯო და სოციალური
            თემატიკისადმი ინტერესის ფორმირების, შენარჩუნების, რეალიზაციის ან/და
            მხარდაჭერის მიზნით.{" "}
          </p>
          <p>
            <span className="font-bold">2.9. პროფაილინგი</span> &#8211;
            პერსონალურ მონაცემთა ავტომატური დამუშავების ნებისმიერი ფორმა,
            რომელიც გულისხმობს პერსონალური მონაცემების გამოყენებას ფიზიკურ
            პირთან დაკავშირებული გარკვეული პიროვნული მახასიათებლების
            შესაფასებლად, კერძოდ, იმ მახასიათებლების ანალიზსა და პროგნოზირებას,
            რომლებიც შეეხება ფიზიკური პირის მიერ სამუშაოს შესრულების ხარისხს,
            მის ეკონომიკურ მდგომარეობას, ჯანმრთელობას, პირად ინტერესებს,
            სანდოობას, ქცევას, ადგილსამყოფელს ან გადაადგილებას;{" "}
          </p>
          <p>
            <span className="font-bold">2.10. სამუშაო დღე</span> &#8211;
            კალენდარული დღები, შაბათის, კვირისა და საქართველოს კანონმდებლობით
            განსაზღვრული უქმე დღეებისა.{" "}
          </p>
          <p className="font-bold">
            3.<b> </b>პერსონალური მონაცემების დამუშავების მიზანი{" "}
          </p>
          <p>
            3.1. შპს “ქიფზ მი” მომხმარებელთა პერსონალურ მონაცემებს ამუშავებს
            მომსახურების ეფექტურად და სწარაფად გაწევის მიზნებისათვის, კერძოდ:{" "}
          </p>
          <p>
            3.1.1. მომხმარებლის რეგისტრაცია კომპანიის ვებ-გვერდზე ან/და
            აპლიკაციის საშუალებით;
          </p>
          <p>
            3.1.2. მომხმარებლისათვის QR კოდისა და დეშბორდის მომზადება
            გადარიცხვებზე ინფორმაციის ასახვის მიზნებისათვის;
          </p>
          <p>
            3.1.3. სსიპ საქართველოს ეროვნული ბანკის მიერ დაწესებული საგადახდო
            სისტემის მომსახურებისა და ღია ბანკინგის რეგულაციების დაცვის
            მიზნებისათვის;{" "}
          </p>
          <p>
            3.1.4. .მომხმარებელთა ვინაობის შემოწმება/ვერიფიცირება პერსონალურ
            მონაცემთა სიზუსტისა ან/და განახლების, ასევე, მონაცემთა უსაფრთხოების
            უზრუნველყოფის მიზნით და ამ მიზნით მომხმარებელთან კომუნიკაცია ზეპირი
            ან წერილობითი (ელექტრონული) ფორმით;{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            3.1.5. მომხმარებლის განცხადებების, რეკლამაციების, პრეტენზიების ან/და
            საჩივრების განხილვა და გადაწყვეტა, მათ შორის, ამ მიზნით
            მომხმარებელთან კომუნიკაცია;{" "}
          </p>
          <p>
            3.1.6. მომსახურებისა და სერვისების/პროდუქტების ხარისხის
            გაუმჯობესებისა და განვითარების მიზნით, მომხმარებლის კმაყოფილებისა და
            სხვა კვლევების განხორციელება და ამ მიზნით მომხმარებელთან კომუნიკაცია
            ზეპირი ან წერილობითი (ელექტრონული) ფორმით;{" "}
          </p>
          <p>
            3.1.7. მომხმარებლისათვის საინტერესო სერვისების/პროდუქტების
            იდენტიფიცირება, განვითარება ან/და პერსონალიზირებული, მომხმარებლის
            ინტერესებსა და პრეფერენციებზე მორგებული შეთავაზებისთვის პერსონალურ
            მონაცემთა ანალიზი და მათი დაჯგუფება/სეგმენტაცია;{" "}
          </p>
          <p>
            3.1.8. მომსახურების გახორციელების მიზნით, კომპანიის მიერ კანონიერი
            მოვალეობებისა ან/და სახელშეკრულებო ვალდებულებების ეფექტიანად
            შესრულება.{" "}
          </p>
          <p className="font-bold">
            4.<b> </b>პერსონალურ მონაცემთა დამუშავების საფუძველი და ფორმა{" "}
          </p>
          <p>
            4.1. მომხმარებლის პერსონალური მონაცემების დამუშავებას კომპანია
            ახორციელებს მაშინ, როდესაც არსებობს ერთ-ერთი შემდეგი საფუძველი:{" "}
          </p>
          <p>
            4.1.1. .მომხმარებელმა შპს “ქიფზ.მ”-ს პორტალზე რეგისტრაციის
            საშუალებით განაცხადა თანხმობა მის შესახებ პერსონალურ მონაცემთა
            მომსახურების გაწევის მიზნისათვის დამუშავებაზე;{" "}
          </p>
          <p>
            4.1.2. პერსონალური მონაცემების დამუშავება აუცილებელია მომსახურების
            გაწევის მიზნისათვის მომხმარებელთან დადებული გარიგებით ნაკისრი
            ვალდებულების შესასრულებლად ან მომხმარებლის მოთხოვნით გარიგების
            დასადებად;{" "}
          </p>
          <p>
            4.1.3. პერსონალურ მონაცემთა დამუშავება აუცილებელია კომპანიის
            მომსახურების გაწევასან დაკავშირებით მომხმარებლის განცხადების
            განსახილველად;{" "}
          </p>
          <p>
            4.1.4. პერსონალური მონაცემების დამუშავება საჭიროა კომპანიის მიერ
            საქართველოს კანონმდებლობით მისთვის დაკისრებული მოვალეობების
            შესასრულებლად;{" "}
          </p>
          <p>
            4.1.5. პერსონალური მონაცემების დამუშავება აუცილებელია კომპანიის ან
            მესამე პირის მნიშვნელოვანი ლეგიტიმური ინტერესების დასაცავად, გარდა
            იმ შემთხვევისა, თუ არსებობს მომხმარებლის უფლებების დაცვის აღმატებული
            ინტერესი;{" "}
          </p>
          <p>
            4.2. მომხმარებლის პერსონალური მონაცემების დამუშავება ასევე შესაძლოა
            განხორციელდეს ერთი-ერთი შემდეგი საფუძვლით:{" "}
          </p>
          <p>
            4.2.1. პერსონალური მონაცემების დამუშავება გათვალისწინებულია კანონით;{" "}
          </p>
          <p>
            4.2.2. კანონის თანახმად, პერსონალური მონაცემი საჯაროდ ხელმისაწვდომია
            ან მომხმარებელმა იგი საჯაროდ ხელმისაწვდომი გახადა;{" "}
          </p>
          <p>
            4.2.3. პერსონალური მონაცემების დამუშავება აუცილებელია მნიშვნელოვანი
            საჯარო ინტერესის დასაცავად;{" "}
          </p>
          <p>
            4.2.4. პერსონალური მონაცემების დამუშავება აუცილებელია საქართველოს
            კანონმდებლობით განსაზღვრული საჯარო ინტერესის სფეროსთვის მიკუთვნებული
            ამოცანების შესასრულებლად, მათ შორის, დანაშაულის თავიდან აცილების,
            დანაშაულის გამოძიების, სისხლისსამართლებრივი დევნის, მართლმსაჯულების
            განხორციელების, პატიმრობისა და თავისუფლების აღკვეთის აღსრულების,
            არასაპატიმრო სასჯელთა აღსრულებისა და პრობაციის, ოპერატიულ-სამძებრო
            საქმიანობის, საზოგადოებრივი უსაფრთხოების, მართლწესრიგის დაცვის, მათ
            შორის, ინფორმაციული უსაფრთხოებისა და კიბერუსაფრთხოების
            უზრუნველყოფის, მიზნებისთვის.{" "}
          </p>
          <p>
            4.3.{" "}
            <span className="font-bold">
              პერსონალური მონაცემების დამუშავება ხორციელდება ნახევრად ავტომატური
              საშუალებებით,
            </span>{" "}
            რაშიც იგულისხმება, რომ კომპანია პერსონალურ მონაცემებს ამუშავებს
            ავტომატური საშუალებების (მონაცემთა დამუშავება ინფორმაციული
            ტექნოლოგიების გამოყენებით) და არაავტომატური საშუალებების (მონაცემთა
            დამუშავება ინფორმაციული ტექნოლოგიების გამოყენების გარეშე) ერთობლივი
            გამოყენებით.{" "}
          </p>
          <p className="font-bold">
            5.<b> </b>დამუშავებული პერსონალური მონაცემების კატეგორიები და
            მოპოვების წყაროები{" "}
          </p>
          <p>
            5.1. კომპანია მომხმარებლების მონაცემებს მოიპოვებს შემდეგი
            საშუალებებით:{" "}
          </p>
          <p>
            5.1.1. უშუალოდ მომხმარებლისაგან შეგროვებით, რომლებსაც მომხმარებელი
            ამჟღავნებს კომპანიის ვებ-გვერდზე ან/და აპლიკაციაზე რეგისტრაციისას.{" "}
          </p>
          <p>
            5.1.2. მომხმარებელთან კომუნიკაციის საშუალებით, მათ შორის, სატელეფონო
            კავშირის, ელექტრონული ფოსტით მიმოწერის ან სხვა გზით, მომსახურების
            გაწევასთან დაკავშირებული ინფორმაციის მოწოდების შესახებ მისი
            მოთხოვნის განხილვისას;{" "}
          </p>
          <p>
            5.1.3. მომხმარებლის განცხადებების, რეკლამაციების, პრეტენზიის ან/და
            საჩივრების განხილვის პროცესში;{" "}
          </p>
          <p>
            5.1.4.მომხმარებელთან კომუნიკაციისას, რომელცი უკავშირდება კომპანიის
            მიერ მომსახურების გაწევასა და მისი სერვისების/პროდუქტების ხარისხის
            გაუმჯობესებისა და განვითარების მიზნით კმაყოფილებისა და სხვა
            კვლევების განხორციელებას, კომპანიის მიერ დასმულ კითხვებზე
            მომხმარებლის მიერ გაცემულ პასუხების სახით, მათ შორის, ინფორმაციას
            მომსახურების ხარისხის, კმაყოფილებისა და მოთხოვნების თაობაზე;{" "}
          </p>
          <p>
            5.2. შეგროვებული მონაცემები, მათ შორის, მოიცავს ნებისმიერ მონაცემს,
            რომლითაც ხდება მომხმარებლის იდენტიფიცირება და მასთან კომუნიკაცია
            (სახელი, გვარი, პირადი ნომერი, დაბადების თარიღი, ასაკი, მისამართი,
            ტელეფონის ნომერი, ელექტრონული ფოსტის მისამართი და სხვა
            მაიდენტიფიცირებელი/საკონტაქტო ინფორმაცია).{" "}
          </p>
          <p>
            5.3. პერსონალური მონაცემის მოწოდებამდე, კომპანია უზრუნველყოფს
            მომხმარებლის სათანადო ინფორმირებას არის თუ არა კონკრეტული
            პერსონალური მონაცემის კომპანიისათვის მოწოდება სავალდებულო, თუ მხოლოდ
            ნებაყოფლობით ხასიათს ატარებს:{" "}
          </p>
          <p>
            5.3.1. კომპანია აგროვებს და ამუშავებს მომხმარებლის ისეთი სახეობის
            პერსონალურ მონაცემებს,{" "}
            <span className="underline">
              რომელთა მიწოდება სავალდებულოა და მათი მოწოდების გარეშე
              მომხმარებელი ვერ ჩაერთვება და ვერ ისარგებლებს კომპანიის
              მომსახურებით
            </span>
            (მაგალითად, სახელი, გვარი, პირადი ნომერი და სხვ.);{" "}
          </p>
          <p>
            5.3.2. კომპანია აგროვებს და ამუშავებს მომხმარებლის ისეთის სახეობის
            პერსონალურ მონაცემებს, რომელთა მიწოდება არ არის სავალდებულო და
            მომხმარებელი{" "}
            <span className="underline">
              ამჟღავნებს საკუთარი სურვილის შესაბამისად.
            </span>{" "}
          </p>
          <p>
            5.4. კომპანიამ შეიძლება შეაგროვოს და დაამუშაოს მესამე პირებისაგან
            მიღებულიპერსონალური მონაცემები, მხოლოდ იმ შემთხვევაში, თუ აღნიშნულზე
            მომხმარებელს გაცემული აქვს თანხმობა.{" "}
          </p>
          <p>
            5.5. კომპანიამ შეიძლება შეაგროვოს და დაამუშაოს მომხმარებლის
            პერსონალური მონაცემები ასევე, საჯაროდ ხელმისაწვდომი წყაროებიდან
            (არსებობის შემთხვევაში).{" "}
          </p>
          <p>
            <span className="font-bold">
              5.7. იმ მომენტიდან როცა მომხმარებელი რეგისტრირდება კომპანიის
              ვებ-გვერდზე ან აპლიკაციაზე, კომპანია ახორციელებს მომხმარებელთა
              შემდეგი კატეგორიის პერსონალურ მონაცემთა დამუშავებას:
            </span>{" "}
          </p>
          <p>
            <span className="font-bold">
              5.5.1. საიდენტიფიკაციო და საკონტაქტო მონაცემი
            </span>{" "}
            - ინფორმაცია, რომლითაც ხდება მომხმარებლის იდენტიფიცირება და
            კომუნიკაცია (მაგალითად, სახელი და გვარი, მისამართი, ტელეფონის
            ნომერი, ელექტრონული ფოსტის მისამართი, კოდური სიტყვა, პირადობის
            ნომერი, დაბადების თარიღი, ასაკი და სხვა);{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <span className="font-bold">5.5.2. პირადი მახასიათებელი</span> -
            ინფორმაცია, რომელიც უკავშირდება მომხმარებლის სპეციფიკურ
            მახასიათებლებს/ნიშან-თვისებებს (მაგალითად, ასაკი, სქესი, დაბადების
            თარიღი და სხვა);{" "}
          </p>
          <p>
            <span className="font-bold">5.5.3. ქცევითი მონაცემი</span> -
            ინფორმაცია, რომელიც უკავშირდება მომხმარებლის მიერ კომპანიის
            სერვისებით/პროდუქტებით სარგებლობის ისტორიას;{" "}
          </p>
          <p>
            <span className="font-bold">5.5.4. ფინანსური მონაცემი</span> -
            ინფორმაცია, რომელი უკავშირდება მომხმარებლის მომხმარებლის საბანკო
            ბარათით სარგებლობას და გადახდებს, მათ შორის, მაგრამ არამხოლოდ,
            მომხმარებლის ანგარიშის ნომრები, iban, და სხვ. საჭიროების შემთხვევაში
            ასევე მონაცემი მომხმარებლის ყოველთვიური ბრუნვის, ტრანზაქციებისა და
            საქმიანობის შესახებ.{" "}
          </p>
          <p>
            <span className="font-bold">5.5.5. საკომუნიკაციო მონაცემი</span> -
            ინფორმაცია/ჩანაწერი, რომელიც უკავშირდება მომხმარებლის კომპანიასთან
            კომუნიკაციას/აქტივობას;{" "}
          </p>
          <p>
            <span className="font-bold">5.5.6. დოკუმენტური მონაცემი</span> -
            მატერიალური ან ელექტრონული ფორმის დოკუმენტები, სადაც ასახულია
            პერსონალური მონაცემები (მაგალითად, პასპორტი, პირადობის
            დამადასტურებელი საბუთი, დაბადების მოწმობა და სხვა);
          </p>
          <p>
            <span className="font-bold">5.5.7. შიდა იდენტიფიკატორი</span> -
            ინფორმაცია მომხმარებლის დეშბორდის თაობაზე;
          </p>
          <p>
            <span className="font-bold">5.5.8. სახელშეკრულებო მონაცემი</span> -
            ინფორმაცია კომპანიის მიერ მომხმარებლისათვის მიწოდებული
            სერვისების/პროდუქტების შესახებ;{" "}
          </p>
          <p>
            <span className="font-bold">
              5.5.9. კვლევასთან დაკავშირებული მონაცემი
            </span>{" "}
            - ინფორმაცია,მათ შორის, შეფასებები, რომელიც უკავშირდება კომპანიის
            მომსახურებასა და მისი სერვისების/პროდუქტების ხარისხის გაუმჯობესებისა
            და განვითარების მიზნით კმაყოფილებისა და სხვა კვლევების
            განხორციელების პროცესში, კომპანიის მიერ დასმულ კითხვეზე მომხმარებლის
            გაცემულ პასუხებს, მათ შორის, ინფორმაციას მომსახურების ხარისხის,
            კმაყოფილებისა და მოთხოვნების თაობაზე;{" "}
          </p>
          <p>
            <span className="font-bold">5.5.10. სეგმენტაციის მონაცემი</span> -
            ინფორმაცია, რომელიც გამოიყენება მომხმარებლების სხვადასხვა
            სეგმენტებში ან ჯგუფებში გადასანაწილებლად, პროფაილინგისათვის
            (მაგალითად, მოხმარების ჩვევები, პირადი ინტერესები და სხვა).{" "}
          </p>
          <p>
            <b>6. პერსონალურ მონაცემთა შენახვის ვადა </b>
          </p>
          <p>
            6.1. პერსონალური მონაცემების შენახვა ხორციელდება მომხმარებლის მიერ
            კომპანიის ვებ-გვერდზე ან/და აპლიკაციაზე რეგისტრაციის ვადით და
            აქტიური პროფილის ნებისმიერი მიზეზით გაუქმებიდან ან პერსონალური
            მონაცემების დამუშავებაზე თანხმობის გამოხმობის/უარის თქმის
            მომენტიდან, მომდევნო 3 წლის ვადით.{" "}
          </p>
          <p>
            6.2. კანონმდებლობით გათვალისწინებულ შემთხვევებში ან/და თუ არსებობს
            დამუშავებისთვის პასუხისმგებელი პირის ან მესამე პირის მნიშვნელოვანი
            ლეგიტიმური ინტერესების დაცვის აუცილებლობა, პერსონალური მონაცემების
            შენახვა განხორციელდება ზემოთ მითითებული 3 წლიან ვადაზე უფრო
            ხანგძლივი, კონკრეტული მიზნების მიღწევისათვის საჭირო ვადით.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            6.3. ზემოთ აღნიშნული მონაცემთა შენახვის ვადის გასვლის ან/და იმ
            მიზნის მიღწევის შემდეგ, რომლისთვისაც მუშავდება პერსონალური
            მონაცემები, მონაცემები შეიძლება შენახულ იქნეს
            სტატისტიკური/ანალიტიკური მიზნებისათვის, დეპერსონალიზებული ფორმით.
            აღნიშნული გულისხმობს მონაცემთა იმგვარ დამუშავებას, როდესაც
            შეუძლებელია მომხმარებელთან მათი დაკავშირება ან ასეთი კავშირის
            დადგენა არაპროპორციულად დიდ ძალისხმევას, ხარჯებს ან/და დროს
            საჭიროებს.{" "}
          </p>
          <p>
            <b>7. პერსონალურ მონაცემთა გადაცემა მესამე პირებზე</b>
          </p>
          <p>
            7.1. შესაბამისი კანონიერი საფუძვლისა და მიზნობრიობის არსებობისას,
            მომხმარებლის პერსონალურ მონაცემები კომპანიამ შეიძლება გადასცეს
            შემდეგ მესამე პირებს (მონაცემთა მიმღებებს):{" "}
          </p>
          <p>
            7.1.1. მომსახურების/სახელშეკრულებო ვალდებულებების შესრულების მიზნით,
            პერსონალური მონაცემები შეიძლება გადაეცეს მომსახურების გამწევ
            პირებს/კონტრაქტორებს/პარტნიორ ორგანიზაციებს. მაგალითად,
            საინფორმაციო-ტექნოლოგიური მომსახურების მიმწოდებლები, პროგრამული
            მხარდაჭერის მიმწოდებლები, პროგრამული უზრუნველყოფის მიმწოდებლები,
            პირდაპირი მარკეტინგის ფარგლებში მომსახურების მიმწოდებლები, მობილური
            საკომუნიკაციო მომსახურების მიმწოდებელი, სარეკლამო სააგენტოები,
            საგადახდო მომსახურების პროვაიდერები, საბანკო-საფინანსო
            დაწესებულებები ან/და იურიდიული მომსახურების მიმწოდებელი, პარტნიორი
            ორგანიზაციები/პირები, რომლებთან ერთად კომპანია მართავს ერთობლივ
            პროექტებს, ან/და სხვა ღონისძიებებს.{" "}
            <span className="underline">
              ასევე , შესაბამისი კანონიერი საფუძვლისა და მიზნობრიობის
              არსებობისას, პერსონალური მონაცემები შესაძლოა გადაეცეს
              სსიპ.სახელწიფო სერვისების განვითარების სააგენტოს, სსიპ საქართველოს
              ეროვნულ ბანკს ან სხვა საჯარო დაწესებულებას.
            </span>{" "}
          </p>
          <p>
            7.1.2. კანონით დადგენილ შემთხვევებში და როცა ეს საჭიროა
            კანონმდებლობით დაკისრებული მოვალეობის შესრულებისათვის, პერსონალური
            მონაცემები შეიძლება გადაეცეს სხვა მესამე პირებს. მაგალითად,
            სამართალდამცავი/საგამოძიებო ორგანოები, საგადასახადო ორგანოები,
            ადმინისტრაციული ორგანოები, სასამართლო ან/და სხვა უფლებამოსილი
            პირები.
          </p>
          <p>
            7.1.3. მნიშვნელოვანი ლეგიტიმური ინტერესების დაცვის მიზნით,
            პერსონალური მონაცემები შეიძლება გადაეცეს სხვა მესამე პირებს.
            მაგალითად, დანაშაულის ან სამართალდარღვევის გამოსავლენად, აღსაკვეთად
            და მისი პრევენციის მიზნით, პერსონალური მონაცემები შესაძლოა გადაეცეს
            სამართალდამცავ ორგანოებს, სასამართლოს ან/და ადმინისტრაციულ
            ორგანოებს.{" "}
          </p>
          <p>
            7.1.4. საწარმოს რეორგანიზაციისა ან გადაცემის მიზნით, პერსონალური
            მონაცემები შეიძლება გადაეცეს ამ საწარმოს სამართალმემკვიდრეებს ან
            უფლებამონაცვლეებს.{" "}
          </p>
          <p>
            7.2. პერსონალური მონაცემები შეიძლება გადაეცეს საქართველოს საზღვრებს
            გარეთ არსებულ მესამე პირებს მხოლოდ იმ შემთხვევაში, თუ მოქმედი
            კანონმდებლობის შესაბამისად ამ ქვეყანაში არსებობს მონაცემთა დაცვის
            სათანადო გარანტიები. სხვა შემთხვევაში, პერსონალური მონაცემების სხვა
            სახელმწიფოში გადაცემა დასაშვებია, თუ პერსონალურ მონაცემთა დაცვის
            სათანადო გარანტიების არარსებობისა და შესაძლო საფრთხეების შესახებ
            ინფორმაციის მიღების შემდეგ, მომხმარებელი განაცხადებს წერილობით
            თანხმობას, არსებობს პერსონალურ მონაცემთა დაცვის სამსახურის მიერ
            გაცემული ნებართვა ან აღნიშნული გათვალისწინებულია კანონმდებლობით.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <b>
              8. პერსონალურ მონაცემებთან დაკავშირებული უფლებები და მათი
              გამოყენება
            </b>{" "}
          </p>
          <p>
            8.1. უფლება პერსონალურ მონაცემთა დამუშავების შესახებ ინფორმაციის
            მიღებაზე
          </p>
          <p>
            8.1.1. მომხმარებელს უფლება აქვს მოსთხოვოს კომპანიას იმის
            დადასტურება, ამუშავებს თუ არა მის პერსონალურ მონაცემებს,
            დასაბუთებულია თუ არა პერსონალურ მონაცემთა დამუშავება და მოთხოვნის
            შესაბამისად უსასყიდლოდ მიიღოს შემდეგი ინფორმაცია:{" "}
          </p>
          <p>
            (1) მის შესახებ იმ პერსონალური მონაცემის თაობაზე, რომელიც მუშავდება,
            აგრეთვე, ამ პერსონალური მონაცემის დამუშავების საფუძვლისა და მიზნის
            შესახებ;
          </p>
          <p>(2) პერსონალურ მონაცემთა შეგროვების/მოპოვების წყაროს შესახებ; </p>
          <p>
            (3) პერსონალურ მონაცემთა შენახვის ვადის (დროის) შესახებ, ხოლო თუ
            კონკრეტული ვადის განსაზღვრა შეუძლებელია, ვადის განსაზღვრის
            კრიტერიუმების თაობაზე;
          </p>
          <p>
            (4)ინფორმაცია პერსონალურ მონაცემთა დაცვასთან დაკავშირებული ყველა იმ
            უფლების თაობაზე, რომელიც მომხმარებელს კანონმდებლობით აქვს მინიჭებული
            ან ამ პოლიტიკით არის გათვალისწინებული;
          </p>
          <p>
            (5) პერსონალურ მონაცემთა გადაცემის სამართლებრივი საფუძვლისა და
            მიზნების, აგრეთვე მონაცემთა დაცვის სათანადო გარანტიების შესახებ, თუ
            პერსონალური მონაცემები გადაეცემა სხვა სახელმწიფოს ან საერთაშორისო
            ორგანიზაციას;{" "}
          </p>
          <p>
            (6) პერსონალურ მონაცემთა მიმღების ვინაობის ან მონაცემთა მიმღებების
            კატეგორიების შესახებ, მათ შორის, ინფორმაცია პერსონალურ მონაცემთა
            გადაცემის საფუძვლისა და მიზნის თაობაზე, თუ პერსონალური მონაცემები
            მესამე პირს გადაეცემა;{" "}
          </p>
          <p>
            (7) ავტომატიზებული დამუშავების, მათ შორის, პროფაილინგის შედეგად
            მიღებული გადაწყვეტილების და იმ ლოგიკის შესახებ, რომელიც გამოიყენება
            ამგვარი გადაწყვეტილების მისაღებად, აგრეთვე პერსონალურ მონაცემთა
            დამუშავებაზე მისი გავლენისა და დამუშავების მოსალოდნელი/სავარაუდო
            შედეგის თაობაზე.{" "}
          </p>
          <p>
            8.1.2. თუ კანონმდებლობით სხვა რამ არ არის
            გათვალისწინებული,მომხმარებელს უფლება აქვს თავად აირჩიოს პერსონალურ
            მონაცემთა დამუშავების შესახებ ინფორმაციის მიწოდების ფორმა.
            ამასთანავე, თუ მომხმარებელი სხვა ფორმით არ მოითხოვს ინფორმაციის
            მიწოდებას, მისთვის ინფორმაციის მიწოდება მოხდება იმავე ფორმით,
            რომლითაც მოხდა ინფორმაციის მოთხოვნა.{" "}
          </p>
          <p>
            8.1.3. მომხმარებელს უფლება აქვსპერსონალურ მონაცემთა დამუშავების
            შესახებ ინფორმაცია მიიღოს მისი მოთხოვნიდან არაუგვიანეს 10 (ათი)
            სამუშაო დღისა. ეს ვადა განსაკუთრებულ შემთხვევებში და სათანადო
            დასაბუთებით შეიძლება გაგრძელდეს არაუმეტეს 10 (ათი) სამუშაო დღით, რის
            შესახებაც მომხმარებელს დაუყოვნებლივ ეცნობება.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            8.1.4. გამჭვირვალობის პრინციპის უზრუნველყოფის მიზნით, კომპანია
            მომხმარებელს მიაწვდის პერსონალურ მონაცემთა დამუშავებასთან
            დაკავშირებულ ყველა საჭირო ინფორმაციას, გარდა იმ შემთხვევისა, როდესაც
            ინფორმაციის გაცემა ეწინააღმდეგება კანონს.{" "}
          </p>
          <p>8.2. უფლება პერსონალურ მონაცემთა გაცნობასა და ასლის მიღებაზე.</p>
          <p>
            8.2.1.მომხმარებელს უფლება აქვს გაეცნოს იმ პერსონალურ მონაცემებს,
            რომელსაც კომპანია ამუშავებს ან/და მიიღოს ამ პერსონალური მონაცემების
            ასლები კომპანიაში დაცული ფორმით, ან მოითხოვოს პერსონალურ მონაცემთა
            ასლების მიწოდება განსხვავებული ფორმით, თუ ეს ტექნიკურად შესაძლებელია
            (მაგალითად, ინფორმაციის მატერიალური ფორმით ამობეჭდვა, სხვა
            ინფორმაციის მატარებელზე ჩაწერა ელექტრონული ფორმით და ა.შ).
          </p>
          <p>
            8.2.2.კომპანიაში შენახვის ფორმისგან განსხვავებული ფორმით პერსონალურ
            მონაცემთა გაცემისთვის დახარჯული რესურსის ან/და მოთხოვნის სიხშირის
            გამო, კომპანიას უფლება აქვს პერსონალურ მონაცემთა გაცნობისა ან/და
            მონაცემთა ასლების გაცემისათვის მოსთხოვოს მომხმარებელს შესაბამისი
            საფასურის გადახდა, კომპანიის მიერ დადგენილი წესების შესაბამისად.{" "}
          </p>
          <p>
            8.2.3. მომხმარებელს უფლებააქვს გაეცნოს პერსონალურ მონაცემებს ან/და
            მიიღოს მათი ასლები მოთხოვნიდან არაუგვიანეს 10 (ათი) სამუშაო დღისა
            (თუ საქართველოს კანონმდებლობით სხვა ვადა არის დადგენილი). ეს ვადა
            განსაკუთრებულ შემთხვევებში და სათანადო დასაბუთებით შეიძლება
            გაგრძელდეს არაუმეტეს 10 (ათი) სამუშაო დღით, რის შესახებაც
            მომხმარებელს დაუყოვნებლივ ეცნობება.
          </p>
          <p>
            8.3. უფლება პერსონალურ მონაცემთა გასწორებაზე, განახლებასა და
            შევსებაზე{" "}
          </p>
          <p>
            8.3.1. მომხმარებელს უფლება აქვს მოსთხოვოს კომპანიას მის შესახებ
            მცდარი, არაზუსტი ან/და არასრული პერსონალური მონაცემების გასწორება,
            გან ახლება ან/და შევსება (ეს ეხება იმ შემთხვევას, როდესაც
            მომხმარებელი აღმოაჩენს, რომ მისი ცალკეული პერსონალური მონაცემი
            მცდარი, არაზუსტი ან არასრულია).{" "}
          </p>
          <p>
            8.3.2. მომხმარებლის მოთხოვნის მიღებიდან არაუგვიანეს 10 (ათი) სამუშაო
            დღისა (თუ საქართველოს კანონმდებლობით სხვა ვადა არ არის დადგენილი)
            კომპანია უზრუნველყოფს მცდარი, არაზუსტი ან/და არასრული შესაბამისი
            პერსონალური მონაცემების გასწორებას, განახლებას ან/და შევსებას ან
            აცნობებს მომხმარებელს მოთხოვნაზე უარის თქმის საფუძველს და მიაწვდის
            ინფორმაციას უარის გასაჩივრების წესის თაობაზე. თუ კომპანია,
            მომხმარებლისაგან დამოუკიდებლად გამოვლენს, რომ კომპანიის ხელთ
            არსებული პერსონალური მონაცემები მცდარი, არაზუსტი ან/და არასრულია,
            კომპანია გონივრულ ვადაში უზრუნველყოფს შესაბამისი მონაცემების
            გაასწორებს, განახლებას ან/და შევსებას და პერსონალურ მონაცემთა
            გასწორებიდან 10 (ათი) სამუშაო დღის ვადაში ამის შესახებ აცნობებს
            მომხმარებელს. აღნიშნულიდან გამონაკლის წარმაოდგენს შემთხვევები,
            როდესაც:
          </p>
          <p>
            (1) პერსონალური მონაცემის გასწორება, განახლება ან/და შევსება
            ტექნიკური შეცდომის გასწორებას/აღმოფხვრას უკავშირდება;{" "}
          </p>
          <p>
            (2) თუ არსებობს ობიექტური გარემოება, რომელიც შეუძლებელს ხდის
            მომხმარებლის ინფორმირებას. ასეთ შემთხვევაში, კომპანია შეეცდება
            ცვლილების განხორციელების შესახებ ინფორმაცია მომხმარებელს მასთან
            პირველივე კომუნიკაციის განხორციელებისთანავე მიაწოდოს.
          </p>
          <p>
            8.4. უფლება პერსონალურ მონაცემთა დამუშავების შეწყვეტაზე, წაშლაზე ან
            განადგურებაზე{" "}
          </p>
          <p>
            8.4.1. მომხმარებელს უფლება აქვს კომპანიას მოსთხოვოს პერსონალური
            მონაცემების დამუშავების (მათ შორის, არსებობის შემთხვევაში,
            პროფაილინგის) შეწყვეტა, წაშლა ან განადგურება.{" "}
          </p>
          <p>
            8.4.2.მომხმარებლის მოთხოვნის მიღებიდან არაუგვიანეს 10 (ათი) სამუშაო
            დღისა (თუ საქართველოს კანონმდებლობით სხვა რამ არ არის დადგენილი),
            კომპანია უზრუნველყოფს მომხმარებლის შესაბამისი პერსონალური
            მონაცემების დამუშავების შეწყვეტას ან/და წაშლას ან განადაგურებას ან
            აცნობებს მომხმარებელს მის მოთხოვნაზე უარის თქმის საფუძველზე და
            მიაწვდის ინფორმაციას უარის გასაჩივრების წესის თაობაზე.
          </p>
          <p>
            8.4.3. კომპანიას უფლება აქვს შესაბამისი დასაბუთებით, უარი უთხრას
            მომხმარებელს პერსონალურ მონაცემთა დამუშავების შეწყვეტის, წაშლის ან
            განადგურების მოთხოვნაზე, თუ:
          </p>
          <p>
            (1) პერსონალური მონაცემების დამუშავება ხორციელდება კანონმდებლობით
            დადგენილი სხვა რომელი საფუძველით, გარდა მომხმარებლის თანხმობისა;{" "}
          </p>
          <p>
            (2) პერსონალური მონაცემები მუშავდება სამართლებრივი მოთხოვნის ან
            შესაგებლის დასაბუთების მიზნით;
          </p>
          <p>
            (3) პერსონალურ მონაცემთა დამუშავება აუცილებელია გამოხატვის ან
            ინფორმაციის თავისუფლების უფლების განსახორციელებლად;{" "}
          </p>
          <p>
            (4) პერსონალური მონაცემები მუშავდება კანონით გათვალისწინებული საჯარო
            ინტერესებისთვის არქივირების მიზნით, სამეცნიერო ან ისტორიული კვლევის
            ან სტატისტიკური მიზნებისთვის და პერსონალურ მონაცემთა დამუშავების
            შეწყვეტის, წაშლის ან განადგურების უფლების განხორციელება შეუძლებელს
            გახდის ან მნიშვნელოვნად დააზიანებს დამუშავების მიზნების მიღწევას.
          </p>
          <p>
            8.4.4. მომხმარებლის მოთხოვნის მიღებიდან არაუგვიანეს 10 (ათი) სამუშაო
            დღისა, კომპანია უზრუნველყოს პერსონალურ მონაცემთა დამუშავების
            შეწყვეტას, წაშლას ან განადგურებას და ამასთანავე, ამავე ვადაში
            შესაბამისი მოქმედებების განხორციელების შესახებ ინფორმაციის
            მომხმარებლისათვის მიწოდებას.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            8.4.5. პერსონალურ მონაცემთა საჯაროდ ხელმისაწვდომი ფორმით დამუშავების
            შემთხვევაში, პერსონალურ მონაცემთა დამუშავების შეწყვეტის, წაშლის ან
            განადგურების გარდა, მომხმარებელს უფლება აქვს დამატებით მოითხოვოს მის
            პერსონალურ მონაცემთა ხელმისაწვდომობის შეზღუდვა ან/და პერსონალურ
            მონაცემთა ასლების ან პერსონალურ მონაცემებთან დამაკავშირებელი
            ნებისმიერი ინტერნეტ ბმულის წაშლა (ე.წ.“დავიწყების უფლება“).
          </p>
          <p>8.5. უფლება პერსონალურ მონაცემთა დაბლოკვაზე </p>
          <p>
            8.5.1. მომხმარებელს უფლება აქვს მოითხოვოს პერსონალურ მონაცემთა
            დაბლოკვა, რაც გულისხმობს მისი მოთხოვნით კომპანიის მიერ, პერსონალური
            მონაცემების დამუშავების დროებით შეჩერებას და ამ მონაცემის მხოლოდ
            შენახვას.
          </p>
          <p>
            8.5.2. მომხმარებელს უფლება აქვს მიმართოს კომპანიას პერსონალურ
            მონაცემთა დაბლოკვის მოთხოვნით, თუ არსებობს ერთ-ერთი შემდეგი
            გარემოება:{" "}
          </p>
          <p>
            (1) მომხმარებელი სადავოდ ხდის პერსონალური მონაცემების ნამდვილობას ან
            სიზუსტეს;
          </p>
          <p>
            (2) პერსონალურ მონაცემთა დამუშავება უკანონოა, თუმცა მომხმარებელი
            ეწინააღმდეგება მათ წაშლას და ითხოვს პერსონალურ მონაცემთა დაბლოკვას;{" "}
          </p>
          <p>
            (3) პერსონალური მონაცემები საჭირო აღარ არის მათი დამუშავების მიზნის
            მისაღწევად, თუმცა მომხმარებელს ისინი გჭირდებათ საჩივრის/სარჩელის
            წარსადგენად;{" "}
          </p>
          <p>
            (4) მომხმარებელი ითხოვთ პერსონალურ მონაცემთა დამუშავების შეწყვეტას,
            წაშლას ან განადგურებას და მიმდინარეობს ამ მოთხოვნის განხილვა;
          </p>
          <p>
            (5) არსებობს პერსონალური მონაცემების მტკიცებულებად გამოყენების
            მიზნით შენახვის აუცილებლობა.{" "}
          </p>
          <p>
            8.5.2. მომხმარებლის მოთხოვნის მიღებიდან არაუგვიანეს მომდევნო 3
            (სამი) სამუშაო დღისა, კომპანია უზრუნველყოფს პერსონალური მონაცემების
            დაბლოკვის თაობაზე მის მიერ მიღებული გადაწყვეტილების შეტყობინებას
            მომხმარებლისათვის და ამავე ვადაში, განახორციელებს მომხმარებლის
            მონაცემების დაბლოკვას, დაბლოკვის მიზეზის არსებობის ვადით (ასევე, ამ
            ვადის განმავლობაში, თუ ეს ტექნიკურად შესაძლებელია პერსონალურ
            მონაცემთან ერთად, დაბლოკვის შესახებ გადაწყვეტილების შენახვას) ან
            მითითებულ ვადაში აცნობებს მომხმარებელს მის გადაწყვეტილებას
            პერსონალურ მონაცემთა დაბლოკვის თაობაზე უარის თქმის თაობაზე,
            შესაბამისი საფუძვლის მითითებით.{" "}
          </p>
          <p>
            8.5.4. მომხმარებლის მოთხოვნის მიუხედავად, კომპანიასს უფლება აქვს არ
            დაბლოკოს ან განბლოკოს დაბლოკილი პერსონალური მონაცემები, თუ არსებობს
            ერთ-ერთი რომელიმე საფუძველი:{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            (1) პერსონალურ მონაცემთა დაბლოკვა საფრთხეს შეუქმნის კანონით ან/და
            კანონითა და მის საფუძველზე გამოცემული კანონქვემდებარე ნორმატიული
            აქტით კომპანიისათვის დაკისრებული მოვალეობების შესრულებას;{" "}
          </p>
          <p>
            (2) პერსონალურ მონაცემთა დაბლოკვა საფრთხეს შეუქმნის კანონის
            შესაბამისად საჯარო ინტერესის სფეროსთვის მიკუთვნებული ამოცანების
            შესრულებას ან საქართველოს კანონმდებლობით კომპანიისათვის მინიჭებული
            უფლებამოსილების განხორციელებას;{" "}
          </p>
          <p>
            (3) პერსონალურ მონაცემთა დაბლოკვა საფრთხეს შეუქმნის კომპანიას ან
            მესამე პირის ლეგიტიმურ ინტერესებს, გარდა იმ შემთხვევისა, როდესაც
            არსებობს პერსონალურ მონაცემთა სუბიექტის, განსაკუთრებით
            არასრულწლოვანის, უფლებების დაცვის აღმატებული ინტერესი;
          </p>
          <p>
            (4) პერსონალურ მონაცემთა დაბლოკვა საფრთხეს შეუქმნის მომხმარებლის ან
            მესამე პირის სასიცოცხლო ინტერესებს, აგრეთვე სახელმწიფო უსაფრთხოებისა
            და თავდაცვის მიზნების.
          </p>
          <p>
            8.5.3. პერსონალურ მონაცემთა დაბლოკვის შემთხვევაში მონაცემები, გარდა
            მათი შენახვისა, შესაძლოა სხვაგვარად დამუშავდეს შემდეგ შემთხვევებში:{" "}
          </p>
          <p>(1) თუ არსებობს მომხმარებლის თანხმობა;</p>
          <p>(2) სამართლებრივი მოთხოვნის ან შესაგებლის დასასაბუთებლად;</p>
          <p>(3) მომხმარებლის ან მესამე პირის ინტერესების დასაცავად;</p>
          <p>(4) კანონის შესაბამისად, საჯარო ინტერესის დასაცავად.</p>
          <p>8.6. უფლება პერსონალურ მონაცემთა გადატანაზე </p>
          <p>
            8.6.1. კომპანია ახორციელებს მომხმარებლის პერსონალური მონაცემების
            ავტომატურად დამუშავებას იმ შემთხვევაში, თუ ეს შესაძლებელია
            ტექნიკურად, ერთი-ერთი შემდეგი საფუძვლით:{" "}
          </p>
          <p>(1) მომხმარებლის თანხმობა, ან </p>
          <p>
            (2) პერსონალურ მონაცემთა დამუშავების აუცილებლობა განპირობებულია
            მომხმარებელთან დადებული გარიგებით ნაკისრი ვალდებულების შესრულებით ან
            მომხმარებლის მიერ მოთხოვნილი გარიგების დადებით;
          </p>
          <p> </p>
          <p>
            8.6.2.მომხმარებელს უფლება აქვს მოსთხოვოს კომპანიას სტრუქტურიზებული,
            საზოგადოდ გამოყენებადი და მანქანურად წაკითხვადი ფორმატით სხვა მესამე
            პირებისაგან მიიღოს მოწოდებული პერსონალური მონაცემები ან მოითხოვოს ამ
            პერსონალურ მონაცემთა სხვა მესამე პირისათვის - სხვა დამუშავებისთვის
            პასუხისმგებელი პირისთვის გადაცემა (ე.წ. პორტირება).{" "}
          </p>
          <p>
            8.7. უფლება პერსონალურ მონაცემთა დამუშავებაზე გაცემული თანხმობის
            გამოხმობაზე{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            8.7.1. მომხმარებელს უფლება აქვს ნებისმიერ დროს, ყოველგვარი
            განმარტების ან დასაბუთების გარეშე გამოიხმოს (უარი თქვას) მის მიერ
            პერსონალურ მონაცემთა დამუშავებაზე გაცემულ თანხმობაზე.{" "}
          </p>
          <p>
            8.7.2. თუ პერსონალურ მონაცემთა დამუშავების სხვა საფუძველი არ
            არსებობს, მომხმარებლის მოთხოვნის მიღებიდან არაუგვიანეს 10 (ათი)
            სამუშაო დღისა, კომპანია უზრუნველყოფს პერსონალური მონაცემების
            დამუშავების შეწყვეტას ან/და დამუშავებული პერსონალური მონაცემების
            წაშლას ან განადგურებას.{" "}
          </p>
          <p>
            8.7.3. მომხმარებელს უფლება აქვს თანხმობა გამოიხმოს იმავე ფორმით,
            რომლითაც თანხმობა განაცხადა, ასევე, უფლება აქვს თანხმობის
            გამოხმობამდე, მოსთხოვოს და მიიღოს კომპანიისაგან ინფორმაცია თანხმობის
            გამოხმობის შესაძლო შედეგების შესახებ.
          </p>
          <p>
            <b>
              9. პერსონალურ მონაცემთა დამუშავებასთან დაკავშირებული უფლებების
              შეზღუდვა
            </b>
          </p>
          <p>
            9.1. მომხმარებელს შეიძლება შეეზღუდოს პერსონალურ მონაცემებთან
            დაკავშირებული შემდეგი უფლებები:
          </p>
          <p>
            9.1.1. უფლება პერსონალურ მონაცემთა დამუშავების შესახებ ინფორმაციის
            მიღებაზე;
          </p>
          <p>9.1.2. უფლება პერსონალურ მონაცემთა გაცნობისა და ასლის მიღებაზე;</p>
          <p>
            9.1.3. უფლება პერსონალურ მონაცემთა გასწორებაზე, განახლებასა და
            შევსებაზე;
          </p>
          <p>
            9.1.4. უფლება პერსონალურ მონაცემთა დამუშავების შეწყვეტაზე, წაშლაზე
            ან განადგურებაზე;
          </p>
          <p>9.1.5. უფლება პერსონალურ მონაცემთა დაბლოკვაზე;</p>
          <p>9.1.6. უფლება პერსონალურ მონაცემთა გადატანაზე;</p>
          <p>
            9.1.7. უფლება პერსონალურ მონაცემთა დამუშავებაზე გაცემული თანხმობის
            გამოხმობაზე;
          </p>
          <p>
            9.1.8. უფლება მინიმალური ინფორმაციის მიღებაზე,როდესაც პერსონალურ
            მონაცემები უშუალოდ მომხმარებლისაგან გროვდება;
          </p>
          <p>
            9.1.9. უფლება მინიმალური ინფორმაციის მიღებაზე,როდესაც პერსონალური
            მონაცემები უშუალოდ მომხმარებლისაგან არ გროვდება.
          </p>
          <p>
            9.2. ზემოთ დასახელებული უფლებები შეიძლება შეიზღუდოს, მხოლოდ იმ
            შემთხვევაში, თუ ეს პირდაპირ არის გათვალისწინებული საქართველოს
            კანონმდებლობით, ამით არ ირღვევა ადამიანის ძირითადი უფლებები და
            თავისუფლებები, ეს არის აუცილებელი და პროპორციული ზომა დემოკრატიულ
            საზოგადოებაში და ამ უფლებების განხორციელებამ შეიძლება საფრთხე
            შეუქმნას:
          </p>
          <p>
            9.2.1. სახელმწიფო უსაფრთხოების, ინფორმაციული უსაფრთხოებისა და
            კიბერუსაფრთხოების ან/და თავდაცვის ინტერესებს;{" "}
          </p>
          <p>
            9.2.2. საზოგადოებრივი უსაფრთხოების ინტერესებს; 9.2.3. დანაშაულის
            თავიდან აცილებას, დანაშაულის გამოძიებას, სისხლისსამართლებრივ დევნას,
            მართლმსაჯულების განხორციელებას, პატიმრობისა და თავისუფლების აღკვეთის
            აღსრულებას, არასაპატიმრო სასჯელთა აღსრულებას და პრობაციას,
            ოპერატიულ-სამძებრო საქმიანობას;{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            9.2.4. ქვეყნისთვის მნიშვნელოვან ფინანსურ ან ეკონომიკურ (მათ შორის,
            მონეტარულ, საბიუჯეტო და საგადასახადო), საზოგადოებრივი ჯანმრთელობისა
            და სოციალური დაცვის საკითხებთან დაკავშირებულ ინტერესებს;{" "}
          </p>
          <p>
            9.2.5. მომხმარებლის მიერ პროფესიული, მათ შორის, რეგულირებადი
            პროფესიის, ეთიკის ნორმების დარღვევის გამოვლენას და პასუხისმგებლობის
            დაკისრებას;{" "}
          </p>
          <p>
            9.2.6. ზემოთ დასახელებულ სფეროებში მარეგულირებელი ან/და
            ზედამხედველობის განმახორციელებელი ორგანოების ფუნქციებისა და
            უფლებამოსილებების განხორციელებას;{" "}
          </p>
          <p>
            9.2.7. მომხმარებლის ან/და სხვა პირების უფლებებსა და თავისუფლებებს,
            მათ შორის, გამოხატვის თავისუფლებას;{" "}
          </p>
          <p>
            9.2.8. სახელმწიფო, კომერციული, პროფესიული და კანონით
            გათვალისწინებული სხვა სახის საიდუმლოებების დაცვას;
          </p>
          <p>9.2.9. სამართლებრივი მოთხოვნის ან შესაგებლის დასაბუთებას. </p>
          <p>
            9.3. შესაბამისი საფუძვლების არსებობისას, კომპანია აცნობებს
            მომხმარებელს უფლების შეზღუდვისა და განხორციელებაზე უარის თქმის
            შესახებ გადაწყვეტილებას, გარდა კანონმდებლობით დადგენილი
            შემთხვევებისა.{" "}
          </p>
          <p>
            9.4. კომპანია უზრუნველყოფს მომხმარებლის ზემოთ მითითებული უფლებების
            განხორციელებას უსასყიდლოდ, გარდა კანონმდებლობით დადგენილი
            გამონაკლისებისა.{" "}
          </p>
          <p>
            9.5. მომხმარებლის მიერ მოთხოვნის არაგონივრული სიხშირით წარდგენის
            შემთხვევაში, კომპანიას უფლება აქვს უარი უთხრას მომხმარებელს მისი
            მოთხოვნის შესრულებაზე, რის შესახებაც მომხმარებელს წერილობით (მათ
            შორის, ელექტრონული ფორმით) აცნობებს და ასევე, მისცემს განმარტებას
            გასაჩივრების უფლების შესახებ.
          </p>
          <p>
            <b>
              10. მომხმარებლის ინფორმირება, როდესაც პერსონალური მონაცემები
              უშუალოდ მისგან გროვდება
            </b>
          </p>
          <p>
            10.1. იმ შემთხვევაში, როდესაც კომპანია პერსონალურ მონაცემებს უშუალოდ
            მომხმარებლისაგან აგროვებს, მომხმარებელს უფლება აქვს პერსონალურ
            მონაცემთა შეგროვებამდე ან შეგროვების დაწყებისთანავე მოითხოვოს და
            მიიღოს სულ მცირე შემდეგი ინფორმაცია (შემდგომში{" "}
            <span className="font-bold">„მინიმალური ინფორმაცია“</span>):{" "}
          </p>
          <p>
            10.1.1. იმ პირის ვინაობა/სახელწოდება და საკონტაქტო ინფორმაცია,ვინც
            ამუშავებს პერსონალურ მონაცემებს (იგულისხმება ინფორმაცია
            დამუშავებისთვის პასუხისმგებელი პირის, მისი წარმომადგენლის ან/და
            დამუშავებაზე უფლებამოსილი პირის თაობაზე (ასეთის არსებობის
            შემთხვევაში));{" "}
          </p>
          <p>
            10.1.2. ინფორმაცია პერსონალურ მონაცემთა დამუშავების მიზნებისა და
            სამართლებრივი საფუძვლის შესახებ;
          </p>
          <p>
            10.1.3. ინფორმაცია პერსონალურ მონაცემთა მიწოდების სავალდებულოობის
            შესახებ, ხოლო თუ პერსონალურ მონაცემთა მიწოდება სავალდებულოა −
            პერსონალურ მონაცემთა მიწოდებაზე უარის თქმის სამართლებრივი შედეგების
            თაობაზე, აგრეთვე ინფორმაცია იმის შესახებ, რომ პერსონალურ მონაცემთა
            შეგროვება/მოპოვება გათვალისწინებულია საქართველოს კანონმდებლობით ან
            აუცილებელი პირობაა ხელშეკრულების დასადებად (ასეთი ინფორმაციის
            არსებობის შემთხვევაში);{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            10.1.4. კომპანიისს ან მესამე პირის მნიშვნელოვანი ლეგიტიმური
            ინტერესების შესახებ, თუ პერსონალურ მონაცემთა დამუშავება აუცილებელია
            კომპანიის ან მესამე პირის მნიშვნელოვანი ლეგიტიმური ინტერესების
            დასაცავად;{" "}
          </p>
          <p>
            10.1.5. პერსონალურ მონაცემთა დაცვის ოფიცრის (ასეთის არსებობის
            შემთხვევაში) ვინაობა და საკონტაქტო ინფორმაცია;{" "}
          </p>
          <p>
            10.1.6. პერსონალურ მონაცემთა მიმღების ვინაობა ან მონაცემთა
            მიმღებების კატეგორიები (ასეთის არსებობის შემთხვევაში);{" "}
          </p>
          <p>
            10.1.7. ინფორმაცია პერსონალურ მონაცემთა დაგეგმილი გადაცემისა და
            პერსონალურ მონაცემთა დაცვის სათანადო გარანტიების არსებობის შესახებ,
            მათ შორის, პერსონალურ მონაცემთა გადაცემაზე ნებართვის თაობაზე (ასეთის
            არსებობის შემთხვევაში), თუ დამუშავებისთვის პასუხისმგებელი პირი
            გეგმავს მონაცემთა სხვა სახელმწიფოსთვის ან საერთაშორისო
            ორგანიზაციისთვის გადაცემას;{" "}
          </p>
          <p>
            10.1.8. ინფორმაცია პერსონალურ მონაცემთა შენახვის ვადის შესახებ, ხოლო
            თუ კონკრეტული ვადის განსაზღვრა შეუძლებელია, ვადის განსაზღვრის
            კრიტერიუმების თაობაზე;{" "}
          </p>
          <p>
            10.1.9. ინფორმაცია პერსონალურ მონაცემთა დაცვის ფარგლებში
            გათვალისწინებული მომხმარებლის უფლებების შესახებ.
          </p>
          <p>
            10.2. ამ მუხლის პირველი პუნქტით დადგენილი წესი არ მოქმედებს, თუ
            სპეციალური კანონმდებლობა ადგენს მომხმარებლისაგან პერსონალური
            მონაცემების შეგროვებისას ინფორმირების განსხვავებულ წესს და ეს წესი
            არ იწვევს მომხმარებლის ძირითადი უფლებებისა და თავისუფლებების
            დარღვევას. ამ შემთხვევაში მომხმარებლის წერილობითი მოთხოვნის
            არსებობისას, კომპანია მოგაწვდით ზემოთ აღნიშნულ ინფორმაციას
            მოთხოვნიდან 10 (ათი) სამუშაო დღის ვადაში (თუ არ არსებობს პერსონალურ
            მონაცემთა დაცვის ფარგლებში გათვალისწინებული უფლების შეზღუდვის
            საფუძვლები). ეს ვადა განსაკუთრებულ შემთხვევებში და სათანადო
            დასაბუთებით შეიძლება გაგრძელდეს არაუმეტეს 10 (ათი) სამუშაო დღით, რის
            შესახებაც მომხმარებელს დაუყოვნებლივ ეცნობება.
          </p>
          <p>
            10.3. მომხმარებელს უფლება აქვს მოითხოვოს ამ მუხლში მითითებული
            მინიმალური ინფორმაცია როგორც ზეპირად, ასევე, წერილობით (მათ შორის,
            ელექტრონული ფორმით).
          </p>
          <p>
            10.4. კომპანიას უფლება აქვს არ მოგაწოდოთ ზემოთ აღნიშნული მინიმალური
            ინფორმაცია, თუ არსებობს გონივრული ვარაუდი, რომ მომხმარებელი უკვე
            ფლობს ამ ინფორმაციას.{" "}
          </p>
          <p>
            <b>
              11. მომხმარებლის ინფორმირება, როდესაც პერსონალური მონაცემები
              უშუალოდ მისგან არ გროვდება
            </b>{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            11.1. იმ შემთხვევაში, როდესაც პერსონალურ მონაცემებს კომპანია უშუალოდ
            მომხმარებლისაგან არ აგროვებს, მომხმარებელს უფლება აქვს მიიღოს
            „მინიმალური ინფორმაცია“ და აგრეთვე, ინფორმაცია იმის შესახებ,
            მომხმარებლის შესახებ რომელი პერსონალური მონაცემები მუშავდება და ამ
            პერსონალურ მონაცემთა მოპოვების წყარო, მათ შორის, მოპოვებულ იქნა თუ
            არა პერსონალური მონაცემები საჯაროდ ხელმისაწვდომი წყაროდან.
          </p>
          <p>
            11.2. მომხმარებელს უფლება აქვს მიიღოს ზემოთ აღნიშნული ინფორმაცია,
            მოთხოვნიდან გონივრულ ვადაში ან, თუ პერსონალური მონაცემები
            გამოიყენება მომხმარებელთან დასაკავშირებლად, მომხმარებელთან პირველი
            კომუნიკაციისთანავე, ხოლო თუ დაგეგმილია პერსონალურ მონაცემთა
            გამჟღავნება, − პერსონალურ მონაცემთა გამჟღავნებამდე, მაგრამ
            პერსონალურ მონაცემთა მოპოვებიდან არაუგვიანეს 10 სამუშაო დღისა (თუ არ
            არსებობს პერსონალი მონაცემთა დაცვის ფარგლებში გათვალისწინებული
            უფლებების შეზღუდვის საფუძვლები).{" "}
          </p>
          <p>
            11.3. კომპანიას უფლება აქვს, მომხმარებელს არ მიაწოდოს ზემოთ
            აღნიშნული ინფორმაცია, თუ არსებობს ერთ-ერთი ნებისმიერი საფუძველი:{" "}
          </p>
          <p>11.3.1. მომხმარებელი უკვე ფლობთ ამ ინფორმაციას; </p>
          <p>
            11.3.2. პერსონალურ მონაცემთა შეგროვება ან გამჟღავნება დადგენილია
            კანონით ან საჭიროა საქართველოს კანონმდებლობით დაკისრებული მოვალეობის
            შესასრულებლად;
          </p>
          <p>
            11.3.3. ინფორმაციის მიწოდება შეუძლებელია ან მოითხოვს არაპროპორციულად
            დიდ ძალისხმევას ან ინფორმაციის მიწოდება მნიშვნელოვან ზიანს მიაყენებს
            ან შეუძლებელს გახდის პერსონალურ მონაცემთა დამუშავების კანონიერი
            მიზნის (მიზნების) შესრულებას. ამ შემთხვევებში კომპანია მიიიღებს
            ყველა სათანადო ზომას მომხმარებლის უფლებებისა და ლეგიტიმური
            ინტერესების დასაცავად, მათ შორის, პერსონალური მონაცემების მოპოვების
            შესახებ ზოგადი ინფორმაციის საჯაროდ/ყველასთვის ხელმისაწვდომი ფორმით
            განთავსების გზით.
          </p>
          <p>
            <b>
              {" "}
              12. ავტომატიზებული ინდივიდუალური გადაწყვეტილების მიღებასთან
              დაკავშირებული უფლებები
            </b>
          </p>
          <p>
            12.1. მომხმარებელს უფლება აქვს არ დაექვემდებაროს მხოლოდ
            ავტომატიზებულად, მათშორის, პროფაილინგის საფუძველზე, მიღებულ
            გადაწყვეტილებას, რომელიც მისთვის წარმოშობს სამართლებრივ ან სხვა
            სახის არსებითი მნიშვნელობის მქონე შედეგს, გარდა იმ შემთხვევისა,
            როდესაც პროფაილინგის საფუძველზე გადაწყვეტილების მიღება:
          </p>
          <p>
            12.1.1. ეფუძნება მომხმარებლის მიერ აშკარად გამოხატულ თანხმობას;{" "}
            12.1.2. აუცილებელია მომხმარებელსა და კომპანიას შორის ხელშეკრულების
            დასადებად ან ხელშეკრულების შესასრულებლად;
          </p>
          <p>
            12.1.3. გათვალისწინებულია კანონით ან კანონის საფუძველზე დელეგირებული
            უფლებამოსილების ფარგლებში გამოცემული კანონქვემდებარე ნორმატიული
            აქტით.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            12.2. მომხმარებლის შესაბამისი მოთხოვნის შემთხვევაში, კომპანია
            უზრუნველყოფს სათანადო ზომების მიღებას მომხმარებლის უფლებების,
            თავისუფლებისა და ლეგიტიმური ინტერესების დასაცავად, მათ შორის,
            ავტომატიზებულად (პროფაილინგის საფუძველზე, მომხმარებლის მიმართ
            ინდივიდუალური გადაწყვეტილების მიღებამდე, გადაწყვეტილების მიღების
            პროცესში ადამიანური რესურსის ჩართვის (გარდა კანონმდებლობით
            გათავლისწინებული გათვალისწინებული შემთხვევებისა), მომხმარებლის
            მხრიდან მოსაზრების გამოთქმის და გადაწყვეტილების გასაჩივრების
            შესაძლებლობის მიცემის გზით.
          </p>
          <p>
            12.3. ავტომატიზებულად (პროფაილინგის საფუძველზე), მომხმარებლის მიმართ
            ინდივიდუალური გადაწყვეტილების მიღებისას, განსაკუთრებული კატეგორიის
            მონაცემების გამოყენება მოხდება მხოლოდ მხოლოდ კანონმდებლობით
            განსაზღვრულ და დაშვებულ შემთხვევაში, თუ არსებობს მომხმარებლის
            უფლებების, თავისუფლებებისა და ლეგიტიმური ინტერესების დაცვის სათანადო
            გარანტიები.{" "}
          </p>
          <p>
            <b>13. პერსონალურ მონაცემთა უსაფრთხოება</b>{" "}
          </p>
          <p>
            13.1. პერსონალურ მონაცემთა უსაფრთხოების უზრუნველყოფა ხორციელდება
            კომპანიის მიერ დადგენილი ინფორმაციული უსაფრთხოების პოლიტიკების
            შესაბამისად.{" "}
          </p>
          <p>
            13.2. კომპანია განსაკუთრებული პასუხისმგებლობით ეკიდება პერსონალურ
            მონაცემთა უსაფრთხოების საკითხს და პერიოდულად ატარებს პერსონალურ
            მონაცემთა დამუშავების შესაძლო და თანამდევი საფრთხეების შესაბამის
            ორგანიზაციულ და ტექნიკური ზომებს (მაგალითად, მონაცემთა დამუშავება
            ხორციელდება უსაფრთხო და სანდო ელექტრონული პროგრამების/სისტემების
            საშულებით, მონაცემები ინახება სათანადოდ დაცულ სერვერზე, რეალურ დროში
            რეზერვირებული მონაცემები პარალელურად ინახება სარეზერვო
            მოწყობილობაზე, დანერგილია კრიტიკული ინფორმაციის გარეთ გადინების
            მონიტორინგის სისტემა, შეზღუდულია წვდომა არასაჭირო ინტერნეტ
            რესურსებსა და ინფორმაციის გარე მატარებელზე (USB,CD), არაელექტრონული
            ფორმით არსებულ მონაცემთა შენახვა ხორციელდება შესაბამისი უსაფრთხოების
            ზომების გატარების გზით და სხვა), რომლებიც უზრუნველყოფს პერსონალურ
            მონაცემთა დაცვას ინციდენტებისაგან და მონაცემების არამართლზომიერი ან
            შემთხვევით დაზიანების, დაკარგვისგან, უკანონო დამუშავებისგან, მათ
            შორის, განადგურებისგან, წაშლისგან, შეცვლისგან, გამჟღავნებისგან,
            მოპოვებისაგან,წვდომის, შეგროვების/მოპოვების ან ნებისმიერი სხვა
            ფორმით უკანონო გამოყენებისაგან.{" "}
          </p>
          <p>
            13.3. პერსონალურ მონაცემთა უსაფრთხოების უზრუნველსაყოფად აუცილებელი
            ორგანიზაციულ-ტექნიკური ზომების განსაზღვრისას კომპანია ითვალისწინებს
            იმ პერსონალურ მონაცემთა კატეგორიებსა და მოცულობას, რომელსაც
            ამუშავებს, აგრეთვე, პერსონალურ მონაცემთა დამუშავების მიზანს, ფორმას,
            საშუალებებსა და მონაცემთა სუბიექტის უფლებების დარღვევის შესაძლო
            საფრთხეებს.{" "}
          </p>
          <p>
            13.4. კომპანია პერიოდულად ახორციელებს პერსონალურ მონაცემთა
            უსაფრთხოების უზრუნველსაყოფად მიღებული ტექნიკური და ორგანიზაციული
            ზომების ეფექტიანობის შეფასებას და, საჭიროების შემთხვევაში,
            უზრუნველყოფს პერსონალურ მონაცემთა უსაფრთხოების დასაცავად ადეკვატური
            ზომების მიღებას ან/და არსებულის განახლებას.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            13.5. ელექტრონული ფორმით არსებულ პერსონალურ მონაცემთა დამუშავებისას,
            კომპანია აღრიცხავს ელექტრონული ფორმით არსებული პერსონალური
            მონაცემების მიმართ შესრულებულ ყველა მოქმედებას (მათ შორის,
            ინფორმაციას ინციდენტების შესახებ, მონაცემთა შეგროვების, შეცვლის,
            მათზე წვდომის, მათი გამჟღავნების (გადაცემის), დაკავშირებისა და
            წაშლის თაობაზე).
          </p>
          <p>
            13.6. არაელექტრონული ფორმით არსებულ პერსონალურ მონაცემთა
            დამუშავებისას, კომპანია აღრიცხავს პერსონალურ მონაცემთა
            გამჟღავნებასთან ან/და ცვლილებასთან დაკავშირებული ყველა მოქმედების
            (მათ შორის, ინფორმაციას ინციდენტების შესახებ) .
          </p>
          <p>
            13.7. კომპანია უზრუნველყოფს, რომ ყველა იმ პირი, რომელიც კომპანიის
            მიერ მინიჭებული უფლებამოსილების ფარგლებში ახორციელებს პერსონალურ
            მონაცემთა დამუშავებას ან აქვს წვდომა მონაცემებზე:{" "}
          </p>
          <p>
            13.7.1. არ გასცდეს მისთვის მინიჭებული უფლებამოსილების ფარგლებს,
            დაიცვას პერსონალურ მონაცემთა საიდუმლოება და კონფიდენციალურობა, მათ
            შორის, შესაბამისი უფლებამოსილების შეწყვეტის შემდეგ;
          </p>
          <p>
            13.7.2. ელექტრონული ფორმით პერსონალურ მონაცემთა დამუშავის
            შემთხვევაში მხოლოდ იმ მოცულობის პერსონალურ მონაცემების დამუშავებაზე
            ჰქონდეს დაშვება, რომელსაც მას კომპანია მიანიჭებს;{" "}
          </p>
          <p>
            13.7.3. იყოს სათანადოდ ინფომირებული პერსონალურ მონაცემთა
            უსაფრთხოების დაცვის საკითხების შესახებ.
          </p>
          <p>
            13.7.4. მომხმარებლისათვის პერსონალური მონაცემების თაობაზე
            ინფორმაციის მიწოდებამდე, მონაცემთა უსაფრთხოების მიზნით, კომპანია
            უფლებამოსილია მოსთხოვოს მომხმარებელს მისი ვინაობის დადასტურება
            (იდენტიფიცირება) ან ვინაობის გადამოწმება (ვერიფიკაცია).{" "}
          </p>
          <p>
            13.7.5. პერსონალურ მონაცემთა უსაფრთხოების მიზნით, მაშინ როცა საქმე
            პერსონალური მონაცემების დამუშავებას, მათ შორის, პერსონალურ მონაცემთა
            შენახვას ან გადაცემას, ეხება, კომპანია ცდილობს საქმიანი ურთიერთობა
            დაამყაროს ისეთ პარტნიორებთან/პირებთან და პერსონალურ მონაცემზე წვდომა
            მიანიჭოს მხოლოდ ისეთ პირებს, რომელთაც დაცული აქვთ პერსონალურ
            მონაცემთა უსაფრთხოება და მხოლოდ მას შემდეგ, რაც უსაფრთხოების
            დაცულობა სათანადოდ იქნება დემონისტრირებული და შემოწმებული.{" "}
          </p>
          <p>
            13.7.6. ითვალისწინებს რა, ე.წ. „Data Protection by Design and by
            Default“ პრინციპებს, ახალი პროდუქტის/მომსახურების შექმნისა და
            საწყისი გამოყენების ეტაპზე, კომპანია უზრუნველყოფს პერსონალურ
            მონაცემთა დაცვის გათვალისწინებას პირველად პარამეტრად და იმ გვარად,
            რომ თავდაპირველად დამუშავდეს მინიმალური, კერძოდ, მხოლოდ ის
            პერსონალური მონაცემები, რომელთა დამუშავებაც აუცილებელია ძირითადი
            ლეგიტიმური მიზნისათვის.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            13.7.7. როგორც პერსონალურ მონაცემთა დამუშავების საშუალებების
            განსაზღვრის, ისე უშუალოდ დამუშავების პროცესში, კომპანია მაქსიმალურ
            ეცდება მიიღოს ისეთი ტექნიკური და ორგანიზაციული ზომები, რომლებიც
            უზრუნველყოფს პერსონალურ მონაცემთა დამუშავების პრინციპების სათანადო
            და ეფექტიან იმპლემენტაციას და პერსონალურ მონაცემთა დამუშავების
            პროცესში, მომხმარებლის სამართლებრივი დაცვის მექანიზმების ჯეროვან
            დანერგვას.{" "}
          </p>
          <p>
            <b>14. პრეტენზიის/საჩივრის წარდგენა</b>
          </p>
          <p>
            14.1. წინამდებარე პოლიტიკით ან მოქმედი კანონმდებლობით განსაზღვრული
            უფლებებისა და დადგენილი წესების დარღვევის შემთხვევაში, მომხმარებელს
            უფლება აქვს მისი არჩევანით, მიმართოს:
          </p>
          <p>14.2. კომპანიას, ამ პოლიტიკიაში მითითებულ საკონტაქტო არხებზე;</p>
          <p>
            14.3. პერსონალურ მონაცემთა დაცვის სამსახურს (
            <a
              style={{ color: "blue" }}
              href="https://personaldata.ge/ka/contact"
            >
              https://personaldata.ge/ka/contact
            </a>
            ); ან
          </p>
          <p>14.4. სასამართლოს.</p>
          <p>
            14.5. მომხმარებელს უფლება აქვს მისი განცხადების განხილვის დასრულების
            თაობაზე გადაწყვეტილების გამოტანამდე, მოსთხოვოს პერსონალურ მონაცემთა
            დაცვის სამსახურს პერსონალური მონაცემების დაბლოკვის შესახებ
            გადაწყვეტილების მიღება.
          </p>
          <p>
            14.6. მომხმარებელს უფლება აქვს პერსონალურ მონაცემთა დაცვის
            სამსახურის ნებისმიერი გადაწყვეტილება გაასაჩივროს სასამართლოში
            საქართველოს კანონმდებლობით გათვალისწინებული პირობებისა და ვადების
            დაცვით.
          </p>
          <p>
            <b>
              15. პერსონალურ მონაცემთა დამუშავება პირდაპირი მარკეტინგის მიზნით
            </b>
          </p>
          <p>
            15.1. პირდაპირი მარკეტინგის მიზნით პერსონალურ მონაცემთა დამუშავება{" "}
          </p>
          <p>
            15.1.1. პირდაპირი მარკეტინგის მიზნით პერსონალური მონაცემების
            დამუშავება განხორციელდება მხოლოდ იმ შემთხვევაში თუ კომპანიას გააჩნია
            მომხმარებლის მიერ გაცემულ თანხმობა.
          </p>
          <p>
            15.1.2. პირდაპირი მარკეტინგის მიზნით პერსონალურ მონაცემთა
            დამუშავებაზე თანხმობის გამოხატვით, მომხმარებელი გასცემს თანხმობას
            შემდეგზე: კომპანიისაგან, მათ შორის, მისი დამუშავებაზე უფლებამოსილი
            პირებისაგან - კომპანიის სახელით ან კომპანიის დაკვეთით მოქმედი
            პირებისაგან, პერიოდულად მიიღოთ მოკლე ტექსტური შეტყობინებები (SMS),
            ტექსტური, ხმოვანი, ელექტრონული და სხვა სახის შეტყობინებები,მათ
            შორის, ხმოვანი სატელეფონო ზარები, ტელეფონის, ფოსტის, ელექტრონული
            ფოსტის, სოციალური ქსელების ან სხვა ელექტრონული საშუალებით, კომპანის
            ან/და კომპანიის საქონლის/პროდუქტის, იდეის, მომსახურების,
            ფასდაკლების, აქციის, წამოწყების, წამახალისებელი ან სხვა
            გათამაშებების, პროექტების ან/და კომპანიის პარტნიორი ორგანიზაციების
            ერთობლივი პროექტების თაობაზე, მიიღოს დაბადების დღის, დღესასწაულებისა
            და სხვა მნიშვნელოვანი და ღირსშესანიშნავი თარიღების თაობაზე
            მილოცვები, ინფორმაცია კომპანიაში არსებული სამუშაოებისა და
            ვაკანსიების თაობაზე, ასევე, სხვა ნებისმიერი სახის
            სარეკლამო/მარკეტინგული შეთავაზებები.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            15.2. პირდაპირი მარკეტინგის მიზნით დამუშავებული პერსონალური
            მონაცემები და მოპოვების წყაროები
          </p>
          <p>
            15.2.1. პირდაპირი მარკეტინგის მიზნით კომპანია ამუშავებს უშუალოდ
            მომხმარებლისაგან შეგროვებულ პერსონალურ მონაცემებს, რომლებსაც
            მომხმარებელი ამჟღავნებს კომპანიის საიტზე ან/და აპლიკაციაში
            რეგისტრაციის დროს ან შემდგომ პერიოდში, კომპანიასთან შემდგომში
            ნებისმიერი კომუნიკაციის დროს: ნებისმიერი მონაცემი, რომლითაც ხდება
            მომხმარებლის იდენტიფიცირება და მასთან კომუნიკაცია (სახელი, გვარი,
            პირადი ნომერი, დაბადების თარიღი, სქესი, ასაკი, მისამართი, ტელეფონის
            ნომერი, ელექტრონული ფოსტის მისამართი და სხვა
            მაიდენტიფიცირებელი/საკონტაქტო ინფორმაცია).{" "}
          </p>
          <p>
            15.2.2. პირდაპირი მარკეტინგის მიზნით კომპანიამ შეიძლება შეაგროვოს და
            დაამუშაოს მესამე პირებისაგან მიღებული პერსონალური მონაცემები მხოლოდ
            იმ შემთხვევაში, თუ მომხმარებელს გაცემული აქვს თანხმობა.
          </p>
          <p>
            15.2.3. მომხმარებლის მიერ თანხმობის გაცემის შემთხვევაში,
            მომხმარებლის პირად ინტერესებსა და პრეფერენციებზე მორგებული
            პერსონალიზირებული სარეკლამო/მარკეტინგული შეთავაზებების დამუშავებისა
            და მიღების მიზნით,მართზომიერად ხელმისაწვდომი და კანონიერად
            დამუშავებული ნებისმიერი პერსონალური მონაცემების (მაგალითად, ასაკი,
            სქესი, შესყიდული პროდუქტების კატეგორია, ბრენდები და სხვა)
            საფუძველზე, კომპანია პერიოდულად განახორციელებს მომხმარებელთა
            გარკვეული პიროვნული მახასიათებლებისა და ქცევის მაჩვენელების თაობაზე
            ინფორმაციის დამუშავებას, შეფასებას, ანალიზს და
            სეგმენტაციას/დაჯგუფებას.{" "}
          </p>
          <p>
            15.3. პირდაპირი მარკეტინგის მიზნით დამუშავებული პერსონალური
            მონაცემების შენახვის ვადა
          </p>
          <p>
            15.3.1. პირდაპირი მარკეტინგის მიზნით დამუშავებული პერსონალურ
            მონაცემების შენახვა ხორციელდება პირდაპირი მარკეტინგის განხორციელების
            ვადით და პირდაპირი მარკეტინგის განხორციელების შეწყვეტიდან, მათ
            შორის, მომხმარებლის რეგისტრაციის ნებისმიერი მიზეზით გაუქმებიდან ან
            მომხმარებლის მიერ პირდაპირი მარკეტინგის მიზნით პერსონალურ მონაცემთა
            დამუშავებაზე გაცემული თანხმობის გამოხმობის/უარის თქმის მომენტიდან,
            მომდევნო 3 წლის ვადით.{" "}
          </p>
          <p>
            15.3.2. კანონმდებლობით გათვალისწინებულ შემთხვევებში ან/და თუ
            არსებობს კომპანიის ან მესამე პირის მნიშვნელოვანი ლეგიტიმური
            ინტერესების დაცვის აუცილებლობა, პირდაპირი მარკეტინგის მიზნით
            დამუშავებული პერსონალური მონაცემების შენახვა განხორციელდება ზემოთ
            მითითებული 3 წლიან ვადაზე უფრო ხანგძლივი, კონკრეტული მიზნების
            მიღწევისათვის საჭირო ვადით.
          </p>
          <p>
            15.3.3. ზემოთ აღნიშნულის მონაცემთა შენახვის ვადის გასვლის შემდეგ,
            პერსონალური მონაცემები შეიძლება შენახულ იქნეს
            სტატისტიკური/ანალიტიკური მიზნებისათვის, დეპერსონალიზებული ფორმით.
            აღნიშნული გულისხმობს მონაცემთა იმგვარ დამუშავებას, როდესაც
            შეუძლებელია მომხმარებელთან მათი დაკავშირება ან ასეთი კავშირის
            დადგენა არაპროპორციულად დიდ ძალისხმევას, ხარჯებს ან/და დროს
            საჭიროებს.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            15.4. პირდაპირი მარკეტინგის მიზნით პერსონალურ მონაცემთა დამუშავებაზე
            გაცემული თანხმობის გამოთხოვა და მონაცემთა დამუშავების შეწყვეტა
          </p>
          <p>
            15.4.1. მომხმარებელს უფლება აქვს ნებისმიერ დროს უარი თქვას პირდაპირი
            მარკეტინგის მიზნით პერსონალურ მონაცემთა დამუშავებაზე და მარტივად და
            უფასოდ გამოიხმოს თანხმობა.{" "}
          </p>
          <p>
            15.4.2. პერსონალურ მონაცემების დამუშავებაზე თანხმობის
            გამოხმობა/პერსონალურ მონაცემთა დამუშავების შეწყვეტის მოთხოვნა (უარი)
            მომხმარებელს შეუძლია განხორციელოს იმავე ფორმით, რომლითაც პირდაპირი
            მარკეტინგი (სარეკლამო/მარკეტინგული შეთავაზებები) ხორციელდება.{" "}
          </p>
          <p>
            15.4.3. მაგალითად, თუ პირდაპირი მარკეტინგი (სარეკლამო/მარკეტინგული
            შეთავაზებები) მოკლე ტექტური შეტყობინების (SMS) გაგზავნის მეშვეობით
            ხორცილდება, ამ მიზნით მონაცემთა დამუშავების შეწყვეტის მოთხოვნის
            (უარის) განხორციელება მომხმარებელს შეუძლია ამავე ფორმით: მაგალითად,
            SMSOFF, STOPSMS ან იმ სხვა ციფრ(ებ)ის/ტექსტის გაგზავნის საშუალებით,
            რომელიც მოკლე ტექსტურ შეტყობინებას ახლავს.
          </p>
          <p>
            15.4.4. მაგალითად, თუ პირდაპირი მარკეტინგი (სარეკლამო/მარკეტინგული
            შეთავაზებები) ელექტრონული ფოსტის მისამართზე, ელექტრონული
            შეტყობინებების გაგზავნის გზით ხორცილდება, ამ მიზნით მონაცემთა
            დამუშავების შეწყვეტის მოთხოვნის (უარის) განხორციელება მომხმარებელს
            შეუძლია ამავე ფორმით, მას შემდეგ რაც გამოიყენებს მექანიზმს
            „გამოწერის გაუქმება“(„unsubscribe“) ან იმ სხვა ანალოგიურ მექანიზმს,
            რომელიც ელექტრონულ შეტყობინებას ახლავს.
          </p>
          <p>
            15.4.5. გამონაკლისის სახით და მომხმარებლის სათანადო იდენტიფიცირების
            პირობებში, პირდაპირი მარკეტინგის მიზნით პერსონალურ მონაცემთა
            დამუშავებაზე თანხმობის გამოხმობა/პერსონალურ მონაცემთა დამუშავების
            შეწყვეტის მოთხოვნა (უარი) მომხმარებელს შეუძლია განაცხადოს ამ
            პოლიტიკაში მითითებულ კომპანიის საკომუნიკაციო არხებზე მომართვის
            საშუალებით.{" "}
          </p>
          <p>
            15.4.6. გარკვეულ შემთხვევებში, როცა ეს ტექნიკურად შესაძლებელია და
            გამართლებულია, პირდაპირი მარკეტინგის მიზნით პერსონალურ მონაცემთა
            დამუშავებაზე თანხმობის გამოხმობა/პერსონალურ მონაცემთა დამუშავების
            შეწყვეტის მოთხოვნა (უარი) მომხმარებელს შეუძლია განახორციელოს
            დიფერენცირებულად (მაგალითად, კომპანიის მხოლოდ ცალკეული
            პროდუქტების/მომსახურების თაობაზე სარეკლამო/მარკეტინგული
            შეთავაზებების მიღება ან კომპანიისაგან ნებისმიერი სახის
            სარეკლამო/მარკეტინგული შეთავაზებების მიღება).
          </p>
          <p>
            15.4.7. ყოველგვარი გაუგებრობისაგან თავის აცილებით მიზნით, კომპანია
            განმარტავს, რომ მომხმარებლის მიერ თანხმობის გამოხმობა არ ეხება
            კომპანიისაგან ისეთი საინფორმაციო ხასიათის შეტყობინებების მიღებასა და
            კომუნიკაციას, რომელიც ემსახურება მომხმარებლის ინფორმირებას კომპანიის
            სერვისების/მომსახურების თაობაზე, მათ შორის, მომხმარებლის მიერ
            შესრულებული ოპერაციების/ტრანზაქციების თაობაზე, ინფორმაცია
            მომსახურების შეზღუდვაზე, სერვისის შეფასების მიზნით კომუნიკაცია,
            პოლიტიკის ცვლილება და სხვა.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            15.4.8. კომპანია უზრუნველყოფს პირდაპირი მარკეტინგის მიზნით
            მომხმარებლის პერსონალური მონაცემების დამუშავების შეწყვეტას,
            მომხმარებლის შესაბამისი მოთხოვნის მიღებიდან არაუგვიანეს მომდევნო 7
            (შვიდი) სამუშაო დღის ვადაში.
          </p>
          <p>
            15.4.9. პირდაპირი მარკეტინგის მიზნით მომხმარებლის პერსონალურ
            მონაცემთა დამუშავება ავტომატურად შეწყდება, მომხმარებლის რეგისტრაციის
            ნებისმიერი მიზეზით გაუქმებიდან არაუგვიანეს მომდევნო 7 (შვიდი)
            სამუშაო დღის ვადაში.
          </p>
          <p>
            15.5. პირდაპირი მარკეტინგის მიზნით დამუშავებული პერსონალური
            მონაცემების გადაცემა მესამე პირებზე
          </p>
          <p>
            15.5.1. შესაბამისი კანონიერი საფუძვლისა და მიზნობრიობის არსებობისას,
            პირდაპირი მარკეტინგის მიზნით დამუშავებულიპერსონალურ მონაცემები
            კომპანიამ შეიძლება გადასცეს შემდეგ მესამე პირებს (მონაცემთა
            მიმღებებს):{" "}
          </p>
          <p>
            (1) მომსახურების/სახელშეკრულებო ვალდებულებების შესრულების მიზნით,
            პირდაპირი მარკეტინგის მიზნით დამუშავებული პერსონალური მონაცემები
            შეიძლება გადაეცეს მომსახურების გამწევ პირებს/კონტრაქტორებს/პარტნიორ
            ორგანიზაციებს.{" "}
          </p>
          <p>
            (2) მაგალითად, საინფორმაციო-ტექნოლოგიური მომსახურების მიმწოდებლები,
            პროგრამული მხარდაჭერის მიმწოდებლები, პროგრამული უზრუნველყოფის
            მიმწოდებლები, პირდაპირი მარკეტინგის ფარგლებში მომსახურების
            მიმწოდებლები, მობილური საკომუნიკაციო მომსახურების მიმწოდებელი,
            სარეკლამო სააგენტოები, საგადახდო მომსახურების პროვაიდერები,
            საბანკო-საფინანსო დაწესებულებები ან/და იურიდიული მომსახურების
            მიმწოდებელი, პარტნიორი ორგანიზაციები/პირები, რომლებთან ერთად
            კომპანია მართავს ერთობლივ პროექტებს ან/და სხვა ღონისძიებებს.{" "}
          </p>
          <p>
            15.5.2. კანონით დადგენილ შემთხვევებში და როცა ეს საჭიროა
            კანონმდებლობით დაკისრებული მოვალეობის შესრულებისათვის, პირდაპირი
            მარკეტინგის მიზნით დამუშავებული პერსონალური მონაცემები შეიძლება
            გადაეცეს სხვა მესამე პირებს. მაგალითად, სამართალდამცავი/საგამოძიებო
            ორგანოები, საგადასახადო ორგანოები, ადმინისტრაციული ორგანოები,
            სასამართლო ან/და სხვა უფლებამოსილი პირები.
          </p>
          <p>
            15.5.3. მნიშვნელოვანი ლეგიტიმური ინტერესების დაცვის მიზნით,
            პირდაპირი მარკეტინგის მიზნით დამუშავებული პერსონალური მონაცემები
            შეიძლება გადაეცეს სხვა მესამე პირებს. მაგალითად, დანაშაულის ან
            სამართალდარღვევის გამოსავლენად, აღსაკვეთად და მისი პრევენციის
            მიზნით, პერსონალური მონაცემები შესაძლოა გადაეცეს სამართალდამცავ
            ორგანოებს, სასამართლოს ან/და ადმინისტრაციულ ორგანოებს.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            15.5.4. საწარმოს რეორგანიზაციისა ან გადაცემის მიზნით, პირდაპირი
            მარკეტინგის მიზნით დამუშავებული პერსონალური მონაცემები შეიძლება
            გადაეცეს ამ საწარმოს სამართალმემკვიდრეებს ან უფლებამონაცვლეებს.{" "}
          </p>
          <p>
            15.5.5. პირდაპირი მარკეტინგის მიზნით დამუშავებული პერსონალური
            მონაცემები შეიძლება გადაეცეს საქართველოს საზღვრებს გარეთ არსებულ
            მესამე პირებს მხოლოდ იმ შემთხვევაში, თუ მოქმედი კანონმდებლობის
            შესაბამისად ამ ქვეყანაში არსებობს მონაცემთა დაცვის სათანადო
            გარანტიები. სხვა შემთხვევაში, პირდაპირი მარკეტინგის მიზნით
            დამუშავებული პერსონალური მონაცემები სხვა სახელმწიფოში გადაცემა
            დასაშვებია, თუ პერსონალურ მონაცემთა დაცვის სათანადო გარანტიების
            არარსებობისა და შესაძლო საფრთხეების შესახებ ინფორმაციის მიღების
            შემდეგ, მომხმარებელი განაცხადებს წერილობით თანხმობას, არსებობს
            პერსონალურ მონაცემთა დაცვის სამსახურის მიერ გაცემული ნებართვა ან
            აღნიშნული გათვალისწინებულია კანონმდებლობით.{" "}
          </p>
          <p>
            <b>16. პოლიტიკის ცვლილება</b>
          </p>
          <p>
            16.1. კომპანიას უფლება აქვს ნებისმიერ დროს შეიტანოს ცვლილება
            პერსონალურ მონაცემთა დამუშავების პოლიტიკაში.{" "}
          </p>
          <p>
            16.2. თუ პოლიტიკაში განხორციელდა არსებითი შინაარსობრივი ცვლილება,
            კომპანია გონივრულ ვადაში შეატყობინებს მომხმარებელს მასში შეტანილი
            ცვლილების თაობაზე და კანონმდებლობით განსაზღვრულ შემთხვევაში,
            წინასწარ უზრუნველყოფს ამ ცვლილებებზე მომხმარებლის მხრიდან თანხმობის
            გამოხატვას.{" "}
          </p>
          <p>
            16.3. კომპანია უზრუნველყოფს წინამდებარე პოლიტიკის ცვლილებების
            თაობაზე ინფორმაციისა და პოლიტიკის ბოლო/მიმდინარე/აქტიური ვერსიის
            ხელმისაწვდომობას კომპანიისათვის ცნობილ მომხმარებლის საკომუნიკაციო
            არხზებზე, მათ შორის, ტელეფონის ნომერზე მოკლე ტექსტური შეტყობინების
            გაგზავნის ან ელექტრონული ფოსტის მისამართზე ელექტრონული კომუნიკაციის
            გზით, კომპანიის ვებგვერდზე/აპლიკაციაში ან/და სხვა შესაბამის სივრცეში
            საჯაროდ გამოქვეყნების/განთავსების ფორმით.
          </p>
          <p>
            16.4. სასურველია მომხმარებელმა პერიოდულად გადახედოს წინამდებარე
            პოლიტიკას, რომ განიახლოს ინფორმაცია პერსონალური მონაცემების
            დამუშავებისა და დაცვის თაობაზე.
          </p>
          <p>
            <b>17. საკონტაქტო ინფორმაცია</b>{" "}
          </p>
          <p>
            17.1. პერსონალურ მონაცემთა დამუშავების, დაცვის ან/და წინამდებარე
            პოლიტიკასთან დაკავშირებით შეკითხვების არსებობის შემთხვევაში,
            მომხმარებელს შუძლია მიმართოს კომპანიას წერილობით შემდეგ ელექტრონულ
            მისამართზე:{" "}
            <a style={{ color: "blue" }} href="mailto:support@keepz.me">
              support@keepz.me
            </a>
          </p>
          <p>ბოლო განახლების თარიღი: 3/25/24</p>
        </div>
      </div>
    </div>
  );
}

export default Ge;
