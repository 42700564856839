import { resetFailOrder } from "api";
import {
  SET_LOADING_PAYMENT,
  SET_PAYMENT_RESULT,
} from "features/bog/reducer/actions";

export const onCancelGoogleWallet = (
  err,
  qrType,
  integratorId,
  integratorOrderId,
  dispatch
) => {
  if (
    err.toString().includes("AbortError") &&
    qrType != "REQUEST" &&
    integratorOrderId
  ) {
    resetFailOrder({
      integratorId: integratorId,
      integratorOrderId: integratorOrderId,
    })
      .then((data) => {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: 100,
            group: 1,
          },
        });
      })
      .catch((err) => console.log(err, "error occured while resetting"));
  }

  dispatch({ type: SET_LOADING_PAYMENT, payload: false });
};

export const ceilToTwo = (num) => {
  let pair = `${num}e`.split("e");
  const value = Math.ceil(Number(pair[0] + "e" + (+pair[1] + 2)));
  pair = `${value}e`.split("e");
  return Number(pair[0] + "e" + (+pair[1] - 2));
};
