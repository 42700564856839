import { ReactComponent as Hlogo } from "assets/images/logo.svg";
import Button from "../Button/Button";
import React, { useEffect, useState } from "react";
import "./Header.css";

import { useQueryString } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";

import { Trans, useTranslation } from "react-i18next";
import PaymentType from "../PaymentType/PaymentType";
import storeIcon from "../../../assets/images/სავაჭრო ობიექტისთვის.png";

import distIcon from "../../../assets/images/Property 1=დისტანციური გადახდა.png";
import tipIcon from "../../../assets/images/Property 1=თიფები.png";

import bagIcon from "../../../assets/images/Property 1=ონლაინ გადახდები.png";

// import earthIcon from "../../../assets/images/Property 1=Keepz sub-merchnat.png";
import keepzLogo from "../../../assets/images/keepzLogo.png";
import keepzLogoBlue from "../../../assets/images/keepzLogoBlue.svg";
import burgerMenu from "../../../assets/images/menu.png";
import burgerMenuDark from "../../../assets/images/icon/burgerMenuDark.svg";
import QrIcon from "../../../assets/images/QRIcon.png";
// import bank from "../../../assets/images/bank.png";

import Accordion from "./Accordion";

const Header = ({ isWhite, openContactModel }) => {
  const { pathname } = useLocation();
  const { parseQuery: parsedQuery, getStringFromQuery } = useQueryString();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [scrollHomepageToTop, setScrollHomepageToTop] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [small, setSmall] = useState(false);

  const handleLangChange = () => {
    const newLang = i18n.language === "ge" ? "en" : "ge";
    const newQueryObj = { ...parsedQuery, lng: newLang };
    const newQueryString = getStringFromQuery(newQueryObj);
    navigate({
      pathname,
      search: newQueryString,
    });
  };

  const handleLogoClick = (location) => {
    if (location === "/") {
      setScrollHomepageToTop(true);
      return;
    }
    navigate("/");
  };

  function closeNav() {
    setIsNavOpen(false);
    document.body.style.overflow = "visible";
  }

  useEffect(() => {
    if (scrollHomepageToTop === false) return;
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollHomepageToTop(false);
  }, [scrollHomepageToTop]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => setSmall(window.scrollY > 20));
    }
  }, []);

  return (
    <>
      <header className="hidden  md:flex items-center      relative h-[90px] max-w-[1440px] m-auto">
        {showModal && (
          <div
            onMouseLeave={() => setShowModal(false)}
            className="flex gap-12 w-[100%] z-[10]   bg-white   absolute top-[240px]  left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[32px] p-[40px] pt-[25px] shadow-md shadow-black-500/10"
          >
            <div>
              <PaymentType
                src={QrIcon}
                name={
                  <Trans i18nKey={"paymentTypes.terminalForMerchant.title"} />
                }
                description={
                  <Trans
                    i18nKey={"paymentTypes.terminalForMerchant.headLines"}
                  />
                }
              />
              <PaymentType
                src={distIcon}
                name={<Trans i18nKey={"paymentTypes.distancePayment.title"} />}
                description={t("paymentTypes.distancePayment.headLines")}
                id={"2"}
              />
              <PaymentType
                src={bagIcon}
                name={<Trans i18nKey={"paymentTypes.onlinePayments.title"} />}
                description={
                  <Trans i18nKey={"paymentTypes.onlinePayments.headLines"} />
                }
                id={"3"}
              />

              {/* <PaymentType
                src={bagIcon}
                name={t("paymentTypes.onlinePayments.title")}
                description={t("paymentTypes.onlinePayments.headLines")}
                id={"6"}
              /> */}
            </div>
            <div>
              <PaymentType
                src={tipIcon}
                name={<Trans i18nKey={"paymentTypes.QRcheck.titleB"} />}
                description={
                  <Trans i18nKey={"paymentTypes.QRcheck.headLines"} />
                }
                id={"4"}
              />
              <PaymentType
                src={storeIcon}
                name={<Trans i18nKey={"paymentTypes.qrForRestaurant.title"} />}
                description={
                  <Trans i18nKey={"paymentTypes.qrForRestaurant.headLines"} />
                }
                id={"5"}
              />
              {/* <h2
                className="text-[25px] font-[700] text-[#8CC63F]"
                style={{ fontVariant: "all-petite-caps" }}
              >
                {t("paymentTypes.forFinancialInstitutions")}
              </h2>

              <PaymentType
                src={bank}
                name={<Trans i18nKey={"paymentTypes.qrForMobileBanks.title"} />}
                description={
                  <Trans i18nKey={"paymentTypes.qrForMobileBanks.headLines"} />
                }
                id={"7"}
              />
              <PaymentType
                src={earthIcon}
                name={"SUPER MERCHANT"}
                description={t("paymentTypes.keepzSubMerchant.headLines")}
                id={"8"}
              /> */}
            </div>
          </div>
        )}
        <div
          className="hover:cursor-pointer"
          onClick={() => handleLogoClick(pathname)}
        >
          <Hlogo />
        </div>
        <ul className=" flex flex-1 gap-[15px] items-center ms-[48px]">
          <li
            className="cursor-pointer hover:text-[#8CC63F] px-[8px]"
            onMouseEnter={() => setShowModal(true)}
          >
            {t("navigation.products")}
          </li>
          <li
            className="cursor-pointer hover:text-[#8CC63F] px-[8px]"
            onMouseEnter={() => setShowModal(false)}
            onClick={() => navigate("/")}
          >
            <a href="#price">{t("navigation.price")}</a>
          </li>
          <li
            className="cursor-pointer hover:text-[#8CC63F] px-[8px]"
            onMouseEnter={() => setShowModal(false)}
            onClick={() => {
              navigate("/");
              // Use a longer timeout to account for slower mobile devices
              setTimeout(() => {
                // Ensure the DOM is fully loaded before scrolling
                const checkAndScroll = () => {
                  const contactSection = document.getElementById("integration");
                  if (contactSection) {
                    contactSection.scrollIntoView({ behavior: "smooth" });
                  } else {
                    // Retry after a short delay if the section is not yet available
                    setTimeout(checkAndScroll, 100);
                  }
                };

                checkAndScroll();
              }, 400); // Increased timeout to 500ms
            }}
          >
            <a href="#integration">{t("navigation.howGetIn")}</a>
          </li>
          <li
            className="cursor-pointer hover:text-[#8CC63F] px-[8px]"
            onMouseEnter={() => setShowModal(false)}
            onClick={() => {
              if (pathname !== "/for-business") {
                navigate("/for-business");
              }
            }}
          >
            {t("navigation.forBusiness")}
          </li>
        </ul>
        <div className="flex gap-[25px] items-center">
          <button className="text-[#B3B5BE]" onClick={handleLangChange}>
            {i18n.language === "ge" ? "ENG" : "GEO"}
          </button>

          <a
            href="https://dashboard.keepz.me/login"
            target="_blank"
            className=" cursor-pointer"
            rel="noreferrer"
          >
            {t("navigation.logIn")}
          </a>
          <Button
            onClick={() => openContactModel()}
            className="border-2 solid bg-[#6C63FF] text-[#fff]  hover:bg-[#fff] hover:text-[#6C63FF] hover:border-[#6C63FF] py-[10px] px-[20px]  w-[133px] h-[50px]  "
          >
            {t("buttons.getStarted")}
          </Button>
        </div>
      </header>

      {/* ////////////////////////////////////////////// mobile ////////////////////////////////////////////////////// */}
      <header
        className={`md:hidden fixed top-0 w-[100%]   flex p-[16px] justify-between z-50 
        `}
        style={{
          boxShadow:
            small || (isWhite && small)
              ? "0px 5px 10px 0px rgba(0,0,0,0.20)"
              : "",
          background:
            small || isWhite
              ? "white"
              : "radial-gradient(143.13% 447.78% at 70.36% 161.31%, #170738 0%, #290C64 100%)",
        }}
      >
        <img
          src={small === false && isWhite === false ? keepzLogo : keepzLogoBlue}
          alt="logo"
          onClick={() => handleLogoClick(pathname)}
        />

        <img
          src={
            small === false && isWhite === false ? burgerMenu : burgerMenuDark
          }
          alt="menu"
          onClick={() => {
            window.scrollTo(0, 0);
            document.body.style.overflow = "hidden";
            setIsNavOpen(true);
          }}
        />
      </header>

      <div
        className={`fixed   w-screen h-screen  top-0 left-0 overflow-auto  p-[16px] bg-white z-[300]   ${
          isNavOpen ? "block" : "hidden"
        } `}
      >
        <div className="flex w-full justify-between items-center ">
          <Hlogo />
          <svg
            onClick={() => {
              closeNav();
            }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div className="w-full mt-[24px] flex flex-col  h-full justify-between  ">
          <div>
            <ul>
              <li className="border-b-[1px] py-[16px] border-[#D2D4DB]">
                <Accordion
                  isAccordionOpen={isAccordionOpen}
                  setIsAccordionOpen={setIsAccordionOpen}
                  closeNav={closeNav}
                />
              </li>
              <li
                className="border-b-[1px] py-[16px] border-[#D2D4DB]"
                onClick={() => {
                  closeNav();
                  navigate("/");
                  // Use a longer timeout to account for slower mobile devices
                  setTimeout(() => {
                    // Ensure the DOM is fully loaded before scrolling
                    const checkAndScroll = () => {
                      const contactSection = document.getElementById("price");
                      if (contactSection) {
                        contactSection.scrollIntoView({ behavior: "smooth" });
                      } else {
                        // Retry after a short delay if the section is not yet available
                        setTimeout(checkAndScroll, 100);
                      }
                    };

                    checkAndScroll();
                  }, 400); // Increased timeout to 500ms
                }}
              >
                <a>
                  <p className="text-[16px] font-[700]">
                    {t("navigation.price")}
                  </p>
                </a>
              </li>
              <li
                className="border-b-[1px] py-[16px] border-[#D2D4DB] "
                onClick={() => {
                  closeNav();
                  navigate("/");
                  // Use a longer timeout to account for slower mobile devices
                  setTimeout(() => {
                    // Ensure the DOM is fully loaded before scrolling
                    const checkAndScroll = () => {
                      const contactSection =
                        document.getElementById("integration");
                      if (contactSection) {
                        contactSection.scrollIntoView({ behavior: "smooth" });
                      } else {
                        // Retry after a short delay if the section is not yet available
                        setTimeout(checkAndScroll, 100);
                      }
                    };

                    checkAndScroll();
                  }, 400); // Increased timeout to 500ms
                }}
              >
                <p className="text-[16px] font-[700]">
                  {t("navigation.howGetIn")}
                </p>
              </li>
              <li
                className="border-b-[1px] py-[16px] border-[#D2D4DB]"
                onClick={() => {
                  if (pathname !== "/for-business") {
                    closeNav();
                    navigate("/for-business");
                  }
                }}
              >
                <p className="text-[16px] font-[700]">
                  {t("navigation.forBusiness")}
                </p>
              </li>
            </ul>

            <div>
              <div
                className="mt-[36px] flex justify-between items-center border-b-[1px] py-[10px] border-[#D2D4DB] "
                onClick={() => {
                  const newLang = "en";
                  const newQueryObj = { ...parsedQuery, lng: newLang };
                  const newQueryString = getStringFromQuery(newQueryObj);
                  navigate({
                    pathname,
                    search: newQueryString,
                  });
                }}
              >
                <p>English</p>
                {i18n.language === "en" && (
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.7566 24.9795C15.9983 24.9637 19.1029 23.6446 21.3957 21.3089C23.6886 18.9732 24.9843 15.8097 25.0015 12.5058C24.9828 9.20118 23.6869 6.03728 21.3947 3.69996C19.1026 1.36264 15.9989 0.0402738 12.7566 0.0195312C9.51363 0.0381124 6.40875 1.35979 4.11615 3.69757C1.82355 6.03536 0.528329 9.20051 0.511719 12.5058C0.528888 15.8097 1.82468 18.9732 4.1175 21.3089C6.41033 23.6446 9.51492 24.9637 12.7566 24.9795ZM11.175 18.8321C10.9371 18.8263 10.7037 18.7651 10.4926 18.6534C10.2814 18.5417 10.0981 18.3822 9.95662 18.1873L6.58927 14.0575C6.36343 13.8066 6.23817 13.4785 6.23825 13.1381C6.23677 12.9772 6.26658 12.8176 6.32596 12.6685C6.38535 12.5195 6.47313 12.3838 6.58423 12.2695C6.69534 12.1552 6.82756 12.0645 6.97327 12.0026C7.11898 11.9407 7.27528 11.9088 7.43315 11.9088C7.63269 11.91 7.82913 11.9592 8.00646 12.0525C8.18379 12.1457 8.33698 12.2804 8.45356 12.4455L11.1372 15.8359L16.3076 7.45657C16.4064 7.25543 16.5563 7.08496 16.7417 6.96295C16.9271 6.84094 17.1412 6.77186 17.3617 6.76289C17.5187 6.75477 17.6757 6.77829 17.8237 6.8321C17.9717 6.88591 18.1079 6.96895 18.2244 7.07648C18.3409 7.184 18.4354 7.31389 18.5026 7.4587C18.5697 7.60352 18.6082 7.76042 18.6158 7.92041C18.5902 8.24221 18.481 8.55127 18.2995 8.81585L12.3566 18.1519C12.2315 18.3573 12.0577 18.5273 11.8512 18.6461C11.6447 18.765 11.4121 18.829 11.175 18.8321Z"
                      fill="#6C63FF"
                    />
                  </svg>
                )}
              </div>
              <div
                className="py-[10px] flex justify-between items-center"
                onClick={() => {
                  const newLang = "ge";
                  const newQueryObj = { ...parsedQuery, lng: newLang };
                  const newQueryString = getStringFromQuery(newQueryObj);
                  navigate({
                    pathname,
                    search: newQueryString,
                  });
                }}
              >
                <p>ქართული</p>
                {i18n.language === "ge" && (
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.7566 24.9795C15.9983 24.9637 19.1029 23.6446 21.3957 21.3089C23.6886 18.9732 24.9843 15.8097 25.0015 12.5058C24.9828 9.20118 23.6869 6.03728 21.3947 3.69996C19.1026 1.36264 15.9989 0.0402738 12.7566 0.0195312C9.51363 0.0381124 6.40875 1.35979 4.11615 3.69757C1.82355 6.03536 0.528329 9.20051 0.511719 12.5058C0.528888 15.8097 1.82468 18.9732 4.1175 21.3089C6.41033 23.6446 9.51492 24.9637 12.7566 24.9795ZM11.175 18.8321C10.9371 18.8263 10.7037 18.7651 10.4926 18.6534C10.2814 18.5417 10.0981 18.3822 9.95662 18.1873L6.58927 14.0575C6.36343 13.8066 6.23817 13.4785 6.23825 13.1381C6.23677 12.9772 6.26658 12.8176 6.32596 12.6685C6.38535 12.5195 6.47313 12.3838 6.58423 12.2695C6.69534 12.1552 6.82756 12.0645 6.97327 12.0026C7.11898 11.9407 7.27528 11.9088 7.43315 11.9088C7.63269 11.91 7.82913 11.9592 8.00646 12.0525C8.18379 12.1457 8.33698 12.2804 8.45356 12.4455L11.1372 15.8359L16.3076 7.45657C16.4064 7.25543 16.5563 7.08496 16.7417 6.96295C16.9271 6.84094 17.1412 6.77186 17.3617 6.76289C17.5187 6.75477 17.6757 6.77829 17.8237 6.8321C17.9717 6.88591 18.1079 6.96895 18.2244 7.07648C18.3409 7.184 18.4354 7.31389 18.5026 7.4587C18.5697 7.60352 18.6082 7.76042 18.6158 7.92041C18.5902 8.24221 18.481 8.55127 18.2995 8.81585L12.3566 18.1519C12.2315 18.3573 12.0577 18.5273 11.8512 18.6461C11.6447 18.765 11.4121 18.829 11.175 18.8321Z"
                      fill="#6C63FF"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div className="flex  justify-between items-center gap-[10px] mb-[50px]  py-[10px]   ">
            <Button
              className="bg-[#6C63FF] w-[50%] px-[15px]  py-[10px] text-[#fff] text-[16px]"
              onClick={() => {
                closeNav();
                openContactModel();
              }}
            >
              <Trans i18nKey={"buttons.getStarted"} />
            </Button>

            <a
              href="https://dashboard.keepz.me/login"
              target="_blank"
              className="bg-[#8CC63F] text-center w-[50%] rounded-[12px] px-[20px] py-[10px] text-[#fff] text-[16px] cursor-pointer"
              rel="noreferrer"
            >
              <Trans i18nKey={"navigation.logIn"} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
