import qs from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useQueryString = () => {
  const { search } = useLocation();

  const getStringFromQuery = (query) =>
    qs.stringify(query, {
      arrayFormat: "repeat",
      indices: true,
      encode: false,
      skipNulls: true,
    });

  const parseQuery = useMemo(
    () =>
      qs.parse(search, {
        ignoreQueryPrefix: true,
      }),
    [search]
  );

  return {
    getStringFromQuery,
    parseQuery,
    search,
  };
};

export default useQueryString;
