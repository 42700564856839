export default function TbcBankIcon(props) {
  return (
    <svg
      width="41"
      height="56"
      viewBox="0 0 41 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.1278 13.9524L36.382 40.0585C36.382 40.0585 36.2477 40.7302 35.9132 40.3568C33.475 38.4391 30.9321 36.6586 28.2961 35.0235C26.0997 33.7661 22.3409 32.3797 22.1488 32.2508C21.3105 31.523 20.7531 30.525 20.573 29.4296C20.0407 25.4495 19.8551 21.4306 20.0182 17.4184C20.0339 16.3391 20.1541 15.2638 20.3768 14.2077C20.5206 13.7926 20.9921 13.716 21.1278 13.9524Z"
        fill="#00A3E0"
      />
      <mask
        id="mask0_2575_3400"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="5"
        y="33"
        width="32"
        height="10"
      >
        <path
          d="M5.53125 33.3975H36.6213V42.4049H5.53125V33.3975Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2575_3400)">
        <path
          d="M6.24043 41.8514C9.38975 40.5596 12.4268 39.0094 15.3204 37.2167C18.2221 35.1478 19.626 34.2115 19.9941 33.9361C20.3622 33.6607 20.8673 33.2859 24.2029 34.4909C27.7919 35.988 31.212 37.8613 34.406 40.0794C35.3235 40.8049 37.3023 42.4478 35.5304 42.4062C33.7584 42.3645 13.9193 42.3901 6.41238 42.4062C5.94757 42.4062 5.30409 42.2127 6.24043 41.8514Z"
          fill="#00A3E0"
        />
      </g>
      <path
        d="M19.5122 14.4622C19.5391 15.5087 19.081 20.709 19.0178 21.6682C18.9547 22.6274 18.9157 26.757 18.9695 27.8317C19.0205 28.862 19.4813 31.7168 18.815 32.6115C15.4327 36.124 11.347 38.883 6.82521 40.7081C3.78511 41.8983 3.93557 41.2454 5.34748 38.693C6.51085 36.604 19.4974 13.8913 19.5122 14.4622Z"
        fill="#00A3E0"
      />
    </svg>
  );
}
