module.exports = {
  API_URL: process.env.REACT_APP_API_URL,
  MERCHANT_NAME: process.env.REACT_APP_MERCHANT_NAME,
  BOG_MERCHANT_ID: process.env.REACT_APP_MERCHANT_ID,
  ACCOUNT_ID: process.env.REACT_APP_ACCOUNT_ID,
  POSSID: process.env.REACT_APP_POSID,
  GPAY_MERCHANT_ID: process.env.REACT_APP_GPAY_MERCHANT_ID,
  BOG_URL: process.env.REACT_APP_BOG_URL,
  GPAY_GATEWAY_MERCHANT_ID: process.env.REACT_APP_GPAY_GATEWAY_MERCHANT_ID,
};
