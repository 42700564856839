import ModalVideo from "react-modal-video";
import UsageCard from "./UsageCard";
import { useState } from "react";
import { Trans } from "react-i18next";

// images
import imgOne from "../../../assets/images/usageImage10.png";
import imgTwo from "../../../assets/images/usageImage11.png";
import imgThree from "../../../assets/images/usageImage12.png";
import imgFour from "../../../assets/images/usageImage13.png";
import imgFive from "../../../assets/images/usageImage14.png";
import imgSix from "../../../assets/images/usageImage15.jpg";

const usageCasesData = [
  {
    img: imgOne,
    text: "erp",
    videoId: "fJB89MyALAQ",
  },
  {
    img: imgTwo,
    text: "qrTerminal",
    videoId: "cnwU1B4G6lk",
  },
  {
    img: imgThree,
    text: "qrTerminalBeautySalon",
    videoId: "HYuUIZsyeDI",
  },
  {
    img: imgFour,
    text: "cryptoPay",
    videoId: "2b20YB85QQA",
  },
  {
    img: imgFive,
    text: "parkingKeepz",
    videoId: "FVyRZn68iuI",
  },
  {
    img: imgSix,
    text: "taxiQr",
    videoId: "J1wnUKgrKSo",
  },
];
const UsageCases = () => {
  const [isOpen, setOpen] = useState(false);
  const [videoId, setVideoId] = useState();
  return (
    <>
      <p
        className="font-bold text-[#6C63FF]  sm:text-[30px] md:text-[40px] text-center md:pt-0 pt-20"
        style={{ fontVariant: "all-petite-caps" }}
      >
        <Trans i18nKey={"whyKeepz.useagExample"} />
      </p>

      <div className="flex flex-wrap gap-5  justify-center    sm:px-[16.5px] md:px-0">
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId={videoId}
          onClose={() => setOpen(false)}
        />

        {usageCasesData.map((el) => (
          <div
            className="cursor-pointer hover:opacity-[70%]"
            onClick={() => {
              setVideoId(el.videoId);
              setOpen(true);
            }}
          >
            <UsageCard
              isBlack={true}
              src={el.img}
              text={<Trans i18nKey={`whyKeepz.${el.text}`} />}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default UsageCases;
