import { createPortal } from "react-dom";

const Loader = () => {
  return createPortal(
    <div className="fixed top-0 left-0 bottom-0 right-0 w-full h-full bg-black-700 opacity-50 flex items-center justify-center z-30">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
      >
        <path
          d="M2 22C2 33.0457 10.9543 42 22 42C33.0457 42 42 33.0457 42 22C42 10.9543 33.0457 2 22 2"
          stroke="#CFF58D"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    </div>,
    document.body
  );
};

export default Loader;
