import React from "react";
import notFoundImg from "assets/images/not-found.svg";
import { Link } from "react-router-dom";
import hlogo from "assets/images/logo.svg";

export default function UserNotFound() {
  return (
    <div className="h-[100vh] w-full grid grid-rows-3 place-items-center px-6">
      <div className="text-center flex justify-center items-center row-span-2">
        <div className="flex flex-col items-center justify-center">
          <img src={notFoundImg} alt="not-found" />
          <p className="text-[17px] font-bold mt-[30px]">
            User or merchant not found
          </p>
          <p className="mt-[15px]">
            User or merchant may be inactive or deleted. Please make sure you
            scan the correct QR.
          </p>
        </div>
      </div>
      <div className="row-span-1">
        <Link to="/">
          <img src={hlogo} alt="logo" width={140} />
        </Link>
      </div>
    </div>
  );
}
