import React from "react";
// import En from "./En";
import Ge from "./Ge";
import Header from "features/newLanding/components/Header";
// import { useParams } from "react-router-dom";

const SupplementaryContractualProvisionsPage = () => {
//   const chosenLanguage = localStorage.getItem("i18nextLng");
//   const { isMobileApp } = useParams();

  return (
    <div className="w-full md:px-[200px]">
      <Header />
      <div className="pt-4 pb-12 px-6 md:px-0">
        {/* {chosenLanguage === "en" ? <En /> : <Ge />} */}
        <Ge />
      </div>
    </div>
  );
};

export default SupplementaryContractualProvisionsPage;
