const UsageCard = ({ src, text, isBlack = false }) => {
  return (
    <>
      {isBlack ? (
        <div
          className={`${
            !isBlack && "sm:h-[268px]"
          } sm2:h-auto sm:mt-[40px]  md:w-[400px] sm:w-[100%] `}
        >
          <img
            className="rounded-[12px] sm:h-[197 px]  md:h-[245px] sm:w-[100%] md:w-[100%]  m-auto"
            src={src}
            alt=""
          />
          <p
            className={`font-[700] ${
              !isBlack ? "text-white" : "text-black"
            } text-[18px] mt-[20px]`}
          >
            {text}
          </p>
        </div>
      ) : (
        <div className="sm:h-[268px] sm2:h-auto sm2:w-[400px] md:w-[353px]">
          <img className="rounded-[12px]  w-[100%]  m-auto" src={src} alt="" />
          <p className="font-[700] text-white text-[18px] mt-[20px]">{text}</p>
        </div>
      )}
    </>
  );
};

export default UsageCard;
