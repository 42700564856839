import { tipWithApplePay, tipWithCard, tipWithGooglePay } from "api";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TipModal from "./TipModal";

const CustomTip = () => {
  const [tip, setTip] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const transactionId = location.state?.transactionId;

  const paymentMethod = localStorage.getItem("paymentMethod");

  const handlTips = async () => {
    try {
      if (paymentMethod === "card") {
        console.log("-----card");
        const data = await tipWithCard(transactionId, tip, undefined);

        setIsSuccess(true);
        setShowModal(true);
        console.log(data);
      } else if (paymentMethod === "googlePay") {
        console.log("-----google");
        const data = await tipWithGooglePay(transactionId, tip, undefined);

        setIsSuccess(true);
        setShowModal(true);
        console.log(data);
      } else if (paymentMethod === "applePay") {
        console.log("-----apple");
        const data = await tipWithApplePay(transactionId, tip, undefined);
        setIsSuccess(true);
        setShowModal(true);
        console.log(data);
      }
    } catch (error) {
      console.log(error);
      setIsSuccess(false);
      setShowModal(true);
    }
  };

  return (
    <div className="w-full  flex  items-center h-full flex-col ">
      <div className="mt-6 flex flex-col justify-around">
        <div class="relative ">
          <input
            onChange={(e) => setTip(e.target.value)}
            type="number"
            id="floating_filled"
            className=" block md:w-[400px] rounded-[16px] px-2.5 pb-2.5 pt-5 w-full   bg-gray-50 dark:bg-gray-700 border-[2px]  border-[#6C63FF] appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=""
          />
          <label
            for="floating_filled"
            class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
            Custom tip
          </label>
        </div>
        <button
          className="w-[100%] p-[10px] rounded-[16px] bg-[#6C63FF] text-[#fff] m-auto mt-[20px]"
          onClick={handlTips}
        >
          Confirm
        </button>
      </div>
      {/* Render the modal conditionally */}
      {showModal && (
        <TipModal
          isSuccess={isSuccess}
          onClose={() => {
            setShowModal(false);
            navigate(-1);
          }} // Pass the closeModal function to close the modal
        />
      )}
    </div>
  );
};

export default CustomTip;
