export default function PhoneIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <mask
        id="mask0_946_20609"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.40918" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_946_20609)">
        <path
          d="M19.95 21.4092C17.8667 21.4092 15.8083 20.955 13.775 20.0467C11.7417 19.1383 9.89167 17.8508 8.225 16.1842C6.55833 14.5175 5.27083 12.6675 4.3625 10.6342C3.45417 8.60085 3 6.54251 3 4.45918C3 4.15918 3.1 3.90918 3.3 3.70918C3.5 3.50918 3.75 3.40918 4.05 3.40918H8.1C8.33333 3.40918 8.54167 3.48835 8.725 3.64668C8.90833 3.80501 9.01667 3.99251 9.05 4.20918L9.7 7.70918C9.73333 7.97585 9.725 8.20085 9.675 8.38418C9.625 8.56751 9.53333 8.72585 9.4 8.85918L6.975 11.3092C7.30833 11.9258 7.70417 12.5217 8.1625 13.0967C8.62083 13.6717 9.125 14.2258 9.675 14.7592C10.1917 15.2758 10.7333 15.755 11.3 16.1967C11.8667 16.6383 12.4667 17.0425 13.1 17.4092L15.45 15.0592C15.6 14.9092 15.7958 14.7967 16.0375 14.7217C16.2792 14.6467 16.5167 14.6258 16.75 14.6592L20.2 15.3592C20.4333 15.4258 20.625 15.5467 20.775 15.7217C20.925 15.8967 21 16.0925 21 16.3092V20.3592C21 20.6592 20.9 20.9092 20.7 21.1092C20.5 21.3092 20.25 21.4092 19.95 21.4092Z"
          fill="#9496A2"
        />
      </g>
    </svg>
  );
}
