export default function LibertyBankIcon(props) {
  return (
    <svg
      width="49"
      height="56"
      viewBox="0 0 49 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2575_3450)">
        <path
          d="M5.34736 30.6323V32.6123H0V23.6123H2.08678V30.6323H5.34736Z"
          fill="black"
        />
        <path
          d="M9.01256 23.6123H6.92578V32.6123H9.01256V23.6123Z"
          fill="black"
        />
        <path
          d="M15.3635 27.9455C15.62 27.7282 15.8243 27.4574 15.9618 27.1528C16.0993 26.8482 16.1666 26.5173 16.1588 26.184C16.1588 24.6275 14.8673 23.6123 13.2768 23.6123H10.5898V25.5459H13.2768C13.7592 25.5459 14.0721 25.8795 14.0721 26.3426C14.0774 26.4476 14.0606 26.5525 14.0227 26.6507C13.9848 26.749 13.9267 26.8384 13.8519 26.9134C13.7772 26.9884 13.6875 27.0472 13.5885 27.0863C13.4895 27.1253 13.3833 27.1437 13.2768 27.1402H12.1186V28.9607H13.5376C14.0593 28.9607 14.3986 29.3213 14.3986 29.8221C14.3986 30.3229 14.0593 30.6835 13.5376 30.6835H12.1186V32.6171H13.5376C15.1674 32.6171 16.4844 31.5759 16.4844 29.9816C16.4938 29.5761 16.3953 29.1753 16.1987 28.8191C16.002 28.4628 15.7141 28.1636 15.3635 27.9513"
          fill="#E03126"
        />
        <path
          d="M23.8285 30.6323V32.6123H18.0898V23.6123H23.7638V25.5923H20.1766V27.0841H23.4372V29.038H20.1766V30.6323H23.8285Z"
          fill="black"
        />
        <path
          d="M39.4568 25.5923H37.1092V32.6123H35.0234V25.5923H32.6758V23.6123H39.4568V25.5923Z"
          fill="black"
        />
        <path
          d="M45.9374 29.1531V32.6123H43.8506V29.1531L40.7852 23.6123H43.1593L44.894 27.0841L46.6277 23.6123H49.0018L45.9374 29.1531Z"
          fill="black"
        />
        <path
          d="M29.4234 29.3078C30.4276 28.807 31.1052 27.8034 31.1052 26.6974C31.1062 26.292 31.026 25.8903 30.8691 25.5156C30.7122 25.1408 30.4818 24.8003 30.191 24.5136C29.9002 24.227 29.5548 23.9998 29.1747 23.8451C28.7946 23.6904 28.3872 23.6113 27.976 23.6123H25.2852V25.5401H27.976C28.5497 25.5401 29.0194 26.0419 29.0194 26.7003C29.0194 27.3587 28.5497 27.8605 27.976 27.8605H26.6374V29.6549H27.4013L29.1243 32.6123H31.368L29.4234 29.3078Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2575_3450">
          <rect
            width="49"
            height="9"
            fill="white"
            transform="translate(0 23.6123)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
