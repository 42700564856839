import React from "react";

export default function BitCoinIcon(props) {
  return (
    <svg
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.4482 10.4524C21.8623 7.72084 19.7461 6.25472 16.8526 5.27359L17.7887 1.57204L15.4966 1.00901L14.5832 4.61021C13.9818 4.46527 13.3634 4.3259 12.7449 4.18654L13.6641 0.563036L11.372 0L10.4301 3.69597C9.93088 3.58448 9.44295 3.47299 8.96637 3.35592V3.34477L5.80621 2.5699L5.19915 4.97813C5.19915 4.97813 6.90121 5.36278 6.86149 5.38508C7.79195 5.61364 7.95648 6.2157 7.92812 6.69511L6.85582 10.9262L5.35801 16.8298C5.24453 17.1085 4.95518 17.521 4.3084 17.3649C4.33109 17.3984 2.64038 16.958 2.64038 16.958L1.5 19.539L4.48428 20.2693C5.04029 20.4087 5.58495 20.548 6.11826 20.6818L5.17078 24.4224L7.46289 24.9854L8.40469 21.2839C9.02878 21.4511 9.63585 21.6072 10.2316 21.7521L9.29544 25.437L11.5875 26L12.535 22.265C16.4441 22.9897 19.383 22.6998 20.6198 19.2268C21.6184 16.4284 20.5688 14.8173 18.5149 13.7637C20.0127 13.4237 21.1418 12.4537 21.4425 10.458L21.4482 10.4524ZM16.2115 17.666C15.5023 20.4644 10.7081 18.9537 9.1536 18.569L10.4131 13.6132C11.9677 13.9923 16.949 14.7504 16.2115 17.666ZM16.9207 10.4078C16.2739 12.9498 12.2854 11.6565 10.9918 11.3443L12.1322 6.84563C13.4258 7.16338 17.5958 7.75429 16.9207 10.4078Z"
        fill="#170738"
      />
    </svg>
  );
}
