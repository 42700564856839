import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "./Modal.css";

const Modal = ({
  children,
  onClose,
  show,
  title,
  position = "center",
  width = "100%",
}) => {
  const nodeRef = useRef(null);

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      unmountOnExit
      nodeRef={nodeRef}
      timeout={{
        enter: 100,
        exit: 500,
      }}
    >
      <div className="modal z-10" ref={nodeRef} onClick={onClose}>
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <div
            className={`modal-content modal-content--${position}`}
            style={{ width: width }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("modal-root")
  );
};

export default Modal;
