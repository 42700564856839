export default function XIcon(props) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2575_3502)">
        <path
          d="M44.8986 12.8115H50.8781L38.4376 25.1317C33.9269 29.5942 26.6231 29.5942 22.1204 25.1317L9.67188 12.8115H15.6594L25.1142 22.17C27.9795 24.9952 32.5785 24.9952 35.4438 22.17L44.9067 12.8115H44.8986Z"
          fill="#23292F"
        />
        <path
          d="M15.5695 47.0674H9.58203L22.1028 34.675C26.6135 30.2125 33.9173 30.2125 38.42 34.675L50.9408 47.0674H44.9613L35.4262 37.6287C32.5609 34.8034 27.9619 34.8034 25.0966 37.6287L15.5615 47.0674H15.5695Z"
          fill="#23292F"
        />
      </g>
      <defs>
        <clipPath id="clip0_2575_3502">
          <rect
            width="59.1045"
            height="59.1045"
            fill="white"
            transform="translate(0.710938 0.387695)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
