import React from "react";

function En() {
  return (
    <div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p className="text-center font-bold">
            PERSONAL DATA PROCESSING POLICY OF USERS OF KEEPZ ME LLC{" "}
          </p>
          <p>
            <span className="font-bold">KEEPZ ME LLC,</span> a Company organized
            and established in accordance with the laws of Georgia,
            identification code: 405618915, legal/actual address: Georgia,
            Tbilisi, Saburtalo district, Mikheil Tamarashvili Avenue, N13h,
            office space N1, floor 1, block &quot;A&quot;. (hereinafter{" "}
            <span className="font-bold">Keepz.Me</span>,{" "}
            <span className="font-bold">Company</span> or{" "}
            <span className="font-bold">Person Eesponsible for Processing</span>
            ) provides acquiring and online transfer services for Users.{" "}
          </p>
          <p className="font-bold">PREAMBLE </p>
          <p>KEEPZ ME: </p>
          <p>
            (1) Honors and acknowledges basic human rights when Personal Data
            processing, including Inviolability of private life communication
            rights;
          </p>
          <p>
            (2) fulfills the responsibility to comply with applicable laws when
          </p>
          <p>(3) provides proper protection and safety to Personal Data;</p>
          <p>
            (4) will use Personal Data only in moderation and in good faith;
          </p>
          <p>
            (5) will deliver to the User full and exhaustive information on his
            Personal Data processing.
          </p>
          <p className="font-bold">
            1.<b> </b>POLICY OBJECTIVE AND SCOPE{" "}
          </p>
          <p>
            1.1. The Personal Data Processing Policy (hereinafter Personal Data
            Processing Policy or Policy) applies to any User who is registered
            and uses any type of service offered by Keepz.Me.{" "}
          </p>
          <p>
            1.2. The purpose of the policy is to describe as simply, clearly and
            comprehensibly as possible the process of Personal Data processing
            by Keepz Me, including what types of Personal Data are processed and
            how they are collected, on what basis and purpose Personal Data is
            processed and with whom it is shared, what rights and legal
            protection mechanisms available to Users regarding Personal Data
            protection, as well as how Personal Data security is ensured.{" "}
          </p>
          <p className="font-bold">
            2.<b> </b>DEFINITION OF TERMS{" "}
          </p>
          <p>
            The terms used in the policy have the meaning defined by the Law of
            Georgia &quot;On Personal Data Protection&quot; and other
            legislative acts.{" "}
          </p>
          <p className="font-bold">
            Terms and definitions used in the Policy have the meanings set forth
            below, unless the context of the Policy clearly indicates otherwise:{" "}
          </p>
          <p>
            <span className="font-bold">
              2.1. Keepz Me LLC., the Company or the Person Responsible for the
              Processing
            </span>{" "}
            &#8211; Keepz.Me LLC, a Company organized and established in
            accordance with the laws of Georgia, identification code: 405618915,
            legal/actual address: Georgia, Tbilisi, Saburtalo district, Mikheil
            Tamarashvili avenue, N13h, office space N1, floor 1, block
            &quot;a&quot;;{" "}
          </p>
          <p>
            <span className="font-bold">2.2. User or Data Subject</span> &#8211;
            A natural and/or legal person who uses or has used the Company's
            services and whose Personal Data is processed by Keepz.Me for the
            purposes of offering and providing this service.{" "}
          </p>
          <p>
            <span className="font-bold">2.3. Personal Data or Data</span>{" "}
            &#8211; Any information that relates to an identified or
            identifiable user. A user is identifiable when it is possible to
            identify the user directly or indirectly, including by name,
            surname, identification number, geolocation data, identifiable
            electronic communication data, physical, physiological, mental,
            psychological, genetic, economic, cultural or social
            characteristics.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <span className="font-bold">2.4. Data Processing</span> &#8211; Any
            actions performed with respect to Personal Data, including their
            collection, acquisition, access to, organization, grouping,
            interconnection, storage, modification, recovery, retrieval, use,
            blocking, deletion or destruction, as well as disclosure of Personal
            Data by their transfer, disclosure, by distributing or otherwise
            making available;{" "}
          </p>
          <p>
            <span className="font-bold">
              2.5. Persons Responsible for Joint Processing
            </span>{" "}
            &#8211; two or more persons responsible for processing who jointly
            determine the purposes and means of Personal Data processing;{" "}
          </p>
          <p>
            <span className="font-bold">2.6. Person Authorized to Process</span>{" "}
            &#8211; natural person (except for employees of Keepz.Me LLC) legal
            entity or public institution that processes Personal Data for Keepz
            Me LLC or on behalf of Keepz Me LLC;{" "}
          </p>
          <p>
            <span className="font-bold">2.7. Data Recipient</span> &#8211; a
            natural person, legal entity or public institution to which the data
            was transferred, except for the Personal Data Protection Service;{" "}
          </p>
          <p>
            <span className="font-bold">2.8. Direct Marketing</span> &#8211;
            direct and immediate delivery of information to the user by phone,
            mail, e-mail or other electronic means, the formation and
            maintenance of interest in an individual and/or legal entity, goods,
            idea, service, work and/or initiative, as well as image and social
            issues, For sales and/or support purposes.{" "}
          </p>
          <p>
            <span className="font-bold">2.9. Direct marketing</span> &#8211; For
            the User of the phone,mail,electronic mail or other electronic by
            means of,information Direct and direct Delivery physical person
            or/and legal person,goods,of the idea,of service,of work or/and
            start,See also Image and social to the topic of interest
            formation,maintenance,realization or/and support purpose.{" "}
          </p>
          <p>
            <span className="font-bold">2.10. Profiling</span> &#8211; any form
            of automatic processing of Personal Data, which involves the use of
            Personal Data to evaluate certain personal characteristics related
            to an individual, in particular, the analysis and prediction of
            those characteristics that concern the quality of the individual's
            work performance, his economic situation, health, personal
            interests, reliability, behavior, location or movement;{" "}
          </p>
          <p>
            <span className="font-bold">2.11. Business Day</span> &#8211;
            Calendar days, Saturdays, Sundays and holidays defined by Georgian
            legislation.{" "}
          </p>
          <p className="font-bold">
            3.<b> </b>PURPOSE OF PERSONAL DATA PROCESSING{" "}
          </p>
          <p>
            3.1. Keepz Me LLC processes the Personal Data of Users for the
            purposes of providing services effectively and efficiently, namely:{" "}
          </p>
          <p>
            3.1.1. User registration on the Company's website and/or through the
            application;{" "}
          </p>
          <p>
            3.1.2. preparing a QR code and a dashboard for the User for the
            purpose of displaying information on transfers;
          </p>
          <p>
            3.1.3. for the purposes of providing payment system services and
            open banking regulations established by the National Bank of
            Georgia;{" "}
          </p>
          <p>
            3.1.4. checking/verifying the identity of Users in order to ensure
            the accuracy and/or updating of Personal Data, as well as data
            security, and for this purpose communication with the User in oral
            or written (electronic) form;{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            3.1.5. consideration and resolution of User statements,
            advertisements, claims and/or complaints, including communication
            with the User for this purpose;{" "}
          </p>
          <p>
            3.1.6. in order to improve and develop the quality of services and
            services/products, to carry out customer satisfaction and other
            surveys and for this purpose to communicate with the customer in
            oral or written (electronic) form;{" "}
          </p>
          <p>
            3.1.7. identification of services/products of interest to the User,
            development and/or analysis of Personal Data and their
            grouping/segmentation for personalized offers tailored to the User's
            interests and preferences;{" "}
          </p>
          <p>
            3.1.8. in order to provide the service, the Company effectively
            fulfills its legal duties and/or contractual obligations.{" "}
          </p>
          <p className="font-bold">
            4.<b> </b>BASIS AND FORM OF PERSONAL DATA PROCESSING{" "}
          </p>
          <p>
            4.1. The company processes the user's Personal Data when there is
            one of the following grounds:{" "}
          </p>
          <p>
            4.1.1. By registering on the portal of Keepz Me LLC, the User
            expressed his consent to the processing of his Personal Data for the
            purpose of providing services;{" "}
          </p>
          <p>
            4.1.2. The processing of Personal Data is necessary for the purpose
            of providing services, to fulfill the obligation assumed by the
            transaction concluded with the customer or to conclude a transaction
            at the customer's request;{" "}
          </p>
          <p>
            4.1.3. Processing of Personal Data is necessary to consider the
            User's application in connection with the provision of the Company's
            services;{" "}
          </p>
          <p>
            4.1.4. Processing of Personal Data is necessary for the Company to
            fulfill its obligations under the legislation of Georgia;{" "}
          </p>
          <p>
            4.1.5. The processing of Personal Data is necessary to protect
            important legitimate interests of the Company or a third party,
            unless there is an overriding interest in protecting the rights of
            the User;{" "}
          </p>
          <p>
            4.2. The processing of the User's Personal Data may also be carried
            out on one of the following grounds:{" "}
          </p>
          <p>4.2.1. Processing of Personal Data is provided by law; </p>
          <p>
            4.2.2. According to the law, the Personal Data is publicly available
            or the User has made it publicly available;
          </p>
          <p>
            4.2.3. Processing of Personal Data is necessary to protect an
            important public interest;{" "}
          </p>
          <p>
            4.2.4. The processing of Personal Data is necessary for the
            performance of tasks assigned to the sphere of public interest
            defined by the legislation of Georgia, including crime prevention,
            crime investigation, criminal prosecution, justice, execution of
            imprisonment and imprisonment, execution of non-custodial sentences
            and probation, operative-search activities, public safety, For the
            purposes of law enforcement, including information security and
            cyber security.{" "}
          </p>
          <p>
            4.3. Processing of personal data is carried out by semi-automatic
            means, which means that the company processes personal data using a
            combination of automatic means (data processing using information
            technologies) and non-automatic means (data processing without using
            information technologies).{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p className="font-bold">
            5.<b> </b>CATEGORIES OF PROCESSED PERSONAL DATA AND SOURCES OF
            ACQUISITION{" "}
          </p>
          <p>
            5.1. Company Users' Data will be obtained by the following means:{" "}
          </p>
          <p>
            5.1.1. By collecting directly from the User, which the User
            discloses when registering on the Company's website and/or
            application.{" "}
          </p>
          <p>
            5.1.2. By means of communication with the customer, including
            telephone connection, e-mail correspondence or in any other way,
            when considering his request for the provision of information
            related to the provision of services;{" "}
          </p>
          <p>
            5.1.3. in the process of reviewing User statements, advertisements,
            claims and/or complaints;{" "}
          </p>
          <p>
            5.1.4. When communicating with the customer, which is related to the
            provision of services by the Company and the implementation of
            satisfaction and other surveys for the purpose of improving and
            developing the quality of its services/products, in the form of
            answers given by the customer to the questions asked by the Company,
            including information about the quality of service, satisfaction and
            requirements;{" "}
          </p>
          <p>
            5.2. The data collected includes, among othersany data,with which
            happens User identify and with him Communication(name, last name,
            personal number, birth date, age, address, phone number, electronic
            mail address and other Identifiable/Contact information).{" "}
          </p>
          <p>
            5.3. Before the provision of Personal Data, the Company provides
            proper information to the User whether the provision of specific
            Personal Data to the Company is mandatory or only voluntary:{" "}
          </p>
          <p>
            5.3.1. The Company collects and processes Personal Data of the User,
            the provision of which is mandatory, and without providing them, the
            User cannot join and use the Company's services (for example, name,
            surname, personal number, etc.);{" "}
          </p>
          <p>
            5.3.2. The Company collects and processes Personal Data of the User,
            the provision of which is not mandatory and the User discloses
            according to his own wishes.{" "}
          </p>
          <p>
            5.4. The company may collect and process personal data received from
            third parties, only if the user has given consent to this.{" "}
          </p>
          <p>
            5.5. The Company may collect and process the User's personal data
            also from publicly available sources (if any).{" "}
          </p>
          <p>
            5.6. From the moment when the user registers on the company's
            website or application, the company processes the personal data of
            the following categories of users:{" "}
          </p>
          <p>
            <span className="font-bold">
              5.6.1. identification and contact data
            </span>{" "}
            - Information that identifies and allows communicates with the user
            (for example, first and last name, address, telephone number, e-mail
            address, code word, identity number, date of birth, age, etc.);{" "}
          </p>
          <p>
            <span className="font-bold">5.6.2. personal characteristic</span> -
            information related to specific characteristics/characteristics of
            the User (for example, age, gender, date of birth, etc.);{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <span className="font-bold">5.6.3. behavioral data</span> -
            information related to the User's history of using the Company's
            services/products;{" "}
          </p>
          <p>
            <span className="font-bold">5.6.4. financial data</span> -
            Information related to the use of the customer's bank card and
            payments, including, but not limited to, customer account numbers,
            iban, etc. If necessary, also data on the User's monthly turnover,
            transactions and activities.{" "}
          </p>
          <p>
            <span className="font-bold">5.6.5. communication data</span> -
            information/records related to the User's communication/activity
            with the Company;{" "}
          </p>
          <p>
            <span className="font-bold">5.6.6. documentary data</span> -
            physical or electronic documents containing Personal Data (for
            example, passport, identity document, birth certificate, etc.);{" "}
          </p>
          <p>
            <span className="font-bold">5.6.7. internal identifier</span> -
            information about the User's dashboard;
          </p>
          <p>
            <span className="font-bold">5.6.8. contractual data</span> -
            information about the services/products provided by the Company to
            the User;
          </p>
          <p>
            <span className="font-bold">5.6.9. data related to research</span> -
            Information, including evaluations, related to the Company's
            services and in the process of carrying out satisfaction and other
            studies for the purpose of improving and developing the quality of
            its services/products, the answers provided by the User to the
            questions asked by the Company, including information about the
            quality of service, satisfaction and requirements;{" "}
          </p>
          <p>
            <span className="font-bold">5.6.10. Segmentation data</span> -
            Information used to divide Users into different segments or groups,
            for profiling (for example, consumption habits, personal interests,
            etc.).{" "}
          </p>
          <p className="font-bold">
            6.<b> </b>PERSONAL DATA RETENTION PERIOD{" "}
          </p>
          <p>
            6.1. The storage of personal data is carried out by the user for the
            period of registration on the company's website and/or application,
            and from the moment of cancellation of the active profile for any
            reason or from the moment of withdrawal/refusal of consent to the
            processing of personal data, for the next 3 years.{" "}
          </p>
          <p>
            6.2. In the cases provided for by law and/or if there is a need to
            protect important legitimate interests of the person responsible for
            the processing or a third party, personal data will be stored for a
            period longer than the 3-year period indicated above, for a period
            necessary to achieve specific goals.{" "}
          </p>
          <p>
            6.3. After the expiration of the aforementioned data storage period
            and/or the achievement of the purpose for which the personal data is
            processed, the data may be stored for statistical/analytical
            purposes in a depersonalized form. This refers to the processing of
            data in such a way that it is impossible to connect them to the user
            or establishing such a connection requires disproportionately large
            efforts, costs and/or time.{" "}
          </p>
          <p className="font-bold">
            7.<b> </b>TRANSFER OF PERSONAL DATA TO THIRD PARTIES{" "}
          </p>
          <p>
            7.1. In the presence of the relevant legal basis and purpose, the
            company may transfer the user's personal data to the following third
            parties (data recipients):{" "}
          </p>
          <p>
            7.1.1. In order to fulfill the service/contractual obligations,
            Personal Data may be transferred to service
            providers/contractors/partner organizations. For example,
            information technology service{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            providers, software support providers, software providers, direct
            marketing service providers, mobile communication service providers,
            advertising agencies, payment service providers, banking and
            financial institutions and/or legal service providers, partner
            organizations/individuals, with which the Company manages joint
            projects and/or other events. Also, in the presence of the relevant
            legal basis and purpose, Personal Data may be transferred to the
            State Services Development Agency of the State Services Development
            Agency, the National Bank of Georgia or another public institution.{" "}
          </p>
          <p>
            7.1.2. In the cases established by the law and when it is necessary
            to fulfill the duty imposed by the law, Personal Data may be
            transferred to other third parties. For example, law
            enforcement/investigative authorities, tax authorities,
            administrative authorities, courts and/or other authorized persons.{" "}
          </p>
          <p>
            7.1.3. In order to protect important legitimate interests, Personal
            Data may be transferred to other third parties. For example, in
            order to detect, prevent and prevent a crime or offence, Personal
            Data may be transferred to law enforcement, judicial and/or
            administrative authorities.{" "}
          </p>
          <p>
            7.1.4. In order to reorganize or transfer the enterprise, Personal
            Data may be transferred to the legal heirs or assignees of this
            enterprise.{" "}
          </p>
          <p>
            7.2. Personal data may be transferred to third parties located
            outside the borders of Georgia only if there are appropriate
            guarantees of data protection in accordance with the applicable
            legislation in this country. In other cases, the transfer of
            Personal Data to another country is allowed if, after receiving
            information about the lack of adequate personal data protection
            guarantees and possible threats, the user applies for written
            consent, there is a permit issued by the Personal Data Protection
            Service or this is provided for by law.{" "}
          </p>
          <p className="font-bold">
            8.<b> </b>RIGHTS RELATED TO PERSONAL DATA AND THEIR USE{" "}
          </p>
          <p>
            8.1. The right to receive information about personal data processing{" "}
          </p>
          <p>
            8.1.1. The User has the right to request the Company to confirm
            whether it is processing his Personal Data, whether the processing
            of Personal Data is justified or not, and to receive the following
            information free of charge upon request:{" "}
          </p>
          <p>
            (1) about the personal data being processed about him, as well as
            about the basis and purpose of the processing of this personal data;{" "}
          </p>
          <p>
            (2) about the source of personal data collection/retrieval; (3)
            about the term of personal data storage, and if it is impossible to
            determine a specific term,{" "}
          </p>
          <p>
            about the criteria for determining the term; (4) information on all
            the rights related to personal data protection, which the user has
            been{" "}
          </p>
          <p>
            granted by law or provided by this policy; (5) about the legal basis
            and purposes of the transfer of personal data, as well as the
            appropriate{" "}
          </p>
          <p>
            guarantees of data protection, if the personal data is transferred
            to another state or international organization;{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            (6) about the identity of the recipient of the personal data or the
            categories of the recipients of the data, including information
            about the basis and purpose of the transfer of the personal data, if
            the personal data is transferred to a third party;{" "}
          </p>
          <p>
            (7) about the decision made as a result of automated processing,
            including profiling, and the logic used to make such a decision, as
            well as its impact on the processing of personal data and the
            expected/probable result of the processing.{" "}
          </p>
          <p>
            8.1.2. Unless otherwise stipulated by the law, the User has the
            right to choose the form of providing information about Personal
            Data processing. In addition, if the User does not require the
            provision of information in another form, the information will be
            provided to him in the same form in which the information was
            requested.{" "}
          </p>
          <p>
            8.1.3. The User has the right to receive information about Personal
            Data processing no later than 10 (ten) working days after his
            request. In special cases and with proper justification, this period
            can be extended by no more than 10 (ten) working days, about which
            the customer will be notified immediately.{" "}
          </p>
          <p>
            8.1.4. In order to ensure the principle of transparency, the Company
            will provide the User with all the necessary information related to
            the processing of Personal Data, except when the disclosure of
            information is against the law.{" "}
          </p>
          <p>8.2. The right to access and receive a copy of personal data. </p>
          <p>
            8.2.1. The User has the right to get acquainted with the Personal
            Data that the Company processes and/or to receive copies of this
            Personal Data in a form protected by the company, or to request the
            provision of copies of personal data in a different form, if this is
            technically possible (for example, printing the information in
            material form, recording it on another information carrier in
            electronic form etc).{" "}
          </p>
          <p>
            8.2.2. Due to the resource spent and/or the frequency of requests
            for the release of Personal Data in a form different from the form
            of storage in the Company, the Company has the right to request the
            User to pay a corresponding fee for the disclosure of Personal Data
            and/or the release of copies of data, in accordance with the rules
            established by the Company.{" "}
          </p>
          <p>
            8.2.3. The User has the right to get acquainted with the Personal
            Data and/or to receive their copies no later than 10 (ten) working
            days after the request (if another period is established by the
            legislation of Georgia). In special cases and with proper
            justification, this period can be extended by no more than 10 (ten)
            working days, about which the customer will be notified immediately.{" "}
          </p>
          <p>
            8.3. right Personal Data on alignment, to update and on filling{" "}
          </p>
          <p>
            8.3.1. The User has the right to request the Company to correct,
            update and/or fill in false, inaccurate and/or incomplete Personal
            Data about him (this applies to the case when the User discovers
            that his individual Personal Data is false, inaccurate or
            incomplete).{" "}
          </p>
          <p>
            8.3.2. No later than 10 (ten) working days after receiving the
            User's request (unless another period is established by the
            legislation of Georgia), the Company ensures the correction,
            updating and/or{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            completion of the incorrect, inaccurate and/or incomplete relevant
            Personal Data or informs the User of the grounds for refusing the
            request and provides information on the refusal Regarding the appeal
            procedure.{" "}
          </p>
          <p>
            8.3.3. If the Company, independently of the User, discovers that the
            Personal Data in the Company's possession is false, inaccurate
            and/or incomplete, the Company will ensure that the relevant data is
            corrected, updated and/or completed within a reasonable period of
            time, and within 10 (ten) working days after the correction of the
            Personal Data, it will inform the User about this. Exceptions to the
            above are cases when:{" "}
          </p>
          <p>
            (1) Correcting, updating and/or completing Personal Data is related
            to correcting/eliminating technical errors;{" "}
          </p>
          <p>
            (2) If there is an objective circumstance that makes it impossible
            to inform the User. In such a case, the Company will try to provide
            information about the change to the customer immediately after the
            first communication with him.{" "}
          </p>
          <p>
            8.4. right Personal Data processing on termination, on deletion or
            on destruction{" "}
          </p>
          <p>
            8.4.1. The User has the right to request the Company to stop
            processing, delete or destroy Personal Data (including profiling, if
            any).{" "}
          </p>
          <p>
            8.4.2. No later than 10 (ten) working days after receiving the
            User's request (unless otherwise established by the legislation of
            Georgia), the Company ensures the termination of the processing
            and/or deletion or destruction of the relevant Personal Data of the
            User or informs the User based on the refusal of his request and
            provides information on the procedure for appealing the refusal. .{" "}
          </p>
          <p>
            8.4.3. The Company has the right, with appropriate justification, to
            refuse the User's request to stop processing, delete or destroy
            Personal Data, if:{" "}
          </p>
          <p>
            (1) Personal Data Processing is carried out by law established other
            which one basis,except User of consent;{" "}
          </p>
          <p>
            (2) Personal Data is being processed legal of demand or of content
            justification purpose; (3) Personal Data Processing necessary
            expression or information of freedom of right to{" "}
          </p>
          <p>
            implement; (4) Personal Data is being processed by law provided for
            public for interests archiving{" "}
          </p>
          <p>
            purpose,Scientific or Historical research or statistical for
            purposes and Personal Data processing termination,will delete or
            destruction of right Implementation impossible will make or
            significantly will damage processing goals to achieve.{" "}
          </p>
          <p>
            8.4.4. No later than 10 (ten) working days after receiving the
            User's request, the Company shall ensure the termination, deletion
            or destruction of Personal Data processing and, at the same time,
            provide the User with information on the implementation of relevant
            actions within the same period.{" "}
          </p>
          <p>
            8.4.5. In the case of processing of Personal Data in a publicly
            accessible form, in addition to the termination, deletion or
            destruction of Personal Data, the User has the right to additionally
            request{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            the restriction of access to his Personal Data and/or the deletion
            of copies of Personal Data or any Internet link connecting to
            Personal Data (the so-called &quot;right to be forgotten&quot;).
            &quot;).{" "}
          </p>
          <p>8.5. Right to blocking of personal data </p>
          <p>
            8.5.1. The User has the right to request the blocking of Personal
            Data, which means the temporary suspension of the processing of
            Personal Data by the Company at his request and only the storage of
            this data.{" "}
          </p>
          <p>
            8.5.2. The User has the right to apply to the Company with a request
            to block personal data, if one of the following circumstances
            exists:{" "}
          </p>
          <p>
            (1) the User disputes the authenticity or accuracy of the Personal
            Data; (2) the processing of Personal Data is illegal, although the
            user objects to their deletion and requests{" "}
          </p>
          <p>
            the blocking of personal data; (3) the personal data are no longer
            necessary to achieve the purpose of their processing, although{" "}
          </p>
          <p>
            the customer needs them to submit a complaint/lawsuit; (4) the User
            requests the termination, erasure or destruction of personal data
            and this request is{" "}
          </p>
          <p>
            being considered; (5) There is a need to store personal data for use
            as evidence.{" "}
          </p>
          <p>
            8.5.3. No later than the next 3 (three) business days after
            receiving the User's request, the Company will notify the User of
            its decision to block Personal Data and, within the same period,
            will block the User's data for the duration of the reason for the
            blocking (also during this period, if it is technically possible
            with the Personal Data together with saving the blocking decision)
            or informs the User within the specified period of his decision to
            refuse the blocking of Personal Data, indicating the relevant
            grounds.{" "}
          </p>
          <p>
            8.5.4. Regardless of the User's request, the Company has the right
            not to block or unblock blocked Personal Data if there is one of the
            following grounds:{" "}
          </p>
          <p>
            (1) Personal Data block danger will create by law or/and by law and
            hers On the basis of Issued under the law normative by actCompanyfor
            imposed duties performance;{" "}
          </p>
          <p>
            (2) Personal Data block danger will create of the law Accordingly
            public of interest for the sphere belonging to tasks performance or
            of Georgia by lawCompanyfor granted authority implementation;{" "}
          </p>
          <p>
            (3) Personal Data block danger will createCompanyS or the third
            person legitimate interests,except that case,when exists Personal
            Data of the subject,especially of a minor,rights protection superior
            interest;{" "}
          </p>
          <p>
            (4) Personal Data block danger will create User or the third person
            vital interests,See also State security and Defense goals.{" "}
          </p>
          <p>
            8.5.5. In case of blocking of Personal Data, the data, in addition
            to their storage, may be processed differently in the following
            cases:{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            (1) If exists User Consent; (2) legal of demand or of content to
            justify; (3) User or the third person interests to protect; (4) of
            the law Accordingly,public of interest to protect.{" "}
          </p>
          <p>8.6. right to transfer Personal Data </p>
          <p>
            8.6.1. The Company processes the User's Personal Data automatically,
            if it is technically possible, on one of the following grounds:{" "}
          </p>
          <p>
            (1) User Consent; or (2) Personal Data processing necessity due to
            with the customer laid down with a deal duty{" "}
          </p>
          <p>obligation by performing or User by requested deal positive; </p>
          <p>
            8.6.2. The User has the right to request the Company to receive the
            provided Personal Data from other third parties in a structured,
            publicly usable and machine-readable format or to request the
            transfer of this Personal Data to another third party - a person
            responsible for other processing (so-called porting).{" "}
          </p>
          <p>
            8.7. The right to withdraw the consent given to the processing of
            Personal Data{" "}
          </p>
          <p>
            8.7.1. The User has the right to revoke (refuse) his consent to
            Personal Data processing at any time, without any explanation or
            justification.{" "}
          </p>
          <p>
            8.7.2. If there is no other basis for Personal Data processing, no
            later than 10 (ten) working days after receiving the User's request,
            the Company ensures the termination of Personal Data processing
            and/or the deletion or destruction of processed Personal Data.{" "}
          </p>
          <p>
            8.7.3. The User has the right to give his consent in the same form
            in which he gave his consent, and before giving his consent, he has
            the right to request and receive information from the Company about
            the possible consequences of giving his consent.{" "}
          </p>
          <p className="font-bold">
            9.<b> </b>LIMITATION OF RIGHTS RELATED TO PERSONAL DATA PROCESSING{" "}
          </p>
          <p>
            9.1. The User may have the following rights related to personal data
            restricted:{" "}
          </p>
          <p>
            9.1.1. the right to receive information about Personal Data
            processing; 9.1.2. the right to access and receive a copy of
            Personal Data; 9.1.3. the right to correct, update and complete
            Personal Data; 9.1.4. the right to stop processing, erasure or
            destruction of Personal Data; 9.1.5. right to block Personal Data;
            9.1.6. right to transfer Personal Data; 9.1.7. the right to withdraw
            the consent given to the processing of Personal Data; 9.1.8. the
            right to receive minimal information when Personal Data is collected
            directly from the User; 9.1.9. The right to receive minimal
            information when Personal Data is not collected directly from the{" "}
          </p>
          <p>User. </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            9.2. The above-mentioned rights can be limited only if it is
            directly provided by the legislation of Georgia, this does not
            violate basic human rights and freedoms, it is a necessary and
            proportionate measure in a democratic society, and the exercise of
            these rights may pose a threat:{" "}
          </p>
          <p>
            9.2.1. the interests of state security, information security and
            cyber security and/or defense; 9.2.2. public safety interests;
            9.2.3. crime prevention, crime investigation, criminal prosecution,
            administration of justice, execution{" "}
          </p>
          <p>
            of imprisonment and imprisonment, execution of non-custodial
            sentences and probation, operative-search activities;{" "}
          </p>
          <p>
            9.2.4. interests related to financial or economic (including
            monetary, budgetary and tax), public health and social protection
            issues important for the country;{" "}
          </p>
          <p>
            9.2.5. detection of violation of professional, including regulated
            profession, ethics norms by the User and imposition of
            responsibility;{" "}
          </p>
          <p>
            9.2.6. implementation of the functions and powers of regulatory
            and/or supervisory bodies in the above-mentioned areas;{" "}
          </p>
          <p>
            9.2.7. the rights and freedoms of the User and/or other persons,
            including freedom of expression; 9.2.8. protection of state,
            commercial, professional and other types of secrets stipulated by
            law; 9.2.9. substantiating a legal claim or objection.{" "}
          </p>
          <p>
            9.3. appropriate the basics in existence, the Companyinforms to the
            customer of right restriction and on implementation refusal says
            about the decision,except by law established cases.{" "}
          </p>
          <p>
            9.4. Companyprovides User up indicated rights implementation for
            free,except by law established Exceptions.{" "}
          </p>
          <p>
            9.5. User by of demand unreasonable frequency submission in case,
            the CompanyS right has refusal to tell him to the customer his of
            demand on performance,what also about to the customer in
            writing(them between,electronic in the form)informs and also,will
            give definition appeal of right about.{" "}
          </p>
          <p className="font-bold">
            10.<b> </b>INFORMING THE USER WHEN PERSONAL DATA IS COLLECTED
            DIRECTLY FROM HIM{" "}
          </p>
          <p>
            10.1. In the event that the company collects personal data directly
            from the user, the user has the right to request and receive at
            least the following information (hereinafter referred to as{" "}
            <span className="font-bold">Minimum Information</span>) before or
            after the collection of personal data:{" "}
          </p>
          <p>
            10.1.1. the identity/title and contact information of the person who
            processes Personal Data (meaning information about the person
            responsible for processing, his representative and/or the person
            authorized to process (if any));{" "}
          </p>
          <p>
            10.1.2. information about the purposes and legal basis of Personal
            Data processing; 10.1.3. Information about the obligation to provide
            Personal Data, and if the provision of Personal{" "}
          </p>
          <p>
            Data is mandatory - about the legal consequences of refusing to
            provide Personal Data, as well as information about the fact that
            the collection/retrieval of Personal Data is provided for by the{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            legislation of Georgia or is a necessary condition for concluding a
            contract (if such information exists);{" "}
          </p>
          <p>
            10.1.4. about important legitimate interests of the Company or a
            third party, if the processing of Personal Data is necessary to
            protect important legitimate interests of the Company or a third
            party;{" "}
          </p>
          <p>
            10.1.5. the identity and contact information of the Personal Data
            Protection Officer (if any); 10.1.6. the identity of the recipient
            of Personal Data or categories of data recipients (if any); 10.1.7.
            Information about the planned transfer of Personal Data and the
            existence of appropriate{" "}
          </p>
          <p>
            safeguards for the protection of Personal Data, including permission
            for the transfer of Personal Data (if any), if the person
            responsible for processing plans to transfer the data to another
            state or international organization;{" "}
          </p>
          <p>
            10.1.8. Information about the term of Personal Data storage, and if
            it is impossible to determine a specific term, about the criteria
            for determining the term;{" "}
          </p>
          <p>
            10.1.9. Information about the User's rights provided for in the
            framework of Personal Data protection.{" "}
          </p>
          <p>
            10.2. The rule established by the first paragraph of this article
            does not apply if the special legislation establishes a different
            rule of informing when collecting personal data from the user, and
            this rule does not lead to a violation of the basic rights and
            freedoms of the user. In this case, upon written request of the
            user, the company will provide the above information within 10 (ten)
            working days from the request. (if there are no grounds for limiting
            the right provided for in the scope of personal data protection). In
            special cases and with proper justification, this term can be
            extended by no more than 10 (ten) working days, about which the
            customer will be notified immediately.{" "}
          </p>
          <p>
            10.3. The User has the right to request the minimum information
            specified in this article both orally and in writing (including in
            electronic form).{" "}
          </p>
          <p>
            10.4. The Company has the right not to provide you with the minimum
            information mentioned above, if there is a reasonable assumption
            that the user already possesses this information.{" "}
          </p>
          <p className="font-bold">
            11.<b> </b>INFORMING THE USER WHEN PERSONAL DATA IS NOT COLLECTED
            DIRECTLY FROM HIM{" "}
          </p>
          <p>
            11.1. In cases where the Company does not collect personal data
            directly from the user, the user has the right to receive Minimum
            Information as well as information about which Personal Data about
            the User is being processed and the source of this Personal Data,
            including whether the personal data was obtained publicly. from an
            available source.{" "}
          </p>
          <p>
            11.2. The user has the right to receive the above information within
            a reasonable time of the request or, if personal data is used to
            contact the user, immediately after the first communication with the
            customer, and if the disclosure of personal data is planned, -
            before the disclosure of personal data, but no later than 10 working
            days after obtaining personal data (if there are no grounds for
            limiting the rights provided for in the scope of personal data
            protection).{" "}
          </p>
          <p>
            11.3. The Company has the right not to provide the User with the
            above-mentioned information if there is one of the following
            grounds:{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            11.3.1. The User already has this information; 11.3.2. The
            collection or disclosure of Personal Data is prescribed by law or
            required to fulfill a duty{" "}
          </p>
          <p>
            imposed by the legislation of Georgia; 11.3.3. The provision of the
            information is impossible or requires a disproportionately large
            effort or{" "}
          </p>
          <p>
            the provision of the information would significantly harm or make
            impossible the fulfillment of the lawful purpose(s) of the Personal
            Data processing. In these cases, the Company will take all
            appropriate measures to protect the User's rights and legitimate
            interests, including by posting general information about the
            collection of Personal Data in a public/publicly accessible form.{" "}
          </p>
          <p className="font-bold">
            12.<b> </b>RIGHTS RELATED TO AUTOMATED INDIVIDUAL DECISION-MAKING{" "}
          </p>
          <p>
            12.1. The User has the right not to be subject to a decision made
            solely on the basis of automated, including profiling, which gives
            rise to a legal or other material consequence for him, except in
            cases where the decision-making based on profiling:{" "}
          </p>
          <p>
            12.1.1. based on the User's express consent; 12.1.2. Necessary to
            enter into a contract between the User and the Company or to perform
            the{" "}
          </p>
          <p>
            contract; 12.1.3. provided for by law or by a subordinate normative
            act issued within the scope of delegated{" "}
          </p>
          <p>authority based on the law. </p>
          <p>
            12.2. In the case of the relevant request of the user, the company
            ensures that appropriate measures are taken to protect the rights,
            freedom and legitimate interests of the user, including automated
            (based on profiling, before making an individual decision regarding
            the user, involving human resources in the decision-making process
            (except for the cases provided for by legislation), by giving the
            user the opportunity to express their opinion and appeal the
            decision.{" "}
          </p>
          <p>
            12.3. Automatically (on the basis of profiling), when making
            individual decisions about the user, special category data will be
            used only in the cases defined and permitted by law, if there are
            appropriate guarantees for the protection of the user's rights,
            freedoms and legitimate interests.{" "}
          </p>
          <p className="font-bold">
            13.<b> </b>PERSONAL DATA SECURITY{" "}
          </p>
          <p>
            13.1. Personal data security is ensured in accordance with the
            information security policies established by the company.{" "}
          </p>
          <p>
            13.2. The company takes special responsibility for the issue of
            personal data security and periodically takes organizational and
            technical measures relevant to the possible and associated threats
            of personal data processing (for example, data processing is carried
            out by means of safe and reliable electronic programs/systems, data
            is stored on a properly protected server, real-time reserved data is
            simultaneously stored on the backup device, A monitoring system for
            the outflow of critical information is implemented, access to
            unnecessary Internet resources and external data carriers (USB, CD)
            is limited, data storage in non-electronic form is carried out by
            implementing appropriate security measures, etc.) that ensure
            personal data protection from incidents and{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            improper or accidental data damage , from losing, from unlawful
            processing, including destruction, deletion, alteration, disclosure,
            retrieval, access, collection/retrieval or any other unlawful use.{" "}
          </p>
          <p>
            13.3. When determining the necessary organizational and technical
            measures to ensure the security of personal data, the company takes
            into account the categories and volume of personal data it
            processes, as well as the purpose, form, means and possible threats
            of violation of the rights of the data subject.{" "}
          </p>
          <p>
            13.4. Company periodically implements Personal Data security to
            ensure accepted technical and organizational dimensions
            effectiveness assessment and,of need in case,provides Personal Data
            security to protect Adequate dimensions acceptance or/and existing
            update.{" "}
          </p>
          <p>
            13.5. When processing personal data in electronic form, the company
            records all actions performed in relation to personal data in
            electronic form (including information about incidents, data
            collection, modification, access to them, their disclosure
            (transfer), connection and deletion).{" "}
          </p>
          <p>
            13.6. When processing personal data in non-electronic form, the
            company records all actions related to the disclosure and/or change
            of personal data (including information about incidents).{" "}
          </p>
          <p>
            13.7. The company ensures that all persons who process personal data
            or have access to data within the scope of the authority granted by
            the company:{" "}
          </p>
          <p>
            13.7.1. not to exceed the scope of the authority granted to him, to
            protect the secrecy and confidentiality of Personal Data, including
            after the termination of the relevant authority;{" "}
          </p>
          <p>
            13.7.2. in the case of processing Personal Data in electronic form,
            to have access to the processing of Personal Data of the volume
            granted to him by the Company;{" "}
          </p>
          <p>
            13.7.3. Be properly informed about Personal Data security issues.
            13.7.4. Before providing information about Personal Data to the
            User, for the purpose of data security,{" "}
          </p>
          <p>
            the Company is entitled to request the User to confirm his identity
            (identification) or verify his identity (verification).{" "}
          </p>
          <p>
            13.7.5. For the purpose of Personal Data security, when it comes to
            the processing of Personal Data, including the storage or transfer
            of Personal Data, the Company strives to establish business
            relations with such partners/persons and to grant access to Personal
            Data only to such persons who maintain the security of Personal Data
            and only to it. After the safety precautions have been properly
            demonstrated and tested.{" "}
          </p>
          <p>
            13.7.6. It takes into account what the so-called According to the
            principles of &quot;Data Protection by Design and by Default&quot;,
            at the stage of creation and initial use of a new product/service,
            the Company ensures that Personal Data protection is taken into
            account as a primary parameter and that the minimum is initially
            processed, in particular, only those Personal Data that are
            necessary to be processed for the main legitimate purposes. for the
            purpose.{" "}
          </p>
          <p>
            13.7.7. Both in the process of determining Personal Data processing
            means and directly in the process of processing, the Company will
            try its best to take such technical and organizational measures that
            ensure the proper and effective implementation of the principles of
            Personal Data processing{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            and in the process of Personal Data processing, the timely
            implementation of legal protection mechanisms for the User.{" "}
          </p>
          <p className="font-bold">
            14.<b> </b>FILING A CLAIM/COMPLAINT{" "}
          </p>
          <p>
            14.1. In case of violation of the rights and established rules
            defined by this policy or the applicable legislation, the user has
            the right, at his choice, to apply to:{" "}
          </p>
          <p>
            14.2. to the Company, on the contact channels indicated in this
            policy; 14.3. Personal Data protection to work (
            <a
              style={{ color: "blue" }}
              href="https://personaldata.ge/ka/contact"
            >
              https://personaldata.ge/ka/contact
            </a>
            );or 14.4. the court. 14.5. The user has the right to request the
            personal data protection service to make a decision on{" "}
          </p>
          <p>
            blocking personal data before making a decision on completing the
            review of his application. 14.6. The user has the right to appeal
            any decision of the Personal Data Protection Service in court{" "}
          </p>
          <p>
            in accordance with the terms and conditions stipulated by the
            legislation of Georgia.{" "}
          </p>
          <p className="font-bold">
            15.<b> </b>PROCESSING OF PERSONAL DATA FOR DIRECT MARKETING PURPOSES{" "}
          </p>
          <p>15.1. Personal Data Processing for Direct marketing purpose </p>
          <p>
            15.1.1. Processing of Personal Data for direct marketing purposes
            will be carried out only if the Company has the consent given by the
            User.{" "}
          </p>
          <p>
            15.1.2. By expressing consent to the processing of Personal Data for
            direct marketing purposes, the User gives consent to the following:
            periodically receive short text messages (SMS), text, voice,
            electronic and other types of messages from the Company, including
            those authorized to process it - on behalf of the Company or on
            behalf of the Company , including voice telephone calls, telephone,
            mail, e-mail, social networks or other electronic means, of the
            Company and/or Company's goods/products, ideas, services, discounts,
            promotions, initiatives, incentives or other sweepstakes, projects
            and/or Regarding the joint projects of the Company's partner
            organizations, to receive congratulations on birthdays, holidays and
            other important and remarkable dates, information about the jobs and
            vacancies in the Company, as well as any other kind of
            advertising/marketing offers.{" "}
          </p>
          <p>
            15.2. Direct marketing purpose processed Personal Data and
            extraction sources{" "}
          </p>
          <p>
            15.2.1. For the purpose of direct marketing, the Company processes
            Personal Data collected directly from the User, which the User
            discloses during registration on the Company's website and/or
            application or during any subsequent communication with the Company:
            any data that identifies the User and communicates with him (name,
            surname, personal number, date of birth, gender, age, address, phone
            number, email address and other personally identifiable/contact
            information).{" "}
          </p>
          <p>
            15.2.2. For the purpose of direct marketing, the Company may collect
            and process Personal Data received from third parties only if the
            User has given consent.{" "}
          </p>
          <p>
            15.2.3. In case of consent by the User, in order to process and
            receive personalized advertising/marketing offers tailored to the
            User's personal interests and preferences, based on any{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            reasonably available and lawfully processed Personal Data (for
            example, age, gender, category of purchased products, brands, etc.),
            the Company will periodically implement Processing, evaluation,
            analysis and segmentation/grouping of information on certain
            personal characteristics and behavioral indicators of Users.{" "}
          </p>
          <p>
            15.3. Direct marketing purpose processed Personal Data storage term{" "}
          </p>
          <p>
            15.3.1. The storage of Personal Data processed for the purpose of
            direct marketing is carried out for the period of the implementation
            of direct marketing and from the termination of the implementation
            of direct marketing, including the cancellation of the User's
            registration for any reason or from the moment of withdrawal/refusal
            of the consent given by the User to the processing of Personal Data
            for the purpose of direct marketing, for the next 3 years.{" "}
          </p>
          <p>
            15.3.2. In cases provided by law and/or if there is a need to
            protect important legitimate interests of the Company or a third
            party, Personal Data processed for direct marketing purposes will be
            stored for a period longer than the 3-year period indicated above,
            for a period necessary to achieve specific goals.{" "}
          </p>
          <p>
            15.3.3. After the expiration of the data storage period mentioned
            above, Personal Data may be stored for statistical/analytical
            purposes in a depersonalized form. This refers to the processing of
            data in such a way that it is impossible to connect them to the User
            or establishing such a connection requires disproportionately large
            efforts, costs and/or time.{" "}
          </p>
          <p>
            15.4. Direct marketing purpose Personal Data on processing Issued
            consent requested and data processing termination{" "}
          </p>
          <p>
            15.4.1. The User has the right to refuse the processing of Personal
            Data for direct marketing at any time and to withdraw his consent
            easily and free of charge.{" "}
          </p>
          <p>
            15.4.2. Withdrawal of consent to the processing of Personal
            Data/request to stop the processing of Personal Data (refusal) can
            be carried out by the User in the same way as direct marketing
            (advertising/marketing offers) is carried out.{" "}
          </p>
          <p>
            15.4.3. For example, if direct marketing (advertising/marketing
            offers) is carried out by sending a short text message (SMS), for
            this purpose, the User can make a request (opt-out) of data
            processing in the same form: for example, SMSOFF, STOPSMS or those
            other number(s)/ By sending a text that accompanies a short text
            message.{" "}
          </p>
          <p>
            15.4.4. For example, if direct marketing (advertising/marketing
            offers) is carried out by sending electronic messages to an e-mail
            address, the User can make a request (opt-out) of data processing
            for this purpose in the same way, after using the
            &quot;unsubscribe&quot; mechanism. or any other similar mechanism
            that accompanies an electronic message.{" "}
          </p>
          <p>
            15.4.5. As an exception and under the conditions of proper
            identification of the User, the User can request consent to the
            processing of Personal Data for direct marketing purposes/request to
            stop the processing of Personal Data (refusal) by contacting the
            Company's communication channels specified in this policy.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            15.4.6. In certain cases, when it is technically possible and
            justified, the User can express consent to the processing of
            Personal Data for direct marketing purposes/request to stop the
            processing of Personal Data (refusal) in a differentiated way (for
            example, receiving advertising/marketing offers only for certain
            products/services of the Company or any kind of information from the
            Company receiving advertising/marketing offers).{" "}
          </p>
          <p>
            15.4.7. In order to avoid any misunderstandings, the Company
            clarifies that the User's consent does not refer to receiving and
            communicating informational messages from the Company that serve to
            inform the User about the Company's services, including
            operations/transactions performed by the User, information on
            service limitations, Communication for service evaluation, policy
            changes, etc.{" "}
          </p>
          <p>
            15.4.8. The Company ensures the termination of the processing of the
            User's Personal Data for the purpose of direct marketing, no later
            than within the next 7 (seven) working days after receiving the
            corresponding request of the User.{" "}
          </p>
          <p>
            15.4.9. The processing of the User's Personal Data for the purpose
            of direct marketing will be automatically terminated, no later than
            within the next 7 (seven) working days after the cancellation of the
            User's registration for any reason.{" "}
          </p>
          <p>
            15.5. Direct marketing purpose processed Personal Data transferthe
            third on persons{" "}
          </p>
          <p>
            15.5.1. In the presence of the relevant legal basis and purpose, the
            Company may transfer Personal Data processed for direct marketing to
            the following third parties (data recipients):{" "}
          </p>
          <p>
            (1) of service/contracts obligations performance purpose,Direct
            marketing purpose processed Personal Data it is possible be given of
            service pull me individuals/contractors/Partner organizations.{" "}
          </p>
          <p>
            (2) for example,informative-technological of service
            suppliers,software support suppliers,software providing
            suppliers,Direct marketing within of service suppliers,mobile
            communication of service supplier,Advertising agencies,payment of
            service providers,banking-Financial institutions or/and legal of
            service supplier,partner organizations/Persons,with which
            togetherCompanymanages together projects or/and other events.{" "}
          </p>
          <p>
            15.5.2. In the cases established by the law and when it is necessary
            to fulfill the duty imposed by the law, the Personal Data processed
            for the purpose of direct marketing may be transferred to other
            third parties. For example, law enforcement/investigative
            authorities, tax authorities, administrative authorities, courts
            and/or other authorized persons.{" "}
          </p>
          <p>
            15.5.3. In order to protect important legitimate interests, Personal
            Data processed for direct marketing purposes may be transferred to
            other third parties. For example, in order to detect, prevent and
            prevent a crime or offence, Personal Data may be transferred to law
            enforcement, judicial and/or administrative authorities.{" "}
          </p>
          <p>
            15.5.4. In order to reorganize or transfer the enterprise, the
            Personal Data processed for the purpose of direct marketing may be
            transferred to the legal heirs or successors of this enterprise.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            15.5.5. Personal Data processed for the purpose of direct marketing
            may be transferred to third parties located outside the borders of
            Georgia only if there are appropriate data protection guarantees in
            this country in accordance with the applicable legislation. In other
            cases, the transfer of Personal Data processed for the purpose of
            direct marketing to another country is allowed if, after receiving
            information about the lack of adequate Personal Data protection
            guarantees and possible threats, the User applies for written
            consent, there is a permit issued by the Personal Data Protection
            Service or this is provided for by law.{" "}
          </p>
          <p className="font-bold">
            16.<b> </b>POLICY CHANGE{" "}
          </p>
          <p>
            16.1. The Company has the right to make changes to the personal data
            processing policy at any time. 16.2. If there is a substantial
            content change in the policy, the company will notify the user about{" "}
          </p>
          <p>
            the change in it within a reasonable period of time and, if
            prescribed by the law, ensure the user's consent to these changes in
            advance. Otherwise, the change will take effect from the date of
            update on the webpage.{" "}
          </p>
          <p>
            16.3. The company provides information on changes to this policy and
            the availability of the latest/current/active version of the policy
            on the customer's communication channels known to the company,
            including by sending a short text message to a phone number or
            electronic communication to an e-mail address, on the company's
            website/application and/or in another relevant space publicly. In
            the form of posting/posting.{" "}
          </p>
          <p>
            16.4. It is recommended that the Student periodically review this
            policy to update information on the processing and protection of
            personal data.{" "}
          </p>
          <p className="font-bold">
            17.<b> </b>CONTACT INFORMATION{" "}
          </p>
          <p>
            17.1. In case of questions regarding personal data processing,
            protection and/or this policy, the user can contact the company in
            writing at the following e-mail address:{" "}
            <a style={{ color: "blue" }} href="mailto:support@keepz.me">
              support@keepz.me
            </a>{" "}
          </p>
          <p>last update date: 3/25/24 </p>
        </div>
      </div>
    </div>
  );
}

export default En;
