export default function TetherIcon(props) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2575_3493)">
        <path
          d="M33.3911 25.9996V21.6012H43.4479V14.8994H16.0627V21.6012H26.1194V25.9915C17.9488 26.3688 11.8008 27.982 11.8008 29.9244C11.8008 31.8667 17.9488 33.4799 26.1194 33.8572V47.9431H33.3911V33.8572C41.5457 33.4799 47.6777 31.8667 47.6777 29.9244C47.6777 27.982 41.5457 26.3688 33.3911 25.9915M33.3911 32.6613C33.1824 32.6693 32.131 32.7335 29.7874 32.7335C27.9093 32.7335 26.593 32.6773 26.1275 32.6533C18.9119 32.3402 13.5184 31.0801 13.5184 29.5792C13.5184 28.0783 18.9039 26.8182 26.1275 26.4972V31.4092C26.601 31.4413 27.9494 31.5216 29.8195 31.5216C32.0588 31.5216 33.1905 31.4253 33.3991 31.4092V26.4972C40.6066 26.8182 45.9841 28.0783 45.9841 29.5792C45.9841 31.0801 40.6066 32.3402 33.3991 32.6613"
          fill="#53AE94"
        />
      </g>
      <defs>
        <clipPath id="clip0_2575_3493">
          <rect
            width="59.1045"
            height="59.1045"
            fill="white"
            transform="translate(0.1875 0.387695)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
