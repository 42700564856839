import tickIcon from "../../../assets/images/tick.png";

import clockIcon from "../../../assets/images/clock.png";
import rectIcon from "../../../assets/images/rect.png";

import Card from "./Card";
import imgOne from "../../../assets/images/usageImage1.png";
import imgTwo from "../../../assets/images/usageImage2.png";
import imgThree from "../../../assets/images/usageImage3.png";
import { Pagination, A11y } from "swiper/modules";
import ModalVideo from "react-modal-video";

import { Swiper, SwiperSlide } from "swiper/react";
import { Trans } from "react-i18next";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// import Button from "../Button/Button";
import NewAppIcon from "assets/icons/NewAppIcon";
import NewGpayIcon from "assets/icons/NewGpayIcon";
import NewVisaIcon from "assets/icons/NewVisaIcon";
import NewMasterIcon from "assets/icons/NewMasterIcon";
import NewCityPayIcon from "assets/icons/NewCityPayIcon";
import NewBankIcon from "assets/icons/NewBankIcon";
import UsageCard from "./UsageCard";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const WhyKeepz = () => {
  const [isOpen, setOpen] = useState(false);
  const [videoId, setVideoId] = useState();
  const navigate = useNavigate();

  const arr = [
    {
      src: tickIcon,
      title: <Trans i18nKey={"whyKeepz.affordable"} />,
      description: <Trans i18nKey={"whyKeepz.affordableDesc"} />,
    },
    {
      src: clockIcon,
      title: <Trans i18nKey={"whyKeepz.fast"} />,
      description: <Trans i18nKey={"whyKeepz.fastDesc"} />,
    },
    {
      src: rectIcon,
      title: <Trans i18nKey={"whyKeepz.multilevel"} />,
      description: <Trans i18nKey={"whyKeepz.multilevelDesc"} />,
    },
  ];
  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setOpen(false)}
      />
      <section
        id="whyKeepz"
        className="bg-[#6C63FF15]  pt-[50px] md:pb-[50px] font-display"
      >
        <h3 className="text-center">
          <span
            className="text-[40px] text-[#6C63FF] font-bold"
            style={{ fontVariant: "all-petite-caps" }}
          >
            <Trans i18nKey={"why"}></Trans>
          </span>
          <span className="ms-[5px] text-[30px] text-[#6C63FF] font-bold">
            KEEPZ?
          </span>
        </h3>
        <div className=" max-w-[1240px] m-auto  scroll-smooth font-display">
          <div className="hidden md:block   m-auto">
            {/* <div className="flex gap-[30px]">
              <div className="w-[390px] relative min-h-[315px]">
                <p className="text-[52px] text-[#6C63FF] font-[700]">
                  <Trans i18nKey={"why"} /> <br />
                  <span className="text-[#170738]">
                    <Trans i18nKey={"keepz"} />?
                  </span>
                </p>
                <img src={logoS} className="absolute bottom-0 right-0" />
              </div>
              <div className="flex-1">
                <Card
                  logo={tickIcon}
                  title={<Trans i18nKey={"whyKeepz.versatile"} />}
                  description={<Trans i18nKey={"whyKeepz.versatileDesc"} />}
                >
                  <img width={"274px"} src={brands} />
                </Card>
              </div>
              I
            </div> */}
            <div className="grid grid-cols-12 gap-[30px]">
              {arr.map(({ src, description, title }) => {
                return (
                  <>
                    <div className="md:col-span-4  mt-[30px] ">
                      <Card
                        logo={src}
                        title={title}
                        description={description}
                      />
                    </div>
                  </>
                );
              })}
            </div>
            <div className="flex w-[100%]  justify-center items-center mt-[50px] gap-[34px]">
              <NewAppIcon />
              <NewGpayIcon />
              <NewVisaIcon />
              <NewMasterIcon />
              <NewCityPayIcon />
              <NewBankIcon />
            </div>
            <div className="mt-[50px] w-[100%] bg-[#6C63FF]  rounded-[16px] p-[50px]">
              <div className="flex justify-between">
                <p
                  className="font-bold text-white text-[40px]"
                  style={{ fontVariant: "all-petite-caps" }}
                >
                  <Trans i18nKey={"whyKeepz.useagExample"} />
                </p>

                <button
                  onClick={() => navigate("/use-cases")}
                  className="bg-[#847eff] rounded-[12px]   hover:border-white border-[#6C63FF] transition duration-300 ease-in-out border-[1px] hover:bg-opacity-0 text-white py-[10px] px-[40px]"
                >
                  <Trans i18nKey={"buttons.seeAll"} />
                </button>
              </div>
              <div className="flex   justify-center flex-wrap gap-[30px] mt-[50px]">
                <div
                  className="cursor-pointer hover:opacity-[70%]"
                  onClick={() => {
                    setVideoId("fJB89MyALAQ");
                    setOpen(true);
                  }}
                >
                  <UsageCard
                    src={imgOne}
                    text={<Trans i18nKey={"whyKeepz.erp"} />}
                  />
                </div>

                <div
                  className="cursor-pointer hover:opacity-[70%]"
                  onClick={() => {
                    setVideoId("cnwU1B4G6lk");
                    setOpen(true);
                  }}
                >
                  <UsageCard
                    src={imgThree}
                    text={<Trans i18nKey={"whyKeepz.qrTerminal"} />}
                  />
                </div>
                <div
                  className="cursor-pointer hover:opacity-[70%]"
                  onClick={() => {
                    setVideoId("HYuUIZsyeDI");
                    setOpen(true);
                  }}
                >
                  <UsageCard
                    src={imgTwo}
                    text={<Trans i18nKey={"whyKeepz.qrTerminalBeautySalon"} />}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*///////////////////////////////////////// using slider for mobile////////////////////////////// */}
          <div className=" md:hidden mt-[35px] w-[100%] ">
            <Swiper
              // install Swiper modules
              modules={[Pagination, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{
                el: "#my-custom-pagination-div",
                clickable: true,
                renderBullet: function (index, className) {
                  return '<span class="' + className + '">' + "</span>";
                },
              }}
              style={{ margin: "auto" }}
              // --swiper-pagination-bottom
              className="sm2:w-[500px] sm:w-[100%]"
            >
              {arr.map(({ src, description, title }) => {
                return (
                  <>
                    <SwiperSlide>
                      <div className="h-[410px] sm:h-[222px] px-[16px]">
                        <Card
                          logo={src}
                          title={title}
                          description={description}
                        />
                      </div>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
          <div id="my-custom-pagination-div" className="md:hidden" />
          {/* <div className="flex w-[100%] md:hidden  justify-center items-center mt-[50px] gap-[10px] pe-3">
            <NewCityPayIcon width={"120px"} />
            <NewGpayIcon width={"100px"} />
            <NewVisaIcon width={"70px"} />
            <NewMasterIcon width={"50px"} />
            <NewBankIcon width={"40px"} />
            <NewAppIcon width={"80px"} />
          </div> */}
        </div>
        <div className="bg-[#6C63FF]  mt-[34px] rounded-[16px] font-display py-[40px] md:hidden">
          <p
            className=" font-bold text-white text-center text-[27px] "
            style={{ fontVariant: "all-petite-caps" }}
          >
            <Trans i18nKey={"whyKeepz.useagExample"} />
          </p>
          <Swiper
            slidesPerView={1.1}
            style={{
              backgroundColor: "#6C63FF",
              borderRadius: "16px",
              marginTop: "40px",
            }}
          >
            <div className=" w-[100%] mt-[100px] bg-[#6C63FF]   ">
              <SwiperSlide style={{ padding: "20px" }}>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setVideoId("fJB89MyALAQ");
                    setOpen(true);
                  }}
                >
                  <UsageCard
                    src={imgOne}
                    text={<Trans i18nKey={"whyKeepz.erp"} />}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ padding: "20px" }}>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setVideoId("cnwU1B4G6lk");
                    setOpen(true);
                  }}
                >
                  <UsageCard
                    src={imgThree}
                    text={<Trans i18nKey={"whyKeepz.qrTerminal"} />}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ padding: "20px" }}>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setVideoId("HYuUIZsyeDI");
                    setOpen(true);
                  }}
                >
                  <UsageCard
                    src={imgTwo}
                    text={<Trans i18nKey={"whyKeepz.qrTerminalBeautySalon"} />}
                  />
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="w-[100%] flex justify-center">
            <button
              className="bg-[#847eff] rounded-[12px] mt-[40px]  text-white py-[10px] px-[40px]"
              onClick={() => navigate("/use-cases")}
            >
              <Trans i18nKey={"buttons.seeAll"} />
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyKeepz;
