export const PAYMENT_PROVIDER_URL = "/api/paymentProvider";

export const USER_PROFILE_URL = "/api/v1/user-account/encrypted";

export const BRANCH_PROFILE_URL = "/api/v1/branch-account/encrypted";

export const SINGLE_PAYMENT_LIMIT_URL = "/api/single-payment/limit";

export const SYSTEM_STATUS_URL = "/system/status";

export const TRANSACTION_STATUS = "/api/gc/transaction/info";

export const LAST_ACTIVE_ORDER = "/api/integrator/order/encrypted";

export const MERCHANT_PROFILE_URL = "/api/v1/branch-account/encrypted";

export const PROFILE_IMAGE_URL =
  "https://wallet-keepz-s3.s3.eu-central-1.amazonaws.com/wallet-keepz-s3/";

export const PROFILE_IMAGE_URL_DEV =
  "https://wallet-keepz-dev-s3.s3.eu-central-1.amazonaws.com/sweeft-wallet/";

export const PRODUCT_URL = "/api/merchant/product/encrypted";
