function VisaMasterIcon(props) {
  return (
    <svg
      width="69"
      height="18"
      viewBox="0 0 69 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.65971 9.179L5.73271 4.03C5.69072 3.74811 5.5423 3.49304 5.31796 3.31725C5.09363 3.14147 4.81046 3.05835 4.52671 3.085H0.237712L0.204712 3.33C1.6828 3.6899 3.04751 4.41346 4.17483 5.43495C5.30214 6.45643 6.15631 7.74343 6.65971 9.179ZM9.77471 3.201L7.06471 11.144L6.78471 9.944C5.94738 8.0286 4.62399 6.3653 2.94571 5.119L5.41971 14.83H8.34271L12.6967 3.202L9.77471 3.201ZM13.8217 3.201L12.0937 14.854H14.8357L16.5687 3.201H13.8217ZM31.9677 3.201H29.8177C29.5009 3.16714 29.1823 3.24574 28.9176 3.42307C28.6529 3.60039 28.4589 3.86514 28.3697 4.171L24.2847 14.842H27.1847C27.1847 14.842 27.6567 13.406 27.7657 13.091H31.3047C31.3867 13.491 31.6397 14.83 31.6397 14.83H34.2047L31.9677 3.201ZM28.5617 10.71C28.7917 10.042 29.6617 7.453 29.6617 7.453C29.6617 7.453 29.8917 6.785 30.0287 6.344L30.2207 7.344L30.8567 10.708L28.5617 10.71ZM22.1397 7.869C21.1797 7.334 20.5877 6.969 20.5937 6.427C20.5997 5.885 21.0937 5.427 22.1727 5.427C22.8877 5.40659 23.597 5.55998 24.2397 5.874L24.4917 6.006L24.8647 3.486C24.0818 3.15815 23.2404 2.99313 22.3917 3.001C19.6497 3.001 17.7357 4.576 17.7197 6.843C17.7197 8.512 19.0907 9.451 20.1377 9.992C21.1847 10.533 21.5907 10.931 21.5907 11.441C21.5907 12.222 20.7297 12.581 19.9457 12.581C19.0388 12.6026 18.1422 12.3853 17.3457 11.951L16.9897 11.762L16.6047 14.369C17.5778 14.7887 18.627 15.0031 19.6867 14.999C22.5867 14.999 24.4737 13.437 24.4957 11.018C24.4907 9.708 23.7557 8.7 22.1427 7.869H22.1397Z"
        fill="#273991"
      />
      <path
        d="M5.77371 3.90489C5.70865 3.6413 5.54882 3.4109 5.32473 3.25762C5.10063 3.10435 4.82795 3.03895 4.55871 3.07389H0.237712L0.204712 3.29189C3.56371 4.10389 5.78471 6.05489 6.70471 8.42489L5.77371 3.90489Z"
        fill="#F99F1B"
      />
      <path
        d="M58.8708 2.07495H51.3584V15.9127H58.8708V2.07495Z"
        fill="#EA560D"
      />
      <path
        d="M51.8308 8.98833C51.8275 7.65746 52.1199 6.34328 52.6858 5.14546C53.2518 3.94764 54.0764 2.89763 55.0971 2.07505C53.8308 1.05413 52.3098 0.419046 50.7079 0.242394C49.1061 0.0657425 47.4881 0.354645 46.0389 1.0761C44.5897 1.79756 43.3678 2.92244 42.5129 4.32212C41.6581 5.72181 41.2047 7.3398 41.2047 8.99112C41.2047 10.6424 41.6581 12.2604 42.5129 13.6601C43.3678 15.0598 44.5897 16.1847 46.0389 16.9061C47.4881 17.6276 49.1061 17.9165 50.7079 17.7399C52.3098 17.5632 53.8308 16.9281 55.0971 15.9072C54.0766 15.0832 53.2522 14.0321 52.6863 12.8335C52.1204 11.6348 51.8279 10.3199 51.8308 8.98833Z"
        fill="#E30613"
      />
      <path
        d="M68.1775 14.446V14.1615H68.2918V14.1001H68.0087V14.1615H68.1176V14.446H68.1775ZM68.7219 14.446V14.1001H68.6348L68.5368 14.3344L68.4388 14.1001H68.3517V14.446H68.417V14.1838L68.5096 14.407H68.5749L68.6674 14.1838V14.446H68.7219Z"
        fill="#F59E00"
      />
      <path
        d="M68.9999 8.98907C69.0035 10.6421 68.5528 12.2627 67.6993 13.6653C66.8459 15.0679 65.6242 16.1957 64.1743 16.9195C62.7244 17.6433 61.1049 17.9339 59.5013 17.758C57.8977 17.582 56.3749 16.9467 55.1074 15.9247C56.1305 15.1025 56.9579 14.0526 57.5268 12.8545C58.0958 11.6563 58.3914 10.3412 58.3914 9.0086C58.3914 7.67604 58.0958 6.36091 57.5268 5.16273C56.9579 3.96455 56.1305 2.91467 55.1074 2.09252C56.3735 1.0717 57.8943 0.436597 59.4959 0.259814C61.0975 0.0830321 62.7154 0.371701 64.1645 1.09282C65.6136 1.81394 66.8356 2.93842 67.6906 4.33772C68.5457 5.73702 68.9994 7.35469 68.9999 9.00581V8.98907Z"
        fill="#F59E00"
      />
    </svg>
  );
}

export default VisaMasterIcon;
