import React from "react";

export default function OnlineBankIcon(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.991 19.959H1.00004C0.734658 19.9673 0.482921 20.0786 0.298132 20.2692C0.113343 20.4599 0.0100098 20.715 0.0100098 20.9805C0.0100098 21.246 0.113343 21.5011 0.298132 21.6918C0.482921 21.8824 0.734658 21.9937 1.00004 22.002H20.99C21.2554 21.9937 21.5072 21.8824 21.692 21.6918C21.8767 21.5011 21.9801 21.246 21.9801 20.9805C21.9801 20.715 21.8767 20.4599 21.692 20.2692C21.5072 20.0786 21.2564 19.9673 20.991 19.959Z"
        fill="#170738"
      />
      <path
        d="M2.27306 17.3038C2.00767 17.3121 1.75593 17.4233 1.57114 17.614C1.38635 17.8046 1.28302 18.0597 1.28302 18.3252C1.28302 18.5908 1.38635 18.8459 1.57114 19.0365C1.75593 19.2272 2.00767 19.3384 2.27306 19.3467H19.7171C19.9824 19.3384 20.2342 19.2272 20.419 19.0365C20.6038 18.8459 20.7071 18.5908 20.7071 18.3252C20.7071 18.0597 20.6038 17.8046 20.419 17.614C20.2342 17.4233 19.9824 17.3121 19.7171 17.3038H19.4681V8.11375H19.7171C19.8523 8.11375 19.982 8.06002 20.0777 7.96438C20.1733 7.86873 20.2271 7.73901 20.2271 7.60375C20.2271 7.46849 20.1733 7.33877 20.0777 7.24313C19.982 7.14748 19.8523 7.09375 19.7171 7.09375H2.27306C2.13779 7.09375 2.00807 7.14748 1.91243 7.24313C1.81679 7.33877 1.76306 7.46849 1.76306 7.60375C1.76306 7.73901 1.81679 7.86873 1.91243 7.96438C2.00807 8.06002 2.13779 8.11375 2.27306 8.11375H2.52206V17.3028L2.27306 17.3038ZM17.4731 8.11375V17.3028H14.4831V8.11375H17.4731ZM12.4891 8.11375V17.3028H9.49906V8.11375H12.4891ZM4.51506 8.11375H7.50705V17.3028H4.51506V8.11375Z"
        fill="#170738"
      />
      <path
        d="M1.00004 6.63759H21C21.2387 6.63149 21.4678 6.54226 21.6477 6.38529C21.8276 6.22832 21.9471 6.01346 21.9855 5.7778C22.0239 5.54214 21.9789 5.30046 21.8581 5.09448C21.7373 4.88851 21.5484 4.73115 21.324 4.64959L11.406 0.0895858C11.2774 0.0305582 11.1376 0 10.996 0C10.8545 0 10.7147 0.0305582 10.586 0.0895858L0.590036 4.68959C0.380693 4.78801 0.210709 4.95423 0.10762 5.16131C0.00453106 5.3684 -0.0256254 5.60422 0.0220357 5.83059C0.0666508 6.05741 0.188475 6.26178 0.366782 6.40891C0.545089 6.55604 0.768864 6.63685 1.00004 6.63759Z"
        fill="#170738"
      />
    </svg>
  );
}
