import React from "react";
import Svg from "../../assets/images/redesign/defaultAvatar.svg";
import merchantIcon from "assets/images/icon/merchant-icon.svg";
import { useQueryString } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PROFILE_IMAGE_URL,
  PROFILE_IMAGE_URL_DEV,
} from "helpers/fetcher/fetcher.config";
import { useTranslation } from "react-i18next";

const TippingUser = ({ user }) => {
  const { pathname } = useLocation();
  const { parseQuery: parsedQuery, getStringFromQuery } = useQueryString();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { name, imagePath, address } = user || {};
  const decodedString = decodeURIComponent(name);
  const userName = decodedString.replace(/\+/g, " ");
  const { receiverType = "" } = parsedQuery;
  const isMerchant = (user?.userType || receiverType) === "BRANCH";
  const isBusiness = (user?.userType || receiverType) === "BUSINESS";

  const handleLangChange = () => {
    const newLang = i18n.language === "ge" ? "en" : "ge";
    const newQueryObj = { ...parsedQuery, lng: newLang };
    const newQueryString = getStringFromQuery(newQueryObj);
    navigate({
      pathname,
      search: newQueryString,
    });
  };

  const img =
    window.location.hostname == "www.web.appdev.keepz.me"
      ? PROFILE_IMAGE_URL_DEV
      : PROFILE_IMAGE_URL;

  const getImage = () => {
    if (imagePath) {
      return img + imagePath;
    } else if (isMerchant || isBusiness) {
      return merchantIcon;
    } else {
      return Svg;
    }
  };
  return (
    <div className="bg-black-500  flex items-center justify-center pt-5 pb-14  ">
      <div
        className="grow flex gap-5 items-center justify-center ml-[40px]"
        style={{ maxWidth: "80%" }}
      >
        <img
          className={`w-[64px] aspect-square rounded-full border-white object-cover ${
            imagePath ? "border-4" : "border-none"
          }`}
          style={imagePath ? {} : { border: "none" }}
          src={`${getImage()}`}
          alt="person"
          width="100"
          height="100"
        />
        <div className="flex flex-col relative" style={{ maxWidth: "60%" }}>
          <div
            className={`text-white ${
              userName?.includes("არქი") ? "text-lg" : "text-2xl"
            }  font-bold leading-8 flex flex-row flex-wrap`}
          >
            <p className="truncate  px-1">{userName?.split(" ")[0]}</p>
            <p className="truncate  px-1">
              {userName?.split(" ").slice(1).join(" ")}
            </p>
          </div>
          <div className="text-white text-sm font-semibold leading-4 max-w-70vw">
            <p className="truncate  px-1">{address}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <button className="text-white mx-5" onClick={handleLangChange}>
          {i18n.language === "ge" ? "ქა" : "EN"}
        </button>
      </div>
    </div>
  );
};

export default TippingUser;
