import React from "react";
import ghost from "assets/images/error/ghost.svg";

import { useTranslation } from "react-i18next";
const DowntimePage = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center mt-[200px]">
      <div className="flex justify-center">
        <img className="ghost" src={ghost} alt="ghost" />
        {/* <img className='err' src={errorText} alt='404' /> */}
      </div>
      <p className="text-[25px] text-black-500 mt-[30px] font-bold">
        {t("description.somethingWentWrong")}
      </p>
      <p className="text-gray-500 mt-4">
        {t("description.weReWorkingOnGettingThisFixed")}
      </p>
    </div>
  );
};

export default DowntimePage;
