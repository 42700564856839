import scanIcon from "../../../assets/images/scanIcon.png";
import clockIcon from "../../../assets/images/clockIcon.png";
import userIcon from "../../../assets/images/userIcon.png";
import favIcon from "../../../assets/images/favIcon.png";
import linkIcon from "../../../assets/images/linkIcon.png";
import cardIcon from "../../../assets/images/cardIcon.png";
import qrTerminalMobile from "../../../assets/images/qrTerminalMobile.png";
import QrNavEl from "./QrNavEl";
import { Trans } from "react-i18next";

const QrNavigation = () => {
  const data = [
    {
      icon: scanIcon,
      title: <Trans i18nKey="qrTerminal.qrNavigation.terminalNavTitle1" />,
      description: (
        <Trans i18nKey="qrTerminal.qrNavigation.terminalNavDescription1" />
      ),
    },
    {
      icon: clockIcon,
      title: <Trans i18nKey="qrTerminal.qrNavigation.terminalNavTitle2" />,
      description: (
        <Trans i18nKey="qrTerminal.qrNavigation.terminalNavDescription2" />
      ),
    },
    {
      icon: userIcon,
      title: <Trans i18nKey="qrTerminal.qrNavigation.terminalNavTitle3" />,
      description: (
        <Trans i18nKey="qrTerminal.qrNavigation.terminalNavDescription3" />
      ),
    },
    {
      icon: favIcon,
      title: <Trans i18nKey="qrTerminal.qrNavigation.terminalNavTitle4" />,
      description: (
        <Trans i18nKey="qrTerminal.qrNavigation.terminalNavDescription4" />
      ),
    },
    {
      icon: linkIcon,
      title: <Trans i18nKey="qrTerminal.qrNavigation.terminalNavTitle5" />,
      description: (
        <Trans i18nKey="qrTerminal.qrNavigation.terminalNavDescription5" />
      ),
    },
    {
      icon: cardIcon,
      title: <Trans i18nKey="qrTerminal.qrNavigation.terminalNavTitle6" />,
      description: (
        <Trans i18nKey="qrTerminal.qrNavigation.terminalNavDescription6" />
      ),
    },
  ];
  return (
    <div className="md:bg-[rgba(108,99,255,0.05)]  rounded-2xl sm:p-[16px] md:grid md:gap-10 md:grid-cols-3 sm:flex sm:flex-col sm:gap-[30px] md:h-[674px]">
      <div className="md:mt-0 md:col-start-2 md:row-start-1 md:row-end-4 md:bg-transparent sm:bg-[rgba(108,99,255,0.05)] md:w-[412.5px] sm:w-[100%] sm:h-[222px] sm:rounded-xl sm:mt-[100px] sm:mb-[80px] md:ms-[-35px] sm:flex justify-center items-center md:block">
        <img
          src={qrTerminalMobile}
          alt="Big icon"
          className="sm:w-[253px] sm:mt-[50px] md:mt-0 md:w-auto md:max-w-full md:h-auto"
        />
      </div>

      {data.map((el, index) => (
        <div
          style={{
            gridColumnStart: index < 3 ? 1 : 3,
            gridRowStart: index < 3 ? index + 1 : index - 2,
            gridRowEnd: index < 3 ? index + 2 : index - 1,
          }}
        >
          <QrNavEl
            key={index}
            icon={el.icon}
            title={el.title}
            description={el.description}
          />
        </div>
      ))}
    </div>
  );
};

export default QrNavigation;
