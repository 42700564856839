import { useNavigate } from "react-router-dom";

const PaymentType = ({
  src,
  name,
  description,
  showText = true,
  svg = false,
  id = false,
  closeNav,
  setIsAccordionOpen,
}) => {
  // const navigate = useNavigate();
  const navigate = useNavigate();

  const navigateToContact = (id) => {
    navigate("/");

    // Use a longer timeout to account for slower mobile devices
    setTimeout(() => {
      // Ensure the DOM is fully loaded before scrolling
      const checkAndScroll = () => {
        const contactSection = document.getElementById(id);
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: "smooth" });
        } else {
          // Retry after a short delay if the section is not yet available
          setTimeout(checkAndScroll, 100);
        }
      };

      checkAndScroll();
    }, 400); // Increased timeout to 500ms
  };

  return (
    <div
      onClick={() => {
        if (!id) {
          navigate("/qr-terminal");
        }
        if (id) {
          console.log(id, "raari too");
          navigateToContact(id);
        }

        if (closeNav) {
          closeNav();
          setIsAccordionOpen(false);
        }
      }}
      className="cursor-pointer"
    >
      {/* <a href={`${id ? `#${id}` : ""}`}> */}
      <div className="flex gap-3 items-center mt-[20px]">
        {svg ? (
          svg
        ) : (
          <img width={"60px"} height={"60px"} src={src} alt="image" />
        )}
        <div>
          <p
            className={`text-[16px] ${
              showText ? "font-[700]" : "font-[400]"
            }  hover:text-[#6C63FF]`}
          >
            {name}
          </p>
          {showText === true && (
            <p className="font-[400] text-[14px] text-[#777987]">
              {description}
            </p>
          )}
        </div>
      </div>
      {/* </a> */}
    </div>
  );
};
export default PaymentType;
