export default function BasisBankIcon(props) {
  return (
    <svg
      width="41"
      height="56"
      viewBox="0 0 41 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2575_3426)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.8689 27.1318C34.9811 25.8976 37.1194 23.3421 37.1194 20.3902C37.1194 16.2311 32.8773 12.8599 27.6466 12.8599C27.4274 12.8599 27.2104 12.8662 26.995 12.8781H22.6055C26.0378 14.2898 28.3831 17.1379 28.3831 20.4211C28.3831 23.1233 26.7941 25.5306 24.3174 27.0858C27.4495 28.7718 29.5121 31.6239 29.5121 34.854C29.5121 38.6255 26.7116 41.8762 22.6735 43.3656H27.2647V43.3593C27.3938 43.3593 27.5236 43.3656 27.6535 43.3656C33.5358 43.3656 38.3027 39.5759 38.3027 34.9016C38.2974 31.4217 35.6503 28.431 31.8689 27.1318Z"
          fill="#0060AE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.3253 34.9033C28.3253 31.4211 25.6783 28.4296 21.8969 27.1304C25.0091 25.8962 27.1473 23.3406 27.1473 20.3887C27.1473 16.2296 22.9052 12.8584 17.6746 12.8584C17.4561 12.8584 17.2384 12.8647 17.0238 12.8766H2.70312V43.3674H17.2873V43.361C17.4164 43.361 17.5463 43.3674 17.6762 43.3674C23.5576 43.3674 28.3253 39.5776 28.3253 34.9033ZM11.6533 19.7451L13.7259 19.7411C15.4065 19.7411 16.7648 20.8223 16.7648 22.1564C16.7648 23.4619 15.4623 24.5249 13.8336 24.57H11.6533V19.7451ZM14.5998 36.017H11.6533V30.8852L14.4852 30.8813C16.2698 30.8813 17.7159 32.0306 17.7159 33.4487C17.7159 34.8383 16.3309 35.9686 14.5998 36.017Z"
          fill="#0060AE"
        />
      </g>
      <defs>
        <clipPath id="clip0_2575_3426">
          <rect
            width="35.5938"
            height="30.5089"
            fill="white"
            transform="translate(2.70312 12.8579)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
