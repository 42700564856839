import { useQueryString } from "hooks";
import React from "react";
import { Navigate } from "react-router-dom";

export default function PaymentRedirect() {
  const { parseQuery: parsedQuery } = useQueryString();
  const { toUserId } = parsedQuery
  const redirectURL = `/pay?receiverId=${toUserId}`;

  return <Navigate to={redirectURL} />;
}
