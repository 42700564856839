export default function EnvelopeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <mask
        id="mask0_946_20604"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.40918" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_946_20604)">
        <path
          d="M4 20.4092C3.45 20.4092 2.97917 20.2133 2.5875 19.8217C2.19583 19.43 2 18.9592 2 18.4092V6.40918C2 5.85918 2.19583 5.38835 2.5875 4.99668C2.97917 4.60501 3.45 4.40918 4 4.40918H20C20.55 4.40918 21.0208 4.60501 21.4125 4.99668C21.8042 5.38835 22 5.85918 22 6.40918V18.4092C22 18.9592 21.8042 19.43 21.4125 19.8217C21.0208 20.2133 20.55 20.4092 20 20.4092H4ZM12 13.4092L20 8.40918V6.40918L12 11.4092L4 6.40918V8.40918L12 13.4092Z"
          fill="#9496A2"
        />
      </g>
    </svg>
  );
}
