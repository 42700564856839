import { useState, useRef, useEffect } from "react";
import useSWR from "swr";
import Modal from "components/modals/TestModal";
import { useTranslation } from "react-i18next";

import useQueryString from "hooks/useQueryString";
import TippingUser from "components/TippingUser";
import { makeEmoneyTransaction } from "api";
import { Navigate, useNavigate } from "react-router-dom";
import PaymentFeedback from "components/PaymentFeedback/PaymentFeedback";
import {
  SYSTEM_STATUS_URL,
  USER_PROFILE_URL,
} from "helpers/fetcher/fetcher.config";

const EmoneyTipPage = () => {
  const amountRef = useRef();
  const orderCodeRef = useRef();
  const customDataRef = useRef();
  const formRef = useRef();
  const checkRef = useRef();
  const currencyRef = useRef();
  // const descriptionRef = useRef();
  const lngRef = useRef();

  const navigate = useNavigate();

  const { data: systemStatusData } = useSWR(SYSTEM_STATUS_URL);

  const { t } = useTranslation();

  const [toggleModal, setToggleModal] = useState(false);

  // BACK INTEGRATION WITH MIN AND MAX VAL
  const [amount, setAmount] = useState(0);
  const [inputError, setInputError] = useState(false);

  const { parseQuery } = useQueryString();
  const { fromUserId = "", toUserId = "", status = "" } = parseQuery;

  const recieverId = toUserId || localStorage.getItem("toUserId");
  // const senderId = fromUserId || localStorage.getItem("fromUserId");

  const { data } = useSWR(`${USER_PROFILE_URL}/${recieverId}`);

  const {
    perTransactionMaxLimit: maxValDB = 0,
    perTransactionMinLimit: minValDB = 0,
  } = data || {};

  const handleInputChange = (e) => {
    const val = e.target.value;
    const value = val.startsWith("₾") ? val.slice(1, val.length) : val;
    if (isNaN(value)) {
      return;
    }

    setInputError(Number(value) < minValDB || Number(value) > maxValDB);
    if (val === "₾" || !val.length) {
      setAmount(0);
      return;
    }
    setAmount(value);
  };

  const staticSubmitHandler = async () => {
    const fixedAmount = amount * 100;

    if (!fixedAmount || !recieverId) {
      return;
    }
    const payload = {
      amount: fixedAmount,
      currency: "GEL",
      description: "Keepz Transaction",
      fromUserId: undefined,
      language: "KA",
      toUserId: recieverId,
    };

    try {
      const { amount, customData, hash, orderCode } =
        await makeEmoneyTransaction(payload);

      orderCodeRef.current.value = orderCode;
      amountRef.current.value = amount;
      customDataRef.current.value = customData;
      checkRef.current.value = hash;

      const form = new FormData(formRef.current);
      for (const [name, value] of form) {
        console.log(name, ":", value);
      }

      formRef.current.submit();
    } catch (error) {
      console.log(error);
      error.response.data.errorCode === 460
        ? setToggleModal(true)
        : console.log("Limit Modal False");
    }
  };

  const enterDisableHandler = (e) => {
    if (e.keyCode === 13) e.preventDefault();
    return false;
  };

  const handleModalClose = () => {
    if (status) {
      navigate(`/tipping?toUserId=${toUserId}`);
    }
    setToggleModal(false);
  };

  useEffect(() => {
    if (status && status.toString() === "FAIL") {
      setToggleModal(true);
    }
  }, [status]);

  if (systemStatusData?.systemStatus === "PASSIVE") {
    return <Navigate to="/downtime" />;
  } else if (systemStatusData?.name === "AxiosError") {
    return <Navigate to="/error" />;
  }
  return (
    <main>
      <Modal show={toggleModal} onClose={handleModalClose}>
        <PaymentFeedback type={status} action={handleModalClose} />
      </Modal>
      <TippingUser user={data} />
      <div className="flex flex-col items-center justify-center px-[25px]">
        <form
          ref={formRef}
          method="post"
          action="https://www.emoney.ge/index.php/main/merchantstransfer"
          onKeyDown={(e) => enterDisableHandler(e)}
        >
          <input type="hidden" name="merchant" value="Keepz" />
          <input type="hidden" name="ordercode" ref={orderCodeRef} />
          <input type="hidden" name="amount" ref={amountRef} />
          <input type="hidden" name="currency" ref={currencyRef} value="GEL" />
          <input type="hidden" name="description" value="Keepz Transaction" />
          <input
            type="hidden"
            name="customdata"
            ref={customDataRef}
            value="rame2"
          />
          <input type="hidden" name="lng" ref={lngRef} value="KA" />
          <input type="hidden" name="check" ref={checkRef} />
          <div className="flex items-center justify-center mt-[88px]">
            <input
              id="amount"
              className="outline-none w-full text-5xl text-center"
              type="tel"
              inputMode="decimal"
              autoFocus
              value={amount ? `₾${amount}` : ""}
              onChange={handleInputChange}
              placeholder="0"
              step="0.5"
            />
          </div>
          {inputError && (
            <p className="text-red text-[11px] text-center mt-2 mr-[5px] mb-[5px]">
              {t("description.enterAmount", { minValDB, maxValDB })}
            </p>
          )}
          <div className="flex items-center justify-center mt-[120px]">
            <button
              className="p-4 w-full text-white bg-black-500 text-center rounded-2xl"
              type="button"
              disabled={!inputError && !amount}
              onClick={staticSubmitHandler}
              style={{
                backgroundColor: inputError || !amount ? "#837B94" : "#170738",
              }}
            >
              {t("description.pay")}
            </button>
          </div>

          <div className="text-black/5 text-[11px] mt-[15px] text-center [&a]:text-red-200 ">
            <p>{t("description.agreetc")}</p>
            <a href="#">{t("description.termsAndCon")}</a>
          </div>
        </form>
      </div>
    </main>
  );
};
export default EmoneyTipPage;
