import React from "react";

export default function NewBankIcon(props) {
  return (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.65937 11.7606H36.1582C36.558 11.7504 36.9416 11.601 37.243 11.338C37.5443 11.0751 37.7445 10.7152 37.8088 10.3205C37.8731 9.92581 37.7976 9.52101 37.5954 9.17602C37.3931 8.83102 37.0767 8.56746 36.7008 8.43084L20.0888 0.793117C19.8734 0.69425 19.6391 0.643066 19.4021 0.643066C19.165 0.643066 18.9308 0.69425 18.7153 0.793117L1.97265 8.49784C1.62201 8.66269 1.3373 8.9411 1.16463 9.28795C0.991964 9.63481 0.941454 10.0298 1.02128 10.4089C1.09601 10.7889 1.30006 11.1312 1.59871 11.3776C1.89737 11.624 2.27218 11.7594 2.65937 11.7606Z"
        fill="#170738"
      />
      <path
        d="M4.79887 30.1876C4.35437 30.2015 3.93272 30.3878 3.62321 30.7072C3.3137 31.0265 3.14063 31.4538 3.14062 31.8985C3.14062 32.3432 3.3137 32.7705 3.62321 33.0898C3.93272 33.4092 4.35437 33.5955 4.79887 33.6095H34.0165C34.461 33.5955 34.8827 33.4092 35.1922 33.0898C35.5017 32.7705 35.6748 32.3432 35.6748 31.8985C35.6748 31.4538 35.5017 31.0265 35.1922 30.7072C34.8827 30.3878 34.461 30.2015 34.0165 30.1876H33.5995V14.7949H34.0165C34.2431 14.7949 34.4603 14.7049 34.6205 14.5447C34.7807 14.3845 34.8707 14.1672 34.8707 13.9406C34.8707 13.7141 34.7807 13.4968 34.6205 13.3366C34.4603 13.1764 34.2431 13.0864 34.0165 13.0864H4.79887C4.57232 13.0864 4.35505 13.1764 4.19485 13.3366C4.03465 13.4968 3.94465 13.7141 3.94465 13.9406C3.94465 14.1672 4.03465 14.3845 4.19485 14.5447C4.35505 14.7049 4.57232 14.7949 4.79887 14.7949H5.21593V30.1859L4.79887 30.1876ZM30.2579 14.7949V30.1859H25.2499V14.7949H30.2579ZM21.9101 14.7949V30.1859H16.902V14.7949H21.9101ZM8.55409 14.7949H13.5655V30.1859H8.55409V14.7949Z"
        fill="#170738"
      />
      <path
        d="M36.1498 34.9351H2.66606C2.22155 34.949 1.79991 35.1353 1.4904 35.4547C1.18089 35.774 1.00781 36.2013 1.00781 36.646C1.00781 37.0907 1.18089 37.518 1.4904 37.8373C1.79991 38.1567 2.22155 38.3431 2.66606 38.357H36.1481C36.5926 38.3431 37.0142 38.1567 37.3238 37.8373C37.6333 37.518 37.8063 37.0907 37.8063 36.646C37.8063 36.2013 37.6333 35.774 37.3238 35.4547C37.0142 35.1353 36.5943 34.949 36.1498 34.9351Z"
        fill="#170738"
      />
    </svg>
  );
}
