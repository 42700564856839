import React from "react";

export default function LocationIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <mask
        id="mask0_946_20599"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="25"
      >
        <rect y="0.40918" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_946_20599)">
        <path
          d="M12 12.4092C12.55 12.4092 13.0208 12.2133 13.4125 11.8217C13.8042 11.43 14 10.9592 14 10.4092C14 9.85918 13.8042 9.38835 13.4125 8.99668C13.0208 8.60501 12.55 8.40918 12 8.40918C11.45 8.40918 10.9792 8.60501 10.5875 8.99668C10.1958 9.38835 10 9.85918 10 10.4092C10 10.9592 10.1958 11.43 10.5875 11.8217C10.9792 12.2133 11.45 12.4092 12 12.4092ZM12 22.4092C9.31667 20.1258 7.3125 18.005 5.9875 16.0467C4.6625 14.0883 4 12.2758 4 10.6092C4 8.10918 4.80417 6.11751 6.4125 4.63418C8.02083 3.15085 9.88333 2.40918 12 2.40918C14.1167 2.40918 15.9792 3.15085 17.5875 4.63418C19.1958 6.11751 20 8.10918 20 10.6092C20 12.2758 19.3375 14.0883 18.0125 16.0467C16.6875 18.005 14.6833 20.1258 12 22.4092Z"
          fill="#9496A2"
        />
      </g>
    </svg>
  );
}
