const Button = ({ className = "", children, ...rest }) => {
  return (
    <button
      className={`rounded-[12px] transition duration-300 ease-in-out  ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
