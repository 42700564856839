export default function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.017 2H7.947C6.37015 2.00185 4.85844 2.62914 3.74353 3.74424C2.62862 4.85933 2.00159 6.37115 2 7.948L2 16.018C2.00185 17.5948 2.62914 19.1066 3.74424 20.2215C4.85933 21.3364 6.37115 21.9634 7.948 21.965H16.018C17.5948 21.9631 19.1066 21.3359 20.2215 20.2208C21.3364 19.1057 21.9634 17.5938 21.965 16.017V7.947C21.9631 6.37015 21.3359 4.85844 20.2208 3.74353C19.1057 2.62862 17.5938 2.00159 16.017 2V2ZM19.957 16.017C19.957 16.5344 19.8551 17.0468 19.6571 17.5248C19.4591 18.0028 19.1689 18.4371 18.803 18.803C18.4371 19.1689 18.0028 19.4591 17.5248 19.6571C17.0468 19.8551 16.5344 19.957 16.017 19.957H7.947C6.90222 19.9567 5.90032 19.5415 5.16165 18.8026C4.42297 18.0638 4.008 17.0618 4.008 16.017V7.947C4.00827 6.90222 4.42349 5.90032 5.16235 5.16165C5.90122 4.42297 6.90322 4.008 7.948 4.008H16.018C17.0628 4.00827 18.0647 4.42349 18.8034 5.16235C19.542 5.90122 19.957 6.90322 19.957 7.948V16.018V16.017Z"
        fill="#170738"
      />
      <path
        d="M11.9818 6.81934C10.6133 6.82145 9.30136 7.36612 8.33372 8.33394C7.36609 9.30176 6.82168 10.6138 6.81982 11.9823C6.82141 13.3513 7.36585 14.6637 8.33372 15.6317C9.3016 16.5998 10.6139 17.1445 11.9828 17.1463C13.3519 17.1447 14.6645 16.6002 15.6326 15.6321C16.6007 14.664 17.1452 13.3514 17.1468 11.9823C17.1447 10.6134 16.5998 9.30122 15.6315 8.33353C14.6633 7.36584 13.3507 6.82166 11.9818 6.82034V6.81934ZM11.9818 15.1383C11.1451 15.1383 10.3426 14.8059 9.7509 14.2143C9.15922 13.6226 8.82682 12.8201 8.82682 11.9833C8.82682 11.1466 9.15922 10.3441 9.7509 9.75241C10.3426 9.16074 11.1451 8.82834 11.9818 8.82834C12.8186 8.82834 13.6211 9.16074 14.2127 9.75241C14.8044 10.3441 15.1368 11.1466 15.1368 11.9833C15.1368 12.8201 14.8044 13.6226 14.2127 14.2143C13.6211 14.8059 12.8186 15.1383 11.9818 15.1383Z"
        fill="#170738"
      />
      <path
        d="M17.1559 8.09509C17.8391 8.09509 18.3929 7.54127 18.3929 6.85809C18.3929 6.17492 17.8391 5.62109 17.1559 5.62109C16.4728 5.62109 15.9189 6.17492 15.9189 6.85809C15.9189 7.54127 16.4728 8.09509 17.1559 8.09509Z"
        fill="#170738"
      />
    </svg>
  );
}
