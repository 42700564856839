import { Trans, useTranslation } from "react-i18next";
import Button from "../Button/Button";
import ModalVideo from "react-modal-video";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MerchantsCard = ({
  src,
  srcTwo,
  title,
  titleTwo,
  description,
  isImageLeft,
  id,
  btnOnebgColor,
  btnTwobgColor,
  openContactModel,
  hoverColor,
  videoButton = false,
  isIcon = true,
  showWithShadow,
  shoWButtons = true,
  otherSize = false,
  specificSize = false,
  showPriceButton = true,
  forBussinesButton = true,
  integrationButton = true,
  showNavigationButton = false,
}) => {
  const [isOpen, setOpen] = useState(false);

  const navigate = useNavigate();

  let content;

  if (videoButton && !forBussinesButton) {
    content = <Trans i18nKey="buttons.seeVideo" />;
  } else if (!videoButton && !forBussinesButton) {
    content = <Trans i18nKey="buttons.request" />;
  } else if (forBussinesButton) {
    content = <Trans i18nKey="buttons.reachOut" />;
  }

  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={"cnwU1B4G6lk"}
        onClose={() => setOpen(false)}
      />
      <div id={id} className="rounded-[32px] sm:mt-[-25px] ">
        <div
          className={`sm:hidden  md:flex  justify-between  items-center mt-[50px]  ${
            isImageLeft ? "" : "flex-row-reverse"
          } `}
        >
          <div className={`w-[600px] `}>
            <div className="flex gap-[16px] items-center ">
              {isIcon && <img src={srcTwo} alt="icon" />}
              <h2
                className={`text-[${btnOnebgColor}] font-[700] mt-[-6px] text-[37px] `}
                style={{
                  fontVariant: "all-petite-caps",
                  lineHeight: "28.6px",
                }}
              >
                {title}
              </h2>
            </div>
            <h2 className="text-[#170738] font-[700] text-[20px] mt-[26px]">
              {titleTwo}
            </h2>
            <p className="text-[#777987] mt-[10px] text-[16px]">
              {description}
            </p>
            {shoWButtons && (
              <div className="flex mt-[40px] gap-[20px]">
                <Button
                  className={`border-2 rounded-[12px] solid bg-[${btnOnebgColor}] text-[#fff] hover:bg-[#fff] hover:text-[${btnOnebgColor}] hover:border-[${btnOnebgColor}] py-[10px] px-[40px]`}
                  onClick={() => {
                    if (videoButton) {
                      setOpen(true);
                    } else {
                      openContactModel();
                    }
                  }}
                >
                  {/* {videoButton && !forBussinesButton ? (
                    <Trans i18nKey="buttons.seeVideo" />
                  ) : (
                    <Trans i18nKey="buttons.request" />
                  )}

                  {forBussinesButton && <Trans i18nKey="buttons.reachOut" />} */}
                  {content}
                </Button>
                {showPriceButton && !showNavigationButton && (
                  <a href="#price">
                    <Button
                      className={`border-2 rounded-[12px] solid ${btnTwobgColor} text-[${btnOnebgColor}] hover:bg-[#fff]  hover:border-[${btnOnebgColor}] py-[10px] px-[40px]`}
                    >
                      <Trans i18nKey="buttons.price" />
                    </Button>
                  </a>
                )}
                {showNavigationButton && (
                  <Button
                    className={`border-2 rounded-[12px] solid ${btnTwobgColor} text-[${btnOnebgColor}] hover:bg-[#fff]  hover:border-[${btnOnebgColor}] py-[10px] px-[40px]`}
                    onClick={() => navigate("qr-terminal")}
                  >
                    <Trans i18nKey="learnMore" />
                  </Button>
                )}
                {integrationButton && (
                  <Button
                    className={`border-2 rounded-[12px] solid ${btnTwobgColor} text-[${btnOnebgColor}] hover:bg-[#fff]  hover:border-[${btnOnebgColor}] py-[10px] px-[40px]`}
                    onClick={() =>
                      (window.location.href =
                        "https://www.developers.keepz.me/")
                    }
                  >
                    <Trans i18nKey="buttons.integration" />
                  </Button>
                )}
              </div>
            )}
          </div>

          <img
            src={src}
            className={`${specificSize && "w-[554px]"} ${
              otherSize ? "w-[500px]" : "w-[540px]"
            } object-fit`}
            alt="Image"
          />
        </div>

        <div className="md:hidden px-[16px] m-auto sm:w-full sm1:w-[480px] sm2:w-[600px] mt-[54px] ">
          <div className="rounded-[16px] grid grid-cols-12">
            <img src={src} alt="image" className="col-span-12 rounded-[16px]" />
          </div>
          <div>
            <div className="flex gap-3 items-center w-[100%] mt-[20px]">
              {isIcon && (
                <div className="">
                  <img src={srcTwo} alt="icon" />
                </div>
              )}
              <h2
                style={{ fontVariant: "all-petite-caps", lineHeight: "22px" }}
                className={`text-[${btnOnebgColor}] font-[700] text-[26px] mt-[-4px]`}
              >
                {title}
              </h2>
            </div>
            <h2 className="text-[#170738] font-[700] text-[18px] mt-[28px]">
              {titleTwo}
            </h2>
            <p className="text-[#777987] text-[14px] mt-[10px]">
              {description}
            </p>
          </div>
          {shoWButtons && (
            <div
              className={`flex ${
                forBussinesButton && "flex-wrap "
              }  mt-[40px]  justify-center gap-[20px] w-full`}
            >
              <Button
                className={`border-2 whitespace-nowrap  rounded-[12px] ${
                  forBussinesButton ? "w-[75%]" : "w-[50%]"
                } solid bg-[${btnOnebgColor}] text-[#fff] hover:bg-[#fff] hover:text-[${btnOnebgColor}] hover:border-[${btnOnebgColor}] py-[10px] 
                  px-[40px]`}
                onClick={() => {
                  if (videoButton) {
                    setOpen(true);
                  } else {
                    openContactModel();
                  }
                }}
              >
                {/* {videoButton && !forBussinesButton ? (
                    <Trans i18nKey="buttons.seeVideo" />
                  ) : (
                    <Trans i18nKey="buttons.request" />
                  )}

                  {forBussinesButton && <Trans i18nKey="buttons.reachOut" />} */}
                {content}
              </Button>
              {showPriceButton && !showNavigationButton && (
                <a href="#price" className="w-[50%]">
                  <Button
                    className={`border-2 rounded-[12px] w-[100%]  solid ${btnTwobgColor} text-[${btnOnebgColor}] hover:bg-[#fff]  hover:border-[${btnOnebgColor}] py-[10px] px-[30px]`}
                  >
                    <Trans i18nKey="buttons.price" />
                  </Button>
                </a>
              )}
              {showNavigationButton && (
                <Button
                  className={`border-2 w-[50%] rounded-[12px] solid ${btnTwobgColor} text-[${btnOnebgColor}] hover:bg-[#fff]  hover:border-[${btnOnebgColor}] py-[10px] px-[15px]`}
                  onClick={() => navigate("qr-terminal")}
                >
                  <Trans i18nKey="learnMore" />
                </Button>
              )}
              {integrationButton && (
                <Button
                  className={`border-2 rounded-[12px] w-[75%] solid ${btnTwobgColor} text-[${btnOnebgColor}] hover:bg-[#fff]  hover:border-[${btnOnebgColor}] py-[10px] px-[64px]`}
                  onClick={() =>
                    (window.location.href = "https://www.developers.keepz.me/")
                  }
                >
                  <Trans i18nKey="buttons.integration" />
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MerchantsCard;
