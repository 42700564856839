const Card = ({ logo, title, description, children }) => {
  return (
    <div className="flex  justify-between items-top      sm:w-[100%] md:max-w-[393px]  bg-white p-[18px] md:p-[40px] flex-wrap h-[100%] md:h-[285px] sm:h-[205px]  rounded-[16px] ">
      <div className={` `}>
        <img src={logo} alt="" />
        <h2 className="font-[700] text-[#170738] text-[18px]  md:text-[24px] mt-[16px]">
          {title}
        </h2>
        <p className=" w-[100%] text-[14px] md:text-[16px] text-[#777987] mt-[16px]">
          {description}
        </p>
      </div>

      {children ? children : null}
    </div>
  );
};

export default Card;
