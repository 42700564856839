export default function ProcBankIcon(props) {
  return (
    <svg
      width="41"
      height="56"
      viewBox="0 0 41 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.8139 39.1069C12.7605 38.4399 6.51558 35.0446 4.19141 31.144C4.31267 31.8716 5.16149 33.4682 5.54549 34.5394C5.80822 35.3073 6.21242 35.9339 6.41453 36.4997C9.32479 38.5612 12.6797 39.6525 17.2876 40.0769C22.037 40.5418 26.2205 39.9557 29.5148 38.3793C26.6247 39.1271 23.4315 39.4504 19.8139 39.1069Z"
        fill="#FFDA00"
      />
      <path
        d="M14.3156 24.8988C11.8903 22.2714 9.70763 19.6037 7.80788 16.9966C7.56535 17.3402 7.28241 17.7241 6.97926 18.1688C6.55485 18.7751 6.23148 19.341 5.96875 19.8058C7.82809 21.847 9.88952 23.9085 12.1126 25.9295C18.4788 31.6692 24.6632 35.408 29.6147 38.096C25.1684 34.8017 19.7521 30.8203 14.3156 24.8988Z"
        fill="#FFDA00"
      />
      <path
        d="M24.3419 20.2496C22.5432 16.632 21.1082 14.227 19.0266 11.438C18.7437 11.4582 18.299 11.6805 17.4906 11.822C16.8843 11.9028 16.278 11.8422 15.793 12.0443C18.8447 15.2375 21.3103 18.5924 24.0185 23.4226C27.0905 28.8996 28.6264 33.265 29.9401 37.8325C29.3742 32.5778 27.7776 27.0605 24.3419 20.2496Z"
        fill="#FFDA00"
      />
      <path
        d="M21.3919 44.7047C12.5601 44.7047 5.4057 37.7928 5.4057 29.2439C5.4057 20.7152 12.5197 14.0458 21.3313 14.0458C30.0015 14.0458 37.075 20.4525 37.358 28.7588C37.358 28.5365 37.358 28.3344 37.358 28.1121C37.358 18.7952 29.7792 11.2568 20.4016 11.2568C11.0443 11.2568 3.44531 18.7952 3.44531 28.1121C3.44531 37.429 11.0443 44.9674 20.4016 44.9674C22.2408 44.9674 23.9991 44.6845 25.6361 44.1388C24.282 44.5026 22.8673 44.7047 21.3919 44.7047Z"
        fill="#003E8F"
      />
      <path
        d="M12.2375 20.9781C18.3006 14.814 27.6983 14.3491 33.2561 19.9271C36.6514 23.3426 38.0055 28.4962 36.8333 32.9223C38.4097 27.6676 37.4396 21.7056 33.4582 17.6838C27.6174 11.8026 17.7549 12.1866 11.712 18.3507C7.73063 22.413 6.29571 28.0314 7.44769 33.0637C7.28601 28.8398 8.90282 24.3936 12.2375 20.9781Z"
        fill="#8C006C"
      />
      <path
        d="M36.528 29.6285C36.4876 25.9301 35.0729 22.4943 32.4658 20.6956C27.4132 17.2195 19.5717 19.9479 14.701 27.0821C12.1748 30.7603 10.9824 34.8428 11.1238 38.42C10.0123 34.2365 10.9419 29.0627 14.0139 24.576C18.8845 17.4418 27.3526 14.7741 32.9104 18.5938C36.2047 20.8573 37.8013 24.9196 37.4981 29.3254C37.4981 29.3254 37.3971 30.5986 37.2758 31.1443C37.1747 31.6698 36.8716 32.8217 36.7503 33.1451C36.6493 33.4887 36.528 29.6285 36.528 29.6285Z"
        fill="#007854"
      />
      <path
        d="M19.7782 27.4857C14.8065 32.7605 13.6747 39.7937 17.2721 43.1688C17.4136 43.3305 17.5955 43.4517 17.7571 43.5932C14.6044 40.4202 15.5542 34.3773 19.9196 29.6482C23.9415 25.303 30.7725 23.4032 34.087 26.5358C36.9972 29.2844 36.6132 34.5592 33.42 39.0257C37.846 33.8519 38.7353 27.2836 35.2996 24.0499C31.7022 20.6546 24.7499 22.1906 19.7782 27.4857Z"
        fill="#EA4C0B"
      />
      <path
        d="M24.4626 32.8419C21.1077 35.7522 19.976 39.9559 21.9566 42.2195C23.4925 43.998 26.4836 44.0586 29.3131 42.6035C26.9283 43.6948 24.4828 43.5735 23.1692 42.0578C21.4109 40.0368 22.381 36.3585 25.3114 33.812C28.2217 31.2656 32.0212 30.8411 33.7593 32.8419C35.073 34.3577 34.8708 36.8031 33.4561 39.006C35.2751 36.4191 35.6388 33.4482 34.1029 31.6698C32.1223 29.4062 27.8175 29.9317 24.4626 32.8419Z"
        fill="#C60012"
      />
    </svg>
  );
}
