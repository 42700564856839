import useSWR from "swr";
import { paymentServiceInstance } from "api";
import { decrypt, decryptAES, parseBase64 } from "utils/encryption";

export const useInstallmentProperties = () => {
  const { data: installmentProperties, error } = useSWR(
    `/api/external/credo/installment/properties?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`,
    async (url) => {
      try {
        const {
          data: {
            value: { encryptedData, encryptedKeys },
          },
        } = await paymentServiceInstance.get(url);

        // Decrypt the secret key
        const encrypteSecretKey = decrypt(encryptedKeys, {
          shouldParse: false,
        });

        // Split into key and IV
        const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
        const secretKey = parseBase64(base64SecretKey);
        const iv = parseBase64(base64IV);

        // Decrypt the actual data
        const decryptedData = decryptAES(encryptedData, secretKey, iv);
        return decryptedData;
      } catch (error) {
        throw error;
      }
    }
  );

  return {
    installmentProperties,
    error,
    isLoading: !error && !installmentProperties,
  };
};
