import storeIcon from "../../../assets/images/სავაჭრო ობიექტისთვის.png";

import distIcon from "../../../assets/images/Property 1=დისტანციური გადახდა.png";

import receiptIcon from "../../../assets/images/receiptIocn.png";
import bagIcon from "../../../assets/images/Property 1=ონლაინ გადახდები.png";
import imgTwo from "../../../assets/images/distantPay.png";
import EcommImg from "../../../assets/images/Ecomm.png";
import QrIcon from "../../../assets/images/QRIcon.png";

import QRimage from "../../../assets/images/QRტერმინალი.png";
import QrCheck from "../../../assets/images/QrCheck.png";
import Receip from "../../../assets/images/Receip.png";

import { Trans } from "react-i18next";

import MerchantsCard from "./MerchantCard";

const Merchants = ({ openContactModel }) => {
  const arr = [
    {
      src: QRimage,
      srcTwo: QrIcon,
      title: <Trans i18nKey={"paymentTypes.terminalForMerchant.titleB"} />,
      id: "1",
      titleTwo: (
        <Trans i18nKey={"paymentTypes.terminalForMerchant.headLines"} />
      ),
      description: (
        <Trans i18nKey={"paymentTypes.terminalForMerchant.description"} />
      ),
      isImageLeft: true,
      videoButton: true,
      showNavigationButton: true,
    },
    {
      src: imgTwo,
      srcTwo: distIcon,
      title: <Trans i18nKey={"paymentTypes.distancePayment.titleB"} />,
      id: "2",
      titleTwo: <Trans i18nKey={"paymentTypes.distancePayment.headLines"} />,
      description: (
        <Trans i18nKey={"paymentTypes.distancePayment.description"} />
      ),
      isImageLeft: false,
      videoButton: false,
      showNavigationButton: false,
    },
    {
      src: EcommImg,
      srcTwo: bagIcon,
      title: <Trans i18nKey={"paymentTypes.onlinePayments.titleB"} />,
      id: "3",
      titleTwo: <Trans i18nKey={"paymentTypes.onlinePayments.headLines"} />,
      description: (
        <Trans i18nKey={"paymentTypes.onlinePayments.description"} />
      ),
      isImageLeft: true,
      videoButton: false,
      showNavigationButton: false,
    },
    {
      src: QrCheck,
      srcTwo: receiptIcon,
      title: <Trans i18nKey={"paymentTypes.QRcheck.title"} />,
      id: "4",
      titleTwo: <Trans i18nKey={"paymentTypes.QRcheck.headLines"} />,
      description: (
        <>
          <ul className="list-disc list-inside ">
            <li>
              <Trans i18nKey={"qrCheck.firstBullet"} />
            </li>
            <li>
              <Trans i18nKey={"qrCheck.secondBullet"} />
            </li>
            <li>
              <Trans i18nKey={"qrCheck.thirdBullet"} />
            </li>
            <li>
              <Trans i18nKey={"qrCheck.fourthBullet"} />
            </li>
          </ul>
        </>
      ),
      isImageLeft: false,
      videoButton: false,
      showNavigationButton: false,
    },
    {
      src: Receip,
      srcTwo: storeIcon,
      title: <Trans i18nKey={"paymentTypes.qrForRestaurant.titleB"} />,
      id: "5",
      titleTwo: <Trans i18nKey={"paymentTypes.qrForRestaurant.headLines"} />,
      description: (
        <>
          <ul className="list-disc list-inside ">
            <li>
              <Trans i18nKey={"qrPayment.firstBullet"} />
            </li>
            <li>
              <Trans i18nKey={"qrPayment.secondBullet"} />
            </li>
            <li>
              <Trans i18nKey={"qrPayment.thirdBullet"} />
            </li>
            <li>
              <Trans i18nKey={"qrPayment.fourthBullet"} />
            </li>
          </ul>
        </>
      ),
      isImageLeft: true,
      videoButton: false,
      showNavigationButton: false,
    },
  ];

  // useEffect(() => {
  //   const href = window.location.href.substring(
  //     window.location.href.lastIndexOf("#") + 1
  //   );
  //   const element = document.getElementById(href);
  //   if (element) {
  //     element.scrollIntoView({ behavior: "instant" });
  //   }
  // }, []);
  return (
    <section className="max-w-[2000px] m-auto">
      <h2
        className="mt-[70px] text-center  text-[#6C63FF] font-[700] md:text-[37px]  sm:text-[30px] "
        style={{ fontVariant: "all-petite-caps" }}
      >
        <Trans i18nKey={"navigation.products"} />
      </h2>

      <div className="md:mt-[-70px] sm:mt-[-25px]">
        {arr.map(
          ({
            src,
            srcTwo,
            title,
            titleTwo,
            description,
            isImageLeft,
            id,
            videoButton,
            showNavigationButton,
          }) => {
            return (
              <MerchantsCard
                src={src}
                srcTwo={srcTwo}
                title={title}
                titleTwo={titleTwo}
                description={description}
                isImageLeft={isImageLeft}
                id={id}
                btnOnebgColor={"#6C63FF"}
                btnTwobgColor={"bg-[#f0efff]"}
                hoverColor={"hover:border-[#6C63FF]"}
                openContactModel={openContactModel}
                showWithShadow={id != "3" && id != "6"}
                videoButton={videoButton}
                forBussinesButton={false}
                integrationButton={false}
                showNavigationButton={showNavigationButton}
              />
            );
          }
        )}
      </div>
    </section>
  );
};

export default Merchants;
