import React, { useEffect } from "react";

// MOBILE PHOTOS
// import Header from "./components/Header";
import Hero from "./components/Hero";

import { useIntercom } from "react-use-intercom";
// import Footer from "./components/Footer";
import Customers from "./Customers/Customers";
import WhyKeepz from "./WhyKeepzSection/WhyKeepz";
import Merchants from "./MerchantsSection/Merchants";
// import StatiticsSection from "./statistics/StatiticsSection";
import RegisterSection from "./HowToRegister/RegisterSection";
// import RequestAndSend from "./RequestAndSendSection/RequestAndSend";
import Price from "./MerchantsSection/Price";
// import Orgnaziations from "./MerchantsSection/Organizations";
// import Modal from "./Modal/Modal";
// import SuccessModal from "./Modal/SuccessModal";
import Request from "./RequestAndSendSection/Request";

const LandingPage = ({ openContactModel }) => {
  // const [showContactModal, setShowContactModal] = useState(false);
  // const [successModal, setSuccessModal] = useState(false);
  const { boot } = useIntercom();
  useEffect(() => {
    boot();
  }, [boot]);

  // function openContactModel() {
  //   setShowContactModal(true);
  // }
  return (
    <section>
      {/* {showContactModal === true && (
        <Modal
          setShowContactModal={setShowContactModal}
          setSuccessModal={setSuccessModal}
        />
      )}
      {showContactModal === false && successModal === true ? (
        <SuccessModal setSuccessModal={setSuccessModal} />
      ) : null} */}

      <Container>
        {/* <Header /> */}
        <Hero openContactModel={openContactModel} />
        <Customers />
      </Container>
      <WhyKeepz />
      <Container>
        <Merchants openContactModel={openContactModel} />
      </Container>
      <Price openContactModel={openContactModel} />
      {/* <Container>
        <Orgnaziations openContactModel={openContactModel} />
      </Container> */}
      <Container>
        {/* <StatiticsSection /> */}
        <RegisterSection />
        <Request openContactModel={openContactModel} />
        {/* <RequestAndSend openContactModel={openContactModel} /> */}
      </Container>

      {/* <Footer /> */}
    </section>
  );
};

export const Container = ({ children }) => (
  <div className=" max-w-[1240px] m-auto  scroll-smooth font-display">
    {children}
  </div>
);
export default LandingPage;
