export default function TeraBankIcon(props) {
  return (
    <svg
      width="41"
      height="56"
      viewBox="0 0 41 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_2575_3431"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="10"
        width="41"
        height="36"
      >
        <path
          d="M0.00390625 10.7612H40.9966V45.4636H0.00390625V10.7612Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2575_3431)">
        <mask
          id="mask1_2575_3431"
          //   style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="10"
          width="41"
          height="36"
        >
          <path
            d="M33.7834 10.7622C33.2102 10.7622 32.6085 10.8183 31.9772 10.9365C27.3074 11.8046 25.0457 14.9555 25.0457 14.9555C25.0457 14.9555 27.8806 14.0226 30.7051 14.0226C30.7389 14.0226 30.7724 14.0226 30.8073 14.0226C33.6786 14.0455 35.2868 15.5867 35.239 17.665C35.1859 19.9467 33.3694 23.1042 31.6756 25.4877C28.7333 29.629 23.2498 34.1658 17.4455 34.1658C14.7917 34.1658 12.0722 33.2177 9.49718 30.848C2.32537 24.2495 0.93953 12.3084 0.93953 12.3084C-1.33639 21.8494 0.297894 39.2677 11.5455 44.2426C13.4565 45.0876 15.3546 45.4629 17.2101 45.4629C26.7122 45.4629 35.0745 35.6294 38.2291 28.9035C41.3034 22.3483 41.8816 16.6278 39.532 13.576C38.1773 11.8173 36.2573 10.7622 33.7834 10.7622Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_2575_3431)">
          <path
            d="M-0.59375 10.125H41.6209V46.0984H-0.59375V10.125Z"
            fill="#9F1C6B"
          />
        </g>
      </g>
      <mask
        id="mask2_2575_3431"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="3"
        y="11"
        width="23"
        height="15"
      >
        <path
          d="M3.44922 11.4468H25.0496V25.5069H3.44922V11.4468Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_2575_3431)">
        <mask
          id="mask3_2575_3431"
          //   style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="3"
          y="11"
          width="23"
          height="15"
        >
          <path
            d="M25.0352 25.2488C24.0403 25.4067 23.0194 25.488 21.9804 25.488C13.1809 25.488 5.76304 19.667 3.49219 11.7272C4.48727 11.5693 5.50804 11.4878 6.54699 11.4878C15.3454 11.4878 22.7645 17.309 25.0352 25.2488Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_2575_3431)">
          <path
            d="M-0.605469 10.1265H41.6092V46.0999H-0.605469V10.1265Z"
            fill="#80C342"
          />
        </g>
      </g>
    </svg>
  );
}
