import React from "react";
import ApplePayIcon from "assets/icons/ApplePayIcon";
import GooglePayIcon from "assets/icons/GooglePayIcon";
import BinanceIcon from "assets/icons/BinanceIcon";
import CardIcon from "assets/icons/CardIcon";
import DropdownIcon from "assets/icons/DropdownIcon";
import { Trans, Translation, useTranslation } from "react-i18next";
import bog from "../../assets/images/bogDef.svg";
import liberty from "../../assets/images/libertyIcon.svg";
import credo from "../../assets/images/credoDef.svg";
import tbc from "../../assets/images/tbcIcon.svg";

export default function Select({ onClick, type, style }) {
  let content = null;

  const { t } = useTranslation();
  switch (type) {
    case "apple":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <ApplePayIcon />
          <p>Apple Pay</p>
        </div>
      );
      break;
    case "gpay":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <GooglePayIcon />
          <p>Google Pay</p>
        </div>
      );
      break;
    case "binance":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <BinanceIcon />
          <p>Binance Pay</p>
        </div>
      );
      break;
    case "ob_bog":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <img src={bog} alt="bog icon" className="w-[30.01px] h-[26.44px]" />
          <p>Bank of Georgia</p>
        </div>
      );
      break;
    case "ob_tbc":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <img src={tbc} alt="tbc icon" className="w-[30.01px] h-[26.44px]" />
          <p>TBC Bank</p>
        </div>
      );
      break;
    case "ob_credo":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <img
            src={credo}
            alt="credo icon"
            className="w-[30.01px] h-[26.44px]"
          />
          <p>Credo Bank</p>
        </div>
      );
      break;
    case "ob_liberty":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <img
            src={liberty}
            alt="liberty icon"
            className="w-[30.01px] h-[26.44px]"
          />

          <p>Liberty Bank</p>
        </div>
      );
      break;
    case "installment":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.0989 12.6113C23.0989 7.49481 18.8977 2.41584 12.3575 2.41584C7.07542 2.41584 4.46481 6.12667 3.35238 8.10008H6.65399C7.17897 8.10008 7.60456 8.50404 7.60456 9.00233C7.60456 9.50063 7.17897 9.90459 6.65399 9.90459H0.95057C0.425589 9.90459 0 9.50063 0 9.00233V3.58881C0 3.09051 0.425589 2.68656 0.95057 2.68656C1.47555 2.68656 1.90114 3.09051 1.90114 3.58881V6.86071C3.24055 4.61965 6.33852 0.611328 12.3575 0.611328C20.0814 0.611328 25 6.63007 25 12.6113C25 18.5926 20.0814 24.6113 12.3575 24.6113C8.66194 24.6113 5.58513 23.2233 3.39445 21.1077C2.22152 19.9748 1.30548 18.6366 0.680913 17.1961C0.481103 16.7353 0.7127 16.208 1.19817 16.0184C1.68365 15.8287 2.23918 16.0485 2.43897 16.5093C2.97289 17.7407 3.75466 18.8811 4.75038 19.8427C6.59795 21.6272 9.19194 22.8068 12.3575 22.8068C18.8977 22.8068 23.0989 17.7279 23.0989 12.6113ZM12.3574 6.29559C12.8824 6.29559 13.308 6.69955 13.308 7.19784V12.2377L16.8318 15.5824C17.203 15.9347 17.203 16.506 16.8318 16.8584C16.4606 17.2107 15.8588 17.2107 15.4875 16.8584L11.6853 13.2494C11.507 13.0802 11.4068 12.8507 11.4068 12.6114V7.19784C11.4068 6.69955 11.8324 6.29559 12.3574 6.29559Z"
              fill="#170738"
            />
          </svg>
          <p>{t("installmentText")}</p>
        </div>
      );
      break;
    default:
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <CardIcon />
          <p>{t("description.payzePayWithCard")}</p>
        </div>
      );
      break;
  }
  return (
    <div
      className="border border-[eef0f3] px-[14px] w-full h-[40px] flex items-center justify-between"
      style={style}
      onClick={onClick}
    >
      <div className="[&_svg]:h-[32px] [&_svg]:w-[32px]">{content}</div>
      <DropdownIcon />
    </div>
  );
}
