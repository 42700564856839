import React from "react";

export default function NewGpayIcon(props) {
  return (
    <svg
      width="84"
      height="33"
      viewBox="0 0 84 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.6671 16.1864V25.8048H36.6133V2.04609H44.7083C46.661 2.00454 48.548 2.74549 49.9503 4.10273C51.3699 5.38034 52.1697 7.20847 52.1385 9.11623C52.1801 11.0378 51.3802 12.8798 49.9503 14.1609C48.5342 15.5112 46.7857 16.1864 44.7083 16.1829L39.6671 16.1864ZM39.6671 4.97178V13.2676H44.7845C45.9201 13.3022 47.0142 12.8556 47.8036 12.0385C49.4067 10.4804 49.4448 7.91479 47.8833 6.31172C47.8556 6.28402 47.8313 6.25632 47.8036 6.23208C47.0246 5.39765 45.9236 4.94062 44.7845 4.97178H39.6671ZM59.174 9.01928C61.4315 9.01928 63.2111 9.62173 64.5164 10.8266C65.8218 12.0315 66.4727 13.6865 66.4727 15.7847V25.8048H63.5539V23.5473H63.4223C62.1586 25.4066 60.4759 26.3345 58.3777 26.3345C56.5876 26.3345 55.0884 25.8048 53.8835 24.7419C52.7133 23.7551 52.0485 22.294 52.0762 20.7601C52.0762 19.0774 52.7133 17.741 53.9839 16.7473C55.2546 15.7536 56.9512 15.255 59.0771 15.255C60.8879 15.255 62.3836 15.5874 63.5539 16.2487V15.5493C63.5608 14.5037 63.0969 13.5134 62.2936 12.8486C61.4869 12.1216 60.4343 11.7234 59.3471 11.7372C57.6437 11.7372 56.2933 12.4574 55.2996 13.8943L52.6128 12.2012C54.0878 10.0788 56.276 9.01928 59.174 9.01928ZM55.2235 20.8294C55.22 21.6188 55.5974 22.3598 56.2345 22.8202C56.9096 23.35 57.7475 23.6339 58.6062 23.6166C59.8942 23.6131 61.1303 23.1007 62.0409 22.1901C63.0519 21.2379 63.5574 20.1231 63.5574 18.8385C62.6052 18.0803 61.2791 17.6994 59.5757 17.6994C58.3361 17.6994 57.3009 17.9972 56.4734 18.5962C55.6389 19.2055 55.2235 19.943 55.2235 20.8294ZM83.2305 9.54902L73.0408 32.9719H69.89L73.6709 24.7765L66.9678 9.54902H70.2847L75.1286 21.2276H75.1944L79.9066 9.54902H83.2305Z"
        fill="#5F6368"
      />
      <path
        d="M27.172 13.9703C27.172 13.0302 27.0968 12.0936 26.9396 11.1675H14.2344V16.4725H21.5096C21.2089 18.185 20.2384 19.6982 18.8203 20.6593V24.1051H23.1636C25.706 21.7112 27.172 18.164 27.172 13.9703Z"
        fill="#4285F4"
      />
      <path
        d="M14.192 27.339C17.8693 27.339 20.966 26.1587 23.2228 24.1194L18.8301 20.7849C17.6066 21.5966 16.0341 22.0599 14.192 22.0599C10.6391 22.0599 7.6219 19.7163 6.54359 16.5576H2.01953V19.9936C4.33168 24.4982 9.04237 27.339 14.192 27.339Z"
        fill="#34A853"
      />
      <path
        d="M6.68755 16.2869C6.10452 14.5996 6.10452 12.7672 6.68755 11.0799V7.57397H2.0622C0.0834347 11.418 0.0834347 15.9488 2.0622 19.7928L6.68755 16.2869Z"
        fill="#FBBC04"
      />
      <path
        d="M14.1426 5.48354C16.077 5.45209 17.9461 6.19289 19.347 7.54868L23.2228 3.61758C20.7652 1.27638 17.5124 -0.00952379 14.1426 0.0289137C9.01387 0.0289137 4.32229 2.96763 2.01953 7.61857L6.52523 11.1688C7.59917 7.9051 10.6041 5.48354 14.1426 5.48354Z"
        fill="#EA4335"
      />
    </svg>
  );
}
export function NewGpayIconTwo(props) {
  return (
    <svg
      width="94"
      height="56"
      viewBox="0 0 94 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.612305" width="94" height="55" rx="12.58" fill="white" />
      <path
        d="M45.5206 30.6246V36.9763H43.5039V21.2867H48.8496C50.1392 21.2593 51.3853 21.7486 52.3113 22.6449C53.2487 23.4886 53.7769 24.6958 53.7563 25.9557C53.7838 27.2246 53.2556 28.441 52.3113 29.287C51.3761 30.1787 50.2215 30.6246 48.8496 30.6223L45.5206 30.6246ZM45.5206 23.2188V28.6971H48.8999C49.6499 28.72 50.3724 28.425 50.8937 27.8854C51.9523 26.8565 51.9775 25.1623 50.9463 24.1036C50.928 24.0854 50.912 24.0671 50.8937 24.0511C50.3793 23.5 49.6522 23.1982 48.8999 23.2188H45.5206ZM58.4024 25.8916C59.8932 25.8916 61.0684 26.2895 61.9304 27.0852C62.7924 27.8809 63.2222 28.9738 63.2222 30.3594V36.9763H61.2948V35.4856H61.2079C60.3733 36.7134 59.2621 37.3262 57.8765 37.3262C56.6944 37.3262 55.7044 36.9763 54.9087 36.2744C54.1359 35.6228 53.6969 34.6579 53.7152 33.645C53.7152 32.5338 54.1359 31.6512 54.975 30.995C55.8141 30.3388 56.9345 30.0095 58.3384 30.0095C59.5342 30.0095 60.5219 30.229 61.2948 30.6657V30.2039C61.2993 29.5134 60.9929 28.8595 60.4625 28.4205C59.9297 27.9403 59.2347 27.6774 58.5167 27.6865C57.3918 27.6865 56.5001 28.1621 55.8439 29.111L54.0696 27.9929C55.0436 26.5913 56.4886 25.8916 58.4024 25.8916ZM55.7936 33.6907C55.7913 34.212 56.0405 34.7013 56.4612 35.0054C56.9071 35.3552 57.4604 35.5427 58.0274 35.5313C58.878 35.529 59.6942 35.1906 60.2956 34.5893C60.9632 33.9605 61.297 33.2243 61.297 32.376C60.6683 31.8753 59.7926 31.6238 58.6676 31.6238C57.8491 31.6238 57.1654 31.8204 56.619 32.216C56.0679 32.6184 55.7936 33.1054 55.7936 33.6907ZM74.2886 26.2415L67.5596 41.7093H65.479L67.9757 36.2973L63.5492 26.2415H65.7396L68.9383 33.9537H68.9818L72.0936 26.2415H74.2886Z"
        fill="#5F6368"
      />
      <path
        d="M37.2624 29.1609C37.2624 28.5401 37.2127 27.9216 37.1089 27.3101H28.7188V30.8134H33.5231C33.3246 31.9442 32.6837 32.9435 31.7472 33.5782V35.8537H34.6154C36.2943 34.2728 37.2624 31.9304 37.2624 29.1609Z"
        fill="#4285F4"
      />
      <path
        d="M28.6985 37.9893C31.1269 37.9893 33.1719 37.2099 34.6622 35.8632L31.7614 33.6612C30.9535 34.1972 29.915 34.5032 28.6985 34.5032C26.3523 34.5032 24.3598 32.9555 23.6477 30.8696H20.6602V33.1386C22.187 36.1134 25.2978 37.9893 28.6985 37.9893Z"
        fill="#34A853"
      />
      <path
        d="M23.7415 30.6898C23.3565 29.5755 23.3565 28.3655 23.7415 27.2512V24.936H20.6871C19.3804 27.4745 19.3804 30.4665 20.6871 33.005L23.7415 30.6898Z"
        fill="#FBBC04"
      />
      <path
        d="M28.6659 23.5563C29.9434 23.5355 31.1776 24.0247 32.1028 24.92L34.6622 22.324C33.0393 20.778 30.8912 19.9288 28.6659 19.9542C25.279 19.9542 22.1808 21.8948 20.6602 24.9662L23.6356 27.3106C24.3448 25.1554 26.3292 23.5563 28.6659 23.5563Z"
        fill="#EA4335"
      />
    </svg>
  );
}
