import React from "react";

export default function GoogleChromeIcon(props) {
  return (
    <svg
      width="1123"
      height="1123"
      viewBox="0 0 1123 1123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6694_18486)">
        <path
          d="M561.5 842.125C716.554 842.125 842.25 716.429 842.25 561.375C842.25 406.321 716.554 280.625 561.5 280.625C406.446 280.625 280.75 406.321 280.75 561.375C280.75 716.429 406.446 842.125 561.5 842.125Z"
          fill="white"
        />
        <path
          d="M561.5 280.751H1047.69C998.426 195.389 927.553 124.502 842.2 75.2187C756.847 25.9355 660.023 -0.00687534 561.463 1.36678e-06C462.903 0.00687808 366.083 25.9628 280.736 75.2579C195.39 124.553 124.527 195.45 75.2715 280.819L318.368 701.876L318.586 701.82C293.856 659.176 280.806 610.766 280.751 561.47C280.695 512.174 293.637 463.735 318.27 421.035C342.904 378.335 378.359 342.884 421.061 318.255C463.764 293.626 512.204 280.69 561.5 280.751Z"
          fill="url(#paint0_linear_6694_18486)"
        />
        <path
          d="M561.501 783.759C684.252 783.759 783.761 684.25 783.761 561.499C783.761 438.748 684.252 339.238 561.501 339.238C438.75 339.238 339.24 438.748 339.24 561.499C339.24 684.25 438.75 783.759 561.501 783.759Z"
          fill="#1A73E8"
        />
        <path
          d="M804.613 701.944L561.517 1123C660.076 1123.02 756.902 1097.08 842.258 1047.8C927.614 998.526 998.493 927.645 1047.77 842.286C1097.04 756.928 1122.97 660.101 1122.95 561.542C1122.94 462.982 1096.97 366.165 1047.67 280.824H561.474L561.416 281.042C610.712 280.947 659.162 293.849 701.881 318.449C744.601 343.049 780.08 378.476 804.742 421.159C829.405 463.842 842.378 512.273 842.356 561.569C842.333 610.865 829.315 659.284 804.613 701.944Z"
          fill="url(#paint1_linear_6694_18486)"
        />
        <path
          d="M318.384 701.948L75.2875 280.891C25.9954 366.239 0.0427359 463.06 0.0390629 561.62C0.0353899 660.179 25.9808 757.003 75.2666 842.354C124.552 927.706 195.441 998.577 280.805 1047.84C366.169 1097.11 462.999 1123.03 561.558 1123L804.655 701.943L804.498 701.784C779.932 744.523 744.533 780.029 701.869 804.725C659.205 829.421 610.785 842.433 561.489 842.45C512.193 842.466 463.764 829.487 421.083 804.82C378.403 780.153 342.979 744.67 318.384 701.948Z"
          fill="url(#paint2_linear_6694_18486)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6694_18486"
          x1="75.2715"
          y1="350.939"
          x2="1047.69"
          y2="350.939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D93025" />
          <stop offset="1" stop-color="#EA4335" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6694_18486"
          x1="484.806"
          y1="1115.49"
          x2="971.019"
          y2="273.352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FCC934" />
          <stop offset="1" stop-color="#FBBC04" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6694_18486"
          x1="622.284"
          y1="1087.94"
          x2="136.072"
          y2="245.797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1E8E3E" />
          <stop offset="1" stop-color="#34A853" />
        </linearGradient>
        <clipPath id="clip0_6694_18486">
          <rect width="1123" height="1123" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
