import { SET_PRODUCTS } from "features/bog/reducer/actions";
import ProductItem from "./ProductItem";
import { useState } from "react";

const ProductList = ({ dispatch, products, productId }) => {
  const [slelectedProduct, setSelectedProduct] = useState();
  return (
    <div className="w-[100%] p-[20px]">
      <div className="flex items-center justify-between w-[50%]">
        <svg
          onClick={() => {
            dispatch({ type: SET_PRODUCTS, payload: { show: false } });
          }}
          width="12"
          height="21"
          viewBox="0 0 12 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 10.5021C0.000155599 10.726 0.0481078 10.9473 0.140652 11.1513C0.233196 11.3552 0.368199 11.537 0.53664 11.6846L9.4328 20.3821C9.5671 20.5245 9.7298 20.6372 9.91038 20.7128C10.091 20.7884 10.2854 20.8252 10.4811 20.821C10.6799 20.8221 10.8769 20.7837 11.0607 20.7082C11.2445 20.6326 11.4116 20.5214 11.5521 20.3808C11.6926 20.2403 11.8039 20.0733 11.8795 19.8894C11.955 19.7056 11.9933 19.5086 11.9922 19.3098C11.9911 19.1073 11.9496 18.9071 11.8701 18.7209C11.7907 18.5346 11.6748 18.3661 11.5294 18.2251L3.59528 10.5021L11.5294 2.77489C11.8211 2.48877 11.9875 2.09872 11.9922 1.69017C11.9933 1.49141 11.955 1.29442 11.8795 1.11058C11.8039 0.926745 11.6926 0.759722 11.5521 0.619181C11.4116 0.478641 11.2445 0.367375 11.0607 0.291825C10.8769 0.216275 10.6799 0.177941 10.4811 0.179045C10.2854 0.174768 10.091 0.211622 9.91038 0.287223C9.7298 0.362823 9.5671 0.475486 9.4328 0.617926L0.53664 9.304C0.369651 9.4561 0.235811 9.64099 0.143478 9.84713C0.0511457 10.0533 0.0023029 10.2762 0 10.5021Z"
            fill="black"
          />
        </svg>

        <h2 className="text-center font-bold translate-x-[50%] text-[19px]">
          Select products
        </h2>
      </div>
      <div className="mt-[20px]">
        {products.map((item) => {
          return (
            <ProductItem
              name={item.name}
              id={item.id}
              amount={item.amountList[0]}
              currency={item.currency}
              currencyRate={item.currencyRate}
              dispatch={dispatch}
              setSelectedProduct={setSelectedProduct}
              slelectedProduct={slelectedProduct}
              productId={productId}
            />
          );
        })}
        {/* <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem /> */}
      </div>
    </div>
  );
};

export default ProductList;
