import { useState } from "react";
import iphoneImageOne from "../../../assets/images/iphoneImgOne.png";
import iphoneImageTwo from "../../../assets/images/iphoneImgTwo.png";
import iphoneImageThree from "../../../assets/images/iphoneImgThree.png";
import googleStoreIcon from "../../../assets/images/googlePlayStoreIcon.png";
import appleStoreIcon from "../../../assets/images/appStoreIcon.png";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import Button from "../Button/Button";
import "./RegisterSection.css";
import { Trans } from "react-i18next";

const RegisterSection = () => {
  const [identity, setIdentity] = useState(1);
  const [activeTab, setActiveTab] = useState(0);
  const [showButtons, setShowButton] = useState(false);

  const arrForSteps = [
    <Trans i18nKey={"register.downloadDesc"} />,
    <Trans i18nKey={"register.getQRDesc"} />,
    <Trans i18nKey={"register.startDesc"} />,
  ];

  const src =
    (identity === 1 && iphoneImageOne) ||
    (identity === 2 && iphoneImageTwo) ||
    (identity === 3 && iphoneImageThree);

  const tabsArray = [
    {
      text: (
        <>
          <Trans i18nKey={"register.download"} />
        </>
      ),
      className: `${
        activeTab === 0 ? "rts___tab___selected" : "text-[#777987]"
      }`,
    },
    {
      text: (
        <>
          <Trans i18nKey={"register.getQR"} />
        </>
      ),
      className: `${
        activeTab === 1 ? "rts___tab___selected_two" : "text-[#777987]"
      }`,
    },
    {
      text: (
        <>
          <Trans i18nKey={"register.start"} />
        </>
      ),
      className: `${
        activeTab === 2 ? "rts___tab___selected_three" : "text-[#777987]"
      }`,
    },
  ];
  return (
    <section className="sm:mt-[72px] md:mt-[90px]" id="integration">
      <div className="sm:hidden md:flex sm:gap-5 items-center ">
        <div className="h-[559px] w-[550px]">
          <img src={src} className="h-[559px]" alt="img" />
        </div>
        <div>
          <h2 className="text-[30px]  text-[#21272A] font-[700]">
            <Trans i18nKey={"register.title"} />
          </h2>
          <div
            className="sm:mt-[10px] md:mt-[20px] cursor-pointer"
            onMouseEnter={() => {
              setIdentity(1);
              setShowButton(true);
            }}
            onMouseLeave={() => {
              setShowButton(false);
            }}
          >
            <h3
              className={`${
                identity === 1 ? "text-[#21272A]" : "text-[#B3B5BE]"
              } md:text-[35px] font-[700] duration-300 ease-in-out`}
              style={{ fontVariant: "all-petite-caps" }}
            >
              <Trans i18nKey={"register.download"} />
            </h3>
            <p
              className={`${
                identity === 1 ? "text-[#000]" : "text-[#B3B5BE]"
              } text-[16px] duration-300 ease-in-out `}
            >
              <Trans i18nKey={"register.downloadDesc"} />
            </p>

            {showButtons === true && (
              <div className="flex flex-row md gap-3 mt-[16px]">
                <div className="rounded-[10px]">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.tipapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={googleStoreIcon} alt="google store icon" />
                  </a>
                </div>
                <div className="rounded-[10px]">
                  <a
                    href="https://apps.apple.com/us/app/tip-app-mobile/id1579469134"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appleStoreIcon} alt="apple store icon" />
                  </a>
                </div>
              </div>
            )}
          </div>
          <div
            className="sm:mt-[10px] md:mt-[40px] cursor-pointer "
            onMouseEnter={() => {
              setIdentity(2);
            }}
          >
            <h3
              className={`${
                identity === 2 ? "text-[#6C63FF]" : "text-[#B3B5BE]"
              } md:text-[35px] font-[700] transition duration-300 ease-in-out`}
              style={{ fontVariant: "all-petite-caps" }}
            >
              <Trans i18nKey={"register.getQR"} />
            </h3>
            <p
              className={`${
                identity === 2 ? "text-[#000]" : "text-[#B3B5BE]"
              } text-[16px] duration-300 ease-in-out `}
            >
              <Trans i18nKey={"register.getQRDesc"} />
            </p>
          </div>
          <div
            className="sm:mt-[10px] md:mt-[40px] cursor-pointer"
            onMouseEnter={() => {
              setIdentity(3);
            }}
          >
            <h3
              className={`${
                identity === 3 ? "text-[#8CC63F]" : "text-[#B3B5BE]"
              } md:text-[35px] font-[700] duration-300 ease-in-out `}
              style={{ fontVariant: "all-petite-caps" }}
            >
              <Trans i18nKey={"register.start"} />
            </h3>
            <p
              className={`${
                identity === 3 ? "text-[#000]" : "text-[#B3B5BE]"
              } text-[16px] duration-300 ease-in-out `}
            >
              <Trans i18nKey={"register.startDesc"} />
            </p>
          </div>
        </div>
      </div>

      {/* ////////////////////////mobile//////////////////// */}

      <h2 className="md:hidden text-[20px] text-center  text-[#21272A] font-[700]">
        <Trans i18nKey={"register.title"} />
      </h2>
      <div className="md:hidden mt-[40px] flex justify-center  items-center  ">
        <Tabs activeTab={activeTab} mode="scrollSelectedToCenterFromView">
          {tabsArray.map(({ text, className }, index) => (
            <Tab
              style={{ borderRadius: "12px", marginLeft: "12px" }}
              className={className}
            >
              <Button
                onClick={() => {
                  setActiveTab(index);
                }}
                className="w-[150px] h-[100%]"
              >
                {text}
              </Button>
            </Tab>
          ))}
        </Tabs>
      </div>
      <div className=" md:hidden w-[100%] mt-[-20px] ">
        <div className="h-[100px]">
          <p className="text-center text-[#000000] text-[18px] mt-[24px] p-[20px]  sm2:w-[500px] sm2:m-auto">
            {arrForSteps[activeTab]}
          </p>
        </div>
        <img
          className="m-auto mt-[20px]"
          src={
            (activeTab === 0 && iphoneImageOne) ||
            (activeTab === 1 && iphoneImageTwo) ||
            (activeTab === 2 && iphoneImageThree)
          }
          alt="icon"
        />
      </div>
    </section>
  );
};

export default RegisterSection;
