export default function BinIcon(props) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2575_3468)">
        <path
          d="M22.0906 26.9938L26.5692 22.5313L27.5082 21.5842L29.7475 19.3529L37.3643 26.9938L41.7948 22.5313L29.7475 10.46L17.6602 22.5313L22.0906 26.9938Z"
          fill="#F0B90B"
        />
        <path
          d="M29.7542 25.7058L25.3047 30.1553L29.7542 34.6047L34.2036 30.1553L29.7542 25.7058Z"
          fill="#F0B90B"
        />
        <path
          d="M37.3643 33.3511L29.7475 40.9679L27.0668 38.2952L26.5772 37.7976L22.0906 33.3511L17.6602 37.7815L29.7475 49.8528L41.7948 37.7815L37.3643 33.3511Z"
          fill="#F0B90B"
        />
        <path
          d="M14.4924 25.7248L10.043 30.1743L14.4924 34.6238L18.9419 30.1743L14.4924 25.7248Z"
          fill="#F0B90B"
        />
        <path
          d="M44.9885 25.7195L40.5391 30.1689L44.9885 34.6184L49.438 30.1689L44.9885 25.7195Z"
          fill="#F0B90B"
        />
      </g>
      <defs>
        <clipPath id="clip0_2575_3468">
          <rect
            width="59.1045"
            height="59.1045"
            fill="white"
            transform="translate(0.1875 0.612305)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
