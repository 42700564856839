export default function PlusIcon({ onClick, color, className }) {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <rect
        x="0.5"
        y="0.359863"
        width="50"
        height="50"
        rx="13"
        fill={color}
        fill-opacity="0.1"
      />
      <path
        d="M25.5087 35.3599C24.5636 35.3599 23.9465 34.6463 23.9465 33.7012V26.9317H17.5434C16.5984 26.9317 15.8848 26.3145 15.8848 25.3695C15.8848 24.4245 16.5984 23.8073 17.5434 23.8073H23.9465V17.0185C23.9465 16.0735 24.5636 15.3599 25.5087 15.3599C26.4537 15.3599 27.0709 16.0735 27.0709 17.0185V23.8073H33.4547C34.3997 23.8073 35.1133 24.4245 35.1133 25.3695C35.1133 26.3145 34.3997 26.9317 33.4547 26.9317H27.0709V33.7012C27.0709 34.6463 26.4537 35.3599 25.5087 35.3599Z"
        fill={color}
      />
    </svg>
  );
}
