import { useState } from "react";

// import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import img from "../../../assets/images/Keto.png";
import { Trans } from "react-i18next";

const RequestAndSend = ({ openContactModel }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <h1
        className="text-center text-[#6C63FF] sm:mt-[77px] md:mt-[30px] font-bold md:text-[30px] sm:text-[25px]"
        style={{ fontVariant: "all-petite-caps", lineHeight: "25px" }}
      >
        <Trans i18nKey={"howQrTerminal"} />
      </h1>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="ZhuraAuDSNU"
        onClose={() => setOpen(false)}
      />

      <div className="  relative sm:mt-[20px] mb-[50px] md:mt-[30px]">
        <img
          src={img}
          className="m-auto rounded-[16px] w-[90%] sm:h-[274px] sm:object-cover   md:h-[560px] "
          alt=""
        />
        <svg
          width="230"
          height="230"
          viewBox="0 0 230 230"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg cursor-pointer sm:w-[100px] sm:h-[100px]  md:w-[200px] md:h-[200px]  "
          onClick={() => setOpen(true)}
        >
          <circle opacity="0.3" cx="115" cy="115" r="115" fill="#6C63FF" />
          <circle cx="114.999" cy="115.001" r="72.8308" fill="#6C63FF" />
          <path
            d="M144.779 108.361C149.7 111.202 149.7 118.305 144.779 121.146L105.288 143.946C100.367 146.787 94.216 143.236 94.216 137.554L94.216 91.9533C94.216 86.271 100.367 82.7196 105.288 85.5607L144.779 108.361Z"
            fill="white"
          />
        </svg>
      </div>

      {/* <section
        style={{
          background:
            "radial-gradient(994.78% 112.69% at 93.23% 14.81%, #280C62 0%, #170738 100%)",
        }}
        className=" flex justify-between sm:flex-wrap md:flex-nowrap sm:rounded-t-[32px] sm:pt-[40px] sm:mt-[72px]  sm:text-center  md:text-left   md:rounded-[32px]  md:ps-[60px]  md:mt-[90px] md:pt-[60px] "
      >
        <div className="m-auto p-[16px] mt-[-20px]">
          <h2 className="md2:text-left sm:text-[28px] md2:text-[40px] font-[600] text-[#fff]">
            <Trans i18nKey={"requestAndSend.title"} />
          </h2>
          <p className="sm:hidden md:block md:text-left mt-[28px] text-[16px] text-[#fff]">
            <Trans i18nKey={"requestAndSend.description"} />
          </p>
          <p className="sm:text-center md:hidden mt-[28px] text-[16px] text-[#fff]">
            <Trans i18nKey={"requestAndSend.descriptionSm"} />
          </p>
          <Button
            onClick={openContactModel}
            className="border-2  border-[#6C63FF] solid bg-[#6C63FF] text-[#fff]  hover:text-[#6C63FF] hover:bg-opacity-0 px-[40px] py-[10px] mt-[68px] "
          >
            <Trans i18nKey={"buttons.request"} />
          </Button>
        </div>

        <img
          src="/illustrationcircle.svg"
          className="h-full  rounded-[32px] mt-[-60px] sm:hidden md:block"
        />
        <img
          src="/svg/ilustartioCircleTwo.svg"
          className="w-full mt-[64px] md:hidden"
        />
      </section> */}
    </>
  );
};

export default RequestAndSend;
