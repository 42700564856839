import React from "react";

export default function CardIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="40"
      viewBox="0 0 32 24"
      {...props}
    >
      <path
        id="Symbol"
        d="M-7.312,7.328H16.006c2.874,0,4.333-1.451,4.333-4.237V-12.42c0-2.8-1.459-4.252-4.333-4.252H-7.312c-2.889,0-4.348,1.437-4.348,4.252V3.091C-11.661,5.892-10.2,7.328-7.312,7.328Zm-2.52-19.661a2.279,2.279,0,0,1,2.55-2.539H15.962a2.3,2.3,0,0,1,2.565,2.539v1.161H-9.833Zm2.55,17.862A2.273,2.273,0,0,1-9.833,2.99V-7.922H18.526V2.99a2.3,2.3,0,0,1-2.565,2.539ZM-5.5.958h3.611A1.364,1.364,0,0,0-.429-.435v-2.7A1.353,1.353,0,0,0-1.888-4.512H-5.5A1.349,1.349,0,0,0-6.944-3.134v2.7A1.36,1.36,0,0,0-5.5.958Z"
        transform="translate(11.661 16.672)"
        fill="#303c42"
      />
    </svg>
  );
}
