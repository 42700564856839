import { Routes, Route } from "react-router-dom";
import React, { lazy, useState } from "react";

import { useEffect } from "react";
import EmoneyTipPage from "features/emoneyTip";
import SuccessPage from "features/success";
import DowntimePage from "features/downtime";
import PrivacyPage from "features/privacy/PrivacyPage";
// import TermsPage from "features/terms/TermsPage-old";
import TermsPageNew from "features/terms/TermsPage";
import { useTranslation } from "react-i18next";
import { useQueryString } from "hooks";
import PaymentFailedPage from "features/fail/PaymentFailedPage";
import BogPayment from "features/bog/BogPayment";
import PaymentRedirect from "features/paymentRedirect/PaymentRedirect";
import ScrollTop from "components/ScrollTop";
import "./index.css";
import { ChatProvider } from "features/chat";
import NewLandingPage, { Container } from "features/newLanding/LandingPage";
import BogPaymentWeb from "features/bog/BogPaymentWeb";
import Pending from "features/Pending/Pending";
import CustomTip from "features/success/components/CustomTip";
import { isMobile } from "react-device-detect";
import SupplementaryContractualProvisionsPage from "features/SupplementaryContractualProvisions/SupplementaryContractualProvisionsPage";
import Layout from "features/newLanding/Layout";
import RequestAndSend from "features/newLanding/RequestAndSendSection/RequestAndSend";
import Modal from "features/newLanding/Modal/Modal";
import SuccessModal from "features/newLanding/Modal/SuccessModal";
import LibertyPage from "features/newLanding/components/LibertyPage";
import UsageCases from "features/newLanding/WhyKeepzSection/UsageCases";
import ForBusiness from "features/newLanding/MerchantsSection/ForBusiness";
import QrTerminal from "features/newLanding/QrTerminal/QrTerminalPage";
import RedirectPage from "components/redirect/RedirectPage";
import StandardPending from "features/Pending/StandardPending";
import ThreeDsPending from "features/Pending/ThreeDsPending";

const TestComponent = lazy(() => import("./features/bog/TestPayment"));
const TestCurrency = lazy(() => import("./features/bog/Test"));
const Test = lazy(() => import("./features/bog/Test2"));

const App = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const { parseQuery: parsedQuery } = useQueryString();
  const { lng = "", tiny } = parsedQuery;
  if (tiny) {
    window.location.href = `https://tinyurl.com/${tiny}`;
  }
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lng) {
      const newLng = lng === "ge" ? "ge" : "en";
      i18n.changeLanguage(newLng);
    }
  }, [lng, i18n]);

  function openContactModel() {
    setShowContactModal(true);
  }

  const reload = () => {
    window.location.reload();
  };

  //h-full max-w-[480px]  md:max-w-[1280px] lg:max-w-[1920px] mx-auto
  return (
    <div className="h-full">
      {showContactModal === true && (
        <Modal
          setShowContactModal={setShowContactModal}
          setSuccessModal={setSuccessModal}
        />
      )}
      {showContactModal === false && successModal === true ? (
        <SuccessModal setSuccessModal={setSuccessModal} />
      ) : null}
      <Routes>
        <Route
          path="/"
          element={
            <ChatProvider>
              <Layout isWhite={false} openContactModel={openContactModel}>
                <NewLandingPage openContactModel={openContactModel} />
              </Layout>
            </ChatProvider>
          }
        ></Route>
        <Route
          path="/how-to-use-QR-terminal"
          element={
            <ChatProvider>
              <Layout isWhite={true}>
                <Container>
                  <RequestAndSend openContactModel={openContactModel} />
                </Container>
              </Layout>
            </ChatProvider>
          }
        />
        <Route
          path="/use-cases"
          element={
            <ChatProvider>
              <Layout isWhite={true} openContactModel={openContactModel}>
                <Container>
                  <UsageCases />
                </Container>
              </Layout>
            </ChatProvider>
          }
        />
        <Route
          path="/for-business"
          element={
            <ChatProvider>
              <Layout isWhite={true} openContactModel={openContactModel}>
                <Container>
                  <ForBusiness openContactModel={openContactModel} />
                </Container>
              </Layout>
            </ChatProvider>
          }
        />
        <Route
          path="/qr-terminal"
          element={
            <ChatProvider>
              <Layout isWhite={true} openContactModel={openContactModel}>
                <QrTerminal openContactModal={openContactModel} />
              </Layout>
            </ChatProvider>
          }
        />

        <Route path="/tippage" element={<EmoneyTipPage />} />
        <Route path="/tipping" element={<PaymentRedirect />} />
        <Route
          path="/pay"
          element={isMobile ? <BogPayment /> : <BogPaymentWeb />}
        />
        <Route path="/payTest" element={<TestComponent />} />
        <Route path="/test" element={<TestCurrency />} />
        <Route path="/test2" element={<Test />} />
        <Route path="/terms-and-conditions" element={<TermsPageNew />} />

        <Route path="/privacy" element={<PrivacyPage />} />
        <Route
          path="/contractual-provisions"
          element={<SupplementaryContractualProvisionsPage />}
        />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/standard-pending" element={<StandardPending />} />
        <Route path="/3ds-pending" element={<ThreeDsPending />} />
        <Route path="/payment-failed" element={<PaymentFailedPage />} />
        <Route path="/downtime" element={<DowntimePage />} />
        <Route path="/apple-app-site-association" onEnter={reload} />

        <Route path="/custom-tip" element={<CustomTip />} />

        <Route path="/redirect" element={<RedirectPage />} />
        <Route path="/lb" element={<LibertyPage />} />
      </Routes>
      <ScrollTop />
    </div>
  );
};

export default App;
