import React from "react";
import ReactDOM from "react-dom";

import { useLockBodyScroll } from "@uidotdev/usehooks";

const SuccessModal = ({ setSuccessModal }) => {
  useLockBodyScroll();
  return ReactDOM.createPortal(
    <>
      <div
        className="flex justify-center  fixed  overflow-hidden top-0 w-[100vw] h-[100vh] bg-black-400 opacity-9 z-10 "
        onClick={(e) => {
          if (e.currentTarget !== e.target) {
            return;
          }
          setSuccessModal(false);
        }}
      >
        <div className=" flex flex-col justify-center items-center  gap-[24px]   bg-[#fff] rounded-[16px] p-[30px] top-[90px] sm:w-[90%]   md:w-[360px]   md:top-[200px] left-[50%] z-20 absolute translate-x-[-50%] ">
          <svg
            width="51"
            height="50"
            viewBox="0 0 51 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.1761 48.0035C32.124 49.3279 28.8312 50.0075 25.5042 49.9999C22.1772 50.0075 18.8844 49.3279 15.8323 48.0035C12.8575 46.7466 10.1503 44.9325 7.85651 42.6591C5.58326 40.3658 3.76924 37.6592 2.51209 34.6849C1.18775 31.6328 0.508088 28.3401 0.515688 25.0131C0.508122 21.6872 1.18374 18.3952 2.50066 15.3412C3.75364 12.3608 5.56806 9.64915 7.84508 7.35395C10.1386 5.07807 12.845 3.26033 15.8193 1.9981C18.867 0.672495 22.1562 -0.00779502 25.4797 6.73812e-05C28.8067 -0.0075331 32.0995 0.672135 35.1516 1.99647C38.1293 3.2575 40.8387 5.07594 43.1339 7.35395C45.4119 9.65075 47.2298 12.3618 48.4898 15.3412C49.8141 18.3933 50.4938 21.686 50.4862 25.0131C50.4938 28.3401 49.8141 31.6328 48.4898 34.6849C47.2346 37.6587 45.4228 40.3653 43.1519 42.6591C40.8581 44.9325 38.1509 46.7466 35.1761 48.0035ZM22.2753 37.682C22.7588 37.6879 23.235 37.5651 23.6553 37.3261C24.0756 37.0871 24.4247 36.7407 24.6668 36.3222L36.815 17.6233C37.1834 17.0933 37.3986 16.472 37.4369 15.8276C37.4421 15.5135 37.377 15.2022 37.2466 14.9164C37.1161 14.6306 36.9235 14.3775 36.6827 14.1757C36.1915 13.7432 35.5562 13.5103 34.9018 13.5227C34.4487 13.5405 34.0085 13.6784 33.6262 13.9222C33.2438 14.166 32.9331 14.507 32.7259 14.9102L22.2051 31.683L16.7301 24.8923C16.4877 24.567 16.1745 24.3012 15.8142 24.1148C15.4539 23.9285 15.056 23.8265 14.6505 23.8165C14.3281 23.8128 14.0083 23.8743 13.7104 23.9975C13.4124 24.1207 13.1425 24.3029 12.9169 24.5331C12.6813 24.7578 12.4948 25.0288 12.3692 25.3292C12.2436 25.6295 12.1817 25.9527 12.1872 26.2782C12.196 26.9585 12.4499 27.6128 12.9022 28.1211L19.7909 36.394C20.0791 36.7843 20.4529 37.1032 20.8835 37.3265C21.3142 37.5498 21.7903 37.6714 22.2753 37.682Z"
              fill="#8CC63F"
            />
          </svg>
          <h2 className="text-[20px] font-bold text-center">
            თქვენი მოთხოვნა გაგზავნილია
          </h2>
          <p className="text-[#B3B5BE] text-[16px]">
            ჩვენ მალე დაგიკავშირდებით
          </p>
        </div>
      </div>
    </>,
    document.getElementById("modal-root")
  );
};

export default SuccessModal;
