import { paymentServiceInstance } from "api";
import { USER_PROFILE_URL } from "helpers/fetcher/fetcher.config";
import useSWR from "swr";
import { decrypt, decryptAES, parseBase64 } from "utils/encryption";

const useUserData = (receiverType, receiverUserId) => {
  const fetcher = async (url) => {
    const {
      data: {
        value: { encryptedData, aes, encryptedKeys },
      },
    } = await paymentServiceInstance.get(url);

    const encrypteSecretKey = decrypt(encryptedKeys, { shouldParse: false });
    const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
    const secretKey = parseBase64(base64SecretKey);
    const iv = parseBase64(base64IV);
    const decrypted = decryptAES(encryptedData, secretKey, iv);

    return decrypted;
  };

  const { data, error } = useSWR(
    receiverType === "USER"
      ? `${USER_PROFILE_URL}/${receiverUserId}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    fetcher
  );

  // Extract userError if it exists
  const userError = error?.response?.data;

  return { data, userError };
};

export default useUserData;
