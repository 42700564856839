import { Container } from "./LandingPage";
import Footer from "./components/Footer";
import Header from "./components/Header";

const Layout = ({ children, isWhite, openContactModel }) => {
  return (
    <>
      <Container>
        <Header isWhite={isWhite} openContactModel={openContactModel} />
      </Container>
      {children}
      <Footer />
    </>
  );
};

export default Layout;
