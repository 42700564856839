import React from "react";

const Ge = () => {
  return (
    <div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <b>
              <span className="flex justify-center">
                დამატებითი სახელშეკრულებო პირობები
              </span>
              <br />
              <div className="flex justify-between">
                <span>ქ.თბილისი </span>
                <span>07.09.2023</span>
              </div>
              <br />
            </b>
          </p>
          <p>
            <b className="flex justify-center">
              თავი I<br />
            </b>
          </p>
          <p>
            <b>
              1. შესავალი
              <br />
            </b>
          </p>
          <p>
            <b>1.1. დამატებითი სახელშეკრულებო პირობები</b>
            (შემდგომში „დამატებითი სახელშეკრულებო პირობები“ ან „წინამდებარე
            დოკუმენტი“) არეგულირებს ურთიერთობებს შპს „ბიქეიენ301 ჯორჯია“-სა
            (შემდგომში „სერვისის მიმწოდებელი“), შპს „ქიფზ.მი“-სა (შემდგომში
            „პლატფორმა“) და სავაჭრო/მომსახურების ობიექტებს (შემდგომში „სავაჭრო
            ობიექტ(ებ)ი“)ს შორის, წარმოადგენს მხარეთა შორის დადებული
            ხელშეკრულებების (შემდგომში ,,ხელშეკრულება“) შემადგენელ ნაწილს და
            განსაზღვრავს მხარეთა დამატებით სახელშეკრულებო პირობებს.
          </p>
          <p>
            <b>
              1.2. წინამდებარე დოკუმენტიში მოცემული მარეგულირებელი დებულებები
              საერთოა ყველა იმ ხელშეკრულებისათვის, რომელზეც ვრცელდება
              წინამდებარე დოკუმენტი.
            </b>
          </p>
          <p>
            <b>1.3.</b> კლიენტის შესახებ სერვისის მიმწოდებელთან
            არსებული/დაფიქსირებული პერსონალური ან ნებისმიერი სხვა ინფორმაციის
            (მათ შორის: საფირმო სახელწოდების ან/და სხვა პირადი/სამეწარმეო
            მონაცემების, საკონტაქტო ინფორმაციის და მონაცემების და ა.შ.)
            ცვლილებისას კლიენტი ვალდებულია წერილობით აცნობოს სერვისი მიმწოდებელს
            აღნიშნული ცვლილებ(ებ)ის შესახებ. კლიენტის მიერ ცვლილების შესახებ
            წერილობით შეტყობინებამდე სერვისის მიმწოდებელი უფლებამოსილია
            იმოქმედოს მასთან დაცული/დაფიქსირებული ინფორმაციის შესაბამისად.
          </p>
          <p>
            <b>1.4.</b> მხარეებს არ შეუძლია უარი განაცხადონ წინამდებარე
            დოკუმენტით გათვალისწინებული პირობების და ვალდებულებების შესრულებაზე
            მხოლოდ იმ მოტივით, რომ მას ხელმოწერა (მატერიალური და/ან ელექტრონული
            ფორმით) არ განუხორციელებია უშუალოდ წინამდებარე დოკუმენტზე
            (წინამდებარე დოკუმენტის ელექტრონულ და/ან მატერიალურ ვერსიაზე).
          </p>
          <p>
            <b>1.5.</b> მხარეებს შორის გაფორმებული ხელშეკრულების ან წინამდებარე
            დოკუმენტის რომელიმე პუნქტის გაუქმება/ბათილად ცნობა არ იწვევს
            მთლიანად ხელშეკრულების/წინამდებარე დოკუმენტის და/ან მათი სხვა
            პუნქტ(ებ)ის გაუქმებას/ბათილობას.
          </p>
          <p>
            <b>1.6.</b> წინამდებარე დოკუმენტი მოცემულია როგორც სერვისი
            მიმწოდებლისა და პლატფორმის ოფისებში, ასევე შემდეგ ვებ.გვერდებზე:{" "}
            <a style={{ color: "blue" }} href="https://301pay.ge/">
              https://301pay.ge/
            </a>
            ;{" "}
            <a style={{ color: "blue" }} href="https://keepz.me/">
              https://keepz.me/
            </a>{" "}
            და კლიენტს თავისუფლად, ყოველგვარი შეზღუდვის გარეშე, შეუძლია გაეცნოს
            მას.
          </p>
          <p>
            <b>1.7.</b> სერვისის მიმწოდებელი: შპს „ბიქეიენ301 ჯორჯია“,
            საიდენტიფიკაციო კოდი 405545227, იურიდიული მისამართი: საქართველო,
            ქალაქი თბილისი, ვაკის რაიონი, ილია ჭავჭავაძის გამზირი, N 83, VI
            ბლოკი, ბინა 56. საკოტანქტო ტელ. ნომერი:
          </p>
          <p>
            <b>1.8.</b> პლატფორმა: შპს „ქიფზ.მი“, საიდენტიფიკაციო კოდი
            405618915, იურიდიული მისამართი: თბილისი, საბურთალოს რაიონი, მიხეილ
            თამარაშვილის გამზირი, N13თ. საკოტანქტო ტელ. ნომერი:
          </p>
          <p>
            <b> 2. გამოყენებული ტერმინები </b>
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <b>2.1. ავტორიზაცია - ბანკის </b>
            ან/და
            <b> საგადახდო სისტემის </b>
            მიერ გაცემული ნებართვა
            <b> საბარათე ოპერაციის </b>
            შესრულებაზე;
            <br />
          </p>
          <p>
            <b>2.2. ავტორიზაციის გვერდი </b>- ექვაირერის ან/და მისი აფილირებული
            პირის მფლობელობაში არსებული ვებ–გვერდ(ებ)ი, რომელზეც მომხმარებელი
            აფიქსირებს ბარათის რეკვიზიტებს ან ავტორიზაციის გვერდზე წვდომის
            მისაღებად განკუთვნილ, მომხმარებლის სახელსა და პაროლს;
          </p>
          <p>
            <b>2.3. ანგარიში – ბანკში </b>
            სავაჭრო ობიექტის ან/და პლატფორმის არსებული ნებისმიერი საბანკო
            ანგარიში (მათ შორის,
            <b> ბანკში </b>
            გახსნილი <b> ანგარიშსწორების ანგარიში </b> და{" "}
            <b> მინიმალური დეპოზიტის ანგარიში);</b>
          </p>
          <p>
            <b>2.4. ბანკი </b>- კომერციული ბანკი;
            <br />
            <b>2.5. სერვისის მიმწოდებელი </b>- წინამდებარე
            <b> ხელშეკრულებით </b>
            განსაზღვრული სამართალ-ურთიერთობის ფარგლებში წარმოადგენს{" "}
            <b>ექვაირინგის </b> განმახორციელებელ <b> პროვაიდერს - ექვაირერს;</b>
          </p>
          <p>
            <b>2.6. პლატფორმა </b>- ელექტრონული კომერციის ერთ-ერთი ფორმა,
            როდესაც სხვადასხვა სავაჭრო ობიექტი ახორციელებს თავისი პროდუქციის
            გაყიდვას ერთი ვებ.გვერდიდან.
          </p>
          <p>
            <b>2.7. სავაჭრო ობიექტი </b>- სავაჭრო/მომსახურების ობიექტი იურიდიული
            პირი ან მეწარმე ფიზიკური პირი, რომელიც პლატფორმის მეშვეობის
            ახორციელებს პროდუქციის გაყიდვას და ხელშეკრულების საფუძველზე და
            წინამდებარე დოკუმენტის პირობებით სარგებლობს სისტემის მიმწოდებლის
            მომსახურებით.
          </p>
          <p>
            <b>2.8. ბარათის მფლობელი </b>- საბარათე ინსტრუმენტით მოსარგებლე
            პირი, რომელიც სავაჭრო ობიექტის სასარგებლოდ პროდუქციის მიღების
            სანაცვლოდ ახორციელებს გადახდას.
          </p>
          <p>
            <b>2.9. მესამე პირი </b>- ნებისმიერი პირი გარდა ხელშკრულების
            მხარეებისა;
          </p>
          <p>
            <b>2.10. ხელშეკრულება </b>
            - მხარეთა მიერ ხელმოწერელი ძირითადი ხელშეკრულება მომსახურების
            შესახებ, რომელზეც ვრცელდება წინამდებარე დოკუმენტის პირობები;
            <br />
          </p>
          <p>
            <b>2.11. დამატებითი პირობები </b>
            - წინამდებარე დოკუმენტი ან/და ხელშეკრულების დანართით (ასეთის
            არსებობის შემთხვევაში) განსაზღვრული პირობები, მასში შეტანილი
            დამატებებისა და ცვლილებების ჩათვლით, რომლებიც წარმოადგენს
            ხელშეკრულების შემადგენელ ნაწილს;
            <br />
          </p>
          <p>
            <b>2.12. დანართი </b>- ასეთის არსებობის შემთხვევაში და თუ ცალსახად
            სხვაგვარად არ იქნა გათვალისწინებული, ხელშეკრულების დანართ(ებ)ი
            (კონტექსტის შესაბამისად), რომელიც/რომლებიც წარმოადგენს ხელშეკრულების
            განუყოფელ ნაწილს და მოქმედებს მასთან ერთად;
          </p>
          <p>
            <b>2.13. მომსახურების საკომისიო </b>
            - საკომისიო, რომელსაც სერვისის მიმწოდებელი ექვაირინგის მომსახურების
            გაწევის სანაცვლოდ მიიღებს, თითოეული საბარათე ოპერაციისთვის;
            <br />
          </p>
          <p>
            <b>2.14. ბარათი </b>- საგადახდო სისტემაში შემავალი ფინანსური
            ინსტიტუტების მიერ გამოშვებული ბარათი/ბარათები ან/და ნებისმიერი სხვა
            სახის საბარათე ინსტრუმენტი;
          </p>
          <p>
            <b>2.15. გადასახდელი </b>- ფულადი სახსრები, რომელსაც ბარათის
            მფლობელი უხდის სავაჭრო ობიექტს პროდუქციის ღირებულების
            ასანაზღაურებლად;
          </p>
          <p>
            <b>2.16. ექვაირინგის მომსახურება </b>- წინამდებარე დოკუმენტით
            გათვალისწინებული წესითა და პირობებით, სერვისის მიმწოდებლის
            ელექტრონულ-ტექნიკური საშუალებით, ბარათის მფლობელის საგადახდო
            ინსტრუმენტის (ბარათის) მეშვეობით საბარათე ოპერაციის განხორციელება და
            შესაბამის ანგარიშსწორება;
          </p>
          <p>
            <b>2.17. საბანკო გადარიცხვის ოპერაცია </b>- ბარათის მფლობელის
            ანგარიშიდან თანხის ჩამოჭრის და პლატფორმის ან სავაჭრო ობიექტის
            ანგარიშზე თანხის ჩარიცხვის ოპერაცია;
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAafter: "always" }}>
        <div>
          <p>
            <b>2.18. მონაცემთა გადაცემის ტექნიკური საშუალება </b>- სავაჭრო
            ობიექტს, პლატფორმასა და სერვისის მიმწოდებელს შორის შეთანხმებული
            ელექტრონული სისტემა, რომელიც მოიცავს დაცული კავშირის საშუალებას და
            რომელიც უზრუნველყოფს საგადამხდელო არხებში გადამხდელისაგან მიღებული
            გადასახდელების ავტომატურ აღრიცხვას და ხელშეკრულებით განსაზღვრული
            ელექტრონული ინფორმაციის გაცვლას მხარეთა შორის;
          </p>
          <p>
            <b>2.19. საანგარიშსწორებო სისტემა </b>- ელექტრონული და პროგრამული
            უზრუნველყოფის ნაკრები, რომელშიც აღირიცხება სერვისის მიმწოდებლის
            ან/და პლატფორმის და სავაჭრო ობიექტის ანგარიში და მის მიერ
            განხორციელებული გადახდები.
          </p>
          <p>
            <b>2.20. საანგარიშსწორებო დღე </b>- პლატფორმის ამ სავაჭრო ობიექტის
            ანგარიშზე თანხის ჩარიცხვის დღე, რომელიც განისაზღვრება სერვისის
            მიმწოდებლის მიერ თანხის მიღებიდან მეორე სამუშაო დღედ.
          </p>
          <p>
            <b>2.21. სამუშაო დღე </b>- ნებისმიერი კალენდარული დღე, გარდა
            შაბათ-კვირისა და საქართველოს შრომის კოდექსით დადგენილი უქმე
            დღეებისა.
          </p>
          <p>
            <b>2.22. საბანკო დღე </b>- დღე (შაბათის, კვირის ან/და კანონმდებლობით
            კომერციული ბანკებისათვის განსაზღვრული ოფიციალური დასვენების დღეების
            გარდა), როდესაც საქართველოში კომერციული ბანკები ღიაა და წარმართავენ
            თავიანთ საქმიანობას;
          </p>
          <p>
            <b>2.23. კანონმდებლობა </b>- საქართველოს მოქმედი საკანონმდებლო და
            კანონქვემდებარე ნორმატიული აქტები და საქართველოს ნორმატიულ აქტთა
            სისტემაში მოქცეული საერთაშორისო ხელშეკრულებები და შეთანხმებები;
          </p>
          <p>
            <b>2.24. მონაცემთა გადაცემის ტექნიკური საშუალება </b>- მხარეებს
            შორის შეთანხმებული ელექტრონული სისტემა, რომელიც შეიძლება მოიცავდეს
            VPN კავშირს ან ნებისმიერ სხვა შიფრის საშუალებას და რომლის
            მეშვეობითაც სერვისის მიმწოდებელი და პლატფორმა ან/და კლიენტი ცვლიან
            ინფორმაციას ავტორიზაციის გვერდის მეშვეობით განხორციელებული გადახდის
            და სხვა ოპერაციების შესახებ;
          </p>
          <p>
            <b>2.25. პროდუქცია </b>- სავაჭრო ობიექტის მიერ პლატფორმის მეშვეობით
            გაყიდული/გასაყიდი საქონელი ან/და მომსახურება;
          </p>
          <p>
            <b>2.26. პროვაიდერი </b>- საქართველოში ლიცენზირებული კომერციული
            ბანკი, რეგისტრირებულ მიკროსაფინანსო ორგანიზაცია ან/და რეგისტრირებულ
            საგადახდო მომსახურების პროვაიდერი;
          </p>
          <p>
            <b>2.27. რევერსალი (Reversal) ოპერაცია </b>- საბარათე ოპერაციის
            გაუქმების შედეგად საბარათე ოპერაციის თანხის ბარათის მფლობელისთვის
            სრულად ან ნაწილობრივ დაუყოვნებლივ დაბრუნების ოპერაცია, რომლის
            შესრულება შესაძლებელია მხოლოდ ვირტუალური პოს-ტერმინალის საოპერაციო
            დღის დახურვამდე.
          </p>
          <p>
            <b>2.28. რეფანდის (Refund) ოპერაცია </b>- პროდუქციის უკან დაბრუნების
            გამო სავაჭრო ობიექტის ან/და პლატფორმის ან სერვისის მიმწოდებლის მიერ
            ბარათის მფლობელისათვის გადასახდელის სრულად ან ნაწილობრივ დაბრუნების
            ოპერაცია;
          </p>
          <p>
            <b>2.29. ფორს-მაჟორი </b>- სტიქიური კატასტროფა, ხანძარი, საომარი
            მოქმედება, ადმინისტრაციულ-სამართლებრივი აქტი ან/და მხარეთათვის
            გადაულახავი და მათი კონტროლისაგან დამოუკიდებელი სხვა გარემოებები,
            რომლებიც: ა) არ არის დაკავშირებული მხარეების შეცდომებსა და
            დაუდევრობასთან, ბ) დაიწყო ან განვითარდა ხელშეკრულების ხელმოწერის
            შემდეგ და გ) პირდაპირ და უშუალო ზეგავლენას ახდენს ხელშეკრულებით
            ნაკისრი ვალდებულებების სრულად ან/და ჯეროვნად შესრულებაზე;
          </p>
          <p>
            <b>2.30. ჩარიცხვის ოპერაცია </b>- სავაჭრო ობიექტის ან/და პლატფორმის
            ანგარიშიდან თანხის ჩამოჭრის და ბარათის მფლობელის საბარათე ანგარიშზე
            თანხის უკან დაბრუნების ოპერაცია (გარდა რეფანდის (Refund)
            ოპერაციისა);
          </p>
          <p>
            <b>2.31. ჩარჯბექი (Chargeback) </b>- ბარათის მფლობელის მიერ,
            საგადახდო სისტემების წესების შესაბამისად, საბარათე ოპერაციის
            გასაჩივრების შედეგად ბარათის მფლობელისათვის გადასახდელის უკან
            დაბრუნების ოპერაცია;
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <b>
              <span className="flex justify-center mt-8">თავი II</span>
              <span className="flex justify-center">
                ძირითადი წესები და პირობები
              </span>
              <br />
            </b>
          </p>
          <p>
            <b>
              1. ხელშეკრულების საგანი
              <br />
            </b>
            1.1. წინამდებარე დოკუმენტი წარმოადგენს, მხარეთა მიერ ნების
            თავისუფალი გამოვლენის საფუძველზე მომსახურების გაწევის შესახებ
            ხელშეკრულების ძირითად შემადგენელ ნაწილს. წინამდებარე დოკუმენტის
            პირობები წარმოადგენს სავალდებულო ძალის მქონე შინაარსს, მომსახურების
            განხორციელების პროცესში.
            <br />
          </p>
          <p>
            1.2. წინამდებარე დოკუმენტით მხარეები განსაზღვრავენ თანამშრომლობის
            ძირითად სტანდარტებსა და პირობებს ხელშეკრულების მოქმედების მთელი
            პერიოდის განმავლობაში.
          </p>
          <p>
            1.3. მხარეთა თანამშრომლობის საგანი განისაზღვრება მათ შორის
            გაფორმებული მომსახურების ხელშეკრულების საფუძველზე.
          </p>
          <p>
            1.4. მომსახურების გაწევა მოხდება წინამდებარე დოკუმენტის პირობებით და
            მხარეთა შორის გაფორმებული ძირითადი ხელშეკრულებით გათვალისწინებული
            ვადებისა და შეთანხმებული მომსახურების საფასურის სანაცვლოდ.
          </p>
          <p>
            <b>2. მხარეთა უფლება მოვალეობები</b>
            2.1. სერვისის მიმწოდებლის საქმიანობას წარმოადგენს საგადახდო
            მომსახურების უზრუნველყოფა. კერძოდ, გამართულ მდგომაროებაში ჰქონდეს
            სისტემა და დეტალურად ფლობდეს ინფორმაციას პლატფორმის
            ვებ.საიტის/აპლიკაციის მეშვეობით განსახორიცელებელი გადახდების და
            გადარიცხვების შესახებ, რათა შეძლოს საგადახდო მომსახურების
            განხორციელება.
          </p>
          <p>
            2.2. თითოეული მხარე ვალდებულია მოქმედებდეს კეთილისინდისირებისა და
            სამართლიანობის პრინციპის შესაბამისად.
          </p>
          <p>
            2.3. თითოეული მხარე ვალდებულია ხელშეკრულების ჯეროვნად
            შესრულებისათვის ითანამშრომლოს მეორე მხარესთან.
          </p>
          <p>
            2.4. მხარეები ვალდებულნი არიან წინასწარ, გონივრული ვადის დაცვით
            შეატყობინოს ერთმანეთს ნებისმიერი არსებული ან/და მოსალოდნელი
            ცვლილების შესახებ, რომელიც მნიშვნელოვანია ხელშეკრულების მიზნების,
            მხარეთა უფლებების და ნაკისრი ვალდებულებების შესრულებისთვის, რაც
            ყოველგვარი შეზღუდვის გარეშე მოიცავს ცვლილებებს მათ სისტემაში და
            მონაცემთა გადაცემის ტექნიკურ საშუალებებში;
          </p>
          <p>
            2.5. თითოეული მხარე ვალდებულია არ გადასცეს მესამე პირებს
            განხორციელებული ოპერაციების შესახებ ინფორმაცია კანონმდებლობით და
            წინამდებარე ხელშეკრულებით გათვალისწინებული შემთხვევების გარდა;
          </p>
          <p>
            2.6. თითოეული მხარე ვალდებულია უზრუნველყოს მონაცემთა გადაცემის
            ტექნიკური საშუალების შეუფერხებელი მუშაობა, ხოლო მათში ხარვეზების
            აღმოჩენის შემთხვევაში დაუყოვნებლივ შეატყობინოს მეორე მხარეს ამის
            შესახებ და უმოკლეს ვადაში (არაუმეტეს 2 სამუშაო დღე) აღადგინოს მათი
            გამართული მუშაობა. ასევე, მონაცემთა გადაცემის ტექნიკური საშუალების
            შეუფერხებლად მუშაობის უზრუნველყოფის მიზნით სერვისის მიმწოდებელს მისი
            მოთხოვნის საფუძველზე მისცეს სატესტო გადახდების განხორციელების
            შესაძლებლობა;
          </p>
          <p>
            <i>
              2.7. <b>პლატფორმა და სავაჭრო ობიექტი უფლებამოსილია:</b>
            </i>
          </p>
          <p>
            2.7.1. მოითხოვოს წინამდებარე ხელშეკრულებით ნაკისრი ვალდებულების
            ჯეროვნად და დროულად შესრულება.
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            2.7.2. მოსთხოვოს სერვისის მიმწოდებელს გაწეული მომსახურების შესახებ
            სრულყოფილი ინფორმაციის დროული მიწოდება.
          </p>
          <p>
            2.7.3. ნებისმიერ დროს შეამოწმოს სერვისის მიმწოდებლის მიერ
            შესრულებული მომსახურების ხარისხი, მოცულობა და მსვლელობა.
          </p>
          <p>
            2.7.4. გამოიყენოს სერვისის მიმწოდებლის საფირმო სახელწოდება და
            სასაქონლო ნიშნები სარეკლამო-საინფორმაციო საშუალებებზე განთავსების
            ან/და საგადამხდელო არხებში განთავსების მიზნით, სერვისის მიმწოდებლის
            წინასწარი თანხმობის საფუძველზე.
          </p>
          <p>
            <i>
              2.8.
              <b>პლატფორმა და სავაჭრო მომსახურების ობიექტი ვალდებულია:</b>
            </i>
          </p>
          <p>
            2.8.1. პლატფორმა და სავაჭრო ობიექტი საბარათე ოპერაციის თანხის
            მისაღებად, მომსახურების ხელშეკრულებაში დაფიქსირებული შესაბამისი
            ანგარიშსწორების ანგარიში იქონიოს მუდმივად აქტიური;
          </p>
          <p>
            2.8.2. პლატფორმა და სავაჭრო ობიექტი საბარათე ოპერაციის
            განხორციელების პროცესში დაიცვას ყველა ის უსაფრთხოების ზომა, რომელსაც
            სერვისის მიმწოდებელი განუსაზღვრავს პლატფორმას ან/და სავაჭრო ობიექტს.
          </p>
          <p>
            2.8.3. პლატფორმა და სავაჭრო ობიექტი სერვისის მიმწოდებლის წინასწარი
            წერილობითი თანხმობის გარეშე არ დააწესოს მინიმალური ან მაქსიმალური
            თანხობრივი ლიმიტი საბარათე ოპერაციებისთვის, ჩარიცხვის ოპერაციისთვის
            ან/და რეფანდის (Refund) ოპერაციისთვის;
          </p>
          <p>
            2.8.4. პლატფორმა და სავაჭრო ობიექტი ვალდებულია სერვისი მიმწოდებლის
            მოთხოვნიდან პირველივე შესაძლებლობისთანავე, თუმცა არაუმეტეს 20 (ოცი)
            კალენდარული დღის ვადაში, თუ კანონმდებლობით, ბანკის მარეგულირებელი
            ორგანოს მითითებით ან/და საგადახდოს სისტემის მოთხოვნით სხვა ვადა არ
            არის დათანხმდეს მომსახურების ხელშეკრულებაში ყველა იმ ცვლილების
            შეტანას, რომელსაც სერვისის მიმწოდებელი განუსაზღვრავს თავის
            შეტყობინებაში და რომელიც უნდა განხორციელდეს კანონმდებლობას, ბანკის
            მარეგულირებელი ორგანოს მითითებასა ან/და საგადახდო სისტემების
            წესებთან შესაბამისობაში ყოფნის მიზნებისათვის. პლატფორმის ან/და
            სავაჭრო ობიექტის ბრალეულობით, აღნიშნული ცვლილებების მომსახურების
            ხელშეკრულებაში შეტანის შეუძლებლობის შემთხვევაში, სერვისი მიმწოდებელი
            უფლებამოსილია დაუყოვნებლივ შეწყვიტოს მომსახურების ხელშეკრულება;
          </p>
          <p>
            2.8.5. პლატფორმა და სავაჭრო ობიექტი ბარათის მფლობელის მიერ
            შესაბამისი ოპერაციის განხორციელების თარიღიდან 1 (ერთი) წლის
            განმავლობაში შეინახოს მომსახურების ხელშეკრულებით გათვალისწინებული
            ნებისმიერ ოპერაციასთან (მათ შორის, შეუზღუდავად საბარათე
            ოპერაციასთან, რევერსალის (Reversal) ოპერაციასთან, რეფანდის (Refund)
            ოპერაციასთან და ჩარიცხვის ოპერაციასთან) დაკავშირებული
            ყველა/ნებისმიერი დოკუმენტაცია/ინფორმაცია/მონაცემი, მათ შორის,
            შეუზღუდავად: (ა) საბარათე ქვითრები; (ბ) პროდუქციის რეალიზაციასთან
            დაკავშირებული: (i) ინვოისი/ანგარიშფაქტურა; (ii)
            ნასყიდობის/მომსახურების ხელშეკრულების დედანი ან/და ბარათის მფლობელის
            მიერ სავაჭრო/მომსახურების ობიექტის სტანდარტულ პირობებზე დათანხმების
            დამადასტურებელი მტკიცებულება; (iii) მიღება-ჩაბარების აქტი და ბარათის
            მფლობელისთვის მიწოდებული პროდუქციის დეტალური აღწერილობა/ჩამონათვალი;
            (iv) სავაჭრო/მომსახურების ობიექტსა და ბარათის მფლობელს შორის
            წარმოებული მატერიალური და ელექტრონული კორესპონდენცია; (გ) ნებისმიერი
            სხვა სახის/შინაარსის დოკუმენტაცია/ინფორმაცია/მონაცემი, რომელიც
            შეიძლება მოთხოვნილ იქნეს სერვისის მიმწოდებლის მიერ ჩარჯბექის
            (Chargeback) ფარგლებში ან ნებისმიერი სხვა მიზნობრიობით - და გადასცეს
            აღნიშნული დოკუმენტაცია/ინფორმაცია/მონაცემები ბანკს, სერვისი
            მიმწოდებლის წერილობითი მოთხოვნიდან, 2 (ორი) საბანკო დღის ვადაში.
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            2.8.6. ლატფორმა და სავაჭრო ობიექტი ბარათის მფლობელთან/ ექვაირინგთან
            (მათ შორის საბარათე ოპერაციასთან), მომსახურების ხელშეკრულებასთან
            დაკავშირებული ნებისმიერი სახის ინფორმაცია/დოკუმენტაცია მიაწოდოს,
            სერვისის მიმწოდებელს, საგადახდო სისტემას ან/და სერვისი მიმწოდებლის
            მიერ განსაზღვრულ ნებისმიერი სხვა პირს, სერვისის მიმწოდებლის მხრიდან
            ზეპირი ან წერილობითი მოთხოვნის საფუძველზე;
          </p>
          <p>
            2.8.7. <b>პლატფორმა ვალდებულია:</b>
          </p>
          <p>
            2.8.7.1. ხელშეკრულებაზე ხელმოწერის შემდეგ გადასცეს სერვისის
            მიმწოდებელს პლატფორმის არსებულ სისტემაზე წვდომის უფლება, ასეთის
            არსებობის შემთხვევაში.
          </p>
          <p>
            2.8.7.2. დროულად მიაწოდოს სერვისის მიმწოდებელს ინფორმაცია, რომელსაც
            არსებითი მნიშვნელობა აქვს მომსახურების გაწევისათვის.
          </p>
          <p>
            2.8.7.3. სერვისის მიმწოდებლისგან მიღებული ინფორმაცია გამოიყენოს
            მხოლოდ წინამდებარე დოკუმენტის, ძირითადი ხელშეკრულების ან/და ბარათის
            მფლობელთან შეთანხმებული მიზნებისათვის;
          </p>
          <p>
            2.8.7.4. ვალდებულია საკუთარ ინტერნეტ პორტალზე სავაჭრო ობიექტის
            განთავსებამდე, მიიღოს შემდეგი ინფორმაცია: საიდენტიფიკაციო და
            საკონტაქტო ინფორმაცია (კომპანიის სახელწოდება (საფირმო)/სახელი,
            გვარი, საიდენტიფიკაციო/პირადი ნომერი, იურიდიული და საკორესპონდენციო
            (ფაქტობრივი) მისამართი, ტელეფონის ნომერი, ელ-ფოსტა და სხვა), სავაჭრო
            ობიექტის საქმიანობის შესახებ ინფორმაცია;
          </p>
          <p>
            2.8.7.5. ვალდებულია სავაჭრო ობიექტის შესახებ მის მიერ მოპოვებული
            ინფრომაცია, სრულად და დაუფარავად გადასცეს სერვისის მიმწოდებელს.
          </p>
          <p>
            2.8.8. <b>სავაჭრო ობიექტი ვალდებულია:</b>
          </p>
          <p>
            2.8.8.1. არ დაუშვას პროდუქციის დეფიციტი ან/და პროდუქციის რეალიზაცია
            არ მოახდინოს სტანდარტულზე მეტ ფასად, მათ შორის არ გააძვიროს
            პროდუქცია სერვისის მიმწოდებლისთვის გადასახდელი
            ექვაირერის/სუბექვაირერის მომსახურების საკომისიოს ხარჯის
            კომპენსირების მიზნით;
          </p>
          <p>
            2.8.8.2. ვალდებულია ინტერნეტ პორტალის საშუალებით გაყიდოს მხოლოდ
            სავაჭრო/მომსახურების ობიექტისთვის მინიჭებული ბიზნეს საქმიანობის
            კატეგორიის შესაბამისი პროდუქცია. სავაჭრო/მომსახურების ობიექტის მიერ
            სავაჭრო/მომსახურების ობიექტის ინტერნეტ პორტალის მეშვეობით
            გაყიდული/გასაყიდი პროდუქცია უნდა იყოს სავაჭრო/მომსახურების
            ობიექტისთვის მინიჭებული ბიზნეს საქმიანობის კატეგორიის შესაბამისი და
            მოქმედი კანონმდებლობით ნებადართული;
          </p>
          <p>
            2.8.8.3. დაუყონებლივ უზრუნველყოს სერვისის მიმწოდებლისთვის ან
            სერვისის მიმწოდებლის მიერ განსაზღვრული პირისთვის თანხის დაბრუნება,
            თუ: ა) თანხის ჩარიცხვა სავაჭრო/მომსახურების ობიექტის ანგარიშზე მოხდა
            შეცდომით ან არასწორად; ბ) საბარათე ოპერაცია შესრულებულია ბარათით
            სარგებლობის პირობების, კანონმდებლობის ან/და ბანკის ინსტრუქციების
            მოთხოვნების დარღვევით, რაც მოიცავს არაუფლებამოსილი პირის მიერ
            განხორციელებულ საბარათე ოპერაციებს ან/და ისეთ საბარათე ოპერაციებს,
            რომელიც არ გამოხატავს ბარათის მფლობელის ნებას; გ) არღვევს
            ხელშეკრულებით გათვალისწინებულ ვალდებულებებს; დ) ბარათის მფლობელი
            აცხადებს, რომ პროდუქცია არ მიუღია ან პროდუქცია ნაკლის მქონეა.
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            2.8.9. სერვისის მიმწოდებლის ზეპირი ან წერილობითი მოთხოვნიდან 3
            (სამი) საბანკო დღის ვადაში უზრუნველყოს შემდეგი ინფორმაციის მიწოდება:
            დოკუმენტაცია/ინფორმაცია, იმ საბარათე ოპერაციასთან დაკავშირებით,
            რომელიც საგადახდო სისტემების წესებისა და კანონმდებლობის მიხედვით
            არსებობს გონივრული ეჭვი, რომ შეიძლება გახდეს/გახდა დავის საგანი;
          </p>
          <p>
            2.8.10. პროდუქტის მიწოდებისას მოახდინოს ბარათის მფლობელის ან/და
            ბართის მფლობელის მიერ განსაზღვრული პროდუქციის ფაქტობრივი მიმღები
            პირის იდენტიფიცირება და პროდუქციის სპეციფიკის გათვალისწინებით,
            გააფორმოს მასთან მიღება-ჩაბარების აქტი;
          </p>
          <p>
            2.8.11. მომსახურების ხელშეკრულებით განსაზღვრული მომსახურების
            მოქმედების ვადის განმავლობაში, მოპოვებული/შენარჩუნებული ჰქონდეს
            ყველა აუცილებელი ლიცენზია და ნებართვა პროდუქციის რეალიზაციისთვის;
          </p>
          <p>
            2.8.12. ოპერაციის განხორციელებამდე ბარათის მფლობელისთვის თვალსაჩინო
            გახადოს საბარათე ოპერაციის თანხა;
          </p>
          <p>
            2.8.13. ვალდებულია აკმაყოფილებდეს მოქმედი კანონმდებლობით დაშვებულ
            საქმიანობას, მათ შორის და არა მარტო შემდეგი პროდუქტებსა და
            მომსახურებას:
          </p>
          <p>
            a. E-ვაჭრობა;
            <br />
            b. კომუნალური გადასახადები;
            <br />
            c. სამოსი;
            <br />
            d. წიგნები და საკანცელარიო ნივთები;
            <br />
            e. მიწოდების მომსახურება;
            <br />
            f. საკვები;
            <br />
            g. ლოჯისტიკა;
            <br />
            h. ონლაინ მოლები;
            <br />
            i. ავტომობილების აქსესუარები/ ნაწილები;
            <br />
            j. ავტომობილები;
            <br />
            k. ავტომობილის შეკეთების სერვისი;
            <br />
            l. ტელევიზია;
            <br />
            m. შეკეთება და დასუფთავება;
            <br />
            n. მოვლა;
            <br />
            o. ვაუჩერები;
            <br />
            p. მარკეტინგული სერვისები;
            <br />
            q. სოფლის მეურნეობა;
            <br />
            r. საგანმანათლებლო მომსახურება;
            <br />
            s. სამედიცინო მომსახურება;
            <br />
            t. ელექტრო ტექნიკა;
            <br />
            u. ბაზრობები;
            <br />
            v. ტრანსპორტი;
            <br />
            w. სხვა მომსახურება და საქონელი, რაც დაშვებულია საქართველოს
            კანონმდებლობით.
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <i>
              2.9.
              <b>
                სერვისის მიმწოდებელი უფლებამოსილია:
                <br />
              </b>
            </i>
          </p>
          <p>
            2.9.1. დროულად მიიღოს პლატფორმისგან/სავაჭრო ობიექტისგან ამომწურავი
            ინფორმაცია/დოკუმენტაცია, რომელსაც არსებითი მნიშვნელობა აქვს
            მომსახურების გაწევისათვის.
          </p>
          <p>
            2.9.2. მოითხოვოს წინამდებარე დოკუმენტის და ხელშეკრულებით ნაკისრი
            ვალდებულების ჯეროვნად და დროულად შესრულება.
          </p>
          <p>
            2.9.3. უფლებამოსილია შეწყვიტოს მომსახურების ხელშეკრულება, თუ
            შეწყვეტა განპირობებულია კანონმდებლობასთან, სერვისის მიმწოდებლის
            მარეგულირებელი ორგანოს მითითებებთან, საგადახდო სისტემების წესებსა და
            სერვისის მიმწოდებლის შიდა პოლიტიკებსა და პროცედურებთან
            შესაბამისობაში ყოფნის მიზნებისათვის;
          </p>
          <p>
            2.9.4. უფლებამოსილია შეწყვიტოს მომსახურების ხელშეკრულება თუ სახეზეა
            წინამდებარე დოკუმენტის 2.8.4. პუნქტის დარღვევა, ან/და პლატფორმა ან
            სავაჭრო ობიექტი არღვევს წინამდებარე დოკუმენტის ან ხელშეკრულებიტ
            გათვალისწინებულ ვალდებულებებს.
          </p>
          <p>
            <i>
              2.10. <b>სერვისის მიმწოდებელი ვალდებულია:</b>
            </i>
          </p>
          <p>
            2.10.1. სრულყოფილად შეისწავლოს პლატფორმასთან არსებული ფუნქციონალები
            და მუდმივად შეამოწმოს ფუნქციონალების გამართული ფუნქციონირება.
          </p>
          <p>
            2.10.2. წინასწარ გააფრთხილოს პლატფორმა მოსალოდნელი შეფერხების ან
            გათიშვის შესახებ.
          </p>
          <p>
            2.10.3. არ გაახმაუროს პლატფორმის და სავაჭრო ობიექტის
            ფინანსური/კომერციული თუ სხვა ტიპის საიდუმლოება.
          </p>
          <p>
            2.10.4. პლატფორმის მოთხოვნის შემთხვევაში დაუყოვნებლივ მიაწოდოს მას
            დეტალური ინფორმაცია მომსახურეობის მიმდინარეობის შესახებ.
          </p>
          <p>
            2.10.5. პლატფორმის ან/და სავაჭრო ობიექტის მოთხოვნის შესაბამისად
            მიაწოდოს მას დეტალური ინფორმაცია ან ანგარიში სერვისის მიმწოდებლის
            მიერ შესრულებული სამუშაოების ხარიხის, მოცულობის და მსვლელობის
            შესახებ.
          </p>
          <p>
            2.10.6. პირადად გაწიოს მომსახურება და პლატფორმის წინასწარი
            წერილობითი თანხმობის გარეშე ხელშეკრულების შინაარსში არ ჩართოს
            ქვეკონტრაქტორები ან ნებისმიერი მესამე პირი/პირები.
          </p>
          <p>
            2.10.7. პლატფორმის წინასწარი წერილობითი თანხმობის გარეშე სერვისის
            მიმწოდებელი არ არის უფლებამოსილი წინამდებარე დოკუმენტიდან და
            ხელშეკრულებიდან გამომდინარე უფლებები ან ვალდებულებები გადასცეს
            ნებისმიერ მესამე პირს/პირებს.
          </p>
          <p>
            2.10.8. მიანიჭოს უფლება პლატფორმას საანგარიშსწორებო სისტემაში
            წვდომის ტექნიკური საშუალებების გამოყენებაზე 24 საათის განმავლობაში
            ხელშეკრულებით ნაკისრი ვალდებულებების შესასრულებლად.
          </p>
          <p>
            2.10.9. ვალდებულია საბარათე ოპერაციის შესრულებამდე მიიღოს ბარათის
            მფლობელის მონაცემები (სახელი და გვარი, პირადი ნომერი, მისამართი,
            ტელეფონის ნომერი და ა.შ.) და შეინახოს ის საბარათე ოპერაციის
            შესრულებიდან არანაკლებ 180 (ას ოთხმოცი) კალენდარული დღის
            განმავლობაში;
          </p>
          <p>
            <b>3. გადასახდელის გადახდის საერთო წესი</b>
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            3.1. ხელშეკრულებითა და სერვისის მიმწოდებლის მიერ განსაზღვრული წესის
            მიხედვით, ბარათის მფლობელის მიერ გადასახდელის გადახდის მიზნით
            სერვისის მიმწოდებლის საგადამხდელო არხები გამოიყენება ერთჯერადად
            ან/და მრავალჯერადად.
          </p>
          <p>
            3.2. სერვისი მიმწოდებელი ახორციელებს რა საგადასახადო დავალების ან
            მოთხოვნის შესრულებას მონაცემების გადაცემის ტექნიკური საშუალების
            გამოყენებით, სერვისის მიმწოდებელი გადახდისთანავე აწვდის პლატფორმას
            ან/და სავაჭრო ობიექტს ინფორმაციას ყოველი წარმატებული ან წარუმატებელი
            გადახდის შესახებ.
          </p>
          <p>
            3.3. მხარეები თანხმდებიან, რომ ყოველი კალენდარული თვის ბოლოს
            შეადგენენ შედარების აქტს, რომელშიც აისახება შესაბამისი თვის
            განმავლობაში სერვისის მიმწოდებლის ელექტრონული მომსახურების ჯამური
            თანხა და საკომისიოს თანხა. აღნიშნული აქტი წარმოადგენს, როგორც ამ
            ხელშეკრულებით ნაკისრი ვალდებულების შესრულების დამადასტურებელ ასევე,
            ფინანსური ხასიათის დოკუმენტს, დაერთვის ხელშეკრულებას და წარმოადგენს
            მომსახურების ხელშეკრულების შემადგენელ/განუყოფელ ნაწილს.
          </p>
          <p>
            <b>4. ანგარიშსწორება</b>
            4.1. მომსახურების საკომისიო განისაზღვრება მხარეთა შორის გაფორმებული
            მომსახურების შესახებ ხელშეკრულებით.
          </p>
          <p>
            4.2. მხარეთა შორის ანგარიშსწორება ხორციელდება უნაღდო ანგარიშსწორების
            გზით მომსახურების ხელშეკრულებაში მითითებულ საბანკო ანგარიშებზე.
          </p>
          <p>
            <b>
              <span className="flex justify-center mt-8">თავი III</span>
              <span className="flex justify-center">ზოგადი პირობები</span>
              <br />
            </b>
          </p>
          <p>
            <b>
              1. კონფიდენციალურობა
              <br />
            </b>
          </p>
          <p>
            1.1. ხელშეკრულების ფარგლებში ან მის მომზადებასთან დაკავშირებით მეორე
            მხარისგან მიღებული ნებისმიერი ინფორმაცია მიიჩნევა მკაცრად
            კონფიდენციალურად.
          </p>
          <p>
            1.2. კონფიდენციალურად ითვლება ყველა ის ინფორმაცია, რომელიც შეიძლება
            მხარეთა გონივრული შეფასების საფუძველზე ჩაითვალოს კონფიდენციალურად და
            რომლის გავრცელებამაც პირდაპირ თუ არაპირდაპირ შეიძლება ზიანი მიაყენოს
            მეორე მხარის სახელსა და კომერციულ ინტერსებს.
          </p>
          <p>
            1.3. თუ ხელშეკრულებით სხვა რამ არ არის გათვალისწინებული, მხარეები
            ვალდებული არიან, როგორც ხელშეკრულების მოქმედების პერიოდში, ასევე
            სახელშეკრულებო ურთიერთობის დამთავრების შემდეგაც დაიცვან
            ხელშეკრულებასთან დაკავშირებით ან/და ხელშეკრულების საფუძველზე მეორე
            მხარისაგან ნებისმიერ დროს/ეტაპზე, წერილობითი ან ზეპირსიტყვიერი
            ფორმით, მიღებული ნებისმიერი სახის ინფორმაციის, მათ შორის თავად
            ხელშეკრულების არსებობის/გაფორმების თაობაზე ინფორმაციის
            კონფიდენციალურობა და მხარეებს უფლება არა აქვთ ზემოაღნიშნული
            ინფორმაცია გაამჟღავნონ (მათ შორის, ნებისმიერი ფორმით და საშუალებით
            საჯაროდ გამოაქვეყნონ, გაავრცელონ, ან ხელმისაწვდომი გახადონ მესამე
            პირებისთვის) ან ხელშეკრულებით განსაზღვრული მიზნობრიობის დარღვევით
            გამოიყენონ იგი.
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            1.4. მხარე უფლებამოსილია ხელშეკრულების ფარგლებში მიღებული ინფორმაცია
            ხელმისაწვდომი გახადოს მხოლოდ მისი იმ თანამშრომლებისთვის, ან
            აფილირებული პირებისათვის, რომელთათვისაც აუცილებელია აღნიშნული
            ინფორმაციის ცოდნა ხელშეკრულების შესრულების მიზნებისთვის. მხარე
            ვალდებულია უზრუნველყოს, რომ აღნიშნული პირები იცნობდნენ ხელშეკრულებით
            გათვალისწინებულ კონფიდენციალურობის ვალდებულებებს და სრულად
            ასრულებდნენ მათ და მხარე პასუხისმგებელია მათ მიერ კონფიდენციალურობის
            ვალდებულების დარღვევაზე, ისევე, როგორც საკუთარ დარღვევაზე.
          </p>
          <p>
            1.5. მხარის მიერ ხელშეკრულების ფარგლებში მიღებული ინფორმაციის
            ნებისმიერი სხვა მესამე პირისთვის გამჟღავნება დასაშვებია მხოლოდ მეორე
            მხარის წინასწარი წერილობითი თანხმობით. მხარე ვალდებულია მესამე პირი
            შებოჭოს იმავე კონფიდენციალურობის ვალდებულებით, რაც ხელშეკრულებით
            ვრცელდება მხარეებზე. ინფორმაციის მიმწოდებელი მხარე სრულად აგებს
            პასუხს მესამე პირის მიერ მისთვის გადაცემული ინფორმაციის
            კონფიდენციალურობის დაცვაზე.
          </p>
          <p>
            1.6. მხარეები თანხმდებიან, რომ სრულად აუნაზღაურებენ ერთმანეთს
            ინფორმაციის კონფიდენციალურობის დაცვის ვალდებულების დარღვევის შედეგად
            მიყენებულ ზიანს (ზარალს). ამასთან, ზიანის ანაზღაურება არ
            ათავისუფლებს მხარეებს ხელშეკრულებით ნაკისრი ვალდებულებების სრულად
            შესრულებისაგან.
          </p>
          <p>
            1.7. კონფიდენციალურობის დაცვის ვალდებულება გრძელდება ხელშეკრულების
            შეწყვეტის შემდეგაც უვადოდ, გარდა კანონით განსაზღვრული
            გამონაკლისებისა.
          </p>
          <p>
            <b>
              1.8. კონფიდენციალურობის შესახებ ზემოაღნიშნული შეზღუდვა არ შეეხება
              ინფორმაციას ან ინფორმაციის გამჟღავნებას:
              <br />
            </b>
            a. რომელიც კანონმდებლობის დარღვევის გარეშე იყო ცნობილი ინფორმაციის
            მიმღები მხარისათვის მეორე მხარის მიერ შესაბამისი ინფორმაციის
            მიწოდებამდე;
          </p>
          <p>
            b. რომლის გამჟღავნებაც მოხდება მხარეთა მიერ კანონმდებლობის
            მოთხოვნათა დაცვით და შესასრულებლად (მათ შორის, რომელიმე მხარის მიერ
            სასამართლო (მათ შორის, საარბიტრაჟო სასამართლო) წესით მისი უფლებების
            განსახორციელებლად);
          </p>
          <p>
            c. რომლის მოპოვებაც შესაძლებელია სხვა საჯაროდ ხელმისაწვდომი
            წყაროებიდან;
          </p>
          <p>
            <b>
              2. პასუხისმგებლობა
              <br />
            </b>
          </p>
          <p>
            2.1. მხარეები პასუხს აგებენ ხელშეკრულებით გათვალისწინებული
            ვალდებულებების შეუსრულებლობით ან არაჯეროვანი შესრულებით გამოწვეულ
            ზარალზე ერთმანეთის წინაშე.
          </p>
          <p>
            2.2.ზიანის ოდენობის განსაზღვრისას მხედველობაში მიიღება ინტერესი,
            რომელიც მხარეს ჰქონდა ვალდებულების ჯეროვანი შესრულების მიმართ.
            ასევე, ზიანის ოდენობის დასადგენად გათვალისწინებული უნდა იქნეს საქმის
            ყველა გარემოება.
          </p>
          <p>
            2.3. ზიანი უნდა ანაზღაურდეს არა მხოლოდ ფაქტობრივად დამდგარი
            ქონებრივი დანაკლისისათვის, არამედ მიუღებელი შემოსავლისთვისაც.
          </p>
          <p>
            2.4. გადამხდელის ან/და კლიენტის მიერ სერვისის მიმწოდებლის ან/და
            კომპანიის მიმართ ზარალის ანაზღაურებაზე წაყენებულ მოთხოვნაზე სერვისის
            მიმწოდებელი პასუხს აგებს მხოლოდ იმ შემთხვევაში, თუ აღნიშნული ზარალი
            გამოწვეულია სერვისის მიმწოდებლის მიერ გადასახდელის ჩარიცხვის
            პროცესში დაშვებული შეცდომით, რაც, მათ შორის და არა მხოლოდ, მოიცავს -
            კომპანიისთვის დაგვიანებით, არასწორად, არასრულად ან/და არაზუსტად
            ინფორმაციის მიწოდებით ან/და გადასახდელის გადახდაზე საგადასახადო
            დავალების ან მოთხოვნის დაგვიანებით/არასწორად შესრულებით. ყოველგვარი
            ეჭვის თავიდან ასაცილებლად, აღნიშნული პასუხისმგებლობა სერვისის
            მიმწოდებელს არ დაეკისრება, თუ იგი განპირობებულია გადამხდელის მიერ
            შეუსაბამო მონაცემების მოწოდებით, კომპანიის მიერ ხელშეკრულებით
            ნაკისრი იმგვარი ვალდებულებების არაჯეროვანი შესრულებით, რომელიც
            პირდაპირ კავშირშია დამდგარი ზიანის გამოწვევასთან ან/და კომპანიის
            სისტემისა და მონაცემთა გადაცემის ტექნიკური საშუალებების
            გაუმართაობით, თუკი ამგვარი გაუმართაობა გამოწვეული არ არის სერვისის
            მიმწოდებლის ბრალეულობით.
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            2.5. სერვისის მიმწოდებელი არ არის პასუხისმგებელი კლიენტების წინაშე
            კომპანიის მიერ ნაკისრ ვალდებულებებზე, რომლებიც მას წარმოეშობა
            მომსახურების პროცესში.
          </p>
          <p>
            2.6. სერვისის მიმწოდებელი პასუხს არ აგებს ანგარიშსწორების
            შეფერხებაზე და დაგვიანებაზე, იმ შემთხვევაში, თუ შეფერხება/დაგვიანება
            გამოწვეულია კომპანიის მიერ არასწორი საბანკო რეკვიზიტების მიწოდებით
            ან საბანკო რეკვიზიტების შეცვლის შესახებ ინფორმაციის დაგვიანებით
            მიწოდებით.
          </p>
          <p>
            2.7. სერვისის მიმწოდებელს არ ეკისრება არანაირი სახის პასუხისმგებლობა
            მესამე პირებისთვის მიყენებულ იმ ზიანზე, რაც გამომდინარეობს კომპანიის
            მიერ რაიმე გარიგებიდან გამომდინარე ნაკისრი ვალდებულების დარღვევიდან.
          </p>
          <p>
            2.8. კომპანია იღებს მთელ პასუხისმგებლობას მომსახურების ხარისხზე,
            კომპანიის მიერ კლიენტებისთვის წარმოდგენილი ნებისმიერი ინფორმაციის
            შინაარსზე, გარდა იმ შემთხვევისა, როდესაც წარდგენილი ინფორმაცია
            წარმოადგენს სერვისის მიმწოდებლის მიერ კომპანიისთვის მოწოდებულ
            ინფორმაციას. აგრეთვე იმ ინფორმაციის შინაარსზე, რომელსაც კომპანია
            ათავსებს ინტერნეტ ქსელში მომსახურებასთან დაკავშირებით, ამასთანავე
            იგი ვალდებულია სერვისის მიმწოდებელს პირველივე თხოვნით (კავშირის
            ნებისმიერი არხებით შესაბამისი მოთხოვნის მიღებისთანავე) წაშალოს
            ნებისმიერი ინფორმაცია სერვისის მიმწოდებლის შესახებ, რომელიც
            კომპანიის მიერ განთავსებულია ინტერნეტ ქსელში.
          </p>
          <p>
            <b>
              3. ფორს-მაჟორი
              <br />
            </b>
            3.1. არც ერთი მხარე პასუხს არ აგებს წინამდებარე ხელშეკრულებით
            ნაკისრი ვალდებულებების შეუსრულებლობაზე, არადროულად ან/და
            არასათანადოდ შესრულებაზე, თუ აღნიშნული შეუსრულებლობა, არასათანადო ან
            არადროული შესრულება განპირობებულია დაუძლეველი ძალის გარემოებათა
            (ფორსმაჟორულ გარემოებათა) დადგომის ან/და მოქმედების შედეგად.
            აღნიშნული ვალდებულება ძალაში შედის დაუძლეველი ძალის გარემოებათა
            გაუქმებისთანავე.
          </p>
          <p>
            3.2. დაუძლეველი ძალის გარემოებები გულისხმობს საგანგებო ხასიათის
            მქონე მოვლენებს, რომლებიც წარმოიქმნა წინამდებარე ხელშეკრულების
            დადების შემდეგ, მხარეთა (მხარის) ნების გარეშე, მაგალითად: სტიქიური
            მოვლენები და კატაკლიზმები, ომი, სამოქალაქო არეულობა, სახელმწიფო
            ხელისუფლების საკანონმდებლო ან/და აღმასრულებელი ორგანოების,
            საერთაშორისო საგადამხდელო სისტემის გადაწყვეტილებები და იმგვარი
            გარემოებები, რომლებიც თავისუფალია მხარეთა გავლენისგან, არ
            ექვემდებარება გამოსწორებას მათი ძალისხმევის მიუხედავად და შეუძლებელს
            ხდის მხარეთა სახელშეკრულებო ვალდებულების შესრულებას. შეტყობინებაში
            ასახული ფაქტები შემდგომში დადასტურებული უნდა იქნას შესაბამისი
            რეგიონის სავაჭრო, სამრეწველო პალატის ან სხვა კომპეტენტური ორგანოს
            მიერ.
          </p>
          <p>
            3.3. მხარე, რომელიც განიცდის ფორსმაჟორული გარემოებების ზემოქმედებას,
            ვალდებულია დაუყოვნებლივ, აღნიშნული გარემოებების დადგომიდან
            არაუგვიანეს 3 (სამი) სამუშაო დღის განმავლობაში, წერილობითი ფორმით
            აცნობოს მეორე მხარეს ფორსმაჟორულ გარემოებათა და მათი დადგომის
            შესახებ და მიიღოს აუცილებელი ზომები ამ გარემოებებით გამოწვეული
            ნეგატიური შედეგების შესამცირებლად. ანალოგიური მოთხოვნები ვრცელდება
            აგრეთვე ფორსმაჟორული გარემოებებისა და მათი შედეგების შეწყვეტის
            შესახებ შეტყობინებაზეც.
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            3.4. თუ დაუძლეველი ძალის გარემოებები გაგრძელდება 1 თვეზე მეტი ხნის
            განმავლობაში, ნებისმიერი მხარე უფლებამოსილია შეწყვიტოს წინამდებარე
            ხელშეკრულება, რა შემთხვევაშიც თითოეულ მხარეს რჩება მოშლის თარიღამდე
            მიღებული მომსახურების საფასური ან/და მომსახურების შედეგი, ხოლო
            ზედმეტად გადახდილი ფულადი თანხები ექვემდებარება დაბრუნებას.
          </p>
          <p>
            3.5. შეუტყობინებლობა ან დაგვიანებით შეტყობინება უფლებას ართმევს
            მხარეს გამოიყენოს ზემოთ მოყვანილი დაუძლეველი ძალის მოვლენები
            პასუხისმგებლობიდან განთავისუფლების საფუძვლად - გარდა იმ
            შემთხვევებისა, თუ დაუძლეველი ძალის გარემოებები საყოველთაოდ ცნობილია.
          </p>
          <p>
            <b>
              4. შესაძლო დავის განხილვის წესი
              <br />
            </b>
          </p>
          <p>
            4.1. წინამდებარე ხელშეკრულება რეგულირდება და განიმარტება საქართველოს
            მოქმედი კანონმდებლობის შესაბამისად.
          </p>
          <p>
            4.2. ხელშეკრულების და წინამდებარე დოკუმენტის ირგვლივ წამოჭრილი
            ნებისმიერი დავა (მათ შორის, ხელშეკრულების არსებობასთან,
            ინტერპრეტაციასთან, შესრულებასთან და აღსრულებასთან დაკავშირებით)
            წყდება მოლაპარაკებით. დავის მოუგვარებლობის შემთხვევაში, მხარეები
            მიმართავენ სასამართლოს.
          </p>
          <p>
            4.3. მხარეები თანხმდებიან, რომ დავასთან დაკავშირებით პირველი
            ინსტანციის სასამართლოს მიერ სერვისის მიმწოდებლის სასარგებლოდ
            მიღებული გადაწყვეტილება მიქცეულ იქნება დაუყოვნებლივ აღსასრულებლად.
          </p>
          <p>
            <b>
              5. სხვა პირობები
              <br />
            </b>
          </p>
          <p>
            5.1. მხარეთა შორის გაფორმებული ძირითადი ხელშეკრულება ითვლება
            გაგრძელებულად ყოველ ჯერზე იგივე ვადით და პირობებით, თუ არც ერთმა
            მხარემ არ აცნობა სერვისი მიმწოდებელს მისი შეწყვეტის ან ხელშეკრულების
            არსებითი პირობების შეცვლის სურვილის შესახებ ხელშეკრულების მოქმედების
            ვადის გასვლამდე 30 (ოცდაათი) სამუშაო დღით ადრე;
          </p>
          <p>
            5.2. მხარეები უფლებამოსილნი არიან შეწყვიტონ ძირითდი ხელშეკრულება
            ნებისმიერ დროს ხელშეკრულების ძალაში შესვლიდან ექვსი თვის გასვლის
            შემდეგ იმ პირობით, რომ ხელშეკრულების შეწყვეტის თარიღამდე 30
            (ოცდაათი) სამუშაო დღით ადრე წერილობით გააფრთხილებენ ამის შესახებ
            მეორე მხარეს.
          </p>
          <p>
            5.3. მხარეები ხელშეკრულების და წინამდებარე დოკუმენტის შესაბამისად
            წარმოადგენენ დამოუკიდებელ ხელშემკვრელ მხარეებს და წინამდებარე
            ხელშეკრულება არ შეიძლება განმარტებულ იქნას როგორც კომპანიის და
            სერვისის მიმწოდებლის მიერ ერთობლივი საწარმოს დაარსება ან/და სააგენტო
            ურთიერთობის ჩამოყალიბება. არც ერთი მხარეს არა აქვს უფლება გამოვიდეს
            მეორე მხარის სახელით ნებისმიერ სამართლებრივ ურთიერთობაში.
          </p>
          <p>
            5.4. თუ წინამდებარე დოკუმენტის ან/და ხელშეკრულების რომელიმე პუნქტი
            ან მისი ნაწილი რაიმე მიზეზით იქნა გაუქმებული, ბათილად ცნობილი ან
            შეწყვეტილი, აღნიშნული პუნქტი ან მისი ნაწილი აღარ იქნება
            გამოყენებული, ხოლო ხელშეკრულების და წინამდებარე დოკუმენტის დანარჩენ
            პუნქტებს აქვს იურიდიულ ძალაზე.
          </p>
          <p>
            5.5. თუ ძირითდი ხელშეკრულებით სხვა რამ არ არის გათვალისწინებული,
            ნებისმიერი შეტყობინება ხორციელდება წერილობითი ფორმით.
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            5.6. მხარის მიერ საკუთარი უფლებების განხორციელების გადავადება არ
            ნიშნავს იმას, რომ მხარე უარს ამბობს თავის უფლებებზე; აგრეთვე
            ნებისმიერი უფლების ნაწილობრივ ან სრულად გამოუყენებლობა არ ნიშნავს
            მომავალში ამ უფლების სრულად გამოყენების შეზღუდვას.
          </p>
          <p>
            5.7. წინამდებარე დოკუმენტიში და ხელშეკრულებაში ცვლილებების ან/და
            დამატებების შეტანა ხორციელდება მხოლოდ მხარეთა ორმხრივი წერილობითი
            შეტყობინების საფუძველზე.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Ge;
