export default function MinusIcon({ onClick, color }) {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect
        x="0.5"
        y="0.359863"
        width="50"
        height="50"
        rx="13"
        fill={color}
        fill-opacity="0.1"
      />
      <path
        d="M19.8805 27.1311C18.809 27.1311 18 26.4314 18 25.36C18 24.2886 18.809 23.5889 19.8805 23.5889H31.1195C32.191 23.5889 33 24.2886 33 25.36C33 26.4314 32.191 27.1311 31.1195 27.1311H19.8805Z"
        fill={color}
      />
    </svg>
  );
}
