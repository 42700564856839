export default function CredoBankIcon(props) {
  return (
    <svg
      width="41"
      height="56"
      viewBox="0 0 41 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.3969 40.8624L34.8969 40.1624C34.5969 39.9624 34.2969 39.8624 33.9969 39.5624C36.7969 36.3624 38.2969 32.2624 38.1969 28.0624C38.1969 18.3624 30.2969 10.4624 20.5969 10.4624C18.3969 10.4624 16.2969 10.8624 14.2969 11.6624V14.8624C16.2969 13.9624 18.3969 13.4624 20.5969 13.4624C28.6969 13.4624 35.2969 20.0624 35.2969 28.1624C35.2969 31.5624 34.0969 34.9624 31.8969 37.5624C31.6969 37.2624 31.4969 36.9624 31.2969 36.5624L30.5969 35.0624C30.3969 34.6624 29.8969 34.4624 29.4969 34.5624C29.1969 34.6624 28.9969 34.9624 28.9969 35.2624V41.5624C28.9969 42.0624 29.3969 42.3624 29.7969 42.3624H36.0969C36.5969 42.3624 36.8969 41.9624 36.8969 41.4624C36.8969 41.2624 36.6969 41.0624 36.3969 40.8624Z"
        fill="#8A8A8D"
      />
      <path
        d="M20.5798 16.4023C18.2798 16.4023 16.1798 17.0023 14.2798 18.2023V20.8023C14.2798 22.4023 12.9798 23.7023 11.4798 23.7023C10.8798 23.7023 10.3798 23.5023 9.8798 23.2023C7.1798 29.1023 9.7798 36.1023 15.6798 38.8023C17.1798 39.5023 18.8798 39.9023 20.5798 39.9023C22.7798 39.9023 24.9798 39.3023 26.8798 38.0023V35.4023C26.8798 33.8023 28.1798 32.5023 29.6798 32.5023C30.2798 32.5023 30.7798 32.7023 31.2798 33.0023C31.9798 31.5023 32.2798 29.8023 32.2798 28.2023C32.2798 21.6023 27.0798 16.4023 20.5798 16.4023Z"
        fill="url(#paint0_radial_2575_3409)"
      />
      <path
        d="M20.5969 42.8626C12.4969 42.7626 5.89687 36.2626 5.89687 28.0626C5.89687 27.7626 5.89687 27.4626 5.99687 27.0626V26.8626C5.99687 26.6626 6.09687 26.3626 6.09687 26.1626V25.9626C6.39687 24.0626 7.09687 22.1626 8.09687 20.4626C8.39687 19.8626 8.89687 19.2626 9.39687 18.7626C9.59687 19.0626 9.79688 19.3626 9.99687 19.7626L10.6969 21.2626C10.8969 21.6626 11.3969 21.8626 11.7969 21.6626C12.0969 21.5626 12.2969 21.2626 12.2969 20.8626V14.5626C12.2969 14.0626 11.8969 13.7626 11.3969 13.7626H5.09687C4.59687 13.6626 4.29688 14.0626 4.19687 14.4626C4.19687 14.7626 4.39687 15.0626 4.69687 15.2626L6.19687 15.9626C6.49687 16.1626 6.89687 16.3626 7.19688 16.5626C6.19688 17.7626 5.29688 18.9626 4.69687 20.3626C4.29688 21.0626 3.99688 21.8626 3.79688 22.6626C3.69687 22.7626 3.69688 22.8626 3.69688 22.9626C3.59688 23.2626 3.49688 23.5626 3.39688 23.9626C3.39688 24.0626 3.29688 24.1626 3.29688 24.1626C3.19688 24.5626 3.09688 24.9626 3.09688 25.2626C3.09688 25.3626 3.09688 25.4626 2.99688 25.5626C2.89688 25.8626 2.89688 26.1626 2.89688 26.4626C2.89688 26.5626 2.89688 26.6626 2.79688 26.7626C2.79688 27.1626 2.89688 27.6626 2.89688 28.0626C2.89688 37.7626 10.7969 45.6626 20.4969 45.7626C22.6969 45.7626 24.7969 45.3626 26.7969 44.5626V41.3626C24.9969 42.2626 22.7969 42.7626 20.5969 42.8626Z"
        fill="#00609C"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2575_3409"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.8318 24.1603) scale(16.109)"
        >
          <stop stop-color="white" />
          <stop offset="0.139" stop-color="#C9DEEA" />
          <stop offset="0.338" stop-color="#83B2CF" />
          <stop offset="0.517" stop-color="#4A8FBA" />
          <stop offset="0.67" stop-color="#2276AA" />
          <stop offset="0.791" stop-color="#0967A1" />
          <stop offset="0.864" stop-color="#00619D" />
          <stop offset="0.967" stop-color="#00609C" />
        </radialGradient>
      </defs>
    </svg>
  );
}
