import axios from "axios";
import React, { useState, useEffect } from "react";
const oidConfig = {
  authority: "https://accounts.libertybank.ge",
  client_id: "Keepzme",
  redirect_uri: "https://app.keepz.me/lb",
  scope:
    "openid profile account_api_public exchangerate_api_public currenttask_api_public transfer_api_public transaction_api_public billing_api_public",
  response_type: "code id_token",
  post_logout_redirect_uri: "https://app.keepz.me/",
  code_challenge_method: "S256",
};
const generateRandomString = (length) => {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ("0" + dec.toString(36)).slice(-2))
    .join("")
    .substring(0, length);
};
const base64UrlEncode = (input) => {
  return btoa(String.fromCharCode(...new Uint8Array(input)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
};
const sha256 = async (message) => {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
  return base64UrlEncode(hashBuffer);
};
const buildAuthorizationUrl = async (state, nonce) => {
  const codeVerifier = generateRandomString(64);
  localStorage.setItem("codeVerifier", codeVerifier);
  const codeChallenge = await sha256(codeVerifier);
  const params = new URLSearchParams({
    client_id: oidConfig.client_id,
    redirect_uri: oidConfig.redirect_uri,
    response_type: oidConfig.response_type,
    scope: oidConfig.scope,
    state: state,
    nonce: nonce,
    code_challenge: codeChallenge,
    code_challenge_method: oidConfig.code_challenge_method,
  });
  return `${oidConfig.authority}/connect/authorize?${params.toString()}`;
};
const LibertyPage = () => {
  const [response, setResponse] = useState("");
  useEffect(() => {
    const state = generateRandomString(16);
    const nonce = generateRandomString(16);

    const handleCallback = async () => {
      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      const code = hashParams.get("code");
      // const id_token = hashParams.get("id_token");
      // if(code){
      //   setResponse(code)
      // }else{
      //   window.location.href = buildAuthorizationUrl(state, nonce);
      // }
      if (code) {
        try {
          // const tokenResponse = await axios.post(
          //   `${oidConfig.authority}/connect/token`,
          //   new URLSearchParams({
          //     grant_type: "authorization_code",
          //     code: code,
          //     redirect_uri: oidConfig.redirect_uri,
          //     client_id: oidConfig.client_id,
          //   }),
          //   {
          //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   }
          // );
          // console.log("Token response:", tokenResponse.data);
          // setResponse(JSON.stringify(tokenResponse.data));
        } catch (error) {
          console.error("Token exchange error:", error);
        }
      } else {
        window.location.href = await buildAuthorizationUrl(state, nonce);
      }
    };
    handleCallback();
  }, []);
  const handleCopy = () => {
    navigator.clipboard
      .writeText(response)
      .then(() => alert("Text copied to clipboard!"))
      .catch((err) => alert("Failed to copy text: ", err));
  };
  return (
    <div className="flex justify-center min-h-screen bg-purple">
      <div className="flex flex-col gap-8 w-full max-w-sm">
        <div className="flex items-center justify-between mt-28 px-5 py-3 bg-gray-200 rounded-xl">
          <span id="codeContainer" className="text-gray-700 overflow-hidden">
            {response}
          </span>
          <button
            onClick={handleCopy}
            className="ml-2 hover:opacity-90 bg-purple text-white py-2 px-3 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Copy
          </button>
        </div>
      </div>
    </div>
  );
};
export default LibertyPage;
