import React from "react";

export default function GooglePayIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="40"
      viewBox="0 0 43.683 23.236"
      {...props}
    >
      <g id="google-pay-mark_800" transform="translate(-170.7 -170)">
        <path
          id="Base_1_"
          d="M202.765,170H182.318A11.652,11.652,0,0,0,170.7,181.618h0a11.652,11.652,0,0,0,11.618,11.618h20.447a11.652,11.652,0,0,0,11.618-11.618h0A11.652,11.652,0,0,0,202.765,170Z"
          transform="translate(0)"
          fill="#fff"
        />
        <path
          id="Outline"
          d="M202.765,170.941a10.539,10.539,0,0,1,4.142.842,10.771,10.771,0,0,1,5.693,5.693,10.6,10.6,0,0,1,0,8.283,10.771,10.771,0,0,1-5.693,5.693,10.539,10.539,0,0,1-4.142.842H182.318a10.54,10.54,0,0,1-4.142-.842,10.772,10.772,0,0,1-5.693-5.693,10.6,10.6,0,0,1,0-8.283,10.772,10.772,0,0,1,5.693-5.693,10.539,10.539,0,0,1,4.142-.842h20.447m0-.941H182.318A11.652,11.652,0,0,0,170.7,181.618h0a11.652,11.652,0,0,0,11.618,11.618h20.447a11.652,11.652,0,0,0,11.618-11.618h0A11.652,11.652,0,0,0,202.765,170Z"
          transform="translate(0)"
          fill="#3c4043"
        />
        <g id="G_Pay_Lockup_1_" transform="translate(177.351 176.645)">
          <g id="Pay_Typeface_3_" transform="translate(13.064 0.633)">
            <path
              id="Letter_p_3_"
              d="M511.215,300.464v3.514H510.1V295.3h2.957a2.666,2.666,0,0,1,1.911.749,2.5,2.5,0,0,1,0,3.671,2.662,2.662,0,0,1-1.911.738h-1.841Zm0-4.1V299.4h1.865a1.489,1.489,0,0,0,1.1-.447,1.463,1.463,0,0,0,.447-1.063,1.547,1.547,0,0,0-1.551-1.51h-1.865Z"
              transform="translate(-510.1 -295.3)"
              fill="#3c4043"
            />
            <path
              id="Letter_a_3_"
              d="M615.891,339.1a2.759,2.759,0,0,1,1.952.662,2.35,2.35,0,0,1,.715,1.812v3.66h-1.063v-.825h-.047a2.121,2.121,0,0,1-1.841,1.017,2.391,2.391,0,0,1-1.644-.581,1.855,1.855,0,0,1-.662-1.452,1.754,1.754,0,0,1,.7-1.464,2.925,2.925,0,0,1,1.859-.546,3.25,3.25,0,0,1,1.632.366v-.256a1.266,1.266,0,0,0-.459-.988,1.575,1.575,0,0,0-1.081-.407,1.694,1.694,0,0,0-1.475.79l-.982-.616A2.724,2.724,0,0,1,615.891,339.1Zm-1.44,4.31a.879.879,0,0,0,.372.726,1.366,1.366,0,0,0,.866.29,1.769,1.769,0,0,0,1.255-.523,1.641,1.641,0,0,0,.552-1.226,2.3,2.3,0,0,0-1.452-.412,1.907,1.907,0,0,0-1.133.325A.992.992,0,0,0,614.45,343.41Z"
              transform="translate(-607.305 -336.556)"
              fill="#3c4043"
            />
            <path
              id="Letter_y_3_"
              d="M711.937,342.4l-3.718,8.551h-1.15l1.383-2.992L706,342.4h1.214l1.766,4.264H709l1.719-4.264Z"
              transform="translate(-694.62 -339.664)"
              fill="#3c4043"
            />
          </g>
          <g id="G_Mark_1_" transform="translate(0 0)">
            <path
              id="Blue_500"
              d="M375.618,355.036a5.871,5.871,0,0,0-.093-1.046H370.85v1.917h2.692a2.306,2.306,0,0,1-1,1.544V358.7h1.6A4.872,4.872,0,0,0,375.618,355.036Z"
              transform="translate(-365.875 -349.948)"
              fill="#4285f4"
            />
            <path
              id="Green_500_1_"
              d="M300.45,388.007a3.015,3.015,0,0,1-4.487-1.577H294.31v1.283a4.977,4.977,0,0,0,4.447,2.741,4.747,4.747,0,0,0,3.3-1.2Z"
              transform="translate(-293.781 -380.503)"
              fill="#34a853"
            />
            <path
              id="Yellow_500_1_"
              d="M287.227,333.815a3,3,0,0,1,.156-.952V331.58h-1.653a4.983,4.983,0,0,0,0,4.469l1.653-1.283A3,3,0,0,1,287.227,333.815Z"
              transform="translate(-285.2 -328.839)"
              fill="#fabb05"
            />
            <path
              id="Red_500"
              d="M298.757,286.369a2.7,2.7,0,0,1,1.909.746l1.42-1.419a4.781,4.781,0,0,0-3.33-1.3,4.978,4.978,0,0,0-4.447,2.741l1.653,1.283A2.977,2.977,0,0,1,298.757,286.369Z"
              transform="translate(-293.781 -284.4)"
              fill="#e94235"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
