import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Trans, useTranslation } from "react-i18next";

import Button from "../Button/Button";
import JSEncrypt from "jsencrypt";
import { sendPartnershipRequest } from "api";
import { useLockBodyScroll } from "@uidotdev/usehooks";

const Modal = ({ setShowContactModal, setSuccessModal }) => {
  const { t } = useTranslation();
  useLockBodyScroll();
  const [infoValues, setInfoValues] = useState({
    name: "",
    companyName: "",
    mobileNumber: "",
  });

  const handleInputChange = (field, value) => {
    setInfoValues({ ...infoValues, [field]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      infoValues.name.length == 0 ||
      infoValues.companyName.length == 0 ||
      infoValues.mobileNumber.length === 0
    ) {
      return;
    }

    const crypt = new JSEncrypt();
    crypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
    const data = {
      encryptedData: crypt.encrypt(JSON.stringify(infoValues)),
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
    };
    try {
      await sendPartnershipRequest(data);

      await setShowContactModal(false);
      setSuccessModal(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  return ReactDOM.createPortal(
    <>
      <div
        className=" flex justify-center   fixed  overflow-hidden top-0 w-[100vw] h-[100vh] bg-black-400 opacity-9 z-50 "
        onClick={(e) => {
          if (e.currentTarget !== e.target) {
            return;
          }
          setShowContactModal(false);
        }}
      >
        <div className="   gap-[20px]   bg-[#fff] rounded-[16px] p-[30px] top-[10px] sm:w-[90%]   md:w-[360px]  md:top-[100px] left-[50%] z-20 absolute translate-x-[-50%] ">
          <div className="flex w-full justify-between ">
            <h2 className=" text-[24px] font-bold  ">
              {/* დატოვეთ <br /> საკონტაქტო <br /> ინფორმაცია */}
              <Trans i18nKey={"leaveInfo"} />
            </h2>

            <svg
              onClick={() => setShowContactModal(false)}
              style={{
                cursor: "pointer",
              }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div className=" flex flex-col gap-[20px] mt-[40px]">
            <form
              onSubmit={handleSubmit}
              className=" flex flex-col gap-[20px] mt-[40px]"
            >
              <input
                required
                onChange={(e) => handleInputChange("name", e.target.value)}
                placeholder={t("name")}
                type="text"
                className=" rounded-[12px] border-[1px] border-[#D0D5DD] px-[14px] py-[13px] focus:border-[#6C63FF] outline-none"
              />
              <input
                required
                onChange={(e) =>
                  handleInputChange("companyName", e.target.value)
                }
                placeholder={t("companyName")}
                type="text"
                className="w-full rounded-[12px] border-[1px] border-[#D0D5DD] px-[14px] py-[13px] focus:border-[#6C63FF] outline-none"
              />
              <input
                required
                onChange={(e) =>
                  handleInputChange("mobileNumber", e.target.value)
                }
                placeholder={t("mobileNumber")}
                type="number"
                className="w-full rounded-[12px] border-[1px] border-[#D0D5DD] px-[14px] py-[13px] focus:border-[#6C63FF] outline-none"
              />
              <Button className="border-[1px] w-full mt-[16px]  solid font-[400] text-[16px] bg-[#6C63FF] text-[#fff] hover:bg-[#fff] px-[14px] py-[13px] hover:text-[#6C63FF] hover:border-[#6C63FF]  ">
                {t("buttons.send")}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("modal-root")
  );
};

export default Modal;
