import JSEncrypt from "jsencrypt";
import CryptoJS from "crypto-js";

export const encrypt = (data, options = {}) => {
  const { shouldEncode = false, stringify = true } = options;
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
  const encryptedData = encrypt.encrypt(
    stringify ? JSON.stringify(data) : data
  );
  return shouldEncode ? encodeURIComponent(encryptedData) : encryptedData;
};

export const decrypt = (encryptedData, options = {}) => {
  const { shouldDecode = false, shouldParse = true } = options;
  const encrypt = new JSEncrypt();
  encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
  const decryptedData = shouldParse
    ? JSON.parse(
        encrypt.decrypt(
          shouldDecode ? decodeURIComponent(encryptedData) : encryptedData
        )
      )
    : encrypt.decrypt(
        shouldDecode ? decodeURIComponent(encryptedData) : encryptedData
      );
  return decryptedData;
};

export const generateKey = () => CryptoJS.lib.WordArray.random(32);
export const generateIV = () => CryptoJS.lib.WordArray.random(16);
export const parseBase64 = (base64) => CryptoJS.enc.Base64.parse(base64);
export const convertBase64 = (data) => CryptoJS.enc.Base64.stringify(data);

export const encryptAES = (data, key, iv, options = {}) => {
  const { shouldEncode = false, stringify = true } = options;
  const encryptedData = CryptoJS.AES.encrypt(
    stringify ? JSON.stringify(data) : data,
    key,
    { iv: iv }
  );

  return shouldEncode
    ? encodeURIComponent(encryptedData)
    : encryptedData.toString();
};

export const decryptAES = (encryptedData, key, iv, options = {}) => {
  const { shouldDecode = false } = options;
  const decryptedData = CryptoJS.AES.decrypt(
    shouldDecode ? decodeURIComponent(encryptedData) : encryptedData,
    key,
    { iv: iv }
  );

  return JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));
};
