export default function BitIcon(props) {
  return (
    <svg
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2575_3477)">
        <path
          d="M43.5285 25.9587C44.1144 22.0259 41.1207 19.915 37.0273 18.5024L38.3517 13.173L35.1091 12.3624L33.8169 17.5473C32.9661 17.3386 32.0913 17.138 31.2164 16.9373L32.5167 11.7203L29.2741 10.9097L27.9418 16.231C27.2354 16.0705 26.5452 15.91 25.871 15.7414V15.7254L21.4004 14.6097L20.5416 18.077C20.5416 18.077 22.9495 18.6308 22.8933 18.6629C24.2096 18.992 24.4423 19.8588 24.4022 20.5491L22.8853 26.6409L20.7664 35.1406C20.6059 35.5419 20.1965 36.1358 19.2815 35.9111C19.3136 35.9593 16.9218 35.3252 16.9218 35.3252L15.3086 39.0413L19.5303 40.0927C20.3169 40.2934 21.0874 40.494 21.8419 40.6867L20.5015 46.0722L23.7441 46.8829L25.0764 41.5535C25.9593 41.7943 26.8181 42.019 27.6608 42.2277L26.3365 47.533L29.5791 48.3436L30.9194 42.9661C36.4495 44.0095 40.607 43.5921 42.3567 38.5918C43.7693 34.5627 42.2845 32.2432 39.379 30.7262C41.4979 30.2366 43.0951 28.8401 43.5205 25.9667L43.5285 25.9587ZM36.1204 36.3445C35.1171 40.3737 28.335 38.1986 26.1359 37.6448L27.9177 30.5095C30.1168 31.0553 37.1638 32.1469 36.1204 36.3445ZM37.1237 25.8945C36.2087 29.5544 30.5663 27.6923 28.7363 27.2429L30.3496 20.7658C32.1796 21.2233 38.0788 22.074 37.1237 25.8945Z"
          fill="#F7931A"
        />
      </g>
      <defs>
        <clipPath id="clip0_2575_3477">
          <rect
            width="59.0965"
            height="59.1045"
            fill="white"
            transform="translate(0.953125 0.612305)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
