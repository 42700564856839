import MerchantsCard from "./MerchantCard";
import suitcaseIcon from "../../../assets/images/suitcaseIcon.png";
import bankIcon from "../../../assets/images/bankIcon.png";
import forBusinessImage1 from "../../../assets/images/forBusinessImage1.png";
import forBusinessImage2 from "../../../assets/images/forBusinessImage2.png";
import { Trans } from "react-i18next";
// import { Trans, useTranslation } from "react-i18next";

const ForBusiness = ({ openContactModel }) => {
  const arr = [
    {
      src: forBusinessImage1,
      srcTwo: bankIcon,
      title: (
        <h2>
          <Trans i18nKey={"forBusiness.qrBank"} />
        </h2>
      ),
      id: "1",
      titleTwo: (
        <>
          <Trans i18nKey={"forBusiness.qrBankTwo"} />
        </>
      ),
      description: (
        <>
          <p>
            <Trans i18nKey={"forBusiness.qrBankDe"} />
          </p>
          <p className="text-[rgba(108,99,255,1)] font-bold mt-2.5">
            <Trans i18nKey={"forBusiness.qrBankForWho"} />
          </p>
          <ul className="mt-2.5 list-disc list-inside">
            <li>
              <Trans i18nKey={"forBusiness.firstBullet"} />
            </li>
            <li>
              <Trans i18nKey={"forBusiness.secondBullet"} />
            </li>
            <li>
              <Trans i18nKey={"forBusiness.thirdBullet"} />
            </li>
            <li>
              <Trans i18nKey={"forBusiness.fourthBullet"} />
            </li>
          </ul>
        </>
      ),
      isImageLeft: true,
      videoButton: true,
      integrationButton: true,
    },
    {
      src: forBusinessImage2,
      srcTwo: suitcaseIcon,
      title: (
        <h2>
          <Trans i18nKey={"forBusiness.qrAgent"} />
        </h2>
      ),
      id: "2",
      titleTwo: (
        <>
          <Trans i18nKey={"forBusiness.qrAgentTwo"} />
        </>
      ),
      description: (
        <p>
          <Trans i18nKey={"forBusiness.qrAgentDesc"} />{" "}
          <span className="text-[rgba(108,99,255,1)] font-bold">
            <Trans i18nKey={"forBusiness.qrAgentSpan"} />
          </span>
        </p>
      ),
      isImageLeft: false,
      videoButton: false,
      integrationButton: false,
    },
  ];

  return (
    <div className=" md:px-[0]">
      <div className="text-center sm:px-[16px] md:px-[0px] sm:mt-[100px] md:mt-[50px] md:mb-[90px]">
        <h4
          className="text-[rgba(108,99,255,1)] sm:text-[20px] md:text-[28px] font-bold"
          style={{ fontVariant: "all-petite-caps" }}
        >
          <Trans i18nKey={"forBusiness.keepzForbusiness"} />
        </h4>
        <h1 className="text-[rgba(23,7,56,1)] sm:text-[20px] md:text-[40px]  font-bold">
          <Trans i18nKey={"forBusiness.getProfit"} />
        </h1>
      </div>

      {arr.map(
        ({
          src,
          srcTwo,
          title,
          titleTwo,
          description,
          isImageLeft,
          id,
          integrationButton,
          // videoButton,
        }) => {
          return (
            <MerchantsCard
              src={src}
              srcTwo={srcTwo}
              title={title}
              titleTwo={titleTwo}
              description={description}
              isImageLeft={isImageLeft}
              id={id}
              btnOnebgColor={"#6C63FF"}
              btnTwobgColor={"bg-[#f0efff]"}
              hoverColor={"hover:border-[#6C63FF]"}
              openContactModel={openContactModel}
              showPriceButton={false}
              integrationButton={integrationButton}
              //   videoButton={videoButton}
            />
          );
        }
      )}
    </div>
  );
};

export default ForBusiness;
