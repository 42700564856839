import React from "react";

export default function ArrowRightIcon(props) {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.2"
        d="M8 6.99804C7.99996 6.84862 7.96801 6.70093 7.90627 6.56486C7.84452 6.42878 7.75443 6.30747 7.642 6.20904L1.707 0.409043C1.61736 0.31389 1.50872 0.238636 1.38814 0.188161C1.26755 0.137686 1.13769 0.113116 1.007 0.116042C0.874838 0.115116 0.743804 0.140399 0.621472 0.190427C0.499141 0.240455 0.387939 0.314236 0.294298 0.407505C0.200657 0.500775 0.126434 0.611681 0.0759189 0.733813C0.0254042 0.855944 -0.000398462 0.986878 1.11597e-06 1.11904C0.000756414 1.25421 0.0284754 1.38787 0.081534 1.51218C0.134593 1.6365 0.211925 1.74899 0.309001 1.84304L5.602 6.99804L0.309 12.154C0.114205 12.3449 0.00308 12.6053 8.79678e-08 12.878C-0.000799103 13.0105 0.0247431 13.1419 0.0751415 13.2644C0.12554 13.387 0.19979 13.4983 0.293578 13.5919C0.387365 13.6855 0.49882 13.7595 0.621465 13.8097C0.744111 13.8598 0.8755 13.8851 1.008 13.884C1.13869 13.887 1.26854 13.8624 1.38913 13.8119C1.50972 13.7614 1.61836 13.6862 1.708 13.591L7.643 7.79104C7.75328 7.69007 7.84181 7.56767 7.9032 7.43132C7.96458 7.29498 7.99752 7.14755 8 6.99804Z"
        fill="#170738"
      />
    </svg>
  );
}
