import React from "react";

export default function ApplePayIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="40"
      viewBox="0 0 40 30"
      {...props}
    >
      <g id="apple-pay" transform="translate(0 -4)">
        <path
          id="Path_28715"
          data-name="Path 28715"
          d="M15.848,12.5H13v7.272h1.132V17.291h1.681a2.314,2.314,0,0,0,2.436-2.4A2.291,2.291,0,0,0,15.848,12.5Zm-.3,3.841H14.132V13.45H15.55A1.367,1.367,0,0,1,17.094,14.9a1.366,1.366,0,0,1-1.544,1.441Zm5.375-2.114a1.949,1.949,0,0,0-2.172,1.681H19.77a1.044,1.044,0,0,1,1.121-.777c.721,0,1.143.343,1.143.971v.424l-1.476.079c-1.348.093-2.093.641-2.093,1.6a1.65,1.65,0,0,0,1.865,1.612,1.91,1.91,0,0,0,1.726-.972h.024v.915h1.04V16.01C23.119,14.936,22.262,14.227,20.925,14.227Zm1.109,3.487a1.315,1.315,0,0,1-1.417,1.245c-.629,0-1.041-.3-1.041-.766,0-.491.389-.766,1.132-.812l1.326-.091Zm4.311,1.04h-.023l-1.407-4.47H23.737l1.991,5.489-.079.285a.945.945,0,0,1-1,.8,1.7,1.7,0,0,1-.343-.023v.891a2.731,2.731,0,0,0,.434.023c1.121,0,1.636-.424,2.1-1.738l2.046-5.729H27.751l-1.407,4.47Z"
          transform="translate(4.41 3.246)"
          fill="#303c42"
          fillRule="evenodd"
        />
        <path
          id="Path_28716"
          data-name="Path 28716"
          d="M37.333,4H2.667A2.7,2.7,0,0,0,0,6.727V31.273A2.7,2.7,0,0,0,2.667,34H37.333A2.7,2.7,0,0,0,40,31.273V6.727A2.7,2.7,0,0,0,37.333,4Zm1.333,27.273a1.351,1.351,0,0,1-1.333,1.364H2.667a1.351,1.351,0,0,1-1.333-1.364V6.727A1.351,1.351,0,0,1,2.667,5.364H37.333a1.351,1.351,0,0,1,1.333,1.364Z"
          fill="#303c42"
          fillRule="evenodd"
        />
        <path
          id="Path_28717"
          data-name="Path 28717"
          d="M12.205,15.629a2.394,2.394,0,0,1,1.146-2.016,2.473,2.473,0,0,0-1.938-1.052c-.825-.082-1.611.486-2.029.486s-1.065-.473-1.748-.46a2.591,2.591,0,0,0-2.192,1.327c-.934,1.623-.238,4.024.672,5.339.445.642.976,1.367,1.673,1.34.672-.026.925-.433,1.735-.433s1.04.433,1.75.42,1.18-.656,1.623-1.3a5.814,5.814,0,0,0,.732-1.509,2.336,2.336,0,0,1-1.423-2.143ZM11.424,10a2.381,2.381,0,0,0-1.562.8,2.215,2.215,0,0,0-.562,1.64,1.967,1.967,0,0,0,1.572-.75A2.336,2.336,0,0,0,11.424,10Z"
          transform="translate(1.679 2.316)"
          fill="#303c42"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
