import { Trans } from "react-i18next";
import Button from "../Button/Button";

const Request = ({ openContactModel, mt = true }) => {
  return (
    <section
      style={{
        background:
          "radial-gradient(994.78% 112.69% at 93.23% 14.81%, #280C62 0%, #170738 100%)",
      }}
      className={` flex justify-between sm:flex-wrap md:flex-nowrap sm:rounded-t-[32px] sm:pt-[40px] ${
        mt && "sm:mt-[72px]"
      }  sm:text-center  md:text-left   md:rounded-[32px]  md:ps-[60px]  ${
        mt && "md:mt-[90px]"
      } md:pt-[60px] `}
    >
      <div className="m-auto p-[16px] mt-[-20px]">
        <h2 className="md2:text-left sm:text-[28px] md2:text-[40px] font-[600] text-[#fff]">
          <Trans i18nKey={"requestAndSend.title"} />
        </h2>
        <p className="sm:hidden md:block md:text-left mt-[28px] text-[16px] text-[#fff]">
          <Trans i18nKey={"requestAndSend.description"} />
        </p>
        <p className="sm:text-center md:hidden mt-[28px] text-[16px] text-[#fff]">
          <Trans i18nKey={"requestAndSend.descriptionSm"} />
        </p>
        <Button
          onClick={openContactModel}
          className="border-2  border-[#6C63FF] solid bg-[#6C63FF] text-[#fff]  hover:text-[#6C63FF] hover:bg-opacity-0 px-[40px] py-[10px] mt-[68px] "
        >
          <Trans i18nKey={"buttons.request"} />
        </Button>
      </div>

      <img
        src="/illustrationcircle.svg"
        className="h-full  rounded-[32px] mt-[-60px] sm:hidden md:block"
        alt="ilistarte svg"
      />
      <img
        src="/svg/ilustartioCircleTwo.svg"
        className="w-full mt-[64px] md:hidden"
        alt=""
      />
    </section>
  );
};

export default Request;
