import terminalScan from "../../../assets/images/terminalScan.png";
import fina from "../../../assets/images/fina.png";
import optimo from "../../../assets/images/optimo.png";
import balansi from "../../../assets/images/balansi.png";
import apex from "../../../assets/images/apex.png";
import tranDetails from "../../../assets/images/tranDetails.png";
import rateBusiness from "../../../assets/images/rateBusiness.png";
import dashboardStat from "../../../assets/images/dashboardStat.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import MerchantReviewCard from "./MerchantReviewCard";
import MerchantsCard from "../MerchantsSection/MerchantCard";
import vintageRed from "../../../assets/images/vintageRed.png";
import georgianBusLogo from "../../../assets/images/georgianBusLogo.png";
import alcoriumLogo from "../../../assets/images/alcoriumLogo.png";
import QrNavigation from "./QrNavigation";
import Price from "../MerchantsSection/Price";
import { Container } from "../LandingPage";
import Request from "../RequestAndSendSection/Request";
import { Trans } from "react-i18next";

const QrTerminal = ({ openContactModal }) => {
  const qrTerminal = [
    {
      src: terminalScan,
      title: (
        <h2 className="text-[rgba(23,7,56,1)] text[28px] gap-0">
          <Trans i18nKey="qrTerminal.qrHowTo.placeQrTerminal" />
        </h2>
      ),
      id: "1",
      description: (
        <>
          <p className="mb-3">
            <Trans i18nKey="qrTerminal.qrHowTo.placeQrTerminalDesc1" />
          </p>
          <p className="mb-3">
            <Trans i18nKey="qrTerminal.qrHowTo.placeQrTerminalDesc2" />
            <span className="text-[rgba(108,99,255,1)]">
              <Trans i18nKey="qrTerminal.qrHowTo.placeQrTerminalDesc2Span" />
            </span>
            <Trans i18nKey="qrTerminal.qrHowTo.placeQrTerminalDesc3" />
          </p>
          <p className="mb-3">
            <Trans i18nKey="qrTerminal.qrHowTo.placeQrTerminalDesc4" />
          </p>
          <div
            className="flex flex-row gap-x-[20px] md:h-[34.71px] 
          sm:h-[24.03px] md:w-[468px] sm:w-[341.85px]"
          >
            <img
              className="object-fill md:w-[57px] sm:w-[39px] md:py-[6.37px] sm:py-[4.51px]"
              src={fina}
              alt="Fina icon"
            />
            <img
              className="object-fill md:w-[122.32px] md:py-[1.35px] sm:py-[1.01px] sm:w-[85px]"
              src={optimo}
              alt="Optimo icon"
            />
            <img
              className="object-fill md:w-[124px] sm:w-[85.85px]"
              src={balansi}
              alt="Balansi icon"
            />
            <img
              className="object-fill md:w-[104px] sm:w-[72px] md:py-[3.85px] sm:py-[2.51px]"
              src={apex}
              alt="apex icon"
            />
          </div>
        </>
      ),
      isImageLeft: false,
      specificSize: false,
    },
    {
      src: tranDetails,
      title: (
        <h2 className="text-[rgba(23,7,56,1)] text[28px] gap-0">
          <Trans i18nKey="qrTerminal.qrHowTo.trackPayments" />
        </h2>
      ),
      id: "1",
      description: (
        <>
          <p className="mb-3">
            <Trans i18nKey="qrTerminal.qrHowTo.trackPaymentsDesc1" />
          </p>
          <p className="mb-3">
            <Trans i18nKey="qrTerminal.qrHowTo.trackPaymentsDesc2" />
          </p>
          <p className="mb-3">
            <Trans i18nKey="qrTerminal.qrHowTo.trackPaymentsDesc3" />
          </p>
        </>
      ),
      isImageLeft: true,
      specificSize: false,
    },
    {
      src: rateBusiness,
      title: (
        <h2 className="text-[rgba(23,7,56,1)] text[28px] gap-0">
          <Trans i18nKey="qrTerminal.qrHowTo.getCustomerFeedback" />
        </h2>
      ),
      id: "1",
      description: (
        <>
          <p className="mb-3">
            <Trans i18nKey="qrTerminal.qrHowTo.getCustomerFeedbackDesc1" />
          </p>
          <p className="mb-3">
            <Trans i18nKey="qrTerminal.qrHowTo.getCustomerFeedbackDesc2" />
          </p>
        </>
      ),
      isImageLeft: false,
      specificSize: false,
    },
    {
      src: dashboardStat,
      title: (
        <h2 className="text-[rgba(23,7,56,1)] text[28px] gap-0">
          <Trans i18nKey="qrTerminal.qrHowTo.manageBranches" />
        </h2>
      ),
      id: "1",
      description: (
        <>
          <p className="mb-3">
            <Trans i18nKey="qrTerminal.qrHowTo.manageBranchesDesc1" />
          </p>
          <p className="mb-3">
            <Trans i18nKey="qrTerminal.qrHowTo.manageBranchesDesc2" />
          </p>
        </>
      ),
      isImageLeft: true,
      specificSize: true,
    },
  ];

  const dataForSwiper = [
    {
      img: vintageRed,
      description: (
        <Trans i18nKey="qrTerminal.qrUserFeedback.vasoKhatiashviliFeedback" />
      ),
      author: <Trans i18nKey="qrTerminal.qrUserFeedback.vasoKhatiashvili" />,
      business: (
        <Trans i18nKey="qrTerminal.qrUserFeedback.operationsDirector" />
      ),
    },
    {
      img: georgianBusLogo,
      description: (
        <Trans i18nKey="qrTerminal.qrUserFeedback.ninoMurvanidzeFeedback" />
      ),
      author: <Trans i18nKey="qrTerminal.qrUserFeedback.tamarMaisuradze" />,
      business: <Trans i18nKey="qrTerminal.qrUserFeedback.generalManager" />,
    },
    {
      img: alcoriumLogo,
      description: (
        <Trans i18nKey="qrTerminal.qrUserFeedback.ninoKobakhidzeFeedback" />
      ),
      author: <Trans i18nKey="qrTerminal.qrUserFeedback.ninoKobakhidze" />,
      business: <Trans i18nKey="qrTerminal.qrUserFeedback.foodStore" />,
    },
  ];

  return (
    <>
      <Container>
        <QrNavigation />
        <div className="flex flex-col items-center"></div>
        <div className="text-center mt-[50px]">
          <h1
            className="text-[rgba(108,99,255,1)] md:text-[40px] sm:text-[28px] font-bold"
            style={{ fontVariant: "all-petite-caps" }}
          >
            <Trans i18nKey="qrTerminal.qrHowTo.title" />
          </h1>
        </div>
        {qrTerminal.map(
          ({
            src,
            srcTwo,
            title,
            titleTwo,
            description,
            isImageLeft,
            id,
            specificSize,
          }) => {
            return (
              <MerchantsCard
                src={src}
                srcTwo={srcTwo}
                title={title}
                titleTwo={titleTwo}
                description={description}
                isImageLeft={isImageLeft}
                id={id}
                btnOnebgColor={"#6C63FF"}
                btnTwobgColor={"bg-[#f0efff]"}
                hoverColor={"hover:border-[#6C63FF]"}
                showWithShadow={id != "3" && id != "6"}
                isIcon={false}
                shoWButtons={false}
                otherSize={true}
                specificSize={specificSize}
              />
            );
          }
        )}
      </Container>
      <Price openContactModel={openContactModal} />
      <Container>
        <div className="md:pt-[70px] md:pb-[90px] sm:py-[40px]  sm:px-[16px] md:px-[0px]">
          <div className="text-center ">
            <h1
              className="text-[rgba(108,99,255,1)]   md:text-[40px] sm:text-[25px]  font-bold"
              style={{ fontVariant: "all-petite-caps", lineHeight: "30px" }}
            >
              <Trans i18nKey="qrTerminal.qrUserFeedback.userFeedbackAboutUs" />
            </h1>
          </div>
          <div>
            <Swiper
              pagination={{
                el: "#my-custom-pagination-div2",
                clickable: true,
                renderBullet: function (index, className) {
                  return '<span class="' + className + '">' + "</span>";
                },
              }}
              className="sm:mt-[30px] md:mt-[60px] rounded-[16px] shadow-[0px_5px_62.87px_0px_rgba(108,99,255,0.2)]"
              modules={[Pagination]}
              slidesPerView={2}
              spaceBetween={20}
              breakpoints={{
                200: {
                  slidesPerView: 1,
                },
                600: {
                  slidesPerView: 3,
                },
              }}
            >
              {dataForSwiper.map(({ img, description, author, business }) => {
                return (
                  <SwiperSlide>
                    <MerchantReviewCard
                      img={img}
                      description={description}
                      author={author}
                      business={business}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div
            className="flex gap-[10px] justify-center mt-[40px]"
            id="my-custom-pagination-div2"
          />
        </div>
        <Request mt={false} />
      </Container>
    </>
  );
};

export default QrTerminal;
