function GooglePay(props) {
  return (
    <svg
      width="38"
      height="26"
      viewBox="0 0 38 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.75"
        y="1.25"
        width="36.5"
        height="23.5"
        rx="2.25"
        stroke="#170738"
        stroke-width="1.5"
      />
      <path
        d="M18.2072 12.8561V16.2263H17.1201V7.89136H19.9467C20.6352 7.89136 21.2875 8.14503 21.7948 8.61613C22.3022 9.051 22.5559 9.70329 22.5559 10.3918C22.5559 11.0804 22.3022 11.6964 21.7948 12.1675C21.2875 12.6386 20.6714 12.8923 19.9467 12.8923L18.2072 12.8561ZM18.2072 8.90604V11.8051H20.0192C20.4178 11.8051 20.8164 11.6602 21.0701 11.3703C21.6499 10.8267 21.6499 9.92072 21.1063 9.37714L21.0701 9.34091C20.7802 9.051 20.4178 8.8698 20.0192 8.90604H18.2072Z"
        fill="#170738"
      />
      <path
        d="M25.0564 10.3555C25.8536 10.3555 26.4697 10.5729 26.9408 11.0078C27.4119 11.4426 27.6293 12.0224 27.6293 12.7472V16.2261H26.6146V15.4289H26.5784C26.1435 16.0812 25.5275 16.4073 24.8027 16.4073C24.1866 16.4073 23.6431 16.2261 23.2082 15.8638C22.8096 15.5014 22.5559 14.994 22.5559 14.4504C22.5559 13.8706 22.7733 13.3995 23.2082 13.0371C23.6431 12.6747 24.2591 12.5298 24.9839 12.5298C25.6362 12.5298 26.1435 12.6385 26.5422 12.8922V12.6385C26.5422 12.2761 26.3972 11.9137 26.1073 11.6963C25.8174 11.4426 25.455 11.2977 25.0564 11.2977C24.4403 11.2977 23.9692 11.5513 23.6431 12.0587L22.7009 11.4789C23.2807 10.7179 24.0417 10.3555 25.0564 10.3555ZM23.6793 14.4867C23.6793 14.7766 23.8243 15.0303 24.0417 15.1752C24.2954 15.3564 24.5853 15.4651 24.8752 15.4651C25.3101 15.4651 25.7449 15.2839 26.0711 14.9578C26.4334 14.6316 26.6146 14.233 26.6146 13.7981C26.2885 13.5445 25.8174 13.3995 25.2013 13.3995C24.7665 13.3995 24.4041 13.5082 24.1142 13.7257C23.8243 13.9069 23.6793 14.1605 23.6793 14.4867Z"
        fill="#170738"
      />
      <path
        d="M33.5 10.5366L29.9123 18.7628H28.8252L30.166 15.8999L27.8105 10.5729H28.9701L30.6733 14.6678H30.7096L32.3766 10.5729H33.5V10.5366Z"
        fill="#170738"
      />
      <path
        d="M13.8949 12.1313C13.8949 11.8051 13.8586 11.479 13.8224 11.1528H9.29254V13.001H11.8655C11.7568 13.5808 11.4306 14.1244 10.9233 14.4506V15.6464H12.4815C13.3875 14.8129 13.8949 13.5808 13.8949 12.1313Z"
        fill="#170738"
      />
      <path
        d="M9.29252 16.806C10.5971 16.806 11.6843 16.3711 12.4815 15.6464L10.9233 14.4505C10.4884 14.7404 9.94482 14.9216 9.29252 14.9216C8.06041 14.9216 6.97325 14.0881 6.61086 12.9285H5.01636V14.1606C5.84985 15.7913 7.48059 16.806 9.29252 16.806Z"
        fill="#170738"
      />
      <path
        d="M6.6109 12.9284C6.39347 12.3486 6.39347 11.6963 6.6109 11.0803V9.84814H5.0164C4.32787 11.189 4.32787 12.7835 5.0164 14.1605L6.6109 12.9284Z"
        fill="#170738"
      />
      <path
        d="M9.29254 9.12342C9.98107 9.12342 10.6334 9.37709 11.1407 9.8482L12.5178 8.47113C11.6481 7.67387 10.4884 7.20277 9.32878 7.23901C7.51684 7.23901 5.84986 8.25369 5.05261 9.88444L6.64711 11.1166C6.97326 9.95691 8.06042 9.12342 9.29254 9.12342Z"
        fill="#170738"
      />
    </svg>
  );
}

export default GooglePay;
