import { commonServiceInstance } from "api";
import { SYSTEM_STATUS_URL } from "helpers/fetcher/fetcher.config";
import useSWR from "swr";

const useSystemStatus = () => {
  return useSWR(SYSTEM_STATUS_URL, async (url) => {
    const response = await commonServiceInstance.get(url);
    return response.data.value;
  });
};

export default useSystemStatus;
